import type { TagToAssetsMap } from './types';

function flatMapAndRemoveDuplicates(nestedArrayOfIds: string[][]) {
  // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
  const arrayOfIds = nestedArrayOfIds.reduce((result, assetIds) => [...result, ...assetIds], []);
  return Array.from(new Set(arrayOfIds));
}

export const getAssetsByTagIds = (tagToAssetMap: TagToAssetsMap, tagIds: string[]) => {
  if (tagIds.length === 0) {
    return undefined;
  }

  const filteredAssetIds = Object.entries(tagToAssetMap)
    .filter(([tagId]) => tagIds.includes(tagId))
    .map(([, assetIds]) => assetIds);

  return flatMapAndRemoveDuplicates(filteredAssetIds);
};
