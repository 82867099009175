import { FormattedMessage } from 'react-intl';
import { DEFAULT_TOOLTIP_DELAY } from '../../../config/config';
import { getFreeTrialTrackingAttributes } from '../../utils/googleTagManagerAttributes';
import { OverlayTrigger, Tooltip } from '../../utils/tooltipReExports';

interface Props {
  termsAccepted: boolean;
  atLeastOneAssetBookable: boolean;
  onClick: () => void;
}

export const RenewDialogUpdateButton = (props: Props) => {
  const { termsAccepted, onClick, atLeastOneAssetBookable } = props;

  if (termsAccepted && atLeastOneAssetBookable) {
    return (
      <button className='btn btn-primary' onClick={onClick} {...getFreeTrialTrackingAttributes('click')}>
        <FormattedMessage id={'marketplace.contracts.updateRenewal'} />
      </button>
    );
  }

  if (!atLeastOneAssetBookable) {
    return <RenewDialogUpdateButtonDisabled labelId={'marketplace.conditionsNotMet'} />;
  }

  return <RenewDialogUpdateButtonDisabled labelId={'marketplace.termsAndConditionsNotAccepted.hint'} />;
};

const RenewDialogUpdateButtonDisabled = ({ labelId }: { labelId: string }) => {
  const tooltip = (
    <Tooltip className={'width-auto max-width-300'}>
      <FormattedMessage id={labelId} />
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement={'top'}
      trigger={OverlayTrigger.TRIGGER_HOVER}
      overlay={tooltip}
      delay={DEFAULT_TOOLTIP_DELAY}
    >
      <span className={'display-inline-block'}>
        <button className={'btn btn-primary disabled'} disabled>
          <FormattedMessage id={'marketplace.contracts.updateRenewal'} />
        </button>
      </span>
    </OverlayTrigger>
  );
};
