import type { Tag, TagToAssetsMap } from '../../customerCenter/assetOverview/redux/types';
import type { ApiAssetInfo, ApiTags } from './tag.types';

export const TAG_UNASSIGNED = 'TAG_UNASSIGNED';

export function mapTags(apiTags: ApiTags): Tag[] {
  return apiTags.items;
}

function addAssetToMap(tagToAssetMap: TagToAssetsMap, tagId: string, assetId: string) {
  if (tagToAssetMap[tagId] === undefined) {
    tagToAssetMap[tagId] = [];
  }
  tagToAssetMap[tagId].push(assetId);
}

export function mapAssetInfo(assetInfos: ApiAssetInfo[]): TagToAssetsMap {
  const tagToAssetMap: TagToAssetsMap = {};
  for (const asset of assetInfos) {
    const tags = asset._embedded.tags.items;
    if (tags.length === 0) {
      addAssetToMap(tagToAssetMap, TAG_UNASSIGNED, asset.id);
    }

    for (const tag of tags) {
      addAssetToMap(tagToAssetMap, tag.id, asset.id);
    }
  }
  return tagToAssetMap;
}
