import isEmpty from 'lodash/isEmpty';
import type { ResourceUsage } from '../redux/types';

export function calculatePriceForResources(enrichedResourceUsages: ResourceUsage[]): number | undefined {
  if (isEmpty(enrichedResourceUsages)) {
    return undefined;
  }
  // allowed because we validate for isNaN in line below
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  const triedSum = enrichedResourceUsages.map(resourceUsage => resourceUsage.price! * 100).reduce((a, b) => a + b, 0);
  return Number.isNaN(triedSum) ? undefined : Math.round(triedSum) / 100;
}
