import Multiselect from '@rio-cloud/rio-uikit/lib/es/Multiselect';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { fetchAllProductDetails } from '../../api/product/productCalls';
import { historyActions } from './redux/history.redux';

interface Props {
  setSelectedProductIds: (ids: string[]) => void;
}

const mapToOption = ({ sku, name }: { sku: string; name: string }) => ({ id: sku, label: name, text: name });

const ProductSelect = ({ setSelectedProductIds }: Props) => {
  const [productOptions, setProductOptions] = useState<Array<{ id: string; label: string; header?: boolean }>>();
  const intl = useIntl();

  useEffect(() => {
    // As soon as the products are used somewhere else -> store them in redux
    fetchAllProductDetails()
      .then(products => {
        const activeProducts = products.filter(({ legacy }) => !legacy).map(mapToOption);
        const legacyProducts = products.filter(({ legacy }) => legacy).map(mapToOption);

        return [
          ...activeProducts,
          { id: 'leg', label: intl.formatMessage({ id: 'marketplace.products.old' }), header: true },
          ...legacyProducts,
        ];
      })
      .then(setProductOptions);
  }, []);

  return (
    <Multiselect
      className={'max-width-500'}
      placeholder={intl.formatMessage({ id: 'marketplace.history.service.select.placeholder' })}
      options={productOptions}
      onChange={(ids: string[]) => setSelectedProductIds([...ids])}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSelectedProductIds: (ids: string[]) => dispatch(historyActions.setSelectedProductIds(ids)),
});

export const ProductSelectContainer = connect(null, mapDispatchToProps)(ProductSelect);
