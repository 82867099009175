import { isRIO4RentalProductId } from '../../../config/rio4rentalProduct';
import type { ProductInfo } from '../redux/types';
import { ProductCellBookableContainer } from './ProductCellBookable';
import { ProductCellBookedContainer } from './ProductCellBooked';
import { ProductCellBookedContractContainer } from './ProductCellBookedContract';
import { ProductCellExternallyManaged } from './ProductCellExternallyManaged';
import { ProductCellNotBookableContainer } from './ProductCellNotBookable';
import { ProductCellPendingContainer } from './ProductCellPending';

interface Props {
  product: ProductInfo;
  assetId: string;
  assetName: string;
}

export const ProductCell = (props: Props) => {
  const { product, assetId, assetName } = props;

  if (isRIO4RentalProductId(product.productId)) {
    return <ProductCellExternallyManaged product={product} />;
  }
  if (product.booked && !product.isPending && product.hasContractPeriod && !product.bookable) {
    return <ProductCellBookedContractContainer product={product} assetId={assetId} />;
  }
  if (product.booked && !product.isPending && !product.hasContractPeriod) {
    return <ProductCellBookedContainer product={product} assetId={assetId} assetName={assetName} />;
  }
  if (product.isPending) {
    return <ProductCellPendingContainer product={product} />;
  }
  if (product.bookable) {
    return <ProductCellBookableContainer product={product} assetId={assetId} assetName={assetName} />;
  }

  return <ProductCellNotBookableContainer product={product} />;
};
