import { FormattedMessage } from 'react-intl';
import type { MessageKey } from '../../../../configuration';
import { ProductType } from './product';

interface Props {
  productType: ProductType | undefined;
  assetBasedId: MessageKey;
  userBasedId: MessageKey;
  values?: Record<string, string | number | boolean>;
}

export function ProductTypedFormattedMessage(props: Props) {
  const { productType, assetBasedId, userBasedId, values } = props;
  if (productType === ProductType.USER_BASED) {
    return <FormattedMessage id={userBasedId} values={{ ...values }} />;
  }
  return <FormattedMessage id={assetBasedId} values={{ ...values }} />;
}
