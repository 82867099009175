import { useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { DiscountBadgeRaw } from '../../../common/discount/DiscountBadge';
import {
  type DiscountIdentifier,
  generateDiscountHint,
  mapStateToFindDiscountProps,
} from '../../../common/discount/DiscountService';
import type { AssetDiscount } from '../../../common/discount/redux/types';
import { useIsFleetUser } from '../../../common/permissions/hooks';
import { OverlayTrigger, Tooltip } from '../../../common/utils/tooltipReExports';
import { DEFAULT_TOOLTIP_DELAY } from '../../../config/config';
import { openCheckoutPopup } from '../../serviceOverview/services/checkoutService';
import { GtmPromotion } from '../../serviceOverview/services/gtmPromotion';
import type { ProductInfo } from '../redux/types';
import { IconButtonPrimary, IconButtonSuccess } from './productCellConfig';

interface Props {
  product: Pick<ProductInfo, 'productId' | 'level' | 'productName' | 'bookableLevels'>;
  assetId: string;
  assetName: string;
  findDiscount: (discountIdentifier: DiscountIdentifier) => AssetDiscount | undefined;
}

export const ProductCellBookable = ({ product, assetId, assetName, findDiscount }: Props) => {
  const intl = useIntl();
  const [isInFocus, setIsInFocus] = useState(false);
  const { productId, level, productName, bookableLevels } = product;
  const { callback, checkoutUrl } = openCheckoutPopup(
    productId,
    GtmPromotion.assetOverviewAddSingle,
    bookableLevels[0],
    [assetId]
  );
  const fleetUser = useIsFleetUser();
  const discount = findDiscount({ assetId, productId, productLevel: level });
  const discountClassName = isInFocus ? IconButtonSuccess.replace(' btn-icon-only', '') : IconButtonSuccess;
  const classNames = discount ? discountClassName : IconButtonPrimary;
  const buttonClassNames = fleetUser ? `${classNames} disabled` : classNames;

  const overlay = (
    <Tooltip id='tooltip' className={'width-auto max-width-300'}>
      <div>
        {intl.formatMessage({ id: 'marketplace.bookServiceForAsset' }, { service: productName, asset: assetName })}
      </div>
      {discount && (
        <div>
          <b>{generateDiscountHint(discount, intl)}</b>
        </div>
      )}
    </Tooltip>
  );

  const onMouseOver = () => {
    if (!isInFocus) {
      setIsInFocus(true);
    }
  };

  return (
    <span onMouseOver={onMouseOver} onMouseLeave={() => setIsInFocus(false)}>
      <OverlayTrigger
        trigger={OverlayTrigger.TRIGGER_HOVER}
        placement='top'
        overlay={overlay}
        delay={DEFAULT_TOOLTIP_DELAY}
      >
        <a className={buttonClassNames} onClick={callback} href={checkoutUrl}>
          <span className='rioglyph rioglyph-shopping-cart' />
          {isInFocus && discount && <DiscountBadgeRaw percentage={discount.discountPercentage} />}
        </a>
      </OverlayTrigger>
    </span>
  );
};

export const ProductCellBookableContainer = connect(mapStateToFindDiscountProps)(ProductCellBookable);
