import { useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  type DiscountIdentifier,
  generateDiscountHint,
  mapStateToFindDiscountProps,
} from '../../../common/discount/DiscountService';
import type { AssetDiscount } from '../../../common/discount/redux/types';
import { isFreeTrialLevel } from '../../../common/termedLevel/freeTrialLevel';
import { OverlayTrigger, Tooltip } from '../../../common/utils/tooltipReExports';
import { DEFAULT_TOOLTIP_DELAY } from '../../../config/config';
import { AssetOverviewGotoContractsDialog } from '../AssetOverviewGotoContractsDialog';
import { AssetOverviewGotoFreeTrialDialog } from '../AssetOverviewGotoFreeTrialDialog';
import type { ProductInfo } from '../redux/types';
import { IconButtonDefault, IconButtonSecondary, IconButtonSuccess } from './productCellConfig';

interface Props {
  product: ProductInfo;
  assetId: string;
  findDiscount: (discountIdentifier: DiscountIdentifier) => AssetDiscount | undefined;
}

const ProductCellBookedContract = ({ product, assetId, findDiscount }: Props) => {
  const [hover, setHover] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const intl = useIntl();
  const { productId, level } = product;

  const isFreeTrial = isFreeTrialLevel(level);
  const discount = findDiscount({ assetId, productId, productLevel: level });

  let buttonClasses: string;
  if (discount) {
    buttonClasses = IconButtonSuccess;
  } else if (isFreeTrial) {
    buttonClasses = IconButtonSecondary;
  } else {
    buttonClasses = IconButtonDefault;
  }

  const tooltipTranslationKey = isFreeTrial
    ? 'marketplace.freeTrial.gotoTab'
    : 'marketplace.myServices.manageContracts';

  const overlay = (
    <Tooltip id='tooltip' className={'width-auto max-width-300'}>
      <div>{intl.formatMessage({ id: tooltipTranslationKey })}</div>
      {discount && (
        <div>
          <b>{generateDiscountHint(discount, intl)}</b>
        </div>
      )}
    </Tooltip>
  );

  return (
    <span>
      {showDialog && isFreeTrial && <AssetOverviewGotoFreeTrialDialog closeDialog={() => setShowDialog(false)} />}
      {showDialog && !isFreeTrial && <AssetOverviewGotoContractsDialog closeDialog={() => setShowDialog(false)} />}
      <OverlayTrigger
        trigger={OverlayTrigger.TRIGGER_HOVER}
        placement='top'
        overlay={overlay}
        delay={DEFAULT_TOOLTIP_DELAY}
      >
        <span>
          <button
            className={buttonClasses}
            onClick={() => setShowDialog(true)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <span className={`rioglyph ${hover ? 'rioglyph-lock' : 'rioglyph-ok'} margin-0`} />
          </button>
        </span>
      </OverlayTrigger>
    </span>
  );
};

export const ProductCellBookedContractContainer = connect(mapStateToFindDiscountProps)(ProductCellBookedContract);
