import type { UserManager } from 'oidc-client-ts';
import { config } from '../config';
import type { messagesEN } from '../features/test/testUtils';
import { DEFAULT_LOCALE, extractLanguage, type supportedLocaleMap } from './lang/lang';
import langReducer, { getDisplayMessages, getLocale } from './lang/langSlice';
import { configureFetchDisplayMessages } from './lang/services';
import {
  type SessionRenewedResult,
  configureMockUserManager,
  configureUserManager,
  createUserManager,
} from './login/login';
import loginReducer, {
  getUserAccount,
  getUserTenant,
  isUserSessionExpired,
  userProfileObtained,
  userSessionExpired,
  userSessionRenewed,
} from './login/loginSlice';
import handleLoginRedirect from './login/redirect';
import configReducer from './setup/configSlice';
import { attemptInitialSignIn } from './setup/oauth';
import { reportErrorToSentry } from './setup/sentry';
import { store } from './setup/store';
import { trace } from './setup/trace';
import { accessToken } from './tokenHandling/accessToken';
import tokenHandlingReducer, {
  accessTokenStored,
  getAccessToken,
  getAccountId,
  getIdToken,
  idTokenStored,
} from './tokenHandling/tokenSlice';

type AllLocales = typeof supportedLocaleMap;
export type SupportedLocale = keyof AllLocales;

export type DisplayMessages = { [K in keyof typeof messagesEN]: string };
export type MessageKey = keyof DisplayMessages;

export const main = async (renderApp: Function) => {
  const fetchDisplayMessages = configureFetchDisplayMessages(store);

  // We want the `<html lang>` attribute to be synced with the
  // language currently displayed
  store.subscribe(() => {
    const lang = extractLanguage(getLocale(store.getState()));
    const html = document.querySelector('html');

    if (html && lang && html.getAttribute('lang') !== lang) {
      html.setAttribute('lang', lang);
    }
  });

  const oauthConfig = {
    onSessionExpired: () => {
      trace('index.onTokenExpired');

      accessToken.discardAccessToken();
      store.dispatch(userSessionExpired());
    },
    onSessionRenewed: (result: SessionRenewedResult) => {
      trace('index.onTokenRenewed', result);

      accessToken.saveAccessToken(result.accessToken);
      store.dispatch(accessTokenStored(result.accessToken));
      store.dispatch(idTokenStored(result.idToken));
      store.dispatch(userProfileObtained(result.profile));

      store.dispatch(userSessionRenewed());

      // You will need to get the user language by yourself then
      // you may fetch the suitable messages. Depending
      // on when and from where you fetch the user settings you might
      // want to employ a loading spinner while the request is ongoing.
      fetchDisplayMessages(result.locale);
    },
  };

  const isAllowedToMockAuth = import.meta.env.DEV;
  const userManager =
    isAllowedToMockAuth && config.login.mockAuthorization
      ? configureMockUserManager(oauthConfig)
      : configureUserManager(oauthConfig, createUserManager());

  const signinSilent = userManager.signinSilent.bind(userManager);
  document.addEventListener('rio.core.user.language.changed', () => signinSilent());
  document.addEventListener('rio.core.user.profile.changed', () => signinSilent());

  try {
    await userManager.clearStaleState();
    await attemptInitialSignIn(userManager as UserManager);
    renderApp();
  } catch (error) {
    trace('could not start application', error);
    reportErrorToSentry(error);
  }
};

export {
  configReducer,
  getAccessToken,
  getUserTenant,
  getIdToken,
  getAccountId,
  getDisplayMessages,
  getLocale,
  getUserAccount,
  handleLoginRedirect,
  isUserSessionExpired,
  langReducer,
  loginReducer,
  store,
  tokenHandlingReducer,
  DEFAULT_LOCALE,
};
