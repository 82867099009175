import SteppedProgressBar from '@rio-cloud/rio-uikit/lib/es/SteppedProgressBar';
import { useNavigate } from 'react-router';

interface Props {
  labels: Array<{ icon: string; label?: JSX.Element }>;
  selectedStepNumber: number;
  disableFollowingPages: number;
  convertStepNumberToPath: (stepNumber: number) => string;
}

export const CheckoutNavigationBar = (props: Props) => {
  const navigate = useNavigate();

  return (
    <SteppedProgressBar
      selectedStepNumber={props.selectedStepNumber}
      onSelectedChanged={(selectedKey: number) => {
        // Workaround to make the "link" in the navigation truly disabled
        if (selectedKey <= props.disableFollowingPages) {
          navigate(props.convertStepNumberToPath(selectedKey));
        }
      }}
      labels={props.labels}
      disableFollowingPages={props.disableFollowingPages}
      mobileBreakpoint={false}
    />
  );
};
