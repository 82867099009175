import * as t from 'io-ts';
import { PeriodCodec } from '../periods';
import { ApiProductTypeCodec } from '../product/product.types';

const ApiActivationSummaryRatePlanCodec = t.intersection([
  t.type({
    name: t.string,
    currency: t.string,
    price: t.number,
  }),
  t.partial({
    level: t.string,
    contract_period: PeriodCodec,
    variant_name: t.string,
    legacy: t.boolean,
  }),
]);

const ApiActivationSummaryProductCodec = t.intersection([
  t.type({
    name: t.string,
    legacy: t.boolean,
    product_type: ApiProductTypeCodec,
  }),
  t.partial({
    rate_plans: t.array(ApiActivationSummaryRatePlanCodec),
  }),
]);

const ApiActivationSummaryItemCodec = t.intersection([
  t.type({
    product_id: t.string,
    amount: t.number,
  }),
  t.partial({
    _embedded: t.partial({ product: ApiActivationSummaryProductCodec }),
  }),
]);
export const ApiActivationSummaryCodec = t.type({
  activations: t.array(ApiActivationSummaryItemCodec),
});

export type ApiActivationSummaryRatePlan = t.TypeOf<typeof ApiActivationSummaryRatePlanCodec>;
export type ApiActivationSummaryProduct = t.TypeOf<typeof ApiActivationSummaryProductCodec>;
export type ApiActivationSummaryItem = t.TypeOf<typeof ApiActivationSummaryItemCodec>;
export type ApiActivationSummary = t.TypeOf<typeof ApiActivationSummaryCodec>;
