import { isPresent } from '../../../../utils/typescriptUtil';
import type { DatedInvoices, Invoice } from './redux/types';

export function getInvoiceNumber(invoices: DatedInvoices[], invoiceId: string) {
  const foundDatedInvoices = invoices.find(invoice => invoice.invoices.map(it => it.id).includes(invoiceId));
  const match = foundDatedInvoices?.invoices.find(invoice => invoice.id === invoiceId);
  return match ? match.invoiceNumber : undefined;
}

export function containsInvoices(invoices: DatedInvoices[]) {
  return !!invoices.find(invoiceMonth => invoiceMonth.invoices.length > 0);
}

export function getTotalByMonth(invoices: Invoice[]) {
  return invoices
    .map(item => item.amount)
    .filter(isPresent)
    .reduce((accumulator, value) => accumulator + value, 0);
}
