import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import type { TermedLevel } from '../../common/termedLevel/types';
import { ContractPanelHeader } from './ContractPanelHeader';
import { ContractTable } from './ContractTable';
import { RenewButtonContainer } from './RenewButton';
import { mapResourceTypeToProductType } from './mapResourceTypeToProductType';

interface Props {
  contracts: TermedLevel[];
  open: boolean;
}

export const ContractPanel = (props: Props) => {
  const { contracts, open } = props;
  const productName = contracts[0].productName;
  const productId = contracts[0].productId;
  const productType = mapResourceTypeToProductType(contracts[0].resourceType);

  return (
    <ExpanderPanel
      title={
        <ContractPanelHeader productName={productName} productId={productId} numberOfContracts={contracts.length} />
      }
      bsStyle='default'
      bodyClassName='padding-0'
      className='ServiceOverviewItem'
      open={open}
    >
      <ContractTable productId={productId} contracts={contracts} resourceType={productType} />
      <span className='margin-15 pull-right'>
        <RenewButtonContainer productId={productId} />
      </span>
    </ExpanderPanel>
  );
};
