import { useParams } from 'react-router';
import { getEasyOnboardingConnectionsUrl } from '../../config/pathURL/config';

export const InboundConnectionsTab = () => {
  const params = useParams();
  const subPath = params['*'];
  const baseUrl = getEasyOnboardingConnectionsUrl();
  return (
    <iframe data-testid='eobs-inbound-connections' src={`${baseUrl}/${subPath}`} className={'unstyled full-window'} />
  );
};
