import type { Product, ViewOnlyProduct } from '../../checkout/redux/types';
import type { TermedProductInfo } from '../../common/termedLevel/redux/types';
import { isServiceVisible } from '../../common/utils/isServiceVisible';
import { getMarketplaceBackend } from '../../config/pathURL/config';
import { decodeJson, jsonOrReject } from '../api';
import { mapProductType } from '../mapProductType';
import { getRequest } from '../requests';
import { ApiProductCodec, ApiProductsCodec } from './product.types';
import { mapContractProductDetails, mapProduct } from './productMapper';

export function fetchAllProductDetails(): Promise<Array<ViewOnlyProduct & { legacy: boolean }>> {
  return fetch(`${getMarketplaceBackend()}/products`, getRequest())
    .then(jsonOrReject)
    .then(json =>
      decodeJson(ApiProductsCodec)(json)
        .products.filter(product => isServiceVisible({ sku: product.id }))
        .map(({ name, id, legacy, product_type }) => ({
          sku: id,
          name,
          legacy,
          productType: mapProductType(product_type),
        }))
    );
}

export function fetchAllNonLegacyProducts(): Promise<Product[]> {
  return fetch(`${getMarketplaceBackend()}/products`, getRequest())
    .then(jsonOrReject)
    .then(json =>
      decodeJson(ApiProductsCodec)(json)
        .products.filter(product => isServiceVisible({ sku: product.id }))
        .filter(p => !p.legacy)
        .map(mapProduct)
    );
}

export function fetchProductDetails(sku: string): Promise<Product> {
  return fetch(`${getMarketplaceBackend()}/products/${sku}`, getRequest())
    .then(jsonOrReject)
    .then(json => mapProduct(decodeJson(ApiProductCodec)(json)));
}

export function fetchContractProductDetails(sku: string): Promise<TermedProductInfo> {
  return fetch(`${getMarketplaceBackend()}/products/${sku}`, getRequest())
    .then(jsonOrReject)
    .then(json => mapContractProductDetails(decodeJson(ApiProductCodec)(json)));
}
