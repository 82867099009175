import { FormattedMessage, useIntl } from 'react-intl';
import { formatPrice } from '../../../common/utils/currencyUtil';
import { getProductLabel } from '../../../common/utils/productLabelUtil';
import { OverlayTrigger, Tooltip } from '../../../common/utils/tooltipReExports';
import type { UsageUnit } from '../redux/types';

type UsageSummaryTableUsageCellProps = { usageUnit: UsageUnit | undefined };

export const UsageSummaryTableUsageCell = (props: UsageSummaryTableUsageCellProps) => {
  const { usageUnit } = props;
  const intl = useIntl();

  if (!usageUnit) {
    return (
      <td className={'text-center white-space-nowrap'}>
        <span className={'text-color-light'}>
          <FormattedMessage id={'marketplace.usage.summary.notBooked'} />
        </span>
      </td>
    );
  }

  const usageUnitPrice = usageUnit.price;
  const usageUnitTotalPrice = usageUnit.totalPrice;
  if (usageUnitPrice === undefined || usageUnitTotalPrice === undefined) {
    return (
      <td className={'text-center white-space-nowrap'}>
        <span>{`${usageUnit.amount} x ${intl.formatMessage({ id: 'marketplace.products.label.price' })}`}</span>
      </td>
    );
  }

  const price = formatPrice(intl, usageUnitPrice, usageUnit.currency);
  const normalizedPrice = formatPrice(intl, usageUnitTotalPrice / usageUnit.amount, usageUnit.currency);
  const displayString = `${usageUnit.amount} x ${normalizedPrice}`;

  const totalPrice = formatPrice(intl, usageUnit.amount * usageUnitPrice, usageUnit.currency);
  const tooltipString = `${usageUnit.amount} x ${price} = ${totalPrice}`;
  const productLabel = getProductLabel(usageUnit, intl);
  const discountString = intl.formatMessage({ id: 'marketplace.discount' });

  const tooltip = (
    <Tooltip id={'assetId'} className={'width-auto max-width-300'}>
      {productLabel ? <div className={'white-space-nowrap'}>{productLabel}</div> : null}
      <div>{tooltipString}</div>
      {(usageUnit.discounts || []).map((discount, index) => (
        <div key={index}>
          {`${discount.amount} x ${discount.percentage}% ${discountString} = ${formatPrice(
            intl,
            ((-1 * (discount.amount * usageUnitPrice)) / 100) * discount.percentage,
            usageUnit.currency
          )}  `}
        </div>
      ))}
      {usageUnit.discounts && (
        <div>
          {intl.formatMessage({ id: 'marketplace.usage.summary.totalSum' })}:{' '}
          {formatPrice(intl, usageUnitTotalPrice, usageUnit.currency)}
        </div>
      )}
    </Tooltip>
  );

  return (
    <td className={'text-center white-space-nowrap'}>
      <OverlayTrigger trigger={OverlayTrigger.TRIGGER_HOVER} placement={'right'} overlay={tooltip}>
        <span>{displayString}</span>
      </OverlayTrigger>
    </td>
  );
};
