import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import { connect } from 'react-redux';
import type { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { useIsFleetUser } from '../../common/permissions/hooks';
import { useCheckboxState } from '../../common/utils/checkboxUtil';
import { getAssetOverviewFilteredRows } from './assetOverviewSearchService';
import { assetOverviewActions, getSelectedAssetIdsInAssetOverview } from './redux/assetOverview.redux';
import type { AssetOverviewRow } from './redux/types';

interface Props {
  selectedAssets: unknown[];
  displayedAssets: AssetOverviewRow[];
  setSelectedAssets: (selectedAssetIds: string[]) => void;
}

function AllAssetsCheckbox({ selectedAssets, displayedAssets, setSelectedAssets }: Props) {
  const checked = useCheckboxState(displayedAssets, selectedAssets);
  const assetsToSelect = checked ? [] : displayedAssets.map(asset => asset.assetId);
  const fleetUser = useIsFleetUser();

  return (
    <Checkbox
      checked={Boolean(checked)}
      indeterminate={checked === undefined}
      onClick={() => setSelectedAssets(assetsToSelect)}
      disabled={fleetUser}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  displayedAssets: getAssetOverviewFilteredRows(state).displayRows,
  selectedAssets: getSelectedAssetIdsInAssetOverview(state),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  setSelectedAssets: (selectedAssetIds: string[]) => dispatch(assetOverviewActions.setSelectedAssets(selectedAssetIds)),
});

export const AllAssetsCheckboxContainer = connect(mapStateToProps, mapDispatchToProps)(AllAssetsCheckbox);
