import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import type { RootState } from '../../../configuration/setup/store';
import { CheckoutConfirmationPageContainer } from './CheckoutConfirmationPage';
import { CheckoutPaymentPageContainer } from './CheckoutPaymentPage';
import { CheckoutPreviewPageContainer } from './CheckoutPreviewPage';
import { CheckoutResourceSelectionContainer } from './CheckoutResourceSelection';
import { CheckoutNavigationBar } from './components/CheckoutNavigationBar';
import { RedirectToWorkshopAssignment } from './components/RedirectToWorkshopAssignment';
import { useCheckoutRouteUpdatingHook } from './deeplinking/checkoutRouteUpdatingHook';
import { getSelectedResourceIds } from './resourceSelection/redux/resourceSelection.redux';

interface Props {
  resourceIds: string[];
  stepNumber: number;
  maximumVisitedStep: number;
}

const serviceCareRoutes = [
  {
    icon: '1',
    label: <FormattedMessage id='intl-msg:navigations.checkout.selection' />,
  },
  {
    icon: '2',
    label: <FormattedMessage id='intl-msg:navigations.checkout.assignment' />,
  },
  {
    icon: '3',
    label: <FormattedMessage id='intl-msg:navigations.checkout.payment' />,
  },
  {
    icon: '4',
    label: <FormattedMessage id='intl-msg:navigations.checkout.confirmation' />,
  },
];

const Checkout3Steps = ({ resourceIds, stepNumber, maximumVisitedStep }: Props) => {
  useCheckoutRouteUpdatingHook();
  const path = '/checkout';
  const { search } = useLocation();

  function getPath(subPath: number) {
    return `${path}/${subPath}${search}`;
  }

  return (
    <div className='Checkout container-fluid fluid-small'>
      {stepNumber < 4 ? (
        <CheckoutNavigationBar
          labels={serviceCareRoutes}
          selectedStepNumber={stepNumber}
          disableFollowingPages={resourceIds.length === 0 ? stepNumber : Math.max(maximumVisitedStep, stepNumber)}
          convertStepNumberToPath={step => getPath(step + 1)}
        />
      ) : null}
      <Routes>
        <Route path={'1'} element={<CheckoutResourceSelectionContainer nextPath={getPath(2)} />} />
        <Route path={'2'} element={<RedirectToWorkshopAssignment nextStep={getPath(3)} previousStep={getPath(1)} />} />
        <Route path={'3'} element={<CheckoutPaymentPageContainer nextPath={getPath(4)} previousPath={getPath(2)} />} />
        <Route path={'4'} element={<CheckoutPreviewPageContainer nextPath={getPath(5)} previousPath={getPath(3)} />} />
        <Route path={'5'} element={<CheckoutConfirmationPageContainer previousPath={getPath(4)} />} />
        <Route path={'*'} element={<Navigate replace to={getPath(1)} />} />
      </Routes>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  resourceIds: getSelectedResourceIds(state),
});

export const Checkout4StepsContainer = connect(mapStateToProps)(Checkout3Steps);
