import { getApiPartners } from '../../config/pathURL/config';
import type { PartnerConnection } from '../../customerCenter/partners/redux/types';
import { decodeJson, ignoreError, jsonOrReject, onRejected, rejectIfNotOk } from '../api';
import { deleteRequest, getRequest } from '../requests';
import {
  type ApiPartner,
  type ApiPartnerConnections,
  ApiPartnerConnectionsCodec,
  ApiPartnersCodec,
} from './partner.types';
import { mapApiPartnerConnections } from './partnerMapper';

export function fetchPartnerConnections(accountId: string): Promise<PartnerConnection[]> {
  return Promise.all([fetchRawPartnerConnections(accountId), fetchPartners()]).then(([connections, partners]) =>
    mapApiPartnerConnections(connections, partners)
  );
}
function fetchRawPartnerConnections(accountId: string): Promise<ApiPartnerConnections> {
  const baseUrl = `${getApiPartners()}/accounts/${accountId}/connections`;
  const urlParams = new URLSearchParams();
  urlParams.append('direction', 'outbound');
  const url = `${baseUrl}?${urlParams}`;
  return fetch(url, getRequest())
    .then(jsonOrReject)
    .then(decodeJson(ApiPartnerConnectionsCodec))
    .catch(onRejected([401]));
}

export function deletePartnerConnection(accountId: string, connectionId: string): Promise<void> {
  return fetch(`${getApiPartners()}/accounts/${accountId}/connections/${connectionId}`, deleteRequest()).then(
    rejectIfNotOk
  );
}

function fetchPartners(): Promise<ApiPartner[]> {
  return fetch(`${getApiPartners()}/partners`, getRequest())
    .then(jsonOrReject)
    .then(decodeJson(ApiPartnersCodec))
    .then(partners => partners.items)
    .catch(error => ignoreError(error, []));
}
