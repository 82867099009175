import groupBy from 'lodash/fp/groupBy';
import { UnreachableCaseError } from '../../../../utils/typescriptUtil';
import { type DatedInvoices, InvoiceType } from '../../customerCenter/invoices/redux/types';
import { type ApiInvoice, type ApiInvoices, CREDIT_MEMO_TYPE_STRING, INVOICE_TYPE_STRING } from './invoices.types';

function convertInvoiceTypeToEnumValues(
  type: typeof CREDIT_MEMO_TYPE_STRING | typeof INVOICE_TYPE_STRING
): InvoiceType {
  switch (type) {
    case INVOICE_TYPE_STRING:
      return InvoiceType.INVOICE;
    case CREDIT_MEMO_TYPE_STRING:
      return InvoiceType.CREDIT_MEMO;
    default:
      throw new UnreachableCaseError(type);
  }
}

function convertToInvoice(apiInvoice: ApiInvoice) {
  const type = convertInvoiceTypeToEnumValues(
    // Narrow type due to filtering
    apiInvoice.type as typeof CREDIT_MEMO_TYPE_STRING | typeof INVOICE_TYPE_STRING
  );
  return {
    id: apiInvoice.id,
    partnerName: apiInvoice.partner_account_name,
    invoiceDate: apiInvoice.issued_at,
    invoiceNumber: apiInvoice.number,
    amount: type === InvoiceType.CREDIT_MEMO ? -1 * Math.abs(apiInvoice.amount) : apiInvoice.amount,
    currency: apiInvoice.currency,
    type: type,
  };
}

const allowedTypes = [CREDIT_MEMO_TYPE_STRING, INVOICE_TYPE_STRING];

export function mapInvoicesResponse(apiInvoices: ApiInvoices): DatedInvoices[] {
  const items = apiInvoices.items.filter(invoice => allowedTypes.includes(invoice.type));
  const groupedInvoices = groupBy((item: ApiInvoice) => item.issued_at)(items);
  return Object.keys(groupedInvoices).map((key: string) => {
    return {
      invoiceDate: key,
      invoices: groupedInvoices[key].map(convertToInvoice),
    };
  });
}
