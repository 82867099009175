import { connect } from 'react-redux';
import type { RootState } from '../../../../../configuration/setup/store';
import { type UsageSummaryColumn, UsageSummaryColumnType } from './types';
import { getUsageSummaryColumns } from './usageSummaryColumnService';

interface TableColGroupsProps {
  columns: UsageSummaryColumn[];
}

const UsageSummaryTableColGroups = (props: TableColGroupsProps) => {
  const { columns } = props;
  return (
    <colgroup>
      {columns.map((_, index) => {
        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
        return <col key={index} />;
      })}
    </colgroup>
  );
};

const mapStateToResourceProps = (): TableColGroupsProps => ({
  columns: [{ type: UsageSummaryColumnType.RESOURCE }],
});
const mapStateToUsagesProps = (state: RootState): TableColGroupsProps => ({
  columns: getUsageSummaryColumns(state),
});
const mapStateToPriceProps = (): TableColGroupsProps => ({
  columns: [{ type: UsageSummaryColumnType.PRICE }],
});

export const UsageSummaryTableResourceColGroupsContainer = connect(mapStateToResourceProps)(UsageSummaryTableColGroups);
export const UsageSummaryTableUsagesColGroupsContainer = connect(mapStateToUsagesProps)(UsageSummaryTableColGroups);
export const UsageSummaryTablePriceColGroupsContainer = connect(mapStateToPriceProps)(UsageSummaryTableColGroups);
