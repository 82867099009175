import type { RootState } from '../../../../../configuration/setup/store';
import {
  getContracts,
  getFreeTrialContracts,
  getMissingRequirementsByAssetForUpdate,
  getTermedLevelIdsForUpdate,
} from '../redux/termedLevelRenewal.redux';
import type { TermedLevelsForUpdate } from '../redux/types';
import { isTermedLevelSelectable } from '../termedLevelSelectionValidationService';
import type { TermedLevel } from '../types';

const termedLevelIdToString = (assetId: string, productId: string): string => `${productId}_${assetId}`;

export const getTermedLevelsForUpdate = (state: RootState): TermedLevelsForUpdate => {
  const contractIdsForUpdate = getTermedLevelIdsForUpdate(state)?.map(it =>
    termedLevelIdToString(it.resourceId, it.productId)
  );
  const selectedContracts = [...getContracts(state), ...getFreeTrialContracts(state)]
    .filter(it => contractIdsForUpdate?.includes(termedLevelIdToString(it.resourceId, it.productId)))
    .filter(it => isTermedLevelSelectable(it));
  const missingRequirementsForAssets = getMissingRequirementsByAssetForUpdate(state);

  function hasMissingRequirements(termedLevel: TermedLevel) {
    return missingRequirementsForAssets && (missingRequirementsForAssets[termedLevel.resourceId]?.length ?? 0) !== 0;
  }

  const addMissingRequirements = (termedLevel: TermedLevel): TermedLevel => ({
    ...termedLevel,
    missingRequirements: missingRequirementsForAssets
      ? missingRequirementsForAssets[termedLevel.resourceId]
      : undefined,
  });

  return {
    updatable: selectedContracts.filter(termedLevel => !hasMissingRequirements(termedLevel)),
    notUpdatable: selectedContracts
      .filter(termedLevel => hasMissingRequirements(termedLevel))
      .map(addMissingRequirements),
  };
};
