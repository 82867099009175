import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import type { MessageKey } from '../../../../configuration';

interface Props {
  disabled?: boolean;
  isInProgress?: boolean;
  confirmMessageId: MessageKey;
  loadingMessageId: MessageKey;
  onClick: (event: React.MouseEvent) => void;
}

export const StripeCreatePaymentButton = (props: Props) => {
  const { disabled = false, isInProgress = false, confirmMessageId, loadingMessageId, onClick } = props;

  if (isInProgress) {
    return (
      <button className='StripeCreatePaymentButton-loading btn btn-primary disabled' key={'loading'}>
        <FormattedMessage id={loadingMessageId} />
      </button>
    );
  }

  return (
    <button
      className={classnames('StripeCreatePaymentButton', 'btn btn-primary', disabled && 'disabled')}
      onClick={onClick}
      key={'clickable'}
    >
      <FormattedMessage id={confirmMessageId} />
    </button>
  );
};
