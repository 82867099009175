import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  type DisplayMessages,
  getDisplayMessages,
  getLocale,
  getUserTenant,
  isUserSessionExpired,
} from '../configuration';
import { AppHeaderContainer } from './AppHeaderContainer';
import { AppSubNavigationContainer } from './AppSubNavigationContainer';
import { MarketplaceContainer } from './app/MarketplaceContainer';
import { fetchPermissionsThunk } from './app/common/permissions/permissions.thunk';
import { initToggles } from './app/common/toggles/featureToggles';
import { useMarketplaceBottomBar } from './app/customerCenter/MarketplaceBottomBar';
import './app/common/IntlDateTimePicker';
import { type RootDispatch, type RootState, useAppDispatch } from '../configuration/setup/store';
import { freeTrialStatusThunk } from './app/freeTrial/freeTrialStatus.thunk';
import { getIsOpenedInPopup } from './app/popup/popup.redux';
import { usePopupDeepLinkingHook } from './app/popup/popupDeepLinkingHook';

// Initialize extra-globals and toggles
initToggles();

interface AppContainerProps {
  fetchPermissions: () => void;
  userLocale: string;
  userTenant?: string;
  showSessionExpired: boolean;
  displayMessages: DisplayMessages;
  isOpenedInPopup: boolean;
}

function App(props: AppContainerProps) {
  const {
    displayMessages,
    showSessionExpired,
    userLocale: locale,
    userTenant,
    isOpenedInPopup,
    fetchPermissions,
  } = props;

  useEffect(() => {
    verifyAndRedirectToLatamMarketplace(userTenant);
  }, [userTenant]);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);
  usePopupDeepLinkingHook();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(freeTrialStatusThunk);
  }, []);

  const MarketplaceBottomBar = useMarketplaceBottomBar();
  const [forcedClose, setForcedClose] = useState(false);

  if (!displayMessages) {
    return null;
  }

  return (
    <ApplicationLayout>
      {!isOpenedInPopup && <AppHeaderContainer />}
      <ApplicationLayout.Body
        bottomBar={MarketplaceBottomBar}
        banner={!isOpenedInPopup && <AppSubNavigationContainer />}
      >
        <NotificationsContainer />
        <SessionExpiredDialog
          locale={locale}
          onClose={() => setForcedClose(true)}
          show={showSessionExpired && !forcedClose}
        />
        <MarketplaceContainer />
      </ApplicationLayout.Body>
    </ApplicationLayout>
  );
}

const verifyAndRedirectToLatamMarketplace = (tenant?: string) => {
  if (tenant?.startsWith('rio-brazil.')) {
    const currentPath = window.location.pathname;
    const currentQueryString = window.location.search;
    const targetURL = 'https://latam-marketplace.rio.cloud';
    window.location.href = targetURL + currentPath + currentQueryString;
  }
};

export const mapDispatchToProps = (dispatch: RootDispatch) => ({
  fetchPermissions: () => dispatch(fetchPermissionsThunk),
});

export const mapStateToProps = (state: RootState) => ({
  displayMessages: getDisplayMessages(state),
  userTenant: getUserTenant(state),
  showSessionExpired: isUserSessionExpired(state),
  userLocale: getLocale(state),
  isOpenedInPopup: getIsOpenedInPopup(state),
});

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
