import OnboardingTip from '@rio-cloud/rio-uikit/lib/es/OnboardingTip';
import type { Placement } from '@rio-cloud/rio-uikit/lib/es/values/Placement';
import { type WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import type { MessageKey } from '../../../../configuration';
import type { RootState } from '../../../../configuration/setup/store';
import { getShowOnboardingTip, onboardingActions } from './redux/oboarding.redux';
import type { Tips } from './types';

// ONBOARDING: This is a general functionality which we might want to use in future though it is currently not in use

interface OnboardingTipWrapperProps {
  tipType: Tips;
  showOnboardingTip: (tip: Tips) => boolean;
  hideOnboardingTip: (tip: Tips) => void;
  titleTranslationId?: MessageKey;
  contentTranslationId: MessageKey;
  children: React.ReactNode;
  className?: string;
  placement?: Placement;
}

export const OnboardingTipWrapper = (props: OnboardingTipWrapperProps & WrappedComponentProps) => {
  const {
    children,
    intl,
    tipType,
    titleTranslationId,
    contentTranslationId,
    hideOnboardingTip,
    showOnboardingTip,
    className = 'max-width-200',
    placement = OnboardingTip.BOTTOM,
  } = props;

  const onHide = () => hideOnboardingTip(tipType);

  return (
    <OnboardingTip
      show={showOnboardingTip(tipType)}
      placement={placement}
      title={titleTranslationId ? intl.formatMessage({ id: titleTranslationId }) : ''}
      content={intl.formatMessage({ id: contentTranslationId })}
      className={className}
      onHide={onHide}
    >
      {children}
    </OnboardingTip>
  );
};

const mapStateToProps = (state: RootState) => ({
  showOnboardingTip: (tip: Tips) => getShowOnboardingTip(state, tip),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  hideOnboardingTip: (tip: Tips) => dispatch(onboardingActions.setShowOnboardingTip({ type: tip, value: false })),
});

export const ConnectedOnboardingTip = connect(mapStateToProps, mapDispatchToProps)(injectIntl(OnboardingTipWrapper));
