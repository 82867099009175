import { setEnforcedLocale } from '../../lang/languageConfiguration';
import { parseQueryString } from '../utils/qsUtil';

function convertToBool(value) {
  return value && value.toLowerCase() === 'true';
}

let enableProfileToggle = false;
export const isProfileToggleEnabled = () => enableProfileToggle;
export const setProfileToggle = enabled => {
  enableProfileToggle = enabled;
};

let enableOrder = false;
export const isOrderEnabled = () => enableOrder;
export const setOrderToggle = enabled => {
  enableOrder = enabled;
};

export const initToggles = () => {
  const searchParams = parseQueryString(window.location.search);

  // Feature toggle to test various languages on demand
  setEnforcedLocale(searchParams.enforceLocale);

  // Toggle profile tab in customer center
  setProfileToggle(convertToBool(searchParams.pro));

  // Toggle for create order button
  setOrderToggle(convertToBool(searchParams.order));
};
