import { configureStore as configureStoreToolkit } from '@reduxjs/toolkit';
import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { AnyAction } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import { accountApiSlice } from '../../features/app/api/accounts/accountApiSlice';
import { rootReducer } from '../../reducers';

export const configureStore = () =>
  configureStoreToolkit({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(accountApiSlice.middleware),
  });

const store = configureStore();

export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

export const useAppDispatch: () => RootDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { store };
