import isEmpty from 'lodash/isEmpty';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { generateDiscountHint } from './DiscountService';
import { getDiscounts } from './redux/discount.redux';
import type { AccountDiscount, DiscountState } from './redux/types';

const displayAccountDiscount = (accountDiscounts: AccountDiscount[], intl: any): JSX.Element => {
  if (accountDiscounts.length < 1) {
    return <></>;
  }

  const accountDiscount = accountDiscounts[0];
  const discountText = generateDiscountHint(accountDiscount, intl);

  return (
    <>
      <div className='margin-top-5 text-color-darkest'>{discountText}</div>
    </>
  );
};

const hasNoDiscount = (discounts: DiscountState) => {
  return (
    isEmpty(discounts.accountDiscounts) && isEmpty(discounts.productDiscounts) && isEmpty(discounts.assetDiscounts)
  );
};

export function DiscountInfoPanel() {
  const discounts = useSelector(getDiscounts);
  const intl = useIntl();

  if (hasNoDiscount(discounts)) {
    return <></>;
  }

  return (
    <div className='alert alert-success margin-bottom-20 padding-10'>
      <div className='display-flex'>
        <span className='display-flex justify-content-center align-items-center margin-right-10 width-50 height-50'>
          <span className='text-color-success text-size-h1 rioglyph rioglyph-cost-efficency' />
        </span>
        <div>
          <strong className='text-size-16 text-color-darkest'>
            <FormattedMessage id={'marketplace.discount.infoPanel.title'} />
          </strong>
          {displayAccountDiscount(discounts.accountDiscounts, intl)}
          <div>
            <FormattedMessage id={'marketplace.discount.infoPanel.checkoutInfo'} />
          </div>
        </div>
      </div>
    </div>
  );
}
