import { differenceInCalendarDays } from 'date-fns';
import { UnreachableCaseError } from '../../../../utils/typescriptUtil';
import { ProductState } from '../../checkout/resourceSelection/redux/types';
import { HumanReadableTermedLevelState, type TermedLevel, TermedLevelState } from '../../common/termedLevel/types';

const REMAINING_DAYS_WARNING_THRESHOLD = 31;

function getContractState(
  contractState: TermedLevelState,
  criticalRemainingDays: boolean,
  hasInactiveActivationState: boolean
): HumanReadableTermedLevelState {
  switch (contractState) {
    case TermedLevelState.ACTIVE:
      if (hasInactiveActivationState) {
        return HumanReadableTermedLevelState.active_without_activation;
      }
      if (criticalRemainingDays) {
        return HumanReadableTermedLevelState.active_terminating_soon;
      }
      return HumanReadableTermedLevelState.active;
    case TermedLevelState.PENDING:
      return HumanReadableTermedLevelState.pending;
    default:
      throw new UnreachableCaseError(contractState);
  }
}

export type PartialContract = Omit<
  TermedLevel,
  'remainingDays' | 'criticalRemainingDays' | 'contractState' | 'hasInactiveActivationState'
>;

export function enhanceContract(contract: PartialContract): TermedLevel {
  const remainingDays = contract.endsAt ? differenceInCalendarDays(new Date(contract.endsAt), new Date()) : undefined;
  const hasInactiveActivationState =
    contract.activationState === ProductState.INACTIVE ||
    contract.activationState === ProductState.PENDING_CANCELLATION;
  const criticalRemainingDays = remainingDays !== undefined && remainingDays <= REMAINING_DAYS_WARNING_THRESHOLD;
  const contractState = getContractState(contract.state, criticalRemainingDays, hasInactiveActivationState);
  const productLevelName = contract.productLevelName;

  return {
    ...contract,
    remainingDays,
    criticalRemainingDays,
    contractState,
    hasInactiveActivationState,
    productLevelName,
  };
}
