import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { compose } from 'redux';
import { isProductInMaintenanceMode } from '../../../configuration/featureToggle/featureToggleService';
import type { RootDispatch, RootState } from '../../../configuration/setup/store';
import { getHasError, getIsLoading } from '../api/redux/api.redux';
import { ApiCalls } from '../api/redux/types';
import { MaintenanceView } from '../common/MaintenanceView';
import LoadingErrorState from '../common/error/LoadingErrorState';
import { UnauthorizedDialog } from '../common/error/UnauthorizedDialog';
import { fetchPaymentDetailsIfNecessaryByTenant } from '../common/payment/paymentMethods.thunk';
import type { PermissionValidatorWrappedComponentProps } from '../common/permissions/PermissionValidatorWrapper';
import { SUBSCRIPTION_WRITE } from '../common/permissions/permissions';
import withPermissionValidator from '../common/permissions/withPermissionValidator';
import { getIsOpenedInPopup } from '../popup/popup.redux';
import { Checkout3StepsContainer } from './Checkout3Steps';
import { Checkout4StepsContainer } from './Checkout4Steps';
import { isSelectedProductBookable } from './CheckoutService';
import { CheckoutNoServiceSelected } from './components/CheckoutNoServiceSelected';
import { CheckoutServiceError } from './components/CheckoutServiceError';
import { isServiceCareProduct } from './components/RedirectToWorkshopAssignment';
import { useCheckoutDeepLinking } from './deeplinking/checkoutDeepLinkingHook';
import { checkoutActions, getSelectedProductId } from './redux/checkout.redux';
import { resourceSelectionActions } from './resourceSelection/redux/resourceSelection.redux';

interface Props extends PermissionValidatorWrappedComponentProps {
  hasProductError: boolean;
  isOpenedInPopup: boolean;
  fetchPaymentDetails: () => void;
  productId: string;
  resetCheckoutState: () => void;
  isProductInMaintenance: (productId?: string) => boolean;
  isProductBookable: boolean;
  isLoading: boolean;
}

function useStepNumberTracking(pathname: string) {
  let stepNumber = 0;
  const numberExtractedFromPath = Number(pathname.substring(pathname.lastIndexOf('/') + 1));
  if (!Number.isNaN(numberExtractedFromPath)) {
    stepNumber = numberExtractedFromPath - 1;
  }
  const [maximumVisitedStep, setMaximumVisitedStep] = useState(0);
  useEffect(() => {
    if (stepNumber > maximumVisitedStep) {
      setMaximumVisitedStep(stepNumber);
    }
  }, [stepNumber, maximumVisitedStep, setMaximumVisitedStep]);
  return { stepNumber, maximumVisitedStep };
}

const Checkout = ({
  fetchPaymentDetails,
  hasProductError,
  userHasPermissions,
  productId,
  resetCheckoutState,
  isOpenedInPopup,
  isProductInMaintenance,
  isProductBookable,
}: Props) => {
  const { pathname } = useLocation();
  const { stepNumber, maximumVisitedStep } = useStepNumberTracking(pathname);

  useCheckoutDeepLinking();

  useEffect(() => {
    fetchPaymentDetails();
  }, [fetchPaymentDetails]);

  useEffect(() => {
    return () => resetCheckoutState();
  }, [resetCheckoutState]);

  if (isProductInMaintenance(productId)) {
    return <MaintenanceView />;
  }
  if (hasProductError) {
    return <CheckoutServiceError />;
  }

  if (!userHasPermissions([SUBSCRIPTION_WRITE])) {
    return <UnauthorizedDialog onClose={isOpenedInPopup ? () => window.close() : undefined} />;
  }

  if (!isProductBookable) {
    return <CheckoutNoServiceSelected />;
  }

  return isServiceCareProduct(productId) ? (
    <Checkout4StepsContainer stepNumber={stepNumber} maximumVisitedStep={maximumVisitedStep} />
  ) : (
    <Checkout3StepsContainer stepNumber={stepNumber} maximumVisitedStep={maximumVisitedStep} />
  );
};

const mapStateToProps = (state: RootState) => ({
  hasProductError: getHasError(state, ApiCalls.CHECKOUT_PRODUCT_DETAILS),
  productId: getSelectedProductId(state),
  isOpenedInPopup: getIsOpenedInPopup(state),
  isProductInMaintenance: isProductInMaintenanceMode(state),
  isProductBookable: getIsLoading(state, ApiCalls.CHECKOUT_PRODUCT_DETAILS) || isSelectedProductBookable(state),
  isLoading: getIsLoading(state, ApiCalls.CHECKOUT_PRODUCT_DETAILS) || getIsLoading(state, ApiCalls.RESOURCE_SELECTION),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  fetchPaymentDetails: () => {
    dispatch(fetchPaymentDetailsIfNecessaryByTenant());
  },
  resetCheckoutState: () => {
    dispatch(resourceSelectionActions.resetResourceSelectionState());
    dispatch(checkoutActions.resetSelectedProductState());
  },
});

export const CheckoutContainer = compose<React.ComponentType>(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissionValidator(LoadingErrorState)
)(Checkout);
