import * as t from 'io-ts';

export const API_FREE_TRIAL_STATUS_ELIGIBLE = 'eligible';
export const API_FREE_TRIAL_STATUS_INELIGIBLE = 'ineligible';
export const API_FREE_TRIAL_STATUS_ACTIVE = 'active';

export const ApiFreeTrialStatusCodec = t.union([
  t.literal(API_FREE_TRIAL_STATUS_ELIGIBLE),
  t.literal(API_FREE_TRIAL_STATUS_INELIGIBLE),
  t.literal(API_FREE_TRIAL_STATUS_ACTIVE),
]);

export type ApiFreeTrialState = t.TypeOf<typeof ApiFreeTrialStatusCodec>;

export const ApiFreeTrialCodec = t.intersection([
  t.type({
    status: ApiFreeTrialStatusCodec,
  }),
  t.partial({
    ends_at: t.string,
    products: t.array(
      t.type({
        product_id: t.string,
        level: t.string,
      })
    ),
  }),
]);

export type ApiFreeTrial = t.TypeOf<typeof ApiFreeTrialCodec>;
