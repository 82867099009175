import { useIntl } from 'react-intl';
import type { TermedLevel } from '../../common/termedLevel/types';
import { DATE_FORMAT } from '../../common/utils/dateUtil';
import { formatDurationUnit } from '../../common/utils/formatDurationUnit';

export function ContractDuration(props: { contract: TermedLevel }) {
  const intl = useIntl();
  return (
    <>
      <div>{`${props.contract.periodLength} ${formatDurationUnit(props.contract.periodUnit, intl)}`}</div>
      {props.contract.startsAt && props.contract.endsAt && (
        <div className='text-color-gray'>
          {intl.formatDate(props.contract.startsAt, DATE_FORMAT)} -{' '}
          {intl.formatDate(props.contract.endsAt, DATE_FORMAT)}
        </div>
      )}
    </>
  );
}
