import { connect } from 'react-redux';
import type { MessageKey } from '../../../../configuration';
import type { RootDispatch } from '../../../../configuration/setup/store';
import LoadingErrorStateWithReload from '../../common/error/LoadingErrorStateWithReload';
import { fetchCheckoutProductDetailsThunk } from '../thunks/productDetails.thunk';

const mapStateToProps = (): { headline: MessageKey } => ({
  headline: 'marketplace.productDetails.fetch.error',
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  onReload: () => dispatch(fetchCheckoutProductDetailsThunk),
});

export const CheckoutServiceError = connect(mapStateToProps, mapDispatchToProps)(LoadingErrorStateWithReload);
