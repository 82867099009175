import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import type { Dispatch } from 'redux';
import type { RootState } from '../../../../configuration/setup/store';
import type { FetchError } from '../../api/FetchError';
import { fetchProductDetails } from '../../api/product/productCalls';
import { apiCallAction } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { checkoutActions, getSelectedProductId, getSelectedProductLevel } from '../redux/checkout.redux';
import { type Product, isMultiLevelProduct } from '../redux/types';

export const fetchCheckoutProductDetailsThunk = (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
  const productId = getSelectedProductId(getState());
  const productLevel = getSelectedProductLevel(getState());

  if (!productId) {
    return Promise.reject();
  }
  dispatch(apiCallAction(ApiCalls.CHECKOUT_PRODUCT_DETAILS, true, false));
  dispatch(checkoutActions.setSelectedProduct(undefined));
  return fetchProductDetails(productId)
    .then((response: Product) => {
      dispatch(checkoutActions.setSelectedProduct(response));
      if (isMultiLevelProduct(response) && (!productLevel || !response.levels.some(it => it.level === productLevel))) {
        dispatch(apiCallAction(ApiCalls.CHECKOUT_PRODUCT_DETAILS, false, true));
        Notification.error('No or invalid target level provided for the checkout, please add as URL param');
      }
      dispatch(apiCallAction(ApiCalls.CHECKOUT_PRODUCT_DETAILS, false));
    })
    .catch((error: FetchError) => {
      if (error.statusCode === 404) {
        dispatch(apiCallAction(ApiCalls.CHECKOUT_PRODUCT_DETAILS, false, false));
      } else {
        dispatch(apiCallAction(ApiCalls.CHECKOUT_PRODUCT_DETAILS, false, true));
      }
    });
};
