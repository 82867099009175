import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import type { ProductRatePlan, ServiceOverviewItem } from '../../customerCenter/serviceOverview/types';
import { mapProductType } from '../mapProductType';
import { mapContractPeriod } from '../periods';
import { sortRatePlans } from '../productSorter';
import type { ApiActivationSummary, ApiActivationSummaryProduct, ApiActivationSummaryRatePlan } from './service.types';

export function mapActivationSummaryResponse(summary: ApiActivationSummary): ServiceOverviewItem[] {
  return sortBy<ServiceOverviewItem>(
    summary.activations.map((item): ServiceOverviewItem => {
      const product: ApiActivationSummaryProduct = item._embedded?.product ?? {
        name: item.product_id,
        legacy: true,
        product_type: 'asset-based',
      };
      return {
        productName: product.name,
        sku: item.product_id,
        legacy: product.legacy,
        amount: item.amount,
        hasRatePlan: (product.rate_plans?.length ?? 0) > 0,
        productRatePlans: product.rate_plans?.map(it => mapProductRatePlan(it)),
        productType: mapProductType(product.product_type),
      };
    }),
    'productName'
  ).map((item: ServiceOverviewItem) => ({
    ...item,
    ...{ productRatePlans: item.productRatePlans && sortRatePlans(item.productRatePlans) },
  }));
}

function mapProductRatePlan(ratePlan: ApiActivationSummaryRatePlan): ProductRatePlan {
  return {
    ...omit(ratePlan, ['contract_period', 'variant_name']),
    contractPeriod: mapContractPeriod(ratePlan.contract_period),
    variantName: ratePlan.variant_name,
    legacy: ratePlan.legacy,
  };
}
