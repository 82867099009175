import flatten from 'lodash/flatten';
import groupBy from 'lodash/fp/groupBy';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import sortBy from 'lodash/fp/sortBy';
import values from 'lodash/fp/values';
import type { UsageProduct } from '../../customerCenter/usage/redux/types';
import { sortRatePlans } from '../productSorter';

export function sortProducts(products: UsageProduct[]): UsageProduct[] {
  return pipe(
    sortBy('productName'),
    groupBy((product: UsageProduct) => product.productSku),
    values,
    map((ratePlans: UsageProduct[]) => sortRatePlans(ratePlans)),
    flatten
  )(products);
}
