import './polyfills';

import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router';
import { IntlWrapper } from './IntlWrapper';
import { config } from './config';
import { handleLoginRedirect, main, store } from './configuration';
import { AppContainer } from './features/AppContainer';
import { ErrorBoundary } from './features/app/common/ErrorBoundary';
import { NoMatch } from './features/app/common/NoMatch';
import {
  MARKETPLACE_FREE_TRIAL_BUTTON,
  MARKETPLACE_FREE_TRIAL_SECTION_FOR_HOMESCREEN,
  MARKETPLACE_START_FREE_TRIAL,
} from './features/app/common/routes';
import { FreeTrialButtonWrapper } from './features/app/freeTrial/FreeTrialButtonWrapper';
import { FreeTrialPopupContainer } from './features/app/freeTrial/FreeTrialPopupContainer';
import { HomescreenFreeTrialSection } from './features/app/freeTrial/HomescreenFreeTrialSection';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { createRoot } from 'react-dom/client';

function renderApplication() {
  const container = document.getElementById('root');
  const root = createRoot(container!);

  root.render(
    <ErrorBoundary>
      <Provider store={store}>
        <IntlWrapper>
          <BrowserRouter>
            <Routes>
              <Route path='/error' element={<NoMatch />} />
              <Route path={MARKETPLACE_FREE_TRIAL_BUTTON} element={<FreeTrialButtonWrapper />} />
              <Route path={MARKETPLACE_FREE_TRIAL_SECTION_FOR_HOMESCREEN} element={<HomescreenFreeTrialSection />} />
              <Route path={MARKETPLACE_START_FREE_TRIAL} element={<FreeTrialPopupContainer />} />
              <Route path='/*' element={<AppContainer />} />
              <Route element={<NoMatch />} />
            </Routes>
          </BrowserRouter>
        </IntlWrapper>
      </Provider>
    </ErrorBoundary>
  );
}

if (window.location.href.startsWith(config.login.redirectUri)) {
  handleLoginRedirect();
} else {
  main(renderApplication);
}
