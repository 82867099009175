import flatten from 'lodash/flatten';
import { isPresent } from '../../../../../utils/typescriptUtil';
import { isFreeTrialLevel } from '../../../common/termedLevel/freeTrialLevel';
import type { ProductRatePlan } from '../types';

export function findSmallestNonFreeTrialLevel(tabList: ProductRatePlan[][]): string {
  const levels = flatten(tabList)
    .map(productRatePlan => productRatePlan.level)
    .filter(level => !isFreeTrialLevel(level))
    .filter(isPresent);
  return levels.sort()[0];
}
