import TableToolbar from '@rio-cloud/rio-uikit/lib/es/TableToolbar';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { RootState } from '../../../../configuration/setup/store';
import { SUBSCRIPTION_WRITE } from '../../common/permissions/permissions';
import { getPermissions } from '../../common/permissions/redux/permissions.redux';
import { TermsAndConditionsContainer } from '../../common/termsAndConditions/TermsAndConditionsContainer';
import { isRIO4RentalProductId } from '../../config/rio4rentalProduct';
import { ChangeSubscriptionButtons } from './components/ChangeSubscriptionsButtons';
import { ServiceDetailsButtonContainer } from './components/ServiceDetailsButtonContainer';
import type { OverviewResource } from './redux/types';
import { hasTwoOrMoreLevels } from './services/serviceOverviewLevelService';
import { makeGetServiceResourcesForSku } from './services/serviceOverviewResourceStateService';
import type { ServiceOverviewItem } from './types';

interface Props {
  service: ServiceOverviewItem;
  hasRightToChangeSubscriptions: boolean;
  tab: string;
  handleConfirmUpdate: () => void;
  resources: OverviewResource[];
  selectedResources: OverviewResource[];
}

function ServiceBodyFooter(props: Props) {
  const { service, resources, hasRightToChangeSubscriptions, tab, handleConfirmUpdate, selectedResources } = props;

  const currentLevel = hasTwoOrMoreLevels(service) ? tab : service.productRatePlans?.[0].level;

  if (isRIO4RentalProductId(service.sku)) {
    return (
      <div className='padding-15 bg-white rounded-bottom text-size-14'>
        <FormattedMessage id='marketplace.productExternallyManaged' values={{ service: service.productName }} />
      </div>
    );
  }

  return (
    <div className='padding-x-15 padding-top-15 bg-white rounded-bottom'>
      <TableToolbar className={'margin-0'}>
        <div className='table-toolbar-container'>
          <div className='table-toolbar-group-left'>
            <div className='table-toolbar-column'>
              <div className='btn-toolbar table-btn-toolbar'>
                <TermsAndConditionsContainer serviceSku={service.sku} level={currentLevel}>
                  <div className='btn btn-default'>
                    <FormattedMessage id={'marketplace.myServices.termsAndConditions.show'} />
                  </div>
                </TermsAndConditionsContainer>
              </div>
            </div>
            <div className='table-toolbar-column'>
              <div className='btn-toolbar table-btn-toolbar'>
                <ServiceDetailsButtonContainer service={service} level={currentLevel} />
              </div>
            </div>
          </div>

          {hasRightToChangeSubscriptions && (
            <ChangeSubscriptionButtons
              service={service}
              currentLevel={currentLevel}
              tab={tab}
              handleConfirmUpdate={handleConfirmUpdate}
              resources={resources}
              selectedResources={selectedResources}
            />
          )}
        </div>
      </TableToolbar>
    </div>
  );
}

function makeMapStateToProps() {
  const getServiceResourcesForSku = makeGetServiceResourcesForSku();

  return (state: RootState, props: { service: ServiceOverviewItem }) => ({
    resources: getServiceResourcesForSku(state, props.service.sku) ?? [],
    hasRightToChangeSubscriptions: getPermissions(state)?.includes(SUBSCRIPTION_WRITE) ?? false,
  });
}

export const ServiceBodyFooterContainer = connect(makeMapStateToProps)(ServiceBodyFooter);
