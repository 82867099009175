import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import type { TermedLevel } from '../../common/termedLevel/types';
import { mapResourceTypeToProductType } from '../contracts/mapResourceTypeToProductType';
import { FreeTrialPanelHeader } from './FreeTrialPanelHeader';
import { FreeTrialRenewButtonContainer } from './FreeTrialRenewButton';
import { FreeTrialTable } from './FreeTrialTable';

interface Props {
  contracts: TermedLevel[];
  open: boolean;
}

export const FreeTrialPanel = (props: Props) => {
  const { contracts, open } = props;
  const productName = contracts[0].productName;
  const productId = contracts[0].productId;
  const numberOfRenewals = contracts.filter(contract => !!contract.renewsToLevel).length;
  const productType = mapResourceTypeToProductType(contracts[0].resourceType);
  return (
    <ExpanderPanel
      title={
        <FreeTrialPanelHeader
          productName={productName}
          productId={productId}
          numberOfContracts={contracts.length}
          numberOfRenewals={numberOfRenewals}
        />
      }
      bsStyle='default'
      bodyClassName='padding-0'
      className='ServiceOverviewItem'
      open={open}
    >
      <FreeTrialTable productId={productId} freeTrialActivations={contracts} productType={productType} />
      <span className='margin-15 pull-right'>
        <FreeTrialRenewButtonContainer productId={productId} />
      </span>
    </ExpanderPanel>
  );
};
