import { useEffect, useState } from 'react';

export function calculateIsChecked(available: unknown[] | undefined, selected: unknown[]) {
  const isAllSelected = available?.length === selected.length;
  const isNothingSelected = selected.length === 0;
  if (isNothingSelected) {
    return false;
  }
  if (isAllSelected) {
    return true;
  }
  return undefined;
}

export function useCheckboxState(available: unknown[] | undefined, selected: unknown[]) {
  const [isChecked, setIsChecked] = useState<boolean | undefined>();

  useEffect(() => {
    setIsChecked(calculateIsChecked(available, selected));
  }, [available, selected]);
  return isChecked;
}
