import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import { connect } from 'react-redux';
import type { RootState } from '../../../../../configuration/setup/store';
import type { TermedLevel } from '../types';
import { CONTRACT_TAGS_PER_ROW } from './ContractList';
import { ContractSelectionItem } from './ContractSelectionItem';
import { getTermedLevelsForUpdate } from './dialogService';

interface Props {
  contracts: TermedLevel[];
}

export const RenewDialogNoRenewal = (props: Props) => {
  const { contracts } = props;

  return (
    <div>
      <div className={'max-height-50vh overflow-auto'}>
        <div className='panel panel-default'>
          <TagList className={'BookableAssets margin-5'} tagsPerRow={CONTRACT_TAGS_PER_ROW}>
            {contracts.map(contract => {
              return <ContractSelectionItem contract={contract} key={contract.resourceId} />;
            })}
          </TagList>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { updatable, notUpdatable } = getTermedLevelsForUpdate(state);
  return {
    contracts: [...updatable, ...notUpdatable],
  };
};

export const RenewDialogNoRenewalContainer = connect(mapStateToProps)(RenewDialogNoRenewal);
