import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { parseQueryString } from '../../common/utils/qsUtil';
import { contractActions } from './redux/contract.redux';

// This value has to be in sync with the link in the contracts email from product-activation
const FROM_EMAIL = 'fromEmail';

const parseFromEmailParameter = (search: string): boolean => {
  const fromEmail = parseQueryString(search)[FROM_EMAIL];
  return fromEmail === undefined ? false : fromEmail === 'true';
};

export function useContractDeepLinkingHook() {
  const { search } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const fromEmail = parseFromEmailParameter(search);
    if (fromEmail) {
      dispatch(contractActions.setDatesFromEmail());
    }
  }, [search, dispatch]);
}
