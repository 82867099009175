import { type IntlShape, useIntl } from 'react-intl';
import type { MessageKey } from '../../../../configuration';
import { PriceInfoDaily } from '../../common/price/PriceInfoDaily';
import { PriceInfoLongTerm } from '../../common/price/PriceInfoLongTerm';
import { OverlayTrigger, Tooltip } from '../../common/utils/tooltipReExports';
import { DEFAULT_TOOLTIP_DELAY } from '../../config/config';
import { type Product, isBookableProduct } from '../redux/types';

const renderEstimatedPriceInfo = (intl: IntlShape, messageKey: MessageKey) => {
  return (
    <OverlayTrigger
      trigger={OverlayTrigger.TRIGGER_HOVER}
      placement='bottom'
      overlay={
        <Tooltip id='tooltip' className={'width-auto max-width-300'}>
          {intl.formatMessage({ id: messageKey })}
        </Tooltip>
      }
      delay={DEFAULT_TOOLTIP_DELAY}
    >
      <span className='PriceDetails-estimated-info-icon rioglyph rioglyph-info-sign' />
    </OverlayTrigger>
  );
};

interface Props {
  selectedProduct: Product;
}

export const PriceDetails = (props: Props) => {
  const { selectedProduct } = props;
  const bookableProduct = isBookableProduct(selectedProduct) ? selectedProduct : undefined;

  const price = bookableProduct?.price;
  const currency = bookableProduct?.currency;
  const contractPeriod = bookableProduct?.contractPeriod;
  const productType = bookableProduct?.productType;

  const intl = useIntl();

  if (price === 0 || price === undefined) {
    return null;
  }
  return (
    <div className='text-right text-size-12'>
      <PriceInfoDaily price={price} currency={currency} productType={productType} />
      <span className={'rioglyph rioglyph-triangle-right'} />
      <PriceInfoLongTerm price={price} currency={currency} contractPeriod={contractPeriod} productType={productType} />{' '}
      {contractPeriod === undefined
        ? renderEstimatedPriceInfo(intl, 'marketplace.productDetails.price.estimated.explanation')
        : renderEstimatedPriceInfo(intl, 'marketplace.productDetails.price.fullTerm.explanation')}
    </div>
  );
};

export const PriceDetailsContainer = PriceDetails;
