import { isRight } from 'fp-ts/lib/Either';
import type * as t from 'io-ts';
import { PathReporter } from 'io-ts/lib/PathReporter';
import { sendError } from '../../../configuration/lang/services';
import { FetchError } from './FetchError';

function sendErrorIfItShouldNotBeIgnored(error: Error, ignoredStatuses: number[]) {
  if (![200, ...ignoredStatuses].includes((error as FetchError).statusCode)) {
    sendError(error);
  }
}

export const onRejected =
  (ignoredStatuses: number[] = []) =>
  (error: Error) => {
    sendErrorIfItShouldNotBeIgnored(error, ignoredStatuses);
    return Promise.reject(error);
  };

export function ignoreError<T>(error: Error, defaultResponse: T) {
  sendErrorIfItShouldNotBeIgnored(error, []);
  return Promise.resolve(defaultResponse);
}

export function ignore404Error<T>(error: Error, defaultResponse: T) {
  if ((error as FetchError).statusCode === 404) {
    return Promise.resolve(defaultResponse);
  }
  return Promise.reject(error);
}

export function jsonOrReject(response: Response = {} as Response) {
  if (response.ok) {
    return response.json().catch(error => {
      throw new FetchError(response, error.message);
    });
  }
  return response.text().then(text => Promise.reject(new FetchError(response, text)));
}

export function rejectIfNotOk(response: Response = {} as Response): Promise<void> {
  if (!response.ok) {
    return response.text().then(text => Promise.reject(new FetchError(response, text)));
  }
  return response.text().then(() => Promise.resolve());
}

export function convertContentToBlob(content: string, type: string) {
  const byteCharacters = atob(content);

  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  return new Blob([byteArray], { type });
}

export function decodeJson<T>(decoder: t.Decoder<unknown, T>) {
  return (json: unknown): T => {
    const validationResult = decoder.decode(json);

    if (isRight(validationResult)) {
      return validationResult.right;
    }
    throw new Error(`Error during parsing response: ${PathReporter.report(validationResult)}`);
  };
}

export function blobOrReject(response: Response = {} as Response): Promise<Blob> {
  if (response.ok) {
    return response.blob().catch(error => Promise.reject(new FetchError(response, error.message)));
  }
  return response.text().then(text => Promise.reject(new FetchError(response, text)));
}
