import getOr from 'lodash/fp/getOr';
import type { Store } from 'redux';
import { getEnforcedLocale } from '../../features/app/lang/languageConfiguration';
import { reportErrorToSentry } from '../setup/sentry';
import { trace } from '../setup/trace';
import { DEFAULT_LOCALE, getSupportedLocale as defaultGetSupportedLocale } from './lang';
import { displayMessagesFetched, localeChanged } from './langSlice';

const MODULE_NAME = 'marketplace-web';

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const sendError = (exception: any, errorInfo?: any) => {
  console.error(exception, errorInfo);
  reportErrorToSentry(exception, {
    tags: {
      module: MODULE_NAME,
    },
    errorInfo,
  });
};

// Webpack is weird sometimes, maybe it's Babel, who knows...
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const normalizeDynamicImport = (imported: any) => getOr(imported, 'default', imported);

export const importDisplayMessages = (locale: string) =>
  import(/* webpackChunkName: "[request]" */ `../../features/app/lang/locales/${locale}.json`)
    .then(normalizeDynamicImport)
    .catch(error => {
      sendError(error);
      return error;
    });

export const configureFetchDisplayMessages =
  (store: Store, fetchDisplayMessages = importDisplayMessages, getSupportedLocale = defaultGetSupportedLocale) =>
  async (locale: string) => {
    if (!locale) {
      console.warn('No "locale" supplied when fetching display messages!');
      return Promise.reject();
    }

    const supportedLocale = getSupportedLocale(getEnforcedLocale() || locale);

    try {
      const displayMessages = await fetchDisplayMessages(supportedLocale);
      trace(`Display messages fetched for "${supportedLocale}"`);
      store.dispatch(displayMessagesFetched({ locale: supportedLocale, displayMessages }));
    } catch (error) {
      console.error(`Display messages for "${supportedLocale}" could not be fetched.`, error);
      sendError(error);
      store.dispatch(localeChanged(DEFAULT_LOCALE));
    }
    return Promise.resolve();
  };
