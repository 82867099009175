import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { parseQueryString } from '../common/utils/qsUtil';
import { popupActions } from './popup.redux';

const parseIsOpenedInPopup = (search: string): boolean => {
  const { popup } = parseQueryString(search);
  return popup === 'true';
};

export function usePopupDeepLinkingHook() {
  const { search } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(popupActions.setIsOpenedInPopup(parseIsOpenedInPopup(search)));
  }, []);
}
