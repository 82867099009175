import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { getLocale } from '../../../../configuration';
import type { RootState } from '../../../../configuration/setup/store';
import { getUsageMonth, getUsageYear, usageActions } from './redux/usage.redux';
import { getUsageSelectionItems } from './usageMonthSelectionService';

export const MAX_NUMBER_OF_USAGE_MONTHS = 12;

interface UsageMonthSelectorProps {
  selectYearAndMonth: (year: number, month: number) => void;
  locale: string;
  year: number;
  month: number;
}

export const UsageMonthSelector = (props: UsageMonthSelectorProps) => {
  const { selectYearAndMonth, year, month } = props;
  const intl = useIntl();
  const selectOptions = getUsageSelectionItems(intl);

  return (
    <Select
      className={'min-width-150'}
      options={selectOptions}
      onChange={item => item && selectYearAndMonth(item.year, item.month)}
      value={[`${year}-${month}`]}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  locale: getLocale(state),
  year: getUsageYear(state),
  month: getUsageMonth(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  selectYearAndMonth: (year: number, month: number) => dispatch(usageActions.setYearAndMonth({ year, month })),
});

export const UsageMonthSelectorContainer = connect(mapStateToProps, mapDispatchToProps)(UsageMonthSelector);
