import useDarkMode from '@rio-cloud/rio-uikit/useDarkMode';
import { Elements } from '@stripe/react-stripe-js';
import type { Stripe } from '@stripe/stripe-js';
import type React from 'react';
import { type WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { RootDispatch, RootState } from '../../../../configuration/setup/store';
import type { PaymentMethodType } from '../../api/paymentMethods/paymentMethodTypes.types';
import { RioPaymentMethod } from './RioPaymentMethod';
import { fetchStripeClientSecretThunk } from './fetchStripeKeys.thunk';
import { publishPaymentMethodThunk } from './paymentMethodsPublish.thunk';
import { getStripeClientSecret } from './redux/paymentMethods.redux';
import type { PaymentMethod } from './redux/types';
import { getOptions } from './stripeElementsConfig';

export type StripePaymentMethod = 'card' | 'sepa_debit';

interface Props {
  selectedPaymentMethod: StripePaymentMethod;
  stripe: Stripe;
  paymentInformation?: PaymentMethod;
  publishPaymentInformation: (payment: { id?: string; type: PaymentMethodType }) => void;
  stripeClientSecret?: string;
  fetchStripeClientSecret: () => void;
}

export const PaymentElementForm: React.FC<Props & WrappedComponentProps> = props => {
  const isDarkMode = useDarkMode();
  const stripeOptions = getOptions(props.selectedPaymentMethod, isDarkMode);

  return (
    <div>
      <Elements stripe={props.stripe} options={stripeOptions}>
        <RioPaymentMethod
          selectedPaymentMethod={props.selectedPaymentMethod}
          stripe={props.stripe}
          stripeClientSecret={props.stripeClientSecret}
          publishPaymentInformation={props.publishPaymentInformation}
          fetchStripeClientSecret={props.fetchStripeClientSecret}
          paymentInformation={props.paymentInformation}
          intl={props.intl}
        />
      </Elements>
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    stripeClientSecret: getStripeClientSecret(state),
  };
}

function mapDispatchToProps(dispatch: RootDispatch) {
  return {
    publishPaymentInformation: ({ type, id }: { type: PaymentMethodType; id?: string }) => {
      dispatch(publishPaymentMethodThunk(type, id));
    },
    fetchStripeClientSecret: () => dispatch(fetchStripeClientSecretThunk),
  };
}

export const PaymentElementContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentElementForm));
