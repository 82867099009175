const manNowProducts = [
  'MAN-SKU00000052', // MAN Now Map Update
  'MAN-SKU00000060', // MAN OnlineTraffic
  'MAN-SKU00000064', // Language package for media system
  'MAN-SKU00000067', // MAN TipMatic
  'MAN-SKU00000072', // MAN EfficientRoute
  'MAN-SKU00000076', // MAN OnlineNews
  'MAN-SKU00000077', // MAN LanguageRecognition
  'MAN-SKU00000084', // MAN Idle Shutdown
  'MAN-SKU00000086', // MAN EfficientRoll
  'MAN-SKU00000087', // MAN EfficientCruise
  'MAN-SKU00000091', // MAN KickdownDeactivation
  'MAN-SKU00000092', // MAN SecondPhone
];

export const isManNowProduct = (productId: string) => {
  return manNowProducts.includes(productId.toUpperCase());
};

export function isManNowParentProduct(productId: string) {
  return productId.toUpperCase() === 'MAN-SKU00000026';
}
