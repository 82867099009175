import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { RootState } from '../../../configuration/setup/store';
import { PaymentMethodPageContainer } from '../common/payment/PaymentMethodPageContainer';
import { getPaymentDetailsIsFetched } from '../common/payment/redux/paymentMethodsSelectionService';
import { safeDataLayerPush } from '../common/utils/googleTagManagerWrapper';
import { isPurchaseFree } from './CheckoutService';
import {
  CheckoutSteps,
  type GACheckoutStepData,
  getGACheckoutStepData,
  getGAEventCheckoutProgress,
} from './checkoutGTMUtils';
import { NextButton, PreviousButton } from './components/NavigationButtons';
import { useRedirectToResourceSelection } from './deeplinking/CheckoutRouteHelper';

interface Props {
  purchaseFree: boolean;
  enableNextStep: boolean;
  nextPath: string;
  previousPath: string;
  gAData: GACheckoutStepData;
}

const CheckoutPaymentPage = (props: Props) => {
  const { purchaseFree, nextPath, previousPath, enableNextStep, gAData } = props;
  useRedirectToResourceSelection();

  useEffect(() => {
    if (gAData.product) {
      safeDataLayerPush(() => getGAEventCheckoutProgress(gAData));
    }
  }, [gAData.product]);

  const content = purchaseFree ? (
    <div className='text-center text-bold margin-10'>
      <FormattedMessage id='marketplace.payment.information.free' />
    </div>
  ) : (
    <PaymentMethodPageContainer />
  );

  return (
    <div className='container-fluid fluid-small'>
      <div className='text-center'>
        <h3>
          <FormattedMessage id='marketplace.payment.headline' />
        </h3>
      </div>
      <div className='CheckoutPaymentPage row panel panel-default'>
        <div className='panel-body'>
          {content}
          <div className='margin-top-10'>
            <PreviousButton previousPath={previousPath} />
            <NextButton nextPath={nextPath} disabled={!enableNextStep} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (state: RootState) => ({
  purchaseFree: isPurchaseFree(state),
  enableNextStep: isPurchaseFree(state) || getPaymentDetailsIsFetched(state),
  gAData: getGACheckoutStepData(state, CheckoutSteps.PAYMENT_INFORMATION_ENTERED),
});

export const CheckoutPaymentPageContainer = connect(mapStateToProps)(CheckoutPaymentPage);
