import packageJson from '../package.json';

export interface ConfigState {
  backend: {
    AUTHENTICATION_SERVICE: string | undefined;
    MENU_SERVICE: string | undefined;
  };
  featureToggles: {
    splitApiKey: string | undefined;
  };
  homeRoute: string | undefined;
  id: string | undefined;
  login: {
    authority: string | undefined;
    clientId: string | undefined;
    oauthScope: string[];
    mockAuthorization: boolean;
    mockLocale: string | undefined;
    preventRedirect: boolean;
    redirectUri: string;
    silentRedirectUri: string | undefined;
    requestType: string;
  };
  logoutUri: string | undefined;
  sentryToken: string | undefined;
  serviceVersion: string;
}

function getBoolEnvValue(envValue: string) {
  return import.meta.env[envValue] !== 'undefined' && import.meta.env[envValue] === 'true';
}

export const config: ConfigState = {
  backend: {
    AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
    MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
  },
  featureToggles: {
    splitApiKey: import.meta.env.VITE_FEATURE_TOGGLE_SPLIT_API_KEY,
  },
  homeRoute: import.meta.env.VITE_HOME_ROUTE,
  id: import.meta.env.VITE_ID,
  login: {
    authority: import.meta.env.VITE_LOGIN_AUTHORITY,
    clientId: import.meta.env.VITE_CLIENT_ID,
    oauthScope: [
      'default',
      'openid',
      'profile',
      'email',
      'marketplace.read',
      'marketplace.write',
      'partner-connection.write',
      'partner-connection.read',
    ],
    requestType: getBoolEnvValue('VITE_LOGIN_REQUEST_TYPE_CODE') ? 'code' : 'id_token token',
    mockAuthorization: getBoolEnvValue('VITE_LOGIN_MOCK_AUTHORIZATION'),
    mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
    preventRedirect: getBoolEnvValue('VITE_LOGIN_PREVENT_REDIRECT'),
    redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI as string,
    silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
  },
  logoutUri: import.meta.env.VITE_LOGOUT_URI,
  sentryToken: import.meta.env.VITE_SENTRY_DSN,
  serviceVersion: packageJson.version,
};
