import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { Selector } from 'reselect';
import type { RootState } from '../../../../../configuration/setup/store';
import type { ServiceOverviewItem } from '../types';
import type { OverviewResource, ResourcesBySku, ServiceOverviewState } from './types';

const initialState: ServiceOverviewState = {
  serviceOverviewList: [],
  cancellationInProgress: [],
  serviceResources: {},
};

export const { reducer: serviceOverviewReducer, actions: serviceOverviewActions } = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setServiceOverviewList: (state: ServiceOverviewState, action: PayloadAction<ServiceOverviewItem[]>) => {
      state.serviceOverviewList = action.payload;
    },
    addToSubscriptionCancelInProgress: (state: ServiceOverviewState, action: PayloadAction<string[]>) => {
      state.cancellationInProgress.push(...action.payload);
    },
    removeFromSubscriptionCancelInProgress: (state: ServiceOverviewState, action: PayloadAction<string[]>) => {
      state.cancellationInProgress = state.cancellationInProgress.filter(id => !action.payload.includes(id));
    },
    addResourcesForSku: (
      state: ServiceOverviewState,
      action: PayloadAction<{ resources: OverviewResource[]; sku: string }>
    ) => {
      state.serviceResources[action.payload.sku] = action.payload.resources;
    },
  },
});

const getBase = (state: RootState): ServiceOverviewState => state.marketplace.services;
export const getServiceOverviewList = (state: RootState) => getBase(state).serviceOverviewList;
export const getCancellingSubscriptions = (state: RootState) => getBase(state).cancellationInProgress;
export const getServiceListResources: Selector<RootState, ResourcesBySku> = (state: RootState) =>
  getBase(state).serviceResources;
