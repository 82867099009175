import Multiselect from '@rio-cloud/rio-uikit/lib/es/Multiselect';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { assetOverviewActions, getTags } from './redux/assetOverview.redux';
import type { Tag } from './redux/types';

interface Props {
  tags: Tag[];
  selectAssetsByTags: (tags: string[]) => void;
}

const TagSelectionDropdown = (props: Props) => {
  const { tags, selectAssetsByTags } = props;
  const intl = useIntl();

  return (
    <div>
      <Multiselect
        useFilter
        placeholder={intl.formatMessage({ id: 'marketplace.assetOverview.groupSelection' })}
        options={tags.map(it => ({
          id: it.id,
          label: it.name,
        }))}
        onChange={selectAssetsByTags}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  tags: getTags(state),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  selectAssetsByTags: (tags: string[]) => {
    dispatch(assetOverviewActions.setAssetsToDisplayByTags(tags));
    dispatch(assetOverviewActions.clearAssetSelection());
    dispatch(assetOverviewActions.setTableSearch(''));
  },
});

export const TagSelectionDropdownContainer = connect(mapStateToProps, mapDispatchToProps)(TagSelectionDropdown);
