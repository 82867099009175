import type { DisplayMessages } from '../../../../configuration';
import type { BookableResource } from '../../checkout/resourceSelection/redux/types';
import { getMarketplaceBackend } from '../../config/pathURL/config';
import { decodeJson, jsonOrReject, onRejected } from '../api';
import { getParameters } from '../parameterService';
import { getRequest } from '../requests';
import { ApiBookableResourcesCodec } from './bookable.types';
import { mapBookableResponse } from './bookableMapper';

export function fetchBookableResources(
  sku: string,
  level: string | undefined,
  displayMessages: DisplayMessages
): Promise<BookableResource[]> {
  const params = getParameters({ level: level });
  return fetch(`${getMarketplaceBackend()}/activations/${sku}/bookable${params}`, getRequest())
    .then(jsonOrReject)
    .then(decodeJson(ApiBookableResourcesCodec))
    .then(mapBookableResponse(displayMessages))
    .catch(onRejected([403]));
}
