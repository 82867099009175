import type { RootState } from '../../../../configuration/setup/store';
import {
  getAllSelectedResourceIds,
  getAvailableResources,
  getInvalidSelectedResourceIds,
} from './redux/resourceSelection.redux';
import { type BookableResource, ProductState } from './redux/types';

export const NOT_FOUND_MISSING_FEATURE =
  'Vehicle could not be found in the system, please consult the fleet administration';

export function getInvalidSelectedResources(state: RootState): BookableResource[] {
  return getInvalidSelectedResourceIds(state)
    .filter(
      it =>
        !getAvailableResources(state)
          .map(resource => resource.id)
          .includes(it)
    )
    .map(it => ({
      id: it,
      name: it,
      bookable: false,
      productState: ProductState.INACTIVE,
      missingRequirements: [[NOT_FOUND_MISSING_FEATURE]],
    }));
}

export function isSelectedFilter(state: RootState) {
  return (it: BookableResource) => getAllSelectedResourceIds(state).includes(it.id);
}
