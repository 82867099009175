import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import type { Dispatch } from 'redux';
import { getAccountId, getDisplayMessages } from '../../../../configuration';
import type { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { deletePartnerConnection, fetchPartnerConnections } from '../../api/partners/partnerCalls';
import { apiCallAction } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { partnersActions } from './redux/partners.redux';

export function getPartnerConnectionsThunk(dispatch: Dispatch, getState: () => RootState) {
  dispatch(apiCallAction(ApiCalls.PARTNER_CONNECTION_GET, true, false));
  const accountId = getAccountId(getState());

  return fetchPartnerConnections(accountId)
    .then(connections => {
      dispatch(partnersActions.setConnections(connections));
      dispatch(apiCallAction(ApiCalls.PARTNER_CONNECTION_GET, false));
    })
    .catch((error: Error) => {
      if (error.message.includes('403')) {
        dispatch(apiCallAction(ApiCalls.PARTNER_CONNECTION_GET, false));
        dispatch(partnersActions.setHasPermissions(false));
      } else {
        dispatch(apiCallAction(ApiCalls.PARTNER_CONNECTION_GET, false, true));
      }
    });
}

export function deletePartnerConnectionThunk(connectionId: string) {
  return (dispatch: RootDispatch, getState: () => RootState) => {
    dispatch(apiCallAction(ApiCalls.PARTNER_CONNECTION_DELETE, true, false));
    const accountId = getAccountId(getState());
    const languageData = getDisplayMessages(getState());

    return deletePartnerConnection(accountId, connectionId)
      .then(() => {
        Notification.success(languageData['marketplace.partners.deleteSuccess']);
        dispatch(getPartnerConnectionsThunk);
        dispatch(apiCallAction(ApiCalls.PARTNER_CONNECTION_DELETE, false));
      })
      .catch((error: Error) => {
        if (error.message.includes('403')) {
          dispatch(apiCallAction(ApiCalls.PARTNER_CONNECTION_DELETE, false));
          Notification.error(
            languageData['marketplace.unauthorized.dialog.message'],
            languageData['marketplace.unauthorized.dialog.title']
          );
        } else {
          Notification.error(languageData['marketplace.partners.deleteError']);
          dispatch(apiCallAction(ApiCalls.PARTNER_CONNECTION_DELETE, false, true));
        }
      });
  };
}
