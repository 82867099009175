import Slider, { type Settings } from 'react-slick';
import { FREE_TRIAL_PRODUCTS } from '../FreeTrialConfig';
import { usePriceFetching } from './priceFetchingHook';
import './slick-dots.css';
import { FreeTrialProductDetails } from './FreeTrialProductDetails';

const sliderSettings: Settings = {
  arrows: false,
  autoplay: true,
  autoplaySpeed: 7000,
  centerMode: true,
  centerPadding: '0',
  dots: true,
  fade: false,
  pauseOnHover: false,
  slidesToScroll: 1,
  slidesToShow: 1,
  variableWidth: false,
  dotsClass: 'slick-dots slick-dots-custom-fte',
};

export function FreeTrialProductsShowcase() {
  const productPrices = usePriceFetching();
  return (
    <div>
      <div className='max-width-350 margin-x-auto margin-bottom-25'>
        <Slider {...sliderSettings}>
          {FREE_TRIAL_PRODUCTS.map(productDetails => {
            const { price, currency } =
              productPrices?.find(
                product =>
                  product.productId.toLowerCase() === productDetails.productId.toLowerCase() &&
                  product.level?.toLowerCase() === productDetails.level?.toLowerCase()
              ) || {};
            return (
              <FreeTrialProductDetails
                productDetails={{ ...productDetails, price, currency }}
                key={productDetails.productId}
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
}
