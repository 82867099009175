import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { InvoiceTable } from './InvoiceTable';
import { InvoicesMonthTitle } from './InvoicesMonthTitle';
import type { DatedInvoices } from './redux/types';

interface Props {
  invoicesMonth: DatedInvoices;
}

export const InvoicesMonth = (props: Props) => {
  const { invoicesMonth } = props;
  return (
    <div className='InvoiceMonth'>
      <br />
      <ExpanderPanel
        title={<InvoicesMonthTitle invoicesMonth={invoicesMonth} />}
        bsStyle='default'
        bodyClassName='padding-0'
      >
        <InvoiceTable invoices={invoicesMonth.invoices} />
      </ExpanderPanel>
    </div>
  );
};
