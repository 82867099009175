import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TermsAndConditionsContainer } from '../../termsAndConditions/TermsAndConditionsContainer';
import { formatDurationUnit } from '../../utils/formatDurationUnit';
import { safeDataLayerPush } from '../../utils/googleTagManagerWrapper';
import type { TermedProductInfo } from '../redux/types';
import type { TermedLevel } from '../types';
import { RenewDialogUpdateButton } from './RenewDialogUpdateButton';
import { getGAEventTermsAccepted } from './contractGtmUtils';

interface Props {
  product: TermedProductInfo;
  selectedLevel: string;
  updatableContracts: TermedLevel[];
  updateContract: () => void;
  isUpdating: boolean;
}

export function RenewFooterRenewal(props: Props) {
  const { selectedLevel, product, updatableContracts, updateContract, isUpdating } = props;
  const intl = useIntl();

  const [termsAccepted, setTermsAccepted] = useState(false);

  const productId = product.productId;
  const productLevel = product.levels.find(level => level.level === selectedLevel);
  const productLevelName = productLevel?.variantName ?? productLevel?.productLevelName ?? '';

  return (
    <div className={'width-50pct-md pull-right text-right margin-top-20 display-flex align-items-end flex-column'}>
      <div className={'margin-bottom-10'}>
        <span className={'rioglyph rioglyph-info-sign margin-right-5'} />
        <FormattedMessage
          id={'marketplace.contracts.renewal.info'}
          values={{
            productLevelName: (
              <b>
                {productLevelName}
                {productLevel?.contractPeriod && (
                  <>
                    {' '}
                    (<FormattedMessage id='intl-msg:common-message.contracts.period' />{' '}
                    {productLevel.contractPeriod.length} {formatDurationUnit(productLevel.contractPeriod.unit, intl)})
                  </>
                )}
              </b>
            ),
          }}
        />
      </div>
      <div className='margin-bottom-20'>
        <Checkbox
          className='checkboxTerms'
          checked={termsAccepted}
          onClick={() => {
            setTermsAccepted(!termsAccepted);
            safeDataLayerPush(() => getGAEventTermsAccepted(productId, selectedLevel));
          }}
        >
          <FormattedMessage id={'marketplace.termsAndConditionCheckPreText'} />
          <TermsAndConditionsContainer serviceSku={productId} level={selectedLevel}>
            <span className='link'>
              <FormattedMessage id={'marketplace.termsAndConditionCheckLinkText'} />
            </span>
          </TermsAndConditionsContainer>
          <FormattedMessage id={'marketplace.termsAndConditionCheckPostTextSingle'} />
        </Checkbox>
      </div>
      {isUpdating ? (
        <Spinner />
      ) : (
        <RenewDialogUpdateButton
          termsAccepted={termsAccepted}
          atLeastOneAssetBookable={updatableContracts.length > 0}
          onClick={updateContract}
        />
      )}
    </div>
  );
}
