import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import DatePicker from '@rio-cloud/rio-uikit/lib/es/DatePicker';
import TableToolbar from '@rio-cloud/rio-uikit/lib/es/TableToolbar';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { getLocale } from '../../../../configuration';
import type { RootState } from '../../../../configuration/setup/store';
import { useIsFleetUser } from '../../common/permissions/hooks';
import { ContractsTableSearchContainer } from './ContractsTableSearchContainer';
import { contractActions, getFromDate, getHideContractsForDeletedAssets, getToDate } from './redux/contract.redux';

interface Props {
  locale: string;
  fromDate?: string;
  onFromDateChange: (fromDate?: string) => void;
  toDate?: string;
  onToDateChange: (toDate?: string) => void;
  hideContractsForDeletedAssets: boolean;
  onToggleHideDeletedContracts: (state: boolean) => void;
}

export function ContractTableToolBar(props: Props) {
  const { locale, fromDate, onFromDateChange, toDate, onToDateChange } = props;
  const isFleetUser = useIsFleetUser();
  return (
    <TableToolbar className={''}>
      <div className='table-toolbar-container'>
        <div className='table-toolbar-group-left'>
          <div className='table-toolbar-column'>
            <label>
              <FormattedMessage id='marketplace.contract.filter.dateFrom' />
            </label>
            <DatePicker
              key={fromDate}
              inputProps={{
                readOnly: true,
                className: 'form-control cursor-pointer bg-white',
                style: { borderColor: 'rgb(167, 175, 187)' },
              }}
              value={fromDate ? new Date(fromDate) : undefined}
              onChange={value => {
                if (typeof value === 'string') {
                  if (value === '') {
                    onFromDateChange(undefined);
                  }
                } else {
                  onFromDateChange(value.format('YYYY-MM-DD'));
                }
              }}
              timeFormat={false}
              minWidth={160}
              closeOnSelect
              clearableInput={true}
              isValidDate={(currentDate: Date) => (toDate ? currentDate < new Date(toDate) : true)}
              locale={locale}
            />
          </div>
          <div className='table-toolbar-column'>
            <label>
              <FormattedMessage id='marketplace.contract.filter.dateTo' />
            </label>
            <DatePicker
              key={toDate}
              inputProps={{
                readOnly: true,
                className: 'form-control cursor-pointer bg-white',
                style: { borderColor: 'rgb(167, 175, 187)' },
              }}
              value={toDate ? new Date(toDate) : undefined}
              onChange={value => {
                if (typeof value === 'string') {
                  if (value === '') {
                    onToDateChange(undefined);
                  }
                } else {
                  onToDateChange(value.format('YYYY-MM-DD'));
                }
              }}
              timeFormat={false}
              minWidth={160}
              closeOnSelect
              clearableInput={true}
              isValidDate={(currentDate: Date) => (fromDate ? currentDate > new Date(fromDate) : true)}
              locale={locale}
            />
          </div>
          <div className='table-toolbar-column'>
            <Checkbox
              disabled={isFleetUser}
              inline
              checked={props.hideContractsForDeletedAssets}
              onClick={() => {
                props.onToggleHideDeletedContracts(!props.hideContractsForDeletedAssets);
              }}
            >
              <FormattedMessage id='marketplace.hideContractsForDeletedVehicles' />
            </Checkbox>
          </div>
        </div>
        <div className='table-toolbar-group-right'>
          <div className='table-toolbar-column'>
            <ContractsTableSearchContainer />
          </div>
        </div>
      </div>
    </TableToolbar>
  );
}

const mapStateToProps = (state: RootState) => ({
  locale: getLocale(state),
  fromDate: getFromDate(state),
  toDate: getToDate(state),
  hideContractsForDeletedAssets: getHideContractsForDeletedAssets(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFromDateChange: (fromDate?: string) => dispatch(contractActions.setFromDate(fromDate)),
  onToDateChange: (toDate?: string) => dispatch(contractActions.setToDate(toDate)),
  onToggleHideDeletedContracts: (state: boolean) => dispatch(contractActions.setHideContractsForDeletedAssets(state)),
});

export const ContractTableToolBarContainer = connect(mapStateToProps, mapDispatchToProps)(ContractTableToolBar);
