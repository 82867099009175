import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { getSelectedResourceIds } from '../resourceSelection/redux/resourceSelection.redux';

export const useRedirectToResourceSelection = () => {
  const navigate = useNavigate();
  const resourceIds = useSelector(getSelectedResourceIds);
  const { search } = useLocation();
  useEffect(() => {
    if (resourceIds.length === 0) {
      navigate(`/checkout/1${search}`, { replace: true });
    }
  }, [resourceIds, navigate, search]);
  return null;
};
