import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router';
import type { RootDispatch, RootState } from '../../../configuration/setup/store';
import { getHasError, getIsLoading } from '../api/redux/api.redux';
import { ApiCalls } from '../api/redux/types';
import LoadingErrorStateWithReload from '../common/error/LoadingErrorStateWithReload';
import { UnauthorizedDialog } from '../common/error/UnauthorizedDialog';
import { fetchPermissionsThunk } from '../common/permissions/permissions.thunk';
import { getPermissions } from '../common/permissions/redux/permissions.redux';
import { getCustomerCenterPath } from '../common/routes';
import { PaymentMethodsContainer } from './PaymentMethodsContainer';
import { AssetOverviewContainer } from './assetOverview/AssetOverviewContainer';
import { CompanySettingsTabContainer } from './companySettings/CompanySettingsTab';
import { ContractPageContainer } from './contracts/ContractPageContainer';
import { getAllVisibleCustomerCenterTabs, getDefaultCustomerCenterTab } from './customerCenterTabService';
import { FreeTrialOverviewContainer } from './freeTrial/FreeTrialOverviewContainer';
import { HistoryContainer } from './history/HistoryContainer';
import { InboundConnectionsTab } from './inbound/InboundConnectionsTab';
import { InvoicesContainer } from './invoices/InvoicesContainer';
import { PartnersContainer } from './partners/PartnersContainer';
import { ProfileTab } from './profile/ProfileTab';
import { ServiceOverviewContainer } from './serviceOverview/ServiceOverviewContainer';
import { CustomerCenterTab, type TabInfo } from './types';
import { Usage } from './usage/Usage';

interface Props {
  permissions?: string[];
  hasError: boolean;
  isLoading: boolean;
  fetchPermissions: () => void;
  defaultTab: CustomerCenterTab | undefined;
  visibleTabs: TabInfo[];
}

export function CustomerCenter(props: Props) {
  const { defaultTab, fetchPermissions, visibleTabs, isLoading, hasError } = props;

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);
  if (isLoading) {
    return <Spinner text={<FormattedMessage id={'marketplace.loading'} />} isInverse={false} />;
  }

  if (hasError) {
    return (
      <LoadingErrorStateWithReload headline={'marketplace.customerCenter.loading.error'} onReload={fetchPermissions} />
    );
  }

  if (visibleTabs.length <= 0 || !defaultTab) {
    return <UnauthorizedDialog />;
  }

  const containsFreeTrial = visibleTabs.some(tab => tab.componentName === CustomerCenterTab.FREE_TRIAL);
  const bookingTab = containsFreeTrial ? CustomerCenterTab.FREE_TRIAL : CustomerCenterTab.SERVICES;

  return (
    <div className={'CustomerCenter'}>
      <Routes>
        <Route path={CustomerCenterTab.SERVICES} element={<ServiceOverviewContainer />} />
        <Route path={CustomerCenterTab.ASSETS} element={<AssetOverviewContainer />} />
        <Route path={CustomerCenterTab.CONTRACTS} element={<ContractPageContainer />} />
        <Route path={CustomerCenterTab.INVOICES} element={<InvoicesContainer />} />
        <Route path={CustomerCenterTab.HISTORY} element={<HistoryContainer />} />
        <Route path={CustomerCenterTab.PROFILE} element={<ProfileTab />} />
        <Route path={CustomerCenterTab.PAYMENT_METHODS} element={<PaymentMethodsContainer />} />
        <Route path={CustomerCenterTab.USAGE} element={<Usage />} />
        <Route path={CustomerCenterTab.COMPANY_SETTINGS} element={<CompanySettingsTabContainer />} />
        <Route
          path={CustomerCenterTab.PARTNERS}
          element={<Navigate replace to={getCustomerCenterPath(CustomerCenterTab.INBOUND)} />}
        />
        <Route path={`${CustomerCenterTab.INBOUND}/*`} element={<InboundConnectionsTab />} />
        <Route path={CustomerCenterTab.OUTBOUND} element={<PartnersContainer />} />
        <Route path={CustomerCenterTab.FREE_TRIAL} element={<FreeTrialOverviewContainer />} />
        <Route
          path={CustomerCenterTab.BOOKINGS}
          element={<Navigate replace to={getCustomerCenterPath(bookingTab)} />}
        />
        <Route path='/' element={<Navigate replace to={getCustomerCenterPath(defaultTab)} />} />
      </Routes>
    </div>
  );
}

export function mapStateToProps(state: RootState) {
  return {
    permissions: getPermissions(state),
    isLoading: getIsLoading(state, ApiCalls.PERMISSIONS),
    hasError: getHasError(state, ApiCalls.PERMISSIONS),
    visibleTabs: getAllVisibleCustomerCenterTabs(state),
    defaultTab: getDefaultCustomerCenterTab(state),
  };
}

export function mapDispatchToProps(dispatch: RootDispatch) {
  return {
    fetchPermissions: () => dispatch(fetchPermissionsThunk),
  };
}

export const CustomerCenterContainer = connect(mapStateToProps, mapDispatchToProps)(CustomerCenter);
