import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import pick from 'lodash/pick';
import type { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { useIsFleetUser } from '../../common/permissions/hooks';
import { ProductTypedFormattedMessage } from '../../common/product/ProductTypedFormattedMessage';
import type { ProductType } from '../../common/product/product';
import { termedLevelRenewalActions } from '../../common/termedLevel/redux/termedLevelRenewal.redux';
import type { TermedLevelId } from '../../common/termedLevel/redux/types';
import type { TermedLevel } from '../../common/termedLevel/types';
import { ContractResourceName } from '../contracts/ContractResourceName';
import { ContractTableRowRenewCell } from '../contracts/ContractTableRowRenewCell';
import { freeTrialTabActions, getSelectedFreeTrialActivations } from './redux/freeTrialTab.redux';

export const getFreeTrialColumns = (
  productType: ProductType
): Array<{ propertyName: keyof TermedLevel; label: ReactElement; width?: string }> => [
  {
    propertyName: 'resourceName',
    label: (
      <ProductTypedFormattedMessage
        productType={productType}
        userBasedId='marketplace.freetrial.user.table.header'
        assetBasedId='marketplace.freetrial.asset.table.header'
      />
    ),
  },
  { propertyName: 'renewsToLevelName', label: <FormattedMessage id={'marketplace.freeTrialTab.renewal'} /> },
];

interface Props {
  freeTrialActivation: TermedLevel;
  updateContract: (data: TermedLevelId & { renewsToLevel: string | undefined }) => void;
  toggleSelectedContract: (contractId: TermedLevelId) => void;
  isContractSelected: boolean;
}

export function FreeTrialTableRow(props: Props) {
  const { freeTrialActivation, updateContract, toggleSelectedContract, isContractSelected } = props;
  const fleetUser = useIsFleetUser();

  const onClickRenewalButton = (e: React.MouseEvent) => {
    e.stopPropagation();
    updateContract({
      productId: freeTrialActivation.productId,
      resourceId: freeTrialActivation.resourceId,
      renewsToLevel: freeTrialActivation.renewsToLevel,
    });
  };

  return (
    <tr
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        toggleSelectedContract(pick(freeTrialActivation, ['productId', 'resourceId']));
      }}
    >
      <td>
        {' '}
        <Checkbox checked={isContractSelected} disabled={fleetUser} />
      </td>
      <td>
        <ContractResourceName contract={freeTrialActivation} />
      </td>
      <td>
        <ContractTableRowRenewCell contract={freeTrialActivation} onClick={onClickRenewalButton} />
      </td>
    </tr>
  );
}

const mapStateToProps = (state: RootState, ownProps: { freeTrialActivation: TermedLevel }) => ({
  isContractSelected:
    getSelectedFreeTrialActivations(state).find(
      contractId =>
        ownProps.freeTrialActivation.productId === contractId.productId &&
        ownProps.freeTrialActivation.resourceId === contractId.resourceId
    ) !== undefined,
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  updateContract: (data: TermedLevelId & { renewsToLevel: string | undefined }) => {
    const { renewsToLevel, ...contractId } = data;
    dispatch(
      termedLevelRenewalActions.selectForUpdate({
        contracts: [contractId],
        renewsToLevel,
        productId: data.productId,
      })
    );
  },
  toggleSelectedContract: (contractId: TermedLevelId) =>
    dispatch(freeTrialTabActions.toggleSelectedFreeTrial(contractId)),
});

export const FreeTrialTableRowContainer = connect(mapStateToProps, mapDispatchToProps)(FreeTrialTableRow);
