import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { RootState } from '../../../../configuration/setup/store';
import { getHasError, getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { getIsCheckoutLocked, getSelectedProduct } from '../redux/checkout.redux';
import type { Product } from '../redux/types';

interface Props {
  hasError: boolean;
  isLoading: boolean;
  isLockedSelected: boolean;
  product: Product | undefined;
}

function BusCustomerHint({ isLoading, hasError, isLockedSelected, product }: Props) {
  if (isLoading || hasError || isLockedSelected || !product || product.sku.toUpperCase() !== 'MAN-SKU00000005') {
    return null;
  }

  return (
    <div className={'pull-left padding-top-5'}>
      <span className={'rioglyph rioglyph-info-sign margin-left-10'} />
      <span>
        {' '}
        <span className={'text-bold'}>
          <FormattedMessage id={'marketplace.resourceSelection.perform.busCustomer.hint1'} />
        </span>{' '}
        <FormattedMessage id={'marketplace.resourceSelection.perform.busCustomer.hint2'} />
      </span>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  hasError: getHasError(state, ApiCalls.RESOURCE_SELECTION),
  isLoading: getIsLoading(state, ApiCalls.CHECKOUT_PRODUCT_DETAILS),
  isLockedSelected: getIsCheckoutLocked(state),
  product: getSelectedProduct(state),
});

export const BusCustomerHintContainer = connect(mapStateToProps)(BusCustomerHint);
