import { normalizeLocaleForKoreanUserOrHandThrough } from '../common/utils/koreaUtil';
import { Tenant } from '../common/utils/tenants';
import {
  KOREA_OPENMARKETPLACE_BASE_URL,
  OPENMARKETPLACE_BASE_URL,
  REDIRECT_CONFIG,
  setLocaleForRedirect,
} from '../config/openMarketplaceRedirectConfig';

export function redirectIfSkuMatches(sku: string | undefined, locale: string, tenant: Tenant) {
  if (!sku) {
    return;
  }
  const isKoreanUser = tenant === Tenant.SOUTH_KOREA;
  const normalizedLocale = normalizeLocaleForKoreanUserOrHandThrough(locale, isKoreanUser);
  const redirect = REDIRECT_CONFIG.filter(entry => (entry.korea || false) === isKoreanUser).find(
    entry => entry.sku.toLowerCase() === sku.toLowerCase()
  );
  if (!redirect) {
    const redirectToStartPage = isKoreanUser
      ? setLocaleForRedirect(KOREA_OPENMARKETPLACE_BASE_URL, normalizedLocale)
      : setLocaleForRedirect(OPENMARKETPLACE_BASE_URL, normalizedLocale);
    window.location.replace(redirectToStartPage);
    return;
  }
  window.location.replace(setLocaleForRedirect(redirect.url, normalizedLocale));
}
