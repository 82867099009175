import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import type { RootState } from '../../../../configuration/setup/store';
import { assetOverviewActions, getTableSearch } from './redux/assetOverview.redux';

interface Props {
  searchValue: string;
  onSearchChange: (searchValue: string) => void;
}

const AssetOverviewTableSearch = (props: Props) => {
  const { searchValue, onSearchChange } = props;
  const intl = useIntl();

  return (
    <TableSearch
      placeholder={intl.formatMessage({ id: 'marketplace.usage.summary.search.placeholder' })}
      value={searchValue}
      onChange={onSearchChange}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  searchValue: getTableSearch(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSearchChange: (searchValue: string) => dispatch(assetOverviewActions.setTableSearch(searchValue)),
});

export const AssetOverviewTableSearchContainer = connect(mapStateToProps, mapDispatchToProps)(AssetOverviewTableSearch);
