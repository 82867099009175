import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../../../configuration/setup/store';
import type { PermissionsState } from './types';

const initialState: PermissionsState = {};

export const { reducer: permissionsReducer, actions: permissionsActions } = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setPermissions: (state: PermissionsState, action: PayloadAction<string[] | undefined>) => {
      state.permissionList = action.payload;
    },
  },
});

const getBase = (state: RootState): PermissionsState => state.marketplace.permissions;

export const getPermissions = (state: RootState) => getBase(state).permissionList;
export const isFleetAdmin = (state: RootState) => getBase(state).permissionList?.includes('subscription.write');
