import { connect } from 'react-redux';

import type { RootState } from '../../../../configuration/setup/store';
import { useRedirectToResourceSelection } from '../deeplinking/CheckoutRouteHelper';
import { getServiceCareCheckoutRoute } from '../deeplinking/checkoutRouteService';

export const NEW_SERVICE_CARE_SKU = 'man-sku00000049';
export const KOREA_SERVICE_CARE_SKU = 'kor-sku00000053';
export const SERVICECARE_EXTRA_STEP_URL = import.meta.env.VITE_SERVICECARE_EXTRA_STEP_URL;

export function isServiceCareProduct(productId: string | undefined): boolean {
  return productId?.toLowerCase() === NEW_SERVICE_CARE_SKU || productId?.toLowerCase() === KOREA_SERVICE_CARE_SKU;
}

interface Props {
  previousStep: string;
  nextStep: string;
  serviceCareSearch: string;
}

function addOrigin(nextStep: string) {
  return encodeURIComponent(`${window.location.origin}${nextStep}`);
}

export const RedirectToWorkshopAssignmentComponent = (props: Props) => {
  const { nextStep, previousStep, serviceCareSearch } = props;
  useRedirectToResourceSelection();

  const enhancedSearch = `?${serviceCareSearch}&next_page=${addOrigin(nextStep)}&previous_page=${addOrigin(
    previousStep
  )}`;
  const workshopAssignedUrl = `${SERVICECARE_EXTRA_STEP_URL}${enhancedSearch}`;
  window.location.replace(workshopAssignedUrl);
  return null;
};

const mapStateToProps = (state: RootState) => ({
  serviceCareSearch: getServiceCareCheckoutRoute(state),
});

export const RedirectToWorkshopAssignment = connect(mapStateToProps)(RedirectToWorkshopAssignmentComponent);
