import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { connect } from 'react-redux';
import type { RootState } from '../../../../configuration/setup/store';
import { getSelectedProduct } from '../redux/checkout.redux';
import { isMultiLevelProduct } from '../redux/types';
import { MultiLevelSelectedResourceListContainer } from './MultiLevelResourceSelectionBookableResourceList';
import { ProductTypeAwareFormattedMessage } from './ProductTypeAwareFormattedMessage';
import { PreSelectedValidResourcesExpander } from './ResourceSelectionExpanderContainer';
import { getSelectedResourceIds } from './redux/resourceSelection.redux';
import { getFilteredGroupedResources } from './resourceSelectionSearchService';

interface Props {
  productIsMultiLevel: boolean;
  numberOfSelectedValidResources: number;
}

function ReadOnlySelectedResources(props: Props) {
  const { productIsMultiLevel, numberOfSelectedValidResources } = props;
  if (numberOfSelectedValidResources === 0) {
    return null;
  }
  return productIsMultiLevel ? (
    <ExpanderPanel
      title={
        <b>
          <ProductTypeAwareFormattedMessage id={'headlineLocked'} />
          {` (${numberOfSelectedValidResources})`}
        </b>
      }
      bsStyle='default'
      bodyClassName={'bg-lightest'}
      open
    >
      <MultiLevelSelectedResourceListContainer />
    </ExpanderPanel>
  ) : (
    <PreSelectedValidResourcesExpander />
  );
}

const mapStateToProps = (state: RootState) => ({
  productIsMultiLevel: isMultiLevelProduct(getSelectedProduct(state)),
  numberOfSelectedValidResources: getFilteredGroupedResources(state).bookable.filter(it =>
    getSelectedResourceIds(state).includes(it.id)
  ).length,
});

export const ReadOnlySelectedResourcesContainer = connect(mapStateToProps)(ReadOnlySelectedResources);
