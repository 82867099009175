import { getMarketplaceBackend } from '../../config/pathURL/config';
import type { ServiceOverviewItem } from '../../customerCenter/serviceOverview/types';
import { decodeJson, jsonOrReject, onRejected } from '../api';
import { getRequest } from '../requests';
import { mapActivationSummaryResponse } from './activationSummaryMapper';
import { ApiActivationSummaryCodec } from './service.types';

export function fetchActivationSummaryDetailed(): Promise<ServiceOverviewItem[]> {
  return fetch(`${getMarketplaceBackend()}/activations/summary?embed=product`, getRequest())
    .then(jsonOrReject)
    .then(decodeJson(ApiActivationSummaryCodec))
    .then(response => mapActivationSummaryResponse(response))
    .catch(onRejected([401, 403]));
}
