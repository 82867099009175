import { FormattedMessage } from 'react-intl';
import type { PaymentMethod } from './redux/types';

export const PaymentInformationCreditCard = (props: { paymentInformation?: PaymentMethod }) => {
  const { ownerName, brand, expirationMonth, expirationYear, paymentAccountNumber } = props.paymentInformation || {};

  return (
    <div className='PaymentCreditCardInfomation'>
      <div className='paymentType'>
        <span className='payment-label'>
          <FormattedMessage id='marketplace.payment.creditCard.label.paymentType' />
          {': '}
        </span>
        <span className='text-bold'>
          <FormattedMessage id='marketplace.payment.method.creditCard' />
        </span>
      </div>
      <div className='brand'>
        <span className='payment-label'>
          <FormattedMessage id='marketplace.payment.creditCard.label.brand' />
          {': '}
        </span>
        <span className='text-bold'>{brand}</span>
      </div>
      {ownerName && (
        <div className='name'>
          <span className='payment-label'>
            <FormattedMessage id='marketplace.payment.creditCard.label.owner.name' />
            {': '}
          </span>
          <span className='text-bold'>{ownerName}</span>
        </div>
      )}
      <div className='accountNumber'>
        <span className='payment-label'>
          <FormattedMessage id='marketplace.payment.creditCard.label.accountNumber' />
          {': '}
        </span>
        <span className='text-bold'>{`****${paymentAccountNumber}`}</span>
      </div>
      <div className='expiration'>
        <span className='payment-label'>
          <FormattedMessage id='marketplace.payment.creditCard.label.expiration' />
          {': '}
        </span>
        <span className='text-bold'>{`${expirationMonth}/${expirationYear}`}</span>
      </div>
    </div>
  );
};
