import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import { connect } from 'react-redux';
import type { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { useIsFleetUser } from '../../common/permissions/hooks';
import type { TermedLevelId } from '../../common/termedLevel/redux/types';
import { isTermedLevelSelectable } from '../../common/termedLevel/termedLevelSelectionValidationService';
import { useCheckboxState } from '../../common/utils/checkboxUtil';
import { contractActions, getSelectedContracts } from './redux/contract.redux';
import { getFilteredContractsByAssets } from './redux/contractSearchService';

interface Props {
  productId: string;
  displayedContracts: TermedLevelId[];
  relevantSelectedContracts: TermedLevelId[];
  otherSelectedContracts: TermedLevelId[];
  setSelectedContracts: (selectedContracts: TermedLevelId[]) => void;
}

function AllContractsCheckbox({
  displayedContracts,
  relevantSelectedContracts,
  otherSelectedContracts,
  setSelectedContracts,
}: Props) {
  const checked = useCheckboxState(displayedContracts, relevantSelectedContracts);
  const fleetUser = useIsFleetUser();
  const contractsToSelect = checked ? otherSelectedContracts : [...otherSelectedContracts, ...displayedContracts];

  return (
    <Checkbox
      checked={Boolean(checked)}
      indeterminate={checked === undefined}
      onClick={() => setSelectedContracts(contractsToSelect)}
      disabled={fleetUser}
    />
  );
}

const mapStateToProps = (state: RootState, { productId }: Pick<Props, 'productId'>) => ({
  displayedContracts: getFilteredContractsByAssets(state).displayRows.filter(
    contract => contract.productId === productId && isTermedLevelSelectable(contract)
  ),
  relevantSelectedContracts: getSelectedContracts(state).filter(contract => contract.productId === productId),
  otherSelectedContracts: getSelectedContracts(state).filter(contract => contract.productId !== productId),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  setSelectedContracts: (selectedContracts: TermedLevelId[]) =>
    dispatch(contractActions.setSelectedContracts(selectedContracts)),
});

export const AllContractsCheckboxContainer = connect(mapStateToProps, mapDispatchToProps)(AllContractsCheckbox);
