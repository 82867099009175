import type { RootState } from '../../../configuration/setup/store';
import { isServiceCareLBeta } from '../common/betaService';
import { getUnappliedAssetDiscounts } from '../common/discount/redux/discount.redux';
import type { AssetDiscount } from '../common/discount/redux/types';
import { isRatePlanBookable } from '../common/product/productService';
import { isManNowProduct } from '../config/manNowProducts';
import {
  getLevelOfProduct,
  getSelectedProduct,
  getSelectedProductId,
  getSelectedProductLevel,
  getSelectedProductOrProductLevel,
} from './redux/checkout.redux';
import {
  type BookableProduct,
  type MultiLevelProduct,
  type Product,
  isBookableProduct,
  isMultiLevelProduct,
  isViewOnlyProduct,
} from './redux/types';
import { getSelectedResourceIds } from './resourceSelection/redux/resourceSelection.redux';

export const isPurchaseFree = (state: RootState) => {
  const isFreeWithoutContractPeriod = (product: BookableProduct) => product.price === 0 && !product.contractPeriod;
  const selectedProduct = getSelectedProduct(state);

  if (isBookableProduct(selectedProduct)) {
    return isFreeWithoutContractPeriod(selectedProduct) || isSelectedProductFullyDiscountedForSelectedResources(state);
  }
  if (isMultiLevelProduct(selectedProduct)) {
    const selectedProductLevel = getSelectedProductLevel(state);

    if (
      isSelectedProductFullyDiscountedForSelectedResources(state) &&
      !selectedLevelRenewsToPaidLevel(selectedProduct, selectedProductLevel)
    ) {
      return true;
    }
  }
  const levelOfProduct = getLevelOfProduct(state);
  if (levelOfProduct?.price === 0 && !levelOfProduct?.contractPeriod) {
    return true;
  }

  return !!isViewOnlyProduct(selectedProduct);
};

function isSelectedProductFullyDiscountedForSelectedResources(state: RootState): boolean {
  const matchesProduct = (discount: AssetDiscount) =>
    discount.productId.toLowerCase() === selectedProduct.sku.toLowerCase() &&
    (!selectedProductLevel || discount.productLevels?.includes(selectedProductLevel));
  const matchesPercentage = (discount: AssetDiscount) => discount.discountPercentage === 100;
  const matchesContractPeriod = (discount: AssetDiscount) => {
    if (!selectedProduct.contractPeriod) {
      return false;
    }
    return (
      selectedProduct.contractPeriod.length === discount.durationPeriod &&
      selectedProduct.contractPeriod.unit.toString().startsWith(discount.durationUnit.toString())
    );
  };

  const assetDiscounts = getUnappliedAssetDiscounts(state);
  const selectedProduct = getSelectedProductOrProductLevel(state);
  const selectedProductLevel = getSelectedProductLevel(state);
  const selectedResourceIds = getSelectedResourceIds(state);
  const relevantAssetDiscounts = assetDiscounts
    .filter(matchesProduct)
    .filter(matchesPercentage)
    .filter(matchesContractPeriod);

  return (
    selectedResourceIds.length > 0 &&
    relevantAssetDiscounts.length > 0 &&
    selectedResourceIds.every(resourceId => relevantAssetDiscounts.some(discount => discount.assetId === resourceId))
  );
}

function selectedLevelRenewsToPaidLevel(
  selectedProduct: MultiLevelProduct,
  selectedProductLevel: string | undefined
): boolean {
  const selectedLevelWithData = selectedProduct.levels.find(
    productLevel => productLevel.level === selectedProductLevel
  );
  const selectedLevelRenewsTo = selectedProduct.levels.find(
    productLevel => productLevel.level === selectedLevelWithData?.renewsToLevel
  );

  return selectedLevelRenewsTo !== undefined && selectedLevelRenewsTo.price !== 0;
}

export const getCurrencyOfSelectedProduct = (state: RootState): string | undefined => {
  const product = getSelectedProduct(state);
  if (isBookableProduct(product)) {
    return product.currency;
  }
  if (isMultiLevelProduct(product)) {
    return getLevelOfProduct(state)?.currency;
  }
  return undefined;
};

function isProductLevelBookable(selectedProduct: Product, selectedProductLevel: string) {
  if (isBookableProduct(selectedProduct) && selectedProductLevel === selectedProduct.level) {
    return true;
  }

  if (isMultiLevelProduct(selectedProduct)) {
    const selectedRatePlan = selectedProduct.levels.find(ratePlan => ratePlan.level === selectedProductLevel);

    return (
      selectedRatePlan !== undefined &&
      isRatePlanBookable(selectedRatePlan) &&
      !isServiceCareLBeta(selectedProduct.sku, selectedProductLevel)
    );
  }

  return false;
}

export const isSelectedProductBookable = (state: RootState): boolean => {
  const selectedProductId = getSelectedProductId(state);
  const selectedProductLevel = getSelectedProductLevel(state);
  const selectedProduct = getSelectedProduct(state);

  if (!selectedProductId || !selectedProduct || isManNowProduct(selectedProductId)) {
    return false;
  }
  if (selectedProductLevel) {
    return isProductLevelBookable(selectedProduct, selectedProductLevel);
  }
  return isBookableProduct(selectedProduct) && !selectedProduct.level && !isMultiLevelProduct(selectedProduct);
};
