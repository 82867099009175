import type { Period } from '../../../common/termedLevel/types';

export enum UsageSummaryColumnType {
  PRODUCT_COLUMN = 0,
  SPACER_COLUMN = 1,
  RESOURCE = 2,
  PRICE = 3,
}

export interface UsageSummaryColumn {
  productName?: string;
  productSku?: string;
  ratePlanId?: string;
  symbol?: string;
  variantName?: string;
  contractPeriod?: Period;
  type: UsageSummaryColumnType;
  level?: string;
}
