export const SUCCESSFULLY_BOOKED = 'SUCCESSFULLY_BOOKED';
export const CLOSE_IFRAME = 'CLOSE_IFRAME';

export interface BookingSuccessMessage {
  type: typeof SUCCESSFULLY_BOOKED;
  productId?: string;
}

export interface CloseIframeMessage {
  type: typeof CLOSE_IFRAME;
}
