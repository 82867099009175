import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { RootDispatch, RootState } from '../../../../../configuration/setup/store';
import { getIsLoading } from '../../../api/redux/api.redux';
import { ApiCalls } from '../../../api/redux/types';
import type { TermedLevelsForUpdate } from '../redux/types';
import { autoRenewToSameLevelThunk, cancelRenewalsThunk } from '../termedLevel.thunk';
import { ContractList } from './ContractList';
import { getTermedLevelsForUpdate } from './dialogService';

interface Props {
  contracts: TermedLevelsForUpdate;
  disableRenewal: () => void;
  enableRenewal: () => void;
  isUpdating: boolean;
}

const RenewDialogContentManNow = (props: Props) => {
  const { contracts, disableRenewal, enableRenewal, isUpdating } = props;

  return (
    <div className={'margin-bottom-20'}>
      <div>
        <div className={'margin-bottom-20'}>
          <FormattedMessage id={'marketplace.contracts.mannow.renew.dialog.header'} />
        </div>
        <ContractList
          contracts={contracts.updatable}
          labelId={'marketplace.contracts.renewable'}
          searchTerm={''}
          open={true}
          singleContractView={contracts.updatable.length === 1}
        />
        <ContractList
          contracts={contracts.notUpdatable}
          labelId={'marketplace.contracts.notRenewable'}
          searchTerm={''}
          open={false}
          singleContractView={contracts.notUpdatable.length === 1}
        />
      </div>
      <div className={'width-50pct-md pull-right text-right margin-top-20 display-flex align-items-end flex-column'}>
        <div>
          <span className={'rioglyph rioglyph-info-sign margin-right-5'} />
          <FormattedMessage id={'marketplace.contracts.mannow.renew.dialog.info'} />
        </div>
        <IneligibleContractsWarning
          ineligibleContractCount={contracts.notUpdatable.length}
          totalContractCount={contracts.updatable.length + contracts.notUpdatable.length}
        />
        <div className={'pull-right text-right display-flex justify-content-end flex-row margin-top-20'}>
          {isUpdating ? (
            <Spinner />
          ) : (
            <div className={'btn-toolbar'}>
              <button type={'button'} className={'btn btn-default'} onClick={disableRenewal}>
                <span className={'rioglyph rioglyph-remove margin-right-5'} />
                <FormattedMessage id={'marketplace.contracts.noRenew'} />
              </button>
              <button
                type={'button'}
                className={'btn btn-primary'}
                onClick={enableRenewal}
                disabled={contracts.updatable.length === 0}
              >
                <span className={'rioglyph rioglyph-repeat margin-right-5'} />
                <FormattedMessage id={'marketplace.contracts.renew'} />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const IneligibleContractsWarning = (props: { ineligibleContractCount: number; totalContractCount: number }) => {
  const { ineligibleContractCount, totalContractCount } = props;
  if (ineligibleContractCount > 0) {
    return (
      <div>
        <span className={'rioglyph rioglyph-exclamation-sign margin-right-5'} />
        <FormattedMessage
          id={'marketplace.contracts.mannow.renew.dialog.eligibilityWarning'}
          values={{
            ineligibleContractCount,
            totalContractCount,
            eligibleContractCount: totalContractCount - ineligibleContractCount,
          }}
        />
      </div>
    );
  }
  return null;
};

const mapStateToProps = (state: RootState) => ({
  contracts: getTermedLevelsForUpdate(state),
  isUpdating: getIsLoading(state, ApiCalls.CONTRACT_UPDATE),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  disableRenewal: () => dispatch(cancelRenewalsThunk),
  enableRenewal: () => dispatch(autoRenewToSameLevelThunk),
});

export const RenewDialogContentManNowContainer = connect(mapStateToProps, mapDispatchToProps)(RenewDialogContentManNow);
