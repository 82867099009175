import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import type { RootState } from '../../../../../configuration/setup/store';
import { loadOnboardingTip, saveOnboardingTip } from '../onboardingToLocalStorage';
import { Tips } from '../types';
import type { OnboardingState } from './types';

const initialState: OnboardingState = {
  [Tips.USAGE_TAB]: loadOnboardingTip(Tips.USAGE_TAB),
  [Tips.RENEW_CONTRACTS]: loadOnboardingTip(Tips.RENEW_CONTRACTS),
};

const name = 'onboarding';

export const { reducer: onboardingReducer, actions: onboardingActions } = createSlice({
  name,
  initialState,
  reducers: {
    setShowOnboardingTip: (state: OnboardingState, action: PayloadAction<{ type: Tips; value: boolean }>) => {
      state[action.payload.type] = action.payload.value;
      saveOnboardingTip(action.payload.type, action.payload.value);
    },
  },
});

const getBase = (state: RootState): OnboardingState | undefined => state.marketplace.onboarding;
export const getShowOnboardingTip = (state: RootState, tip: Tips): boolean => get(getBase(state), tip, false);
