import { loadFromLocalStorage, saveInLocalStorage } from './handleLocalStorage';
import type { Tips } from './types';

export const saveOnboardingTip = (tip: Tips, value: boolean) => {
  saveInLocalStorage(tip, value);
};
export const loadOnboardingTip = (tip: Tips): boolean => {
  const loadedShowTipFlag = loadFromLocalStorage(tip);
  return loadedShowTipFlag === undefined ? true : loadedShowTipFlag;
};
