export enum GtmPromotion {
  customerCenterAddMore = 'customerCenter-addMore',
  assetOverviewAddSingle = 'assetOverview-addSingle',
  assetOverviewAddMultiple = 'assetOverview-addMultiple',
  customerCenterUpgrade = 'customerCenter-upgrade',
  customerCenterDowngrade = 'customerCenter-downgrade',
}

export function getGtmPromotionViewEvent(promotion: GtmPromotion) {
  return {
    event: 'EECpromotionImpression',
    ecommerce: {
      promoView: {
        promotions: [{ id: promotion, name: promotion }],
      },
    },
  };
}

export function getGtmPromotionClickEvent(promotion: GtmPromotion) {
  return {
    event: 'EECpromotionClick',
    ecommerce: {
      promoClick: {
        promotions: [{ id: promotion, name: promotion }],
      },
    },
  };
}
