import debounce from 'lodash/fp/debounce';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { getCheckoutRouteSearch } from './checkoutRouteService';

const PUSH_ROUTE_DEBOUNCE = 200;

export const useCheckoutRouteUpdatingHook = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const routeSearchToPushTo = useSelector(getCheckoutRouteSearch);

  const replaceRoute = useMemo<(route: string) => void>(() => {
    return debounce(PUSH_ROUTE_DEBOUNCE)(route =>
      navigate(route, {
        replace: true,
      })
    );
  }, [navigate]);

  useEffect(() => {
    if (search !== `?${routeSearchToPushTo}`) {
      replaceRoute(`${pathname}?${routeSearchToPushTo}`);
    }
  }, [replaceRoute, routeSearchToPushTo, pathname, search]);
};
