import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { Elements } from '@stripe/react-stripe-js';
import { type Stripe, type StripeElementLocale, loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import type { RootDispatch, RootState } from '../../../configuration/setup/store';
import { getHasError } from '../api/redux/api.redux';
import { ApiCalls } from '../api/redux/types';
import LoadingErrorState from '../common/error/LoadingErrorState';
import { UnauthorizedDialog } from '../common/error/UnauthorizedDialog';
import { fetchStripeApiKeyThunk } from '../common/payment/fetchStripeKeys.thunk';
import { getStripeApiKey } from '../common/payment/redux/paymentMethods.redux';
import { PAYMENT_INFORMATION_WRITE } from '../common/permissions/permissions';
import withPermissionValidator from '../common/permissions/withPermissionValidator';
import { getLocaleShort } from '../lang/languageData';
import ReauthenticationPageContainer from './ReauthenticationPageContainer';

interface Props {
  stripeApiKey?: string;
  stripeApiKeyFetchError: boolean;
  fetchStripeApiKey: () => void;
  userHasPermissions: (permissions: string[]) => boolean;
}

export function ReauthenticationPageStripeContainer(props: Props) {
  const userHasPermissionsToWritePaymentDetails = () => props.userHasPermissions([PAYMENT_INFORMATION_WRITE]);
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    if (userHasPermissionsToWritePaymentDetails()) {
      if (!props.stripeApiKey) {
        props.fetchStripeApiKey();
      } else if (!stripe) {
        loadStripe(props.stripeApiKey).then(setStripe);
      }
    }
  }, [props.stripeApiKey, stripe]);

  if (!userHasPermissionsToWritePaymentDetails()) {
    return <UnauthorizedDialog />;
  }
  if (props.stripeApiKeyFetchError) {
    return <LoadingErrorState />;
  }
  if (!props.stripeApiKey || !stripe) {
    return (
      <div>
        <Spinner text={<FormattedMessage id={'marketplace.loading'} />} isInverse={false} />
      </div>
    );
  }

  return (
    <Elements stripe={stripe} options={{ locale: getLocaleShort() as StripeElementLocale }}>
      <ReauthenticationPageContainer stripe={stripe} />
    </Elements>
  );
}

export function mapStateToProps(state: RootState) {
  return {
    stripeApiKey: getStripeApiKey(state),
    stripeApiKeyFetchError: getHasError(state, ApiCalls.STRIPE_API_KEY),
  };
}

export function mapDispatchToProps(dispatch: RootDispatch) {
  return {
    fetchStripeApiKey: () => {
      dispatch(fetchStripeApiKeyThunk);
    },
  };
}

export default compose<React.ComponentType>(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissionValidator(LoadingErrorState)
)(ReauthenticationPageStripeContainer);
