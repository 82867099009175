import { FormattedMessage } from 'react-intl';
import type { PaymentMethod } from './redux/types';

interface Props {
  paymentInformation: PaymentMethod;
}

export const PaymentInformationSepa = (props: Props) => {
  const { paymentType, paymentAccountNumber, bankCode, country } = props.paymentInformation;

  return (
    <div className='PaymentSepaInformation'>
      <div className='paymentType'>
        <span>
          <FormattedMessage id='marketplace.payment.sepa.label.paymentType' />
          {': '}
        </span>
        <span className='text-bold'>{paymentType}</span>
      </div>
      <div className='accountNumber'>
        <span>
          <FormattedMessage id='marketplace.payment.sepa.label.accountNumber' />
          {': '}
        </span>
        <span className='text-bold'>{`******${paymentAccountNumber}`}</span>
      </div>
      <div className='bankCode'>
        <span>
          <FormattedMessage id='marketplace.payment.sepa.label.bankCode' />
          {': '}
        </span>
        <span className='text-bold'>{bankCode}</span>
      </div>
      <div className='country'>
        <span>
          <FormattedMessage id='marketplace.payment.sepa.label.country' />
          {': '}
        </span>
        <span className='text-bold'>{country}</span>
      </div>
    </div>
  );
};
