import DatePicker from '@rio-cloud/rio-uikit/lib/es/DatePicker';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { getLocale } from '../../../../configuration';
import type { RootState } from '../../../../configuration/setup/store';
import { getHistoryFromDate, getHistoryToDate, historyActions } from './redux/history.redux';

interface Props {
  locale: string;
  fromDate?: string;
  toDate?: string;
  setFromDate: (from?: string) => void;
  setToDate: (to?: string) => void;
}

const HistoryDateRange = ({ locale, fromDate, toDate, setFromDate, setToDate }: Props) => {
  return (
    <div className={'display-flex form-inline'}>
      <div className={'form-group margin-0 margin-right-10'}>
        <label>
          <FormattedMessage id={'marketplace.date.from'} />
          {':'}
        </label>
        <DatePicker
          inputProps={{
            readOnly: true,
            className: 'form-control cursor-pointer bg-white',
            style: { borderColor: 'rgb(167, 175, 187)' },
          }}
          closeOnSelect
          onChange={value => {
            if (typeof value === 'string') {
              if (value === '') {
                setFromDate(undefined);
              }
            } else {
              setFromDate(value.toISOString());
            }
          }}
          value={fromDate ? new Date(fromDate) : ''}
          dateFormat={true}
          timeFormat={false}
          clearableInput={true}
          isValidDate={(currentDate: Date) => (toDate ? currentDate < new Date(toDate) : true)}
          locale={locale}
        />
      </div>
      <div className={'form-group margin-0'}>
        <label>
          <FormattedMessage id={'marketplace.date.to'} />
          {':'}
        </label>
        <DatePicker
          inputProps={{
            readOnly: true,
            className: 'form-control cursor-pointer bg-white',
            style: { borderColor: 'rgb(167, 175, 187)' },
          }}
          closeOnSelect
          alignRight
          onChange={value => {
            if (typeof value === 'string') {
              if (value === '') {
                setToDate(undefined);
              }
            } else {
              setToDate(value.endOf('D').toISOString());
            }
          }}
          value={toDate ? new Date(toDate) : ''}
          timeFormat={false}
          clearableInput={true}
          isValidDate={(currentDate: Date) => (fromDate ? currentDate > new Date(fromDate) : true)}
          locale={locale}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  locale: getLocale(state),
  fromDate: getHistoryFromDate(state),
  toDate: getHistoryToDate(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setFromDate: (from?: string) => dispatch(historyActions.setFromDate(from)),
  setToDate: (to?: string) => dispatch(historyActions.setToDate(to)),
});

export const HistoryDateRangeContainer = connect(mapStateToProps, mapDispatchToProps)(HistoryDateRange);
