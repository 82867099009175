import React from 'react';
import { useIntl } from 'react-intl';
import { reportErrorToSentry } from '../../../../configuration/setup/sentry';
import { AssetCheckboxContainer } from './AssetCheckboxContainer';
import { getProductColumnKey } from './assetOverviewAssetMapper';
import { ProductCell } from './productCell/ProductCell';
import type { AssetOverviewRow, HeaderProduct, ProductInfo } from './redux/types';

interface Props {
  tableRow: AssetOverviewRow;
  tableColumns: HeaderProduct[];
}

function extractProduct(row: AssetOverviewRow, columnKey: string): ProductInfo | undefined {
  const product = row.products[columnKey];
  if (!product) {
    reportErrorToSentry(new Error(`Product ${columnKey} not found in row, but expected it.`));
    return undefined;
  }
  return product;
}

const AssetOverviewTableRowRaw = (props: Props) => {
  const intl = useIntl();
  const row = props.tableRow;
  const columns = props.tableColumns;
  return (
    <tr key={row.assetId}>
      <td>
        <span>
          <AssetCheckboxContainer assetId={row.assetId} />
        </span>
      </td>
      <td data-field={intl.formatMessage({ id: 'marketplace.assetOverview.assetHeader' })}>
        <span>{row.assetName}</span>
      </td>
      {columns.map(column => {
        const columnKey = getProductColumnKey(column);
        const product = extractProduct(row, columnKey);
        if (!product) {
          return (
            <td key={columnKey} className={'text-center'} data-field={'No Product'}>
              <span className='text-center'>
                <span className='rioglyph rioglyph-question-sign text-color-lighter width-25 text-size-20' />
              </span>
            </td>
          );
        }
        return (
          <td key={columnKey} className={'text-center'} data-field={product.productName}>
            <span>
              <ProductCell product={product} assetId={row.assetId} assetName={row.assetName} />
            </span>
          </td>
        );
      })}
    </tr>
  );
};

export const AssetOverviewTableRow = React.memo(AssetOverviewTableRowRaw);
