import type { IntlShape } from 'react-intl';
import { isFreeTrialLevel } from '../termedLevel/freeTrialLevel';
import { isIntegratedOfferLevel } from '../termedLevel/integratedOfferLevel';
import { type Period, PeriodUnit, type TermedLevel } from '../termedLevel/types';

export const getProductLabelFromContract = (contract: TermedLevel, intl: IntlShape) => {
  if (isIntegratedOfferLevel(contract.level)) {
    return `${contract.variantName} (${intl.formatMessage({ id: 'marketplace.integrated.offer.contract.label' })})`;
  }

  return getLocalizedLabel(contract.variantName, { unit: contract.periodUnit, length: contract.periodLength }, intl);
};

export const getRenewsToProductLabelFromContract = (contract: TermedLevel, intl: IntlShape): string => {
  const {
    renewsToLevelName,
    renewsToLevelVariantName,
    renewsToLevelContractPeriodLength,
    renewsToLevelContractPeriodUnit,
  } = contract;
  let contractPeriod;
  if (renewsToLevelContractPeriodLength && renewsToLevelContractPeriodUnit) {
    contractPeriod = {
      unit: renewsToLevelContractPeriodUnit,
      length: renewsToLevelContractPeriodLength,
    };
  }
  return getLocalizedLabel(renewsToLevelVariantName ?? renewsToLevelName ?? '', contractPeriod, intl);
};

export const getProductLabel = (
  product: { variantName?: string; productName?: string; name?: string; contractPeriod?: Period; level?: string },
  intl: IntlShape
): string => {
  if (isFreeTrialLevel(product.level)) {
    return product.productName ?? product.name ?? '';
  }
  if (isIntegratedOfferLevel(product.level)) {
    return `${product.variantName} (${intl.formatMessage({ id: 'marketplace.integrated.offer.contract.label' })})`;
  }
  return getLocalizedLabel(
    product.variantName ?? product.productName ?? product.name ?? '',
    product.contractPeriod,
    intl
  );
};

export const getLocalizedLabel = (name: string, contractPeriod: Period | undefined, intl: IntlShape): string => {
  if (contractPeriod) {
    const localizedPeriod = getLocalizedPeriod(contractPeriod, intl);
    return `${name} (${localizedPeriod})`;
  }
  return name;
};

const getLocalizedPeriod = (contractPeriod: Period, intl: IntlShape) => {
  if (contractPeriod && contractPeriod.length > 0) {
    const durationUnitIntlId = getDurationUnitIntlId(contractPeriod);
    if (!durationUnitIntlId) {
      console.error(
        `${contractPeriod.unit} is not a valid ContractPeriodUnit. Contract duration will not be rendered.`
      );
      return '';
    }
    return `${contractPeriod.length} ${intl.formatMessage({ id: durationUnitIntlId })}`;
  }
  return '';
};

const getDurationUnitIntlId = (contractPeriod: Period): string | undefined => {
  switch (contractPeriod.unit) {
    case PeriodUnit.YEAR:
      return contractPeriod.length === 1 ? 'marketplace.durationUnit.year' : 'marketplace.durationUnit.years';
    case PeriodUnit.MONTH:
      return contractPeriod.length === 1 ? 'marketplace.durationUnit.month' : 'marketplace.durationUnit.months';
    case PeriodUnit.DAY:
      return contractPeriod.length === 1 ? 'marketplace.durationUnit.day' : 'marketplace.durationUnit.days';
    default:
      return undefined;
  }
};
