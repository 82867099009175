import type { Dispatch } from 'redux';
import type { AppThunk, RootState } from '../../../../configuration/setup/store';
import { fetchPermissions } from '../../api/permissions/permissionsCalls';
import { apiCallAction } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { getPermissions, permissionsActions } from './redux/permissions.redux';

export const fetchPermissionsThunk: AppThunk<Promise<void>> = (dispatch: Dispatch, getState: () => RootState) => {
  if (getPermissions(getState())) {
    return Promise.resolve();
  }
  dispatch(apiCallAction(ApiCalls.PERMISSIONS, true, false));
  return fetchPermissions()
    .then(permissions => {
      dispatch(permissionsActions.setPermissions(permissions));
      dispatch(apiCallAction(ApiCalls.PERMISSIONS, false));
    })
    .catch(() => {
      dispatch(apiCallAction(ApiCalls.PERMISSIONS, false, true));
    });
};
