import { connect } from 'react-redux';
import { getHasError, getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { fetchInvoicesThunk } from './invoices.thunk';
import { getInvoices } from './redux/invoices.redux';

import EmptyState from '@rio-cloud/rio-uikit/lib/es/EmptyState';
import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import orderBy from 'lodash/fp/orderBy';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import type { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { InvoicesMonth } from './InvoicesMonth';
import { containsInvoices } from './invoiceService';
import type { DatedInvoices } from './redux/types';

interface Props {
  invoices: DatedInvoices[];
  hasError: boolean;
  isLoading: boolean;
  fetchInvoices: () => void;
}

function wrapInDiv(content: React.ReactNode) {
  return <div className='InvoicesTable container-fluid fluid-default'>{content}</div>;
}

export class Invoices extends Component<Props> {
  componentDidMount() {
    this.props.fetchInvoices();
  }

  getInvoiceList(invoices: DatedInvoices[]) {
    const orderedByMonth = orderBy<DatedInvoices>('invoiceDate', ['desc'])(invoices);

    const invoicesWithoutZeroAmount = orderedByMonth.map(invoiceMonth => ({
      ...invoiceMonth,
      invoices: invoiceMonth.invoices.filter(invoice => invoice.amount !== 0),
    }));
    return (
      <div className='InvoiceList'>
        {invoicesWithoutZeroAmount
          .filter(invoiceMonth => invoiceMonth.invoices.length > 0)
          .map((invoiceMonth, index) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            <InvoicesMonth invoicesMonth={invoiceMonth} key={index} />
          ))}
      </div>
    );
  }

  render() {
    if (this.props.isLoading) {
      return wrapInDiv(<Spinner text={<FormattedMessage id={'marketplace.loading'} />} isInverse={false} />);
    }
    if (this.props.hasError) {
      return wrapInDiv(<ErrorState headline={<FormattedMessage id={'marketplace.invoices.fetch.error'} />} />);
    }
    if (!containsInvoices(this.props.invoices)) {
      return wrapInDiv(
        <EmptyState
          headline={<FormattedMessage id={'marketplace.invoices.not.found'} />}
          message={<FormattedMessage id={'marketplace.invoices.not.found.message'} />}
          outerClassName='margin-top-15pct'
        />
      );
    }
    return wrapInDiv(this.getInvoiceList(this.props.invoices));
  }
}

export function mapStateToProps(state: RootState) {
  return {
    invoices: getInvoices(state),
    hasError: getHasError(state, ApiCalls.INVOICES_GET),
    isLoading: getIsLoading(state, ApiCalls.INVOICES_GET),
  };
}

export function mapDispatchToProps(dispatch: RootDispatch) {
  return {
    fetchInvoices: () => dispatch(fetchInvoicesThunk),
  };
}

export const InvoicesContainer = connect(mapStateToProps, mapDispatchToProps)(Invoices);
