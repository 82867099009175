import type { IntlShape } from 'react-intl';

const getTechnicalDetails = (missingFeatures: string[]) => {
  return missingFeatures.map((feature, index) => {
    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
    return <li key={index}>{feature}</li>;
  });
};

interface Props {
  intl: IntlShape;
  missingRequirements: string[][];
}

export const MissingRequirement = (props: Props) => {
  const { intl, missingRequirements } = props;
  return (
    <div className='missingFeatures text-left margin-bottom-10'>
      <br />
      {`${intl.formatMessage({ id: 'marketplace.missingFeatureTooltip.header' })}:`}
      {missingRequirements.map((missingFeatures, index) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
        <div key={index}>
          {index > 0 && intl.formatMessage({ id: 'marketplace.missingFeature.requirementDivider' })}
          <ul className='margin-bottom-0'>{getTechnicalDetails(missingFeatures)}</ul>
        </div>
      ))}
    </div>
  );
};
