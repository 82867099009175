import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import { useSelector } from 'react-redux';
import { getProductDiscounts } from '../../../common/discount/redux/discount.redux';
import { isLegacy } from '../../../common/product/productService';
import { isFreeTrialLevel } from '../../../common/termedLevel/freeTrialLevel';
import { isIntegratedOfferLevel } from '../../../common/termedLevel/integratedOfferLevel';
import { ServiceResourceContainer } from '../ServiceResourceContainer';
import type { OverviewResource } from '../redux/types';
import { isResourceActiveForLevel, isTermedLevel } from '../services/serviceOverviewLevelService';
import type { ServiceOverviewItem } from '../types';
import { EmptyResourcesMessage } from './EmptyResourcesMessage';
import { LevelDivider } from './LevelDivider';

const SERVICE_OVERVIEW_RESOURCES_PER_ROW = 3;

interface Props {
  service: ServiceOverviewItem;
  filteredResources: OverviewResource[];
  isCurrentlyProcessingCancellation: (sku: string, resourceId?: string) => boolean;
  selectedResources: OverviewResource[];
  selectResource: (resource: OverviewResource) => void;
  level: string | undefined;
  hasTermedLevels: boolean;
  canBeModified: boolean;
}

export function ServiceResourceListLevelItem(props: Props) {
  const {
    level,
    service,
    filteredResources,
    hasTermedLevels,
    isCurrentlyProcessingCancellation,
    canBeModified,
    selectResource,
    selectedResources,
  } = props;

  const currentRatePlan = service.productRatePlans?.find(ratePlan => ratePlan.level === level);
  const period = currentRatePlan?.contractPeriod;
  const legacyLevel = isLegacy(currentRatePlan);
  const freeTrialLevel = isFreeTrialLevel(level);
  const integratedOfferLevel = isIntegratedOfferLevel(level);
  const resourcesForLevel = filteredResources.filter(level ? isResourceActiveForLevel(level) : () => true);
  const productDiscounts = useSelector(getProductDiscounts);
  const productDiscountForIdAndLevel = productDiscounts.find(discount => {
    if (level) {
      return discount.productId.toLowerCase() === service.sku.toLowerCase() && discount.levels?.includes(level);
    }
    return discount.productId.toLowerCase() === service.sku.toLowerCase();
  });

  if (freeTrialLevel && resourcesForLevel.length === 0) {
    return null;
  }

  if (integratedOfferLevel && resourcesForLevel.length === 0) {
    return null;
  }

  return (
    <div key={level ?? 'none'}>
      {hasTermedLevels && (
        <LevelDivider
          period={period}
          freeTrial={freeTrialLevel}
          integratedOffer={integratedOfferLevel}
          legacy={legacyLevel}
          discount={productDiscountForIdAndLevel}
        />
      )}
      {resourcesForLevel.length === 0 ? (
        <EmptyResourcesMessage productType={service.productType} />
      ) : (
        <TagList
          className={'margin-5 margin-top-0 padding-top-0 max-height-50vh overflow-auto'}
          tagsPerRow={SERVICE_OVERVIEW_RESOURCES_PER_ROW}
        >
          {resourcesForLevel.map(resource => {
            return (
              <ServiceResourceContainer
                resource={resource}
                resourceInProgress={isCurrentlyProcessingCancellation(service.sku, resource.id)}
                isRemovable={canBeModified && !isTermedLevel(service, level)}
                onSelectResource={selectedResource => selectResource(selectedResource)}
                key={resource.id}
                isSelected={selectedResources.includes(resource)}
                productId={service.sku}
                displayedLevel={level}
                productType={service.productType}
              />
            );
          })}
        </TagList>
      )}
    </div>
  );
}
