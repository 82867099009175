import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { reportErrorToSentry } from '../../../../configuration/setup/sentry';
import type { RootState } from '../../../../configuration/setup/store';
import { useCheckboxState } from '../../common/utils/checkboxUtil';
import { getSelectedProduct } from '../redux/checkout.redux';
import { isMultiLevelProduct } from '../redux/types';
import {
  ProductTypeAwareFormattedMessage,
  type ProductTypeAwareFormattedMessageId,
} from './ProductTypeAwareFormattedMessage';
import { ResourceSelectionItemContainer } from './ResourceSelectionItem';
import { ResourceSelectionSearchContainer } from './ResourceSelectionSearch';
import { SelectionMenuContainer } from './SelectionMenuContainer';
import { RESOURCE_SELECTION_TAGS_PER_ROW } from './constants';
import { getInvalidSelectedResources, isSelectedFilter } from './preSelectionService';
import { getSelectedResourceIds, resourceSelectionActions } from './redux/resourceSelection.redux';
import type { BookableResource } from './redux/types';
import { getFilteredGroupedResources } from './resourceSelectionSearchService';

interface BaseProps extends React.PropsWithChildren {
  resources: BookableResource[];
  labelId: ProductTypeAwareFormattedMessageId;
  isBooked: boolean;
  open?: boolean;
  isSelected?: boolean;
}

interface AvailableVehiclesProps extends BaseProps {
  isSelected: boolean;
  selectedResources: BookableResource[];
  onSelectAll: (resources: BookableResource[], selected: boolean) => void;
  onSelectResource: (resourceId: string) => void;
  isMultiLevel: boolean;
}

const ResourceSelectionExpander = ({
  children,
  resources,
  labelId,
  isBooked,
  isSelected = false,
  open = false,
}: BaseProps) => {
  if (resources.length === 0) {
    return null;
  }

  return (
    <ExpanderPanel
      title={
        <b>
          <ProductTypeAwareFormattedMessage id={labelId} />
          {` (${resources.length})`}
        </b>
      }
      bsStyle='default'
      open={open}
    >
      <TagList tagsPerRow={RESOURCE_SELECTION_TAGS_PER_ROW}>
        {resources.map(resource => (
          <ResourceSelectionItemContainer
            resource={resource}
            key={resource.id}
            isBooked={isBooked}
            isSelected={isSelected}
            noBookableTooltip={isSelected}
          />
        ))}
      </TagList>
      {children}
    </ExpanderPanel>
  );
};

const mapStateToBookedProps = (state: RootState) => ({
  resources: getFilteredGroupedResources(state).booked,
  labelId: 'bookedResources' as const,
  isBooked: true,
});

const mapStateToNotBookableProps = (state: RootState) => ({
  resources: getFilteredGroupedResources(state).notBookable,
  labelId: 'notBookableResources' as const,
  isBooked: false,
});

const mapStateToPreSelectedProps = (state: RootState) => ({
  resources: getFilteredGroupedResources(state).bookable.filter(isSelectedFilter(state)),
  labelId: 'bookableResources' as const,
  isBooked: false,
  open: true,
  isSelected: true,
});

const mapStateToPreSelectedNotBookableProps = (state: RootState) => ({
  resources: getFilteredGroupedResources(state).notBookable.filter(isSelectedFilter(state)),
  labelId: 'notBookableResources' as const,
  isBooked: false,
  open: true,
});

const mapStateToPreSelectedBookedProps = (state: RootState) => ({
  resources: getFilteredGroupedResources(state).booked.filter(isSelectedFilter(state)),
  labelId: 'alreadyBookedResources' as const,
  isBooked: true,
  open: true,
});

const mapStateToPreSelectedInvalidProps = (state: RootState) => ({
  resources: getInvalidSelectedResources(state),
  labelId: 'notFoundResources' as const,
  isBooked: false,
  open: true,
});

const mapStateToAvailableVehiclesProps = (state: RootState) => {
  const bookableResources = getFilteredGroupedResources(state).bookable;
  const selectedIds = getSelectedResourceIds(state);
  const selectedProduct = getSelectedProduct(state);

  return {
    resources: bookableResources,
    labelId: 'availableVehicles' as const,
    isBooked: false,
    open: true,
    isSelected: selectedIds.length === bookableResources.length,
    selectedResources: bookableResources.filter(r => selectedIds.includes(r.id)),
    isMultiLevel: isMultiLevelProduct(selectedProduct),
  };
};

const mapDispatchToProps = {
  onSelectAll: (resources: BookableResource[], selected: boolean) =>
    resourceSelectionActions.selectAllResources({ selected }),
  onSelectResource: (resourceId: string) => resourceSelectionActions.selectResource(resourceId),
};

const ResourceSelectionAvailableVehiclesExpanderComponent = ({
  children,
  resources,
  labelId,
  isBooked,
  isSelected = false,
  open = false,
  selectedResources,
  onSelectAll,
  onSelectResource,
  isMultiLevel,
}: AvailableVehiclesProps) => {
  const isChecked = useCheckboxState(resources, selectedResources);

  if (resources.length === 0) {
    return (
      <div className='panel-body text-center margin-15'>
        <ProductTypeAwareFormattedMessage id='noSearchedResourcesFound' />
      </div>
    );
  }

  return (
    <ExpanderPanel
      title={
        <b>
          <ProductTypeAwareFormattedMessage id={labelId} />
          {` (${resources.length})`}
        </b>
      }
      bsStyle='default'
      open={open}
    >
      <div className='table-toolbar margin-bottom-0'>
        <div className='table-toolbar-container'>
          <div className='table-toolbar-group-left'>
            <div className='table-toolbar-column'>
              <div className='padding-bottom-10'>
                {isMultiLevel ? (
                  <SelectionMenuContainer />
                ) : (
                  <label className='checkbox checkbox-inline'>
                    <input type='checkbox' checked={isChecked} onChange={() => onSelectAll(resources, !isChecked)} />
                    <span className='checkbox-text'>
                      <span>
                        <ProductTypeAwareFormattedMessage id='selectAll' />
                      </span>
                    </span>
                  </label>
                )}
              </div>
            </div>
          </div>
          <div className='table-toolbar-group-right'>
            <div className='table-toolbar-column padding-10-xs'>
              <div className='table-toolbar-search input-group'>
                <span className='input-group-addon'>
                  <span className='rioglyph rioglyph-search' />
                </span>
                <div className='ClearableInput input-group'>
                  <ResourceSelectionSearchContainer />
                  <span className='clearButton hide'>
                    <span className='clearButtonIcon rioglyph rioglyph-remove-sign' />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TagList tagsPerRow={RESOURCE_SELECTION_TAGS_PER_ROW}>
        {resources.map(resource => (
          <ResourceSelectionItemContainer
            resource={resource}
            key={resource.id}
            isBooked={isBooked}
            isSelected={selectedResources.includes(resource)}
            noBookableTooltip={isSelected}
            onSelect={onSelectResource}
          />
        ))}
      </TagList>
      {children}
    </ExpanderPanel>
  );
};

export const ResourceSelectionAvailableVehiclesExpander = connect(
  mapStateToAvailableVehiclesProps,
  mapDispatchToProps
)(ResourceSelectionAvailableVehiclesExpanderComponent);

export const ResourceSelectionBookedExpander = connect(mapStateToBookedProps)(ResourceSelectionExpander);
export const ResourceSelectionNotBookableExpander = connect(mapStateToNotBookableProps)(ResourceSelectionExpander);

export const PreSelectedValidResourcesExpander = connect(mapStateToPreSelectedProps)(ResourceSelectionExpander);
export const PreSelectedAlreadyBookedResourcesExpander = connect(mapStateToPreSelectedBookedProps)(
  ResourceSelectionExpander
);
export const PreSelectedNotBookableResourcesExpander = connect(mapStateToPreSelectedNotBookableProps)(
  ResourceSelectionExpander
);

const InvalidResourceSelectionExpander = (props: BaseProps) => {
  useEffect(() => {
    if (props.resources.length > 0) {
      const resourceIds = props.resources.map(it => it.id);
      reportErrorToSentry(`Unknown resource ids found in resource selection: ${resourceIds}`);
    }
  }, [props.resources.length, props.resources]);
  return <ResourceSelectionExpander {...props} />;
};
export const PreSelectedNotExistentResourceExpander = connect(mapStateToPreSelectedInvalidProps)(
  InvalidResourceSelectionExpander
);
