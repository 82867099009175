import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../configuration/setup/store';
import type { PopUpState } from './types';

const initialState: PopUpState = {
  isOpenedInPopup: false,
};

export const { reducer: popupReducer, actions: popupActions } = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    setIsOpenedInPopup: (state: PopUpState, action: PayloadAction<boolean>) => {
      state.isOpenedInPopup = action.payload;
    },
  },
});

const getBase = (state: RootState) => state.popup;

export const getIsOpenedInPopup = (state: RootState) => getBase(state).isOpenedInPopup;
