import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { RootDispatch, RootState } from '../../../../../configuration/setup/store';
import { getIsRenewDialogOpen, termedLevelRenewalActions } from '../redux/termedLevelRenewal.redux';
import { RenewDialogContentContainer } from './RenewDialogContent';

interface Props {
  isOpen: boolean;
  close: () => void;
  titleId: string;
}

const RenewDialog = ({ isOpen, close, titleId }: Props) => {
  return (
    <Dialog
      title={<FormattedMessage id={titleId} />}
      show={isOpen}
      body={<RenewDialogContentContainer />}
      onHide={() => close()}
      showCloseButton={true}
      bsSize={'lg'}
      footer={null}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  isOpen: getIsRenewDialogOpen(state),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  close: () => {
    dispatch(termedLevelRenewalActions.setIsRenewDialogOpen(false));
    dispatch(termedLevelRenewalActions.clearUpdateState());
  },
});

export const RenewDialogContainer = connect(mapStateToProps, mapDispatchToProps)(RenewDialog);
