import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';
import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import { FormattedMessage } from 'react-intl';
import { sortingByName } from '../../common/utils/sortUtils';
import { OverlayTrigger, Tooltip } from '../../common/utils/tooltipReExports';
import type { Item } from './redux/types';

export const getDefaultAssetName = () => {
  return <FormattedMessage id={'marketplace.history.details.tooltip.unknown'} />;
};

type Props = {
  resources: Item[];
};

const tagClassNames = 'HistoryResourceList-item rounded width-100pct margin-bottom-0';

export const HistoryResourceList = (props: Props) => {
  const { resources } = props;
  return (
    <TagList className={'HistoryResourceList'}>
      {[...resources].sort(sortingByName).map((resource, index) => {
        const tooltip = (
          <Tooltip className={'width-auto max-width-300'}>
            <span>{resource.id}</span>
          </Tooltip>
        );
        return (
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          <OverlayTrigger key={index} placement='top' trigger='click' rootClose overlay={tooltip}>
            <Tag size='small' className={`${tagClassNames} ${index > 0 ? 'margin-top-5' : ''}`}>
              <span>{resource.name || getDefaultAssetName()}</span>
            </Tag>
          </OverlayTrigger>
        );
      })}
    </TagList>
  );
};
