import type { Dispatch } from 'redux';
import type { RootDispatch } from '../../../../configuration/setup/store';
import { apiCallAction } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { fetchAssetInfo, fetchTags } from '../../api/tags/tagCalls';
import { assetOverviewActions } from './redux/assetOverview.redux';

export const fetchAssetInfoThunk = (dispatch: Dispatch) => {
  dispatch(apiCallAction(ApiCalls.ASSET_OVERVIEW_ASSET_INFO, true, false));

  return fetchAssetInfo()
    .then(tagToAssetMap => {
      dispatch(assetOverviewActions.setTagToAssetMap(tagToAssetMap));
      dispatch(apiCallAction(ApiCalls.ASSET_OVERVIEW_ASSET_INFO, false, false));
    })
    .catch(() => {
      dispatch(apiCallAction(ApiCalls.ASSET_OVERVIEW_ASSET_INFO, false, true));
    });
};

export const fetchTagsThunk = (dispatch: RootDispatch) => {
  dispatch(apiCallAction(ApiCalls.ASSET_OVERVIEW_TAGS, true, false));

  return fetchTags()
    .then(tags => {
      dispatch(assetOverviewActions.setTags(tags));
      dispatch(apiCallAction(ApiCalls.ASSET_OVERVIEW_TAGS, false, false));
    })
    .catch(() => {
      dispatch(apiCallAction(ApiCalls.ASSET_OVERVIEW_TAGS, false, true));
    });
};

export const fetchTagsInfoThunk = (dispatch: RootDispatch) => {
  return Promise.all([dispatch(fetchTagsThunk), dispatch(fetchAssetInfoThunk)]);
};
