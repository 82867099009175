import { REDIRECT_CONFIG, setLocaleForRedirect } from '../config/openMarketplaceRedirectConfig';
import { normalizeLocaleForKoreanUserOrHandThrough } from './utils/koreaUtil';

export function getLinkForProductDetails(
  sku: string,
  level: string | undefined,
  locale: string,
  isKoreanUser: boolean
) {
  const productLevelId = level ? `${sku}-${level}` : sku;
  const redirect = REDIRECT_CONFIG.filter(entry => (entry.korea || false) === isKoreanUser).find(
    entry => entry.sku.toLowerCase() === productLevelId.toLowerCase()
  );
  if (!redirect) {
    return undefined;
  }
  return setLocaleForRedirect(redirect.url, normalizeLocaleForKoreanUserOrHandThrough(locale, isKoreanUser));
}
