import { useLocation } from 'react-router';
import { getCustomerCenterPath } from '../common/routes';
import { AssetOverviewBottomBarContainer } from './assetOverview/AssetOverviewBottomBarContainer';
import { CustomerCenterTab } from './types';

export function useMarketplaceBottomBar(): JSX.Element | null {
  const { pathname } = useLocation();
  const assetOverviewPath = getCustomerCenterPath(CustomerCenterTab.ASSETS);
  if (pathname === assetOverviewPath) {
    return <AssetOverviewBottomBarContainer />;
  }
  // Hook is necessary because the ApplicationLayoutBody adds an 'has-footer' css class
  // if a truthy React component is passed as bottomBar.
  return null;
}
