import type { ProductType } from '../../common/product/product';
import type { Period } from '../../common/termedLevel/types';

export interface ProductFuturePrice {
  price: number;
  currency: string;
  effectiveStartDate: string;
}

export interface ViewOnlyProduct {
  name: string;
  sku: string;
  productType: ProductType;
}

export interface BookableProduct {
  name: string;
  sku: string;
  price: number;
  currency: string;
  ratePlanId: string;
  productType: ProductType;
  level?: string;
  contractPeriod?: Period;
  variantName?: string;
  renewsToLevel?: string;
  effectiveEndDate?: string;
  futurePrice?: ProductFuturePrice;
}

export interface ProductLevel {
  name: string;
  level: string;
  ratePlanId: string;
  currency: string;
  price: number;
  contractPeriod?: Period;
  variantName?: string;
  legacy?: boolean;
  renewsToLevel?: string;
  effectiveEndDate?: string;
  futurePrice?: ProductFuturePrice;
}

export interface MultiLevelProduct {
  sku: string;
  name: string;
  productType: ProductType;
  levels: ProductLevel[];
}

export type Product = ViewOnlyProduct | MultiLevelProduct | BookableProduct;

export interface CheckoutState {
  selectedProduct: Product | undefined;
  selectedProductId: string | undefined;
  selectedProductLevel: string | undefined;
  locked: boolean;
}

export const isBookableProduct = (product: Product | undefined): product is BookableProduct =>
  product !== undefined && (product as BookableProduct).price !== undefined;

export const isMultiLevelProduct = (product: Product | undefined): product is MultiLevelProduct =>
  product !== undefined && (product as MultiLevelProduct).levels !== undefined;

export const isViewOnlyProduct = (product: Product | undefined): product is ViewOnlyProduct =>
  product !== undefined && !isBookableProduct(product) && !isMultiLevelProduct(product);
