import { FormattedMessage, useIntl } from 'react-intl';
import { formatPrice } from '../utils/currencyUtil';

interface Props {
  totalPrice: number;
  currency: string | undefined;
}

export function TotalPrice({ totalPrice, currency }: Props) {
  const intl = useIntl();
  return (
    <>
      <span className='text-size-20 text-medium'>
        <FormattedMessage id={'marketplace.shoppingCart.total'} />
        {': '}
        <span>{formatPrice(intl, totalPrice, currency)}</span>
      </span>
      <span className='totalPriceRate'>
        {' '}
        <FormattedMessage id={'marketplace.productDetails.price.rate.total'} />
      </span>
    </>
  );
}
