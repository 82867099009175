import head from 'lodash/fp/head';

import type { SupportedLocale } from '../../../configuration';
import { supportedLocaleMap } from '../../../configuration/lang/lang';
import messagesBG from './locales/bg-BG.json';
import messagesCS from './locales/cs-CZ.json';
import messagesDA from './locales/da-DK.json';
import messagesDE from './locales/de-DE.json';
import messagesEL from './locales/el-GR.json';
import messagesEN from './locales/en-GB.json';
import messagesES from './locales/es-ES.json';
import messagesET from './locales/et-EE.json';
import messagesFI from './locales/fi-FI.json';
import messagesFR from './locales/fr-FR.json';
import messagesHR from './locales/hr-HR.json';
import messagesHU from './locales/hu-HU.json';
import messagesIT from './locales/it-IT.json';
import messagesKO from './locales/ko-KR.json';
import messagesLT from './locales/lt-LT.json';
import messagesLV from './locales/lv-LV.json';
import messagesNB from './locales/nb-NO.json';
import messagesNL from './locales/nl-NL.json';
import messagesPL from './locales/pl-PL.json';
import messagesPTBR from './locales/pt-BR.json';
import messagesPT from './locales/pt-PT.json';
import messagesRO from './locales/ro-RO.json';
import messagesSK from './locales/sk-SK.json';
import messagesSL from './locales/sl-SI.json';
import messagesSV from './locales/sv-SE.json';

// Get the locale short that will be used to find the right translation file
// biome-ignore lint/style/noNonNullAssertion: <explanation>
const createLocaleShort = (locale: string) => head(locale.split('-'))!.toLowerCase();
const browserLocale = navigator.language as string;
let enforcedLocale: string;

// adapt for possible language code extensions (http://www.lingoes.net/en/translator/langcode.htm)
const DEFAULT_LOCALE = 'en-GB';
const messages = {
  // Bulgarian
  bg: messagesBG,
  'bg-BG': messagesBG,

  // Greek
  el: messagesEL,
  'el-GR': messagesEL,

  // Finnish
  fi: messagesFI,
  'fi-FI': messagesFI,

  // Croatian
  hr: messagesHR,
  'hr-HR': messagesHR,
  'hr-BA': messagesHR,

  // English
  en: messagesEN,
  'en-GB': messagesEN,
  'en-US': messagesEN,

  // German
  de: messagesDE,
  'de-DE': messagesDE,
  'de-AT': messagesDE,
  'de-LI': messagesDE,
  'de-LU': messagesDE,
  'de-CH': messagesDE,

  // Dutch
  nl: messagesNL,
  'nl-NL': messagesNL,
  'nl-BE': messagesNL,

  // Spanish
  es: messagesES,
  'es-ES': messagesES,

  // Czech
  cs: messagesCS,
  'cs-CZ': messagesCS,

  // Danish
  da: messagesDA,
  'da-DK': messagesDA,

  // French
  fr: messagesFR,
  'fr-FR': messagesFR,
  'fr-BE': messagesFR,
  'fr-CA': messagesFR,
  'fr-LU': messagesFR,
  'fr-MC': messagesFR,
  'fr-CH': messagesFR,

  // Italian
  it: messagesIT,
  'it-IT': messagesIT,
  'it-CH': messagesIT,

  // Polish
  pl: messagesPL,
  'pl-PL': messagesPL,

  // Portuguese
  pt: messagesPT,
  'pt-PT': messagesPT,

  // Brazilian Portuguese
  'pt-BR': messagesPTBR,

  // Romanian
  ro: messagesRO,
  'ro-RO': messagesRO,

  // Lithuanian
  lt: messagesLT,
  'lt-LT': messagesLT,

  // Latvian
  lv: messagesLV,
  'lv-LV': messagesLV,

  // Estonian
  et: messagesET,
  'et-EE': messagesET,

  // Slovakian
  sk: messagesSK,
  'sk-SK': messagesSK,

  // Hungarian
  hu: messagesHU,
  'hu-HU': messagesHU,

  // Korean
  ko: messagesKO,
  'ko-KR': messagesKO,

  // Norwegian
  nb: messagesNB,
  'nb-NO': messagesNB,
  no: messagesNB, // TODO: Can be removed once language code has been changed in account menu
  'no-NO': messagesNB,

  // Sweden
  sv: messagesSV,
  'se-SE': messagesSV,
  'sv-SE': messagesSV,

  // Slovenia
  sl: messagesSL,
  'sl-SI': messagesSL,
};

/*
 Languages returned by UserAdmin Service:
 cs-CZ
 da-DK
 de-DE
 nl-NL
 en-US
 es-ES
 fr-FR
 it-IT
 pl-PL
 ro-RO
 */

const getSupportedLanguageKey = (locale: string): SupportedLocale => {
  if (Object.prototype.hasOwnProperty.call(supportedLocaleMap, enforcedLocale)) {
    return enforcedLocale as SupportedLocale;
  }
  if (Object.prototype.hasOwnProperty.call(supportedLocaleMap, locale)) {
    return locale as SupportedLocale;
  }
  if (Object.prototype.hasOwnProperty.call(supportedLocaleMap, browserLocale)) {
    return browserLocale as SupportedLocale;
  }
  return DEFAULT_LOCALE;
};

const setEnforcedLocale = (locale: string) => {
  if (locale) {
    enforcedLocale = getSupportedLanguageKey(locale);
  }
};

const getEnforcedLocale = () => enforcedLocale;

export {
  getSupportedLanguageKey,
  setEnforcedLocale,
  createLocaleShort,
  getEnforcedLocale,
  browserLocale,
  messages,
  DEFAULT_LOCALE,
};
