import { useIntl } from 'react-intl';
import { OverlayTrigger, Tooltip } from '../../common/utils/tooltipReExports';
import { DEFAULT_TOOLTIP_DELAY } from '../../config/config';

interface Props {
  termsAccepted: boolean;
  onBuyClick: () => void;
}

const CheckoutBuyButtonDisabled = () => {
  const intl = useIntl();

  const tooltip = (
    <Tooltip id='buyButtonDisabled' className='nextStepTooltip width-auto max-width-300'>
      {intl.formatMessage({ id: 'marketplace.termsAndConditionsNotAccepted.hint' })}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      trigger={OverlayTrigger.TRIGGER_HOVER}
      placement={'top'}
      overlay={tooltip}
      delay={DEFAULT_TOOLTIP_DELAY}
    >
      <span className='d-inline-block'>
        <button className='btn btn-primary disabled' disabled>
          {intl.formatMessage({ id: 'marketplace.confirm.buy' })}
        </button>
      </span>
    </OverlayTrigger>
  );
};

export const CheckoutBuyButton = (props: Props) => {
  const intl = useIntl();

  if (props.termsAccepted) {
    return (
      <button className='nextStep btn btn-primary' onClick={() => props.onBuyClick()}>
        {intl.formatMessage({ id: 'marketplace.confirm.buy' })}
      </button>
    );
  }

  return <CheckoutBuyButtonDisabled />;
};
