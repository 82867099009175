import type { DisplayMessages } from '../../../../configuration';
import { UnreachableCaseError } from '../../../../utils/typescriptUtil';
import { type BookableResource, ProductState } from '../../checkout/resourceSelection/redux/types';
import { sortingByName } from '../../common/utils/sortUtils';
import {
  API_PRODUCT_STATE_ACTIVE,
  API_PRODUCT_STATE_INACTIVE,
  API_PRODUCT_STATE_PENDING_ACTIVATION,
  API_PRODUCT_STATE_PENDING_CANCELLATION,
  type ApiBookableResources,
  type ApiProductState,
} from './bookable.types';
import { translateMissingFeature } from './missingFeaturesTranslator';

function convertApiState(apiState: ApiProductState): ProductState {
  switch (apiState) {
    case API_PRODUCT_STATE_ACTIVE:
      return ProductState.ACTIVE;
    case API_PRODUCT_STATE_INACTIVE:
      return ProductState.INACTIVE;
    case API_PRODUCT_STATE_PENDING_ACTIVATION:
      return ProductState.PENDING_ACTIVATION;
    case API_PRODUCT_STATE_PENDING_CANCELLATION:
      return ProductState.PENDING_CANCELLATION;
    default:
      throw new UnreachableCaseError(apiState);
  }
}

export function mapBookableResponse(displayMessages: DisplayMessages) {
  return (apiBookableResources: ApiBookableResources): BookableResource[] => {
    const mappedAssets = apiBookableResources.resources.map(resource => ({
      id: resource.resource_id,
      name: resource.resource_name ? resource.resource_name : resource.resource_id,
      bookable: resource.bookable,
      originLevel: resource.origin_level,
      targetLevel: resource.target_level,
      productState: convertApiState(resource.activation_state),
      missingRequirements: (resource.missing_requirements ?? []).map(requirement =>
        requirement.map(it => translateMissingFeature(it, displayMessages))
      ),
      hasOpenContract: resource.has_open_contract,
    }));
    return mappedAssets.sort(sortingByName);
  };
}
