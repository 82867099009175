import ApplicationLayoutBodyNavigation from '@rio-cloud/rio-uikit/lib/es/ApplicationLayoutBodyNavigation';
import SubNavigation from '@rio-cloud/rio-uikit/lib/es/SubNavigation';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router';
import type { RootState } from '../configuration/setup/store';
import { getCustomerCenterPath } from './app/common/routes';
import { getSubNavigationCustomerCenterTabs, isSubTabVisible } from './app/customerCenter/customerCenterTabService';
import { CustomerCenterTab, type TabInfo } from './app/customerCenter/types';
import { FreeTrialTab } from './app/freeTrial/tab/FreeTrialTab';

function getRouteElement(item: TabInfo) {
  const isFreeTrialTab = item.componentName === CustomerCenterTab.FREE_TRIAL;

  const regularRouteElement = (
    <NavLink className='line-height-14 height-100pct' to={getCustomerCenterPath(item.componentName)}>
      <div className='display-flex height-100pct align-items-center'>
        <span>
          <FormattedMessage id={item.labelId} />
        </span>
      </div>
    </NavLink>
  );
  const freeTrialRouteElement = (
    <NavLink
      className='line-height-14 height-100pct bg-secondary display-flex align-items-center gap-5 padding-x-10 padding-bottom-20'
      to={getCustomerCenterPath(item.componentName)}
    >
      <FreeTrialTab />
    </NavLink>
  );

  return isFreeTrialTab ? freeTrialRouteElement : regularRouteElement;
}

function AppSubNavigation({ availableTabs }: { availableTabs: TabInfo[] }) {
  const { pathname } = useLocation();

  const visibleTabs = availableTabs.filter(tab => isSubTabVisible(tab.componentName, pathname));

  const tabs = visibleTabs.map(item => {
    return {
      key: item.componentName,
      route: getRouteElement(item),
    };
  });

  if (tabs.length === 0) {
    return null;
  }

  return (
    <ApplicationLayoutBodyNavigation className='has-offset'>
      <SubNavigation navItems={tabs} />
    </ApplicationLayoutBodyNavigation>
  );
}

export const mapStateToProps = (state: RootState) => ({
  availableTabs: getSubNavigationCustomerCenterTabs(state),
});

export const AppSubNavigationContainer = connect(mapStateToProps)(AppSubNavigation);
