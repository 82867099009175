import { addOffsetToDate, getCurrentDate } from '../../common/utils/dateUtil';
import { getMarketplaceEUBackend } from '../../config/pathURL/config';
import type { FailedPayment } from '../../paymentReauthentication/redux/types';
import { decodeJson, jsonOrReject } from '../api';
import { getParameters } from '../parameterService';
import { getRequest } from '../requests';
import { ApiPaymentSecretCodec, ApiPaymentsCodec } from './payments.types';

const PAYMENT_SORT_DIRECTION = {
  ASC: '+',
  DESC: '-',
};
const PAYMENT_SORT_FIELD = {
  TIMESTAMP: 'timestamp',
};

const FAILED_PAYMENT_DATE_OFFSET_IN_DAYS = -45;
const PAYMENT_STATUS = {
  FAILED: 'FAILED',
  SUCCESSFUL: 'SUCCESSFUL',
};

export function fetchFailedPayments(accountId: string): Promise<FailedPayment[]> {
  const date = getCurrentDate();
  const startingAfter = addOffsetToDate(date, FAILED_PAYMENT_DATE_OFFSET_IN_DAYS).toISOString();
  const params = getParameters({
    starting_after: startingAfter,
    limit: 1,
    sort: `${PAYMENT_SORT_DIRECTION.DESC}${PAYMENT_SORT_FIELD.TIMESTAMP}`,
    status_type: PAYMENT_STATUS.FAILED,
  });
  return fetch(`${getMarketplaceEUBackend()}/accounts/${accountId}/payments${params}`, getRequest())
    .then(jsonOrReject)
    .then(decodeJson(ApiPaymentsCodec))
    .then(response => response.payments);
}

export function fetchPaymentSecret(accountId: string, paymentId: string): Promise<string> {
  const params = getParameters({ payment_id: paymentId });
  return fetch(`${getMarketplaceEUBackend()}/accounts/${accountId}/payment-secret${params}`, getRequest())
    .then(jsonOrReject)
    .then(decodeJson(ApiPaymentSecretCodec))
    .then(response => response.secret);
}
