import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { fetchInvoiceDownloadThunk } from './invoices.thunk';
import { getInvoiceDownloadId } from './redux/invoices.redux';

interface Props {
  invoiceId: string;
  downloadId: string | undefined;
  handleDownloadInvoice: (invoiceId: string) => void;
}

const InvoiceDownloadButton = (props: Props) => {
  const { invoiceId, downloadId, handleDownloadInvoice } = props;
  if (invoiceId === downloadId) {
    return (
      <button key='loading' className='downloadButton btn btn-default btn-loading' disabled={true}>
        <FormattedMessage id={'marketplace.invoice.download'} />
      </button>
    );
  }

  return (
    <button
      key='ready'
      className='downloadButton btn btn-default'
      type='button'
      onClick={() => handleDownloadInvoice(invoiceId)}
    >
      <span className='rioglyph rioglyph-cloud-download' aria-hidden='true' />{' '}
      <FormattedMessage id={'marketplace.invoice.download'} />
    </button>
  );
};

const mapStateToProps = (state: RootState) => ({
  downloadId: getInvoiceDownloadId(state),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  handleDownloadInvoice: (invoiceId: string) => dispatch(fetchInvoiceDownloadThunk(invoiceId)),
});

export const InvoiceDownloadButtonContainer = connect(mapStateToProps, mapDispatchToProps)(InvoiceDownloadButton);
