import { accessToken } from '../../../configuration/tokenHandling/accessToken';

export function getRequest(): RequestInit {
  return {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken.getAccessToken()}`,
    },
  };
}

export function deleteRequest(): RequestInit {
  return {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken.getAccessToken()}`,
    },
  };
}

export function postRequest<T>(body?: T, additionalHeaders: { [key: string]: string } = {}): RequestInit {
  return {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken.getAccessToken()}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...additionalHeaders,
    },
    body: body !== undefined ? JSON.stringify(body) : undefined,
  };
}

export function fileUpload(body: File, additionalHeaders: { [key: string]: string } = {}): RequestInit {
  const data = new FormData();
  data.append('file', body);
  return {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken.getAccessToken()}`,
      Accept: 'application/json',
      ...additionalHeaders,
    },
    body: data,
  };
}
