import * as t from 'io-ts';

const ApiAssetDiscountCodec = t.intersection([
  t.type({
    asset_id: t.string,
    product_id: t.string,
    discount_percentage: t.number,
    duration_period: t.number,
    duration_unit: t.union([t.literal('day'), t.literal('month'), t.literal('year')]),
  }),
  t.partial({
    product_levels: t.array(t.string),
    ends_at: t.string,
    campaign: t.string,
  }),
]);

const ApiProductDiscountCodec = t.intersection([
  t.type({
    product_id: t.string,
    discount_percentage: t.number,
    duration_period: t.number,
    duration_unit: t.union([t.literal('day'), t.literal('month'), t.literal('year')]),
  }),
  t.partial({
    levels: t.array(t.string),
    ends_at: t.string,
    campaign: t.string,
  }),
]);

const ApiAccountDiscountCodec = t.intersection([
  t.type({
    discount_percentage: t.number,
    duration_period: t.number,
    duration_unit: t.union([t.literal('day'), t.literal('month'), t.literal('year')]),
  }),
  t.partial({
    ends_at: t.string,
    campaign: t.string,
  }),
]);

export const ApiDiscountsCodec = t.type({
  asset_discounts: t.array(ApiAssetDiscountCodec),
  account_discounts: t.array(ApiAccountDiscountCodec),
  product_discounts: t.array(ApiProductDiscountCodec),
});

export type ApiDiscounts = t.TypeOf<typeof ApiDiscountsCodec>;
export type ApiAssetDiscount = t.TypeOf<typeof ApiAssetDiscountCodec>;
