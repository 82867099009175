import { getApiAssets, getApiTags } from '../../config/pathURL/config';
import type { Tag, TagToAssetsMap } from '../../customerCenter/assetOverview/redux/types';
import { decodeJson, ignoreError, jsonOrReject } from '../api';
import { getRequest } from '../requests';
import { type ApiAssetInfoBody, ApiAssetInfoBodyCodec, ApiTagsCodec } from './tag.types';
import { mapAssetInfo, mapTags } from './tagMapper';

export function fetchTags(): Promise<Tag[]> {
  return fetch(`${getApiTags()}/tags`, getRequest())
    .then(jsonOrReject)
    .then(decodeJson(ApiTagsCodec))
    .then(mapTags)
    .catch(error => ignoreError(error, []));
}

export async function fetchAssetInfo(): Promise<TagToAssetsMap> {
  const fetchPage = (url: string): Promise<ApiAssetInfoBody> =>
    fetch(url, getRequest())
      .then(jsonOrReject)
      .then(decodeJson(ApiAssetInfoBodyCodec))
      .catch(error => ignoreError(error, { items: [] }));

  let page = await fetchPage(`${getApiAssets()}/assets?embed=(tags)&limit=1000`);
  let allItems = page.items;
  while (page._links?.next) {
    page = await fetchPage(page._links.next.href);
    allItems = allItems.concat(page.items);
  }
  return mapAssetInfo(allItems);
}
