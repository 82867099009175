import { FormattedMessage } from 'react-intl';
import { ProductIcon } from '../../common/productImages/ProductIcon';

interface Props {
  productName: string;
  productId: string;
  numberOfContracts: number;
  numberOfRenewals: number;
}

export const FreeTrialPanelHeader = (props: Props) => {
  const { productName, productId, numberOfContracts, numberOfRenewals } = props;
  return (
    <div className={'display-flex align-items-center'}>
      <ProductIcon
        productId={productId}
        className={'productIcon width-50 height-50 margin-right-10 position-relative'}
      />
      <div className={'flex-1-0 line-height-125rel'}>
        <div className={'productName text-size-h4 text-bold'}>{productName}</div>
        <div className={'serviceType'}>
          <span>
            <FormattedMessage id={'marketplace.freeTrialTab.activations'} />
            {`: ${numberOfContracts}`}
          </span>
          <span className='margin-x-5'>|</span>
          <span>
            <FormattedMessage id={'marketplace.freeTrialTab.renewals'} />
            {`: ${numberOfRenewals}`}
          </span>
        </div>
      </div>
    </div>
  );
};
