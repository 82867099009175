import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { RootState } from '../../../../../configuration/setup/store';
import { UnreachableCaseError } from '../../../../../utils/typescriptUtil';
import { isFreeTrialLevel } from '../../../common/termedLevel/freeTrialLevel';
import { getProductLabel } from '../../../common/utils/productLabelUtil';
import { type UsageSummaryColumn, UsageSummaryColumnType } from './types';
import { getUsageSummaryColumns } from './usageSummaryColumnService';

interface TableHeadProps {
  columns: UsageSummaryColumn[];
}

const UsageSummaryTableHead = (props: TableHeadProps) => {
  const { columns } = props;

  function getTableHeader(column: UsageSummaryColumn, index: number) {
    switch (column.type) {
      case UsageSummaryColumnType.RESOURCE:
        return (
          <th key={index} className={'user-select-none'}>
            <span>&nbsp;</span>
          </th>
        );
      case UsageSummaryColumnType.PRICE:
        return (
          <th key={index} className={'user-select-none text-center'}>
            <div>
              <FormattedMessage id={'marketplace.products.label.price'} />
              {'*'} {/* Reference to list-price explanation */}
            </div>
          </th>
        );
      case UsageSummaryColumnType.PRODUCT_COLUMN:
        return (
          <th
            key={index}
            className={classnames(
              'user-select-none text-center',
              isFreeTrialLevel(column.level) && 'text-color-secondary'
            )}
          >
            <div>{getProductLabel(column, useIntl())}</div>
          </th>
        );
      case UsageSummaryColumnType.SPACER_COLUMN:
        return <th key={index} className={'user-select-none text-center padding-0'} />;
      default:
        throw new UnreachableCaseError(column.type);
    }
  }

  return (
    <thead>
      <tr>
        {columns.map((column, index: number) => {
          return getTableHeader(column, index);
        })}
      </tr>
    </thead>
  );
};

const mapStateToAssetProps = () => ({
  columns: [{ type: UsageSummaryColumnType.RESOURCE }],
});

const mapStateToUsagesProps = (state: RootState) => ({
  columns: getUsageSummaryColumns(state),
});

const mapStateToPriceProps = () => ({
  columns: [{ type: UsageSummaryColumnType.PRICE }],
});

export const UsageSummaryTableResourceHeadContainer = connect(mapStateToAssetProps)(UsageSummaryTableHead);
export const UsageSummaryTableUsagesHeadContainer = connect(mapStateToUsagesProps)(UsageSummaryTableHead);
export const UsageSummaryTablePriceHeadContainer = connect(mapStateToPriceProps)(UsageSummaryTableHead);
