import type { Discounts } from '../../common/discount/redux/types';
import { getMarketplaceBackend } from '../../config/pathURL/config';
import { decodeJson, jsonOrReject, onRejected } from '../api';
import { getParameters } from '../parameterService';
import { getRequest } from '../requests';
import { ApiDiscountsCodec } from './discount.types';
import { mapApiDiscounts } from './discountMapper';

export function fetchDiscounts(productId?: string, level?: string): Promise<Discounts> {
  const params = getParameters({ product_id: productId, level: level });
  return fetch(`${getMarketplaceBackend()}/discounts${params}`, getRequest())
    .then(jsonOrReject)
    .then(decodeJson(ApiDiscountsCodec))
    .then(mapApiDiscounts)
    .catch(onRejected([401]));
}
