import { reportErrorToSentry } from '../../../../configuration/setup/sentry';
import type { Product, ProductLevel } from '../../checkout/redux/types';
import { isRatePlanBookable } from '../../common/product/productService';
import type { TermedProductInfo, TermedProductLevel } from '../../common/termedLevel/redux/types';
import { mapProductType } from '../mapProductType';
import { mapContractPeriod } from '../periods';
import { sortRatePlans } from '../productSorter';
import type { ApiProduct, ApiRatePlan } from './product.types';

function mapToLevelDetails(apiRatePlan: ApiRatePlan): ProductLevel {
  if (!apiRatePlan.name || !apiRatePlan.level) {
    throw new Error();
  }
  return {
    level: apiRatePlan.level,
    name: apiRatePlan.name,
    ratePlanId: apiRatePlan.id,
    currency: apiRatePlan.currency,
    price: apiRatePlan.price,
    contractPeriod: mapContractPeriod(apiRatePlan.contract_period),
    variantName: apiRatePlan.variant_name,
    legacy: apiRatePlan.legacy,
    renewsToLevel: apiRatePlan.renews_to_level,
    effectiveEndDate: apiRatePlan.effective_end_date,
    futurePrice: apiRatePlan.future_price
      ? {
          price: apiRatePlan.future_price.price,
          currency: apiRatePlan.future_price.currency,
          effectiveStartDate: apiRatePlan.future_price.effective_start_date,
        }
      : undefined,
  };
}

export function mapProduct(apiProductDetails: ApiProduct): Product {
  if (apiProductDetails.legacy) {
    throw new Error(`Legacy product cannot be booked ${apiProductDetails}`);
  }
  if (!apiProductDetails.rate_plans || apiProductDetails.rate_plans.length === 0) {
    return {
      sku: apiProductDetails.id,
      name: apiProductDetails.name,
      productType: mapProductType(apiProductDetails.product_type),
    };
  }
  if (apiProductDetails.rate_plans.length === 1) {
    const firstRatePlan = apiProductDetails.rate_plans[0];
    return {
      sku: apiProductDetails.id,
      name: firstRatePlan.name,
      productType: mapProductType(apiProductDetails.product_type),
      ratePlanId: firstRatePlan.id,
      currency: firstRatePlan.currency,
      price: firstRatePlan.price,
      level: firstRatePlan.level,
      contractPeriod: mapContractPeriod(firstRatePlan.contract_period),
      variantName: firstRatePlan.variant_name,
      renewsToLevel: firstRatePlan.renews_to_level,
      effectiveEndDate: firstRatePlan.effective_end_date,
      futurePrice: firstRatePlan.future_price
        ? {
            price: firstRatePlan.future_price.price,
            currency: firstRatePlan.future_price.currency,
            effectiveStartDate: firstRatePlan.future_price.effective_start_date,
          }
        : undefined,
    };
  }
  if (apiProductDetails.rate_plans.filter(it => !it.level).length > 0) {
    throw new Error(`Level information is incomplete on api-product ${apiProductDetails}`);
  }

  return {
    sku: apiProductDetails.id,
    name: apiProductDetails.name,
    productType: mapProductType(apiProductDetails.product_type),
    levels: sortRatePlans(apiProductDetails.rate_plans.map(apiRatePlan => mapToLevelDetails(apiRatePlan))),
  };
}

const getContractLevels = (apiRatePlans?: ApiRatePlan[]): TermedProductLevel[] => {
  if (!apiRatePlans || apiRatePlans.length === 0) {
    throw Error('Expected at least one rate plan on termed product.');
  }

  return apiRatePlans
    .filter(isRatePlanBookable)
    .map(ratePlan => {
      if (!ratePlan.level) {
        reportErrorToSentry(`Ignoring rate plan without level. Rate plans: ${JSON.stringify(apiRatePlans)}`);
        return undefined;
      }
      return {
        level: ratePlan.level,
        productLevelName: ratePlan.name,
        price: ratePlan.price,
        currency: ratePlan.currency,
        contractPeriod: mapContractPeriod(ratePlan.contract_period),
        variantName: ratePlan.variant_name,
        futurePrice: ratePlan.future_price
          ? {
              price: ratePlan.future_price.price,
              currency: ratePlan.future_price.currency,
              effectiveStartDate: ratePlan.future_price.effective_start_date,
            }
          : undefined,
      };
    })
    .filter((it): it is TermedProductLevel => it !== undefined);
};

export function mapContractProductDetails(apiProduct: ApiProduct): TermedProductInfo {
  return {
    productId: apiProduct.id,
    name: apiProduct.name,
    levels: sortRatePlans(getContractLevels(apiProduct.rate_plans)),
    productType: mapProductType(apiProduct.product_type),
  };
}
