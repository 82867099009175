import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { getAccountId, getDisplayMessages } from '../../../../../configuration';
import type { RootDispatch, RootState } from '../../../../../configuration/setup/store';
import { apiCallAction } from '../../../api/redux/api.redux';
import { ApiCalls } from '../../../api/redux/types';
import { postSubscriptionCancel } from '../../../api/subscription/subscriptionCalls';
import { serviceOverviewActions } from '../redux/serviceOverview.redux';
import { pushGaCancellationEvent } from '../services/cancellationGTMUtils';
import { makeIds } from '../services/subscriptionService';
import type { ServiceOverviewItem } from '../types';
import { fetchActivationSummaryThunk } from './activationSummary.thunk';
import { fetchResourcesForSkuThunk } from './resourceActivations.thunk';

export const cancelSubscriptionThunk =
  (service: ServiceOverviewItem, resourceIds: string[], level: string | undefined) =>
  (dispatch: RootDispatch, getState: () => RootState) => {
    const languageData = getDisplayMessages(getState());

    const loadingItemIds = makeIds(service.sku, resourceIds);
    dispatch(serviceOverviewActions.addToSubscriptionCancelInProgress(loadingItemIds));
    dispatch(apiCallAction(ApiCalls.SUBSCRIPTION_CANCEL, true));

    const accountId = getAccountId(getState());
    pushGaCancellationEvent(service, accountId, resourceIds, level);

    return postSubscriptionCancel(service.sku, resourceIds)
      .then(() => {
        dispatch(apiCallAction(ApiCalls.SUBSCRIPTION_CANCEL, false));
        dispatch(fetchActivationSummaryThunk(false));
        return dispatch(fetchResourcesForSkuThunk(service.sku));
      })
      .then(() => {
        dispatch(serviceOverviewActions.removeFromSubscriptionCancelInProgress(loadingItemIds));
      })
      .catch(() => {
        dispatch(serviceOverviewActions.removeFromSubscriptionCancelInProgress(loadingItemIds));
        Notification.error(languageData['marketplace.myServices.subscription.cancel.error']);
        dispatch(apiCallAction(ApiCalls.SUBSCRIPTION_CANCEL, false, true));
      });
  };
