import type { MessageKey } from '../../../configuration';
import type { Tenant } from '../common/utils/tenants';

export interface TabInfo {
  componentName: CustomerCenterTab;
  limitedToTenants: Tenant[];
  requiredPermissions: string[];
  labelId: MessageKey;
}

export enum CustomerCenterTab {
  SERVICES = 'SERVICES',
  BOOKINGS = 'BOOKINGS',
  ASSETS = 'ASSETS',
  CONTRACTS = 'CONTRACTS',
  INVOICES = 'INVOICES',
  HISTORY = 'HISTORY',
  PAYMENT_METHODS = 'PAYMENTMETHODS',
  USAGE = 'USAGE',
  FREE_TRIAL = 'FREETRIAL',
  PROFILE = 'PROFILE',
  PARTNERS = 'PARTNERS',
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
  COMPANY_SETTINGS = 'COMPANY',
}
