import { DEFAULT_BILLING_PERIOD, PRODUCT_CATEGORY_GA } from '../../../common/product/product';
import { safeDataLayerPush } from '../../../common/utils/googleTagManagerWrapper';
import { getGTMPurchaseId } from '../../../common/utils/purchaseIdUtil';
import type { ProductRatePlan, ServiceOverviewItem } from '../types';
import { hasTwoOrMoreLevels } from './serviceOverviewLevelService';

export function getGAEventServiceCancellation(
  service: ServiceOverviewItem,
  accountId?: string,
  resourceIds?: string[],
  level?: string
) {
  let ratePlan: ProductRatePlan | undefined;
  if (hasTwoOrMoreLevels(service)) {
    ratePlan = service?.productRatePlans?.find(rp => rp.level === level);
  } else if (service?.productRatePlans && service.productRatePlans.length > 0) {
    ratePlan = service.productRatePlans[0];
  }
  if (!ratePlan) {
    return;
  }
  const cancellationDate = new Date();

  return {
    event: 'EECrefund',
    ecommerce: {
      currencyCode: ratePlan.currency,
      refund: {
        actionField: {
          id: getGTMPurchaseId(service.sku, accountId ?? '', level),
        },
        products: [
          {
            name: ratePlan.name || service.productName,
            id: service.sku + (ratePlan.level ? `_${ratePlan.level}` : ''), // SKU
            price: ratePlan.price.toString(), // price without tax
            priceModel: DEFAULT_BILLING_PERIOD, // Daily, Monthly, ....
            category: ratePlan.price > 0 ? PRODUCT_CATEGORY_GA.PAID : PRODUCT_CATEGORY_GA.FREE,
            quantity: resourceIds?.length, // License or number of assets
            cancellationDate:
              `${cancellationDate.getUTCDate()}` +
              `.${cancellationDate.getUTCMonth() + 1}.${cancellationDate.getUTCFullYear()}`,
          },
        ],
      },
    },
  };
}

export function pushGaCancellationEvent(
  subscription: ServiceOverviewItem,
  accountId?: string,
  resourceIds?: string[],
  level?: string
) {
  safeDataLayerPush(() => getGAEventServiceCancellation(subscription, accountId, resourceIds, level));
}
