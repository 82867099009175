import { UnreachableCaseError } from '../../../../utils/typescriptUtil';
import { PaymentMethodType } from '../../api/paymentMethods/paymentMethodTypes.types';
import { safeDataLayerPush } from '../utils/googleTagManagerWrapper';
import type { PaymentMethod } from './redux/types';

export type GAPaymentType = 'sepa_debit' | 'card' | 'bank_transfer';

export function getGAPaymentType(paymentMethodType: PaymentMethodType): GAPaymentType {
  switch (paymentMethodType) {
    case PaymentMethodType.SEPA:
      return 'sepa_debit';
    case PaymentMethodType.CREDIT_CARD:
      return 'card';
    case PaymentMethodType.BANK_TRANSFER:
      return 'bank_transfer';
    default:
      throw new UnreachableCaseError(paymentMethodType);
  }
}

export function publishPaymentMethodUpdateToGA(
  previousInformation: PaymentMethod | undefined,
  newPaymentType: GAPaymentType
) {
  if (previousInformation?.paymentType) {
    const paymentType = previousInformation.paymentType;
    safeDataLayerPush(() => ({
      event: 'PaymentMethodChange',
      eventPayload: {
        paymentType: newPaymentType,
        previousPaymentType: getGAPaymentType(paymentType),
      },
    }));
  } else {
    safeDataLayerPush(() => ({
      event: 'PaymentMethodAdded',
      eventPayload: {
        paymentType: newPaymentType,
      },
    }));
  }
}
