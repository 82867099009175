import { useEffect } from 'react';
import { type IntlShape, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { MarketplaceContent } from './MarketplaceContent';
import { setAemLanguageCode } from './lang/aemLanguageConfiguration';
import { createLocaleShort, getSupportedLanguageKey } from './lang/languageConfiguration';
import { setLocaleShort } from './lang/languageData';
import 'cssuseragent';
import { useLocation } from 'react-router';
import { getLocale } from '../../configuration';
import type { RootState } from '../../configuration/setup/store';
import { getIsAccountNotFound } from './common/billing/redux/billing.redux';
import { AccountNotFoundState } from './common/error/AccountNotFoundState';
import { safeDataLayerPush } from './common/utils/googleTagManagerWrapper';

interface Props {
  isAccountNotFound: boolean;
  userLanguage: string;
}

function setDocumentTitle(pathname: string, intl: IntlShape) {
  if (pathname.includes('checkout')) {
    document.title = 'RIO Checkout';
  } else {
    document.title = `RIO ${intl.formatMessage({ id: 'marketplace.customerCenter' })}`;
  }
}

function Marketplace(props: Props) {
  const { userLanguage, isAccountNotFound } = props;
  const { pathname } = useLocation();
  useEffect(
    () =>
      safeDataLayerPush(() => ({
        event: 'virtPath',
        virtPath: pathname,
      })),
    [pathname]
  );
  const intl = useIntl();
  setDocumentTitle(pathname, intl);

  const key = getSupportedLanguageKey(userLanguage);
  setLocaleShort(createLocaleShort(key));
  setAemLanguageCode(key);

  if (isAccountNotFound) {
    return <AccountNotFoundState />;
  }
  return <MarketplaceContent />;
}

export function mapStateToProps(state: RootState) {
  return {
    userLanguage: getLocale(state),
    isAccountNotFound: getIsAccountNotFound(state),
  };
}

export const MarketplaceContainer = connect(mapStateToProps)(Marketplace);
