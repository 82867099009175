import { getMarketplaceBackend } from '../../config/pathURL/config';
import { rejectIfNotOk } from '../api';
import { postRequest } from '../requests';

export interface CreateOrderData {
  productId: string;
  assetIds: string[];
  externalReference: string;
  level: string;
  orderInfo: string;
}

export function createOrder({
  assetIds,
  productId,
  level,
  externalReference,
  orderInfo,
}: CreateOrderData): Promise<void> {
  const requestBody = {
    product_id: productId,
    level: level,
    asset_ids: assetIds,
    external_reference: externalReference,
    order_info: orderInfo,
  };
  return fetch(`${getMarketplaceBackend()}/orders`, postRequest(requestBody)).then(rejectIfNotOk);
}
