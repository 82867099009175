import TableViewToggles from '@rio-cloud/rio-uikit/lib/es/TableViewToggles';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { RootState } from '../../../../configuration/setup/store';
import { isServiceVisible } from '../../common/utils/isServiceVisible';
import { getProductLabel } from '../../common/utils/productLabelUtil';
import { AllAssetsCheckboxContainer } from './AllAssetsCheckboxContainer';
import { AssetOverviewTableHeaderItem } from './AssetOverviewTableHeaderItem';
import { AssetOverviewTableRow } from './AssetOverviewTableRow';
import { getAssetOverviewFilteredRows } from './assetOverviewSearchService';
import { getAssetOverviewColumns, getViewType } from './redux/assetOverview.redux';
import type { AssetOverviewRow, HeaderProduct, TableViewTogglesValues } from './redux/types';

const tableClassNames = (viewType: TableViewTogglesValues) =>
  classNames(
    'table',
    'table-layout-fixed',
    'table-column-overflow-hidden',
    'table-bordered',
    'table-condensed',
    'table-sticky',
    'table-hover',
    'table-head-filled',
    viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD && 'table-cards table-single-card',
    viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS && 'table-cards table-multi-cards'
  );

interface Props {
  tableColumns: HeaderProduct[];
  tableRows: AssetOverviewRow[];
  viewType: TableViewTogglesValues;
  tableMessage?: string;
}

const AssetOverviewTableContent = (props: Props) => {
  const { tableColumns, tableRows, viewType, tableMessage } = props;

  return (
    <div>
      <table className={tableClassNames(viewType)}>
        <colgroup>
          <col className='table-checkbox' />
          {tableColumns.map(column => (
            <col key={getProductLabel(column, useIntl())} />
          ))}
          <col />
        </colgroup>
        <thead>
          <tr>
            <th className='align-middle'>
              <span>
                <AllAssetsCheckboxContainer />
              </span>
            </th>
            <th className='align-middle'>
              <FormattedMessage id='marketplace.assetOverview.assetHeader' />{' '}
            </th>
            {tableColumns.map(column => (
              <th key={getProductLabel(column, useIntl())} className='align-top text-center'>
                <AssetOverviewTableHeaderItem {...column} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableRows.map(row => (
            <AssetOverviewTableRow tableRow={row} tableColumns={tableColumns} key={row.assetId} />
          ))}
          {viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS &&
            // Placeholder workaround for equal with cards of the last row
            Array.from(Array(10).keys()).map(it => <tr key={it} className='table-card-placeholder' />)}
        </tbody>
      </table>
      {tableMessage && (
        <div className='text-center'>
          <FormattedMessage id={tableMessage} />
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state: RootState) => {
  const { displayRows, message } = getAssetOverviewFilteredRows(state);
  return {
    tableColumns: getAssetOverviewColumns(state).filter(column => isServiceVisible({ sku: column.productId })),
    tableRows: displayRows,
    tableMessage: message,
    viewType: getViewType(state),
  };
};

export const AssetOverviewTableContainer = connect(mapStateToProps)(AssetOverviewTableContent);
