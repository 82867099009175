import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../../../configuration/setup/store';
import type { PaymentMethodType } from '../../../api/paymentMethods/paymentMethodTypes.types';
import type { PaymentMethod, PaymentMethodsState } from './types';

const initialState: PaymentMethodsState = {
  editMode: false,
  paymentMethods: undefined,
  paymentMethodTypes: undefined,
  stripeApiKey: undefined,
};

export const { reducer: paymentMethodsReducer, actions: paymentMethodsActions } = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    setPaymentMethods: (state: PaymentMethodsState, action: PayloadAction<PaymentMethod[] | undefined>) => {
      state.paymentMethods = action.payload;
    },
    setPaymentMethodDefaultId: (state: PaymentMethodsState, action: PayloadAction<string | undefined>) => {
      state.paymentMethodDefaultId = action.payload;
    },
    setPaymentMethodTypes: (state: PaymentMethodsState, action: PayloadAction<PaymentMethodType[]>) => {
      state.paymentMethodTypes = action.payload;
    },
    setStripeApiKey: (state: PaymentMethodsState, action: PayloadAction<string>) => {
      state.stripeApiKey = action.payload;
    },
    setStripeClientSecret: (state: PaymentMethodsState, action: PayloadAction<string>) => {
      state.stripeClientSecret = action.payload;
    },
    setPaymentMethodEditMode: (state: PaymentMethodsState, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
    },
  },
});

const getBase = (state: RootState) => state.marketplace.paymentMethods;
export const getPaymentMethodEditMode = (state: RootState) => getBase(state).editMode;
export const getStripeApiKey = (state: RootState) => getBase(state).stripeApiKey;
export const getPaymentMethodTypes = (state: RootState) => getBase(state).paymentMethodTypes;
export const getPaymentMethods = (state: RootState) => getBase(state).paymentMethods;
export const getPaymentMethodDefaultId = (state: RootState) => getBase(state).paymentMethodDefaultId;
export const getStripeClientSecret = (state: RootState) => getBase(state).stripeClientSecret;
