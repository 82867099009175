import * as t from 'io-ts';

export const ApiTagCodec = t.type({
  id: t.string,
  name: t.string,
});

export const ApiTagsCodec = t.type({
  items: t.array(ApiTagCodec),
});

export type ApiTags = t.TypeOf<typeof ApiTagsCodec>;

export const ApiAssetInfoCodec = t.type({
  id: t.string,
  _embedded: t.type({
    tags: t.type({
      items: t.array(
        t.type({
          id: t.string,
        })
      ),
    }),
  }),
});

export const ApiAssetInfoBodyCodec = t.intersection([
  t.type({
    items: t.array(ApiAssetInfoCodec),
  }),
  t.partial({
    _links: t.partial({
      next: t.type({
        href: t.string,
      }),
    }),
  }),
]);

export type ApiAssetInfoBody = t.TypeOf<typeof ApiAssetInfoBodyCodec>;
export type ApiAssetInfo = t.TypeOf<typeof ApiAssetInfoCodec>;
