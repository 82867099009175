import orderBy from 'lodash/orderBy';
import { FormattedMessage } from 'react-intl';
import { HistoryActionInfo } from './HistoryActionInfo';
import { HistoryItem } from './HistoryItem';
import { HistoryLoadMoreButtonContainer } from './HistoryLoadMoreButtonContainer';
import type { HistoryEvent } from './redux/types';

interface Props {
  historyEvents: HistoryEvent[];
}

export function HistoryTable({ historyEvents }: Props) {
  const orderedByDate = orderBy(historyEvents, [(event: HistoryEvent) => event.timestamp], ['desc']);
  return (
    <div className={'margin-bottom-20'}>
      <table className={'table table-head-filled table-sticky margin-bottom-20'}>
        <colgroup>
          <col />
          <col />
          <col />
          <col className='width-300' />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th>
              <FormattedMessage id={'marketplace.history.header.date'} />
            </th>
            <th>
              <FormattedMessage id={'marketplace.history.header.action'} />
              <HistoryActionInfo />
            </th>
            <th>
              <FormattedMessage id={'marketplace.history.header.service'} />
            </th>
            <th>
              <FormattedMessage id={'marketplace.history.header.details'} />
            </th>
            <th>
              <FormattedMessage id={'marketplace.history.header.triggeredBy'} />
            </th>
          </tr>
        </thead>
        <tbody>
          {orderedByDate.map((historyEvent, index) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            <HistoryItem item={historyEvent} key={index} />
          ))}
        </tbody>
      </table>
      <HistoryLoadMoreButtonContainer />
    </div>
  );
}
