import { connect } from 'react-redux';
import type { RootState } from '../../../../configuration/setup/store';
import { DiscountTextBadge } from '../../common/discount/DiscountBadge';
import type { ProductDiscount } from '../../common/discount/redux/types';
import { ProductTypedFormattedMessage } from '../../common/product/ProductTypedFormattedMessage';
import { ProductIcon } from '../../common/productImages/ProductIcon';
import { makeGetServiceResourcesForSku } from './services/serviceOverviewResourceStateService';
import type { ServiceOverviewItem } from './types';

interface ServiceOverviewItemHeaderParams {
  service: ServiceOverviewItem;
  discount?: ProductDiscount;
  numberOfFetchedResources: number | undefined;
}

const ServiceHeader = (props: ServiceOverviewItemHeaderParams) => {
  const { productName, sku, amount, productType } = props.service;
  const { discount, numberOfFetchedResources } = props;

  return (
    <div className={'display-flex align-items-center'}>
      <ProductIcon productId={sku} className={'productIcon width-50 height-50 margin-right-10 position-relative'} />
      <div className={'flex-1-0 line-height-125rel'}>
        <div className={'productName text-size-h4 text-bold'}>{productName}</div>
        <div className={'serviceType'}>
          <ProductTypedFormattedMessage
            productType={productType}
            userBasedId='marketplace.myServices.user.numberOfResources.label'
            assetBasedId='marketplace.myServices.asset.numberOfResources.label'
          />
          {`: ${numberOfFetchedResources !== undefined ? numberOfFetchedResources : amount}`}
        </div>
      </div>
      {discount && (
        <span className={'margin-right-10'}>
          <DiscountTextBadge />
        </span>
      )}
    </div>
  );
};

function makeMapStateToProps() {
  const getServiceResourcesForSku = makeGetServiceResourcesForSku();

  return (state: RootState, ownProps: { service: ServiceOverviewItem }) => ({
    numberOfFetchedResources: getServiceResourcesForSku(state, ownProps.service.sku)?.length,
  });
}

export const ServiceHeaderContainer = connect(makeMapStateToProps)(ServiceHeader);
