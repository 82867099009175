import { useEffect, useState } from 'react';
import { fetchAllNonLegacyProducts } from '../../api/product/productCalls';
import { type Product, isBookableProduct, isMultiLevelProduct } from '../../checkout/redux/types';

type ProductPriceDetail = { productId: string; level?: string; price: number; currency?: string };

export function usePriceFetching(): ProductPriceDetail[] {
  const [priceDetails, setPriceDetails] = useState<ProductPriceDetail[]>([]);

  useEffect(() => {
    fetchAllNonLegacyProducts()
      .then(products => {
        const details = products.flatMap(mapProductToPriceDetails);
        setPriceDetails(details);
      })
      .catch();
  }, []);
  return priceDetails;
}

function mapProductToPriceDetails(product: Product): ProductPriceDetail[] {
  if (isBookableProduct(product)) {
    return [{ productId: product.sku, level: product.level, price: product.price, currency: product.currency }];
  }
  if (isMultiLevelProduct(product)) {
    return product.levels.map(level => ({
      productId: product.sku,
      level: level.level,
      price: level.price,
      currency: level.currency,
    }));
  }
  return [];
}
