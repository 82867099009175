import omit from 'lodash/fp/omit';
import type { UserProfile } from 'oidc-client-ts';
import type { UserProfile as CustomUserProfile } from './loginSlice';

const stripSnakeProps = omit(['family_name', 'given_name']);

export const mapUserProfile = (profile: UserProfile): CustomUserProfile => ({
  azp: profile.azp,
  email: profile.email,
  familyName: profile.family_name,
  givenName: profile.given_name,
  locale: profile.locale,
  name: profile.name,
  sub: profile.sub,
  ...stripSnakeProps(profile),
});
