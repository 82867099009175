import * as t from 'io-ts';

export const ApiProductCodec = t.intersection([
  t.type({
    product_sku: t.string,
    product_name: t.string,
    product_type: t.string,
    currency: t.string,
    price: t.number,
    rate_plan_id: t.string,
  }),
  t.partial({
    level: t.string,
    variant_name: t.string,
    contract_period: t.string,
  }),
]);

export type ApiProduct = t.TypeOf<typeof ApiProductCodec>;

export const ApiUsageUnitDiscountCodec = t.intersection([
  t.type({
    percentage: t.number,
    amount: t.number,
  }),
  t.partial({
    campaign: t.string,
  }),
]);

export const ApiUsageUnitCodec = t.intersection([
  t.type({
    product_sku: t.string,
    amount: t.number,
    rate_plan_id: t.string,
  }),
  t.partial({
    discounts: t.array(ApiUsageUnitDiscountCodec),
  }),
]);

export type ApiUsageUnit = t.TypeOf<typeof ApiUsageUnitCodec>;

export const ApiResourceUsageCodec = t.intersection([
  t.type({
    resource_id: t.string,
    resource_type: t.string,
    usage_units: t.array(ApiUsageUnitCodec),
  }),
  t.partial({
    resource_name: t.string,
    resource_identification: t.string,
  }),
]);

export type ApiAssetUsage = t.TypeOf<typeof ApiResourceUsageCodec>;

export const ApiUsageSummaryCodec = t.type({
  products: t.array(ApiProductCodec),
  usages: t.array(ApiResourceUsageCodec),
});
export type ApiUsageSummary = t.TypeOf<typeof ApiUsageSummaryCodec>;
