import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { accessToken } from '../../../../configuration/tokenHandling/accessToken';
import { getIAMBackend } from '../../config/pathURL/config';
import { FetchError } from '../FetchError';
import { onRejected } from '../api';
import { mapAccountResponse } from './accountMapper';
import type { Account } from './accountTypes';

export const accountApiSlice = createApi({
  reducerPath: 'accountApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getIAMBackend(),
    prepareHeaders: headers => {
      const token = accessToken.getAccessToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    getAccountById: builder.query<Account, { accountId: string }>({
      query: ({ accountId }) => `/accounts/${accountId}`,
      transformResponse: (response: unknown) => mapAccountResponse(response),
      transformErrorResponse: (response: { status: number; data: unknown }) => {
        const error = new FetchError({ ok: false, status: response.status } as Response, JSON.stringify(response.data));
        return onRejected([403, 404, 401])(error);
      },
    }),
  }),
});

export const { useGetAccountByIdQuery, useLazyGetAccountByIdQuery } = accountApiSlice;
