import { createSelector } from 'reselect';
import type { RootState } from '../../../../../configuration/setup/store';
import { getIsLoading } from '../../../api/redux/api.redux';
import { ApiCalls } from '../../../api/redux/types';
import { getPaymentMethodDefaultId, getPaymentMethodEditMode, getPaymentMethods } from './paymentMethods.redux';
import type { PaymentMethod } from './types';

export const getDefaultPaymentInformation = createSelector(
  [getPaymentMethods, getPaymentMethodDefaultId],
  (methods: PaymentMethod[] | undefined, methodDefaultId: string | undefined): PaymentMethod | undefined => {
    if (methods?.length) {
      return methods.find(paymentMethod => paymentMethod.id === methodDefaultId);
    }
    return undefined;
  }
);

export const getPaymentDetailsIsFetched = (state: RootState): boolean => {
  return !(
    getPaymentMethodEditMode(state) ||
    !getDefaultPaymentInformation(state) ||
    getIsLoading(state, ApiCalls.PAYMENT_METHODS_POST) ||
    getIsLoading(state, ApiCalls.PAYMENT_METHODS_GET) ||
    getIsLoading(state, ApiCalls.PAYMENT_METHODS_TYPES_GET)
  );
};

export const getPaymentDetailsIsLoading = (state: RootState): boolean => {
  return (
    getIsLoading(state, ApiCalls.PAYMENT_METHODS_POST) ||
    getIsLoading(state, ApiCalls.PAYMENT_METHODS_GET) ||
    getIsLoading(state, ApiCalls.PAYMENT_METHODS_TYPES_GET)
  );
};
