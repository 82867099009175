import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { type Stripe, loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { type WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getAccountId } from '../../../../configuration';
import type { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { PaymentMethodType } from '../../api/paymentMethods/paymentMethodTypes.types';
import { getHasError, getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import LoadingErrorState from '../error/LoadingErrorState';
import { PaymentElementContainer, type StripePaymentMethod } from './PaymentElementContainer';
import { fetchStripeApiKeyThunk, fetchStripeClientSecretThunk } from './fetchStripeKeys.thunk';
import { publishPaymentMethodThunk } from './paymentMethodsPublish.thunk';
import { getStripeApiKey, getStripeClientSecret } from './redux/paymentMethods.redux';
import type { PaymentMethod } from './redux/types';

export const PAYMENT_SANDBOX_ACCOUNT_ID = 'de05ca38-ea9a-4b69-93dc-99452c2193ce';
export const LOCAL_MOCK_ACCOUNT_ID = 'mockaccount';

interface Props {
  fetchStripeApiKey: () => void;
  fetchStripeClientSecret: () => void;
  paymentMethod?: PaymentMethodType;
  paymentInformation?: PaymentMethod;
  publishPaymentInformation: (data: { type: PaymentMethodType; id?: string }) => void;
  paymentFetchInProgress: boolean;
  stripeApiKey?: string;
  stripeClientSecret?: string;
  hasError: boolean;
  setSubmittingToStripe: (submitting: boolean) => void;
  accountId: string;
}

// 1. call BE getPaymentInformation
//  a) nothing there, show CreditCard
//  b) data there - show respective tab and show payment data read only as text
//     in case of CreditCard show ********1234
function StripePaymentForm(props: Props & WrappedComponentProps) {
  const { hasError, stripeApiKey, fetchStripeApiKey, paymentMethod = PaymentMethodType.SEPA } = props;

  const selectedPaymentType: StripePaymentMethod =
    paymentMethod === PaymentMethodType.CREDIT_CARD ? 'card' : 'sepa_debit';

  const [stripe, setStripe] = useState<Stripe | null>(null);
  useEffect(() => {
    if (!stripeApiKey) {
      fetchStripeApiKey();
    } else if (!stripe) {
      loadStripe(stripeApiKey).then(setStripe);
    }
  }, [stripeApiKey, stripe]);

  if (hasError) {
    return <LoadingErrorState />;
  }

  if (!stripeApiKey || !stripe) {
    return (
      <div className='padding-20'>
        <Spinner />
      </div>
    );
  }

  return (
    <PaymentElementContainer
      stripe={stripe}
      selectedPaymentMethod={selectedPaymentType}
      paymentInformation={props.paymentInformation}
    />
  );
}

function mapStateToProps(state: RootState) {
  return {
    stripeApiKey: getStripeApiKey(state),
    stripeClientSecret: getStripeClientSecret(state),
    hasError: getHasError(state, ApiCalls.STRIPE_API_KEY) || getHasError(state, ApiCalls.STRIPE_CLIENT_SECRET),
    paymentFetchInProgress:
      getIsLoading(state, ApiCalls.PAYMENT_METHODS_GET) || getIsLoading(state, ApiCalls.PAYMENT_METHODS_POST),
    accountId: getAccountId(state),
  };
}

function mapDispatchToProps(dispatch: RootDispatch) {
  return {
    publishPaymentInformation: ({ type, id }: { type: PaymentMethodType; id?: string }) => {
      dispatch(publishPaymentMethodThunk(type, id));
    },
    fetchStripeClientSecret: () => dispatch(fetchStripeClientSecretThunk),
    fetchStripeApiKey: () => dispatch(fetchStripeApiKeyThunk),
  };
}

export const StripePaymentFormContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(StripePaymentForm));
