import hoistNonReactStatic from 'hoist-non-react-statics';
import {
  type PermissionValidatorWrappedComponentProps,
  wrapWithPermissionsValidator,
} from './PermissionValidatorWrapper';

export function getWrappedDisplayName<T>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  return `WithPermissionValidator(${displayName})`;
}

export default function withPermissionValidator<T>(ErrorStateComponent: React.ComponentType) {
  return (
    WrappedComponent: React.ComponentType<T & PermissionValidatorWrappedComponentProps>
  ): React.ComponentType<T> => {
    const WithValidator = wrapWithPermissionsValidator<T>(ErrorStateComponent)(WrappedComponent);

    WithValidator.displayName = getWrappedDisplayName(WrappedComponent);

    hoistNonReactStatic(WithValidator, WrappedComponent);

    return WithValidator;
  };
}
