import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';

interface NextButtonProps {
  nextPath: string;
  disabled: boolean;
  onClick?: () => void;
}

const EnabledNextButton = (props: { to: string; onClick: (() => void) | undefined }) => (
  <Link to={props.to} onClick={props.onClick} className='nextStep btn btn-primary btn-icon-right pull-right'>
    <span className='rioglyph rioglyph-chevron-right' aria-hidden='true' />
    <FormattedMessage id='intl-msg:navigations.next' />
  </Link>
);

const DisabledNextButton = () => (
  <div className='nextStep btn btn-primary btn-icon-right pull-right disabled'>
    <span className='rioglyph rioglyph-chevron-right' aria-hidden='true' />
    <FormattedMessage id='intl-msg:navigations.next' />
  </div>
);

export const NextButton = ({ nextPath, disabled = false, onClick }: NextButtonProps) => {
  if (disabled) {
    return <DisabledNextButton key={'disabled'} />;
  }
  return <EnabledNextButton key={'enabled'} to={nextPath} onClick={onClick} />;
};

interface PreviousButtonProps {
  previousPath: string;
}

export const PreviousButton = ({ previousPath }: PreviousButtonProps) => (
  <Link to={previousPath} className='previousStep btn btn-default pull-left'>
    <span className='rioglyph rioglyph-chevron-left' aria-hidden='true' />
    <FormattedMessage id='intl-msg:navigations.previous' />
  </Link>
);
