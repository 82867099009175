import * as t from 'io-ts';

export const ApiStripePublicKeyCodec = t.type({
  public_key: t.string,
});

export type ApiStripePublicKey = t.TypeOf<typeof ApiStripePublicKeyCodec>;

export const ApiStripeSecretCodec = t.type({
  secret: t.string,
});

export type ApiStripeSecret = t.TypeOf<typeof ApiStripeSecretCodec>;
