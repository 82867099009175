import type { IntlShape } from 'react-intl';
import { createSelector } from 'reselect';
import type { RootState } from '../../../../configuration/setup/store';
import { DATE_FORMAT } from '../utils/dateUtil';
import { formatDurationUnit } from '../utils/formatDurationUnit';
import { getAssetDiscounts } from './redux/discount.redux';
import type { AssetDiscount, Discount, ProductDiscount } from './redux/types';

function isAccountDiscount(discount: Discount): discount is ProductDiscount {
  return !Object.hasOwn(discount, 'productId') && !Object.hasOwn(discount, 'assetId');
}

function isProductDiscount(discount: Discount): discount is ProductDiscount {
  return Object.hasOwn(discount, 'productId') && !Object.hasOwn(discount, 'assetId');
}

export function generateDiscountHint(discount: Discount, intl: IntlShape): string {
  const discountPercentage = `-${Math.round(discount.discountPercentage)}%`;
  // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
  let durationString;
  if (discount.endsAt) {
    const endsAt = intl.formatDate(discount.endsAt, { ...DATE_FORMAT, timeZone: 'UTC' });
    durationString = intl.formatMessage({ id: 'marketplace.until' }, { date: endsAt });
  } else {
    const forString = intl.formatMessage({ id: 'marketplace.discount.for' });
    const durationUnit = formatDurationUnit(discount.durationUnit, intl);
    durationString = `${forString} ${discount.durationPeriod} ${durationUnit}`;
  }

  const accountDiscountAdditionalText = isAccountDiscount(discount)
    ? intl.formatMessage({ id: 'marketplace.discount.allBookings' })
    : '';
  const productDiscountAdditionalText = isProductDiscount(discount)
    ? intl.formatMessage({ id: 'marketplace.discount.allBookingsForProduct' })
    : '';

  return `${intl.formatMessage({ id: 'marketplace.discount' })}${
    discount.campaign ? ` ${discount.campaign}` : ''
  }: ${discountPercentage} ${productDiscountAdditionalText} ${accountDiscountAdditionalText} ${durationString}`;
}

export interface DiscountIdentifier {
  assetId: string;
  productId: string;
  productLevel?: string;
}

function getDiscountKey(discount: { assetId: string; productId: string }) {
  return `${discount.assetId}_${discount.productId.toLocaleLowerCase()}}`;
}

export const getDiscountMap = createSelector([getAssetDiscounts], (discounts: AssetDiscount[]) => {
  const map = new Map<string, AssetDiscount[]>();
  discounts.forEach(discount => {
    const key = getDiscountKey(discount);
    if (!map.has(key)) {
      map.set(key, []);
    }
    map.get(key)?.push(discount);
  });
  return map;
});

export const mapStateToFindDiscountProps = (state: RootState) => {
  return {
    findDiscount: (discountIdentifier: DiscountIdentifier): AssetDiscount | undefined =>
      getDiscountMap(state)
        .get(getDiscountKey(discountIdentifier))
        ?.find(
          discount =>
            discountIdentifier.productLevel === undefined ||
            discount.productLevels?.includes(discountIdentifier.productLevel)
        ),
  };
};
