import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { RootState } from '../../../../configuration/setup/store';
import { PaymentInformation } from '../../common/payment/PaymentInformation';
import { getDefaultPaymentInformation } from '../../common/payment/redux/paymentMethodsSelectionService';
import type { PaymentMethod } from '../../common/payment/redux/types';
import { isPurchaseFree } from '../CheckoutService';

interface Props {
  isPurchaseFree: boolean;
  paymentInformation?: PaymentMethod;
}

const PaymentInformationPreview = (props: Props) => {
  if (props.isPurchaseFree) {
    return (
      <div className='noPaymentInfo'>
        <FormattedMessage id='marketplace.payment.information.free' />
      </div>
    );
  }
  return <PaymentInformation paymentInformation={props.paymentInformation} />;
};

const mapStateToProps = (state: RootState) => ({
  isPurchaseFree: isPurchaseFree(state),
  paymentInformation: getDefaultPaymentInformation(state),
});

export const PaymentInformationPreviewContainer = connect(mapStateToProps)(PaymentInformationPreview);
