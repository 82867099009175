import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import debounce from 'lodash/fp/debounce';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { historyActions } from './redux/history.redux';

interface Props {
  onSearchChange: (searchValue?: string) => void;
}

const HistoryTableSearch = (props: Props) => {
  const { onSearchChange } = props;
  const [localSearch, setLocalSearch] = useState<string>();
  const intl = useIntl();

  const replaceSearch = useMemo<(search: string) => void>(() => {
    return debounce(500)(search => onSearchChange(search));
  }, [onSearchChange]);

  return (
    <TableSearch
      placeholder={intl.formatMessage({ id: 'marketplace.history.search.placeholder' })}
      value={localSearch}
      onChange={(text: string) => {
        setLocalSearch(text);
        replaceSearch(text);
      }}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSearchChange: (searchValue?: string) => dispatch(historyActions.setResourceSearch(searchValue)),
});

export const HistoryTableSearchContainer = connect(null, mapDispatchToProps)(HistoryTableSearch);
