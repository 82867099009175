import { UnreachableCaseError } from '../../../../utils/typescriptUtil';
import { getMarketplaceBackend } from '../../config/pathURL/config';
import { type FreeTrial, FreeTrialStatus } from '../../freeTrial/redux/types';
import { decodeJson, jsonOrReject, onRejected } from '../api';
import { getRequest, postRequest } from '../requests';
import {
  API_FREE_TRIAL_STATUS_ACTIVE,
  API_FREE_TRIAL_STATUS_ELIGIBLE,
  API_FREE_TRIAL_STATUS_INELIGIBLE,
  type ApiFreeTrial,
  ApiFreeTrialCodec,
  type ApiFreeTrialState,
} from './freeTrial.types';

export function convertFreeTrialResponse(apiFreeTrial: ApiFreeTrial): FreeTrial {
  const convertFreeTrialState = (apiFreeTrialState: ApiFreeTrialState) => {
    switch (apiFreeTrialState) {
      case API_FREE_TRIAL_STATUS_ELIGIBLE:
        return FreeTrialStatus.ELIGIBLE;
      case API_FREE_TRIAL_STATUS_INELIGIBLE:
        return FreeTrialStatus.INELIGIBLE;
      case API_FREE_TRIAL_STATUS_ACTIVE:
        return FreeTrialStatus.ACTIVE;
      default:
        throw new UnreachableCaseError(apiFreeTrialState);
    }
  };

  return {
    status: convertFreeTrialState(apiFreeTrial.status),
    endsAt: apiFreeTrial.ends_at ? new Date(apiFreeTrial.ends_at) : undefined,
    products: apiFreeTrial.products?.map(p => ({
      productId: p.product_id,
      level: p.level,
    })),
  };
}

export function fetchFreeTrialStatus(): Promise<FreeTrial> {
  return fetch(`${getMarketplaceBackend()}/free-trial`, getRequest())
    .then(jsonOrReject)
    .then(decodeJson(ApiFreeTrialCodec))
    .then(convertFreeTrialResponse)
    .catch(onRejected([403]));
}

export function activateFreeTrial(): Promise<FreeTrial> {
  return fetch(`${getMarketplaceBackend()}/free-trial`, postRequest())
    .then(jsonOrReject)
    .then(decodeJson(ApiFreeTrialCodec))
    .then(convertFreeTrialResponse)
    .catch(onRejected());
}
