/**
 * @fileOverview: Reducer for configuration settings.
 */

/**
 * Service name for the backend marketplace service
 * @type {string}
 */

export function getMarketplaceEUBackend() {
  return import.meta.env.VITE_MARKETPLACE_EU_BACKEND_URL;
}

export function getMarketplaceBackend() {
  return import.meta.env.VITE_MARKETPLACE_BACKEND_URL;
}

export function getIAMBackend() {
  return import.meta.env.VITE_IAM_BACKEND_URL;
}

export function getEasyOnboardingConnectionsUrl() {
  return import.meta.env.VITE_EASY_ONBOARDING_CONNECTIONS_URL;
}

export function getApiTags() {
  return import.meta.env.VITE_API_TAGS;
}

export function getApiAssets() {
  return import.meta.env.VITE_API_ASSETS;
}

export function getApiPartners() {
  return import.meta.env.VITE_PARTNERS_BACKEND;
}
