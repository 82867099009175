import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { mapStateToFindDiscountProps } from '../../../common/discount/DiscountService';
import { OverlayTrigger, Tooltip } from '../../../common/utils/tooltipReExports';
import { DEFAULT_TOOLTIP_DELAY } from '../../../config/config';
import type { ProductInfo } from '../redux/types';
import { IconButtonPrimary, IconButtonSecondary } from './productCellConfig';

interface Props {
  product: ProductInfo;
}

export const ProductCellPending = ({ product }: Props) => {
  const intl = useIntl();

  const isFreeTrial = product.level?.endsWith('-ft');
  const buttonClasses = isFreeTrial ? IconButtonSecondary : IconButtonPrimary;

  const overlay = (
    <Tooltip id='tooltip' className={'width-auto max-width-300'}>
      {intl.formatMessage({ id: 'marketplace.asset.pending.activation.info' })}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      trigger={OverlayTrigger.TRIGGER_HOVER}
      placement='top'
      overlay={overlay}
      delay={DEFAULT_TOOLTIP_DELAY}
    >
      <span>
        <button className={`${buttonClasses} disabled`}>
          <span className='rioglyph rioglyph-hour-glass' />
        </button>
      </span>
    </OverlayTrigger>
  );
};

export const ProductCellPendingContainer = connect(mapStateToFindDiscountProps)(ProductCellPending);
