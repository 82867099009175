import { FormattedMessage, useIntl } from 'react-intl';
import { ProductTypedFormattedMessage } from '../product/ProductTypedFormattedMessage';
import type { ProductType } from '../product/product';
import type { Period } from '../termedLevel/types';
import { formatPrice } from '../utils/currencyUtil';
import { formatDurationUnit } from '../utils/formatDurationUnit';
import { getEstimatedPrice, getFullTermPrice } from './priceUtil';

interface Props {
  price: number;
  currency: string | undefined;
  contractPeriod: Period | undefined;
  productType: ProductType | undefined;
}

export function PriceInfoLongTerm(props: Props) {
  const { price, currency, contractPeriod, productType } = props;
  const intl = useIntl();

  if (price === 0) {
    return <FormattedMessage id={'marketplace.free'} />;
  }

  return (
    <>
      {contractPeriod === undefined ? (
        <>
          <span>{formatPrice(intl, getEstimatedPrice(price), currency)}</span>{' '}
          <ProductTypedFormattedMessage
            productType={productType}
            userBasedId='marketplace.productDetails.price.user.estimated'
            assetBasedId='marketplace.productDetails.price.asset.estimated'
          />
        </>
      ) : (
        <>
          <span>{formatPrice(intl, getFullTermPrice(price, contractPeriod), currency)}</span>{' '}
          <ProductTypedFormattedMessage
            productType={productType}
            userBasedId='marketplace.productDetails.price.user.fullTerm'
            assetBasedId='marketplace.productDetails.price.asset.fullTerm'
            values={{
              amount: contractPeriod.length,
              unit: formatDurationUnit(contractPeriod.unit, intl),
            }}
          />
        </>
      )}
    </>
  );
}
