import type { OnErrorFn } from '@formatjs/intl';
import type React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { DEFAULT_LOCALE, type DisplayMessages, getDisplayMessages, getLocale } from './configuration';
import { sendError } from './configuration/lang/services';
import type { RootState } from './configuration/setup/store';

const intlErrorHandler: OnErrorFn = error => {
  // Ignore errors like 'Missing locale data for locale XY. Using default locale "en-GB" as fallback.'
  if (error.code !== 'MISSING_DATA') {
    sendError(error);
  }
};

interface Props {
  displayMessages: DisplayMessages;
  userLocale: string;
  children: React.ReactNode;
}

function IntlWrapperComponent(props: Props) {
  const { displayMessages, userLocale, children } = props;

  return (
    <IntlProvider
      key={userLocale}
      locale={userLocale}
      messages={displayMessages}
      defaultLocale={DEFAULT_LOCALE}
      onError={intlErrorHandler}
    >
      {children}
    </IntlProvider>
  );
}

export const mapStateToProps = (state: RootState) => ({
  displayMessages: getDisplayMessages(state),
  userLocale: getLocale(state),
});

export const IntlWrapper = connect(mapStateToProps)(IntlWrapperComponent);
