import { getFeatureEnabled } from '../../../configuration/featureToggle/featureToggleService';
import { MAINTENANCE } from '../../../configuration/featureToggle/toggles';
import type { RootState } from '../../../configuration/setup/store';
import { getPermissions } from '../common/permissions/redux/permissions.redux';
import { getCustomerCenterPath } from '../common/routes';
import { type Tenant, getTenant } from '../common/utils/tenants';
import { getFreeTrialEndDate } from '../freeTrial/redux/freeTrial.redux';
import { getCustomerCenterTabs } from './customerCenterTabs';
import { CustomerCenterTab } from './types';

const subTabsByMainTab = (): Partial<Record<CustomerCenterTab, CustomerCenterTab[]>> => {
  return {
    [CustomerCenterTab.BOOKINGS]: [
      CustomerCenterTab.SERVICES,
      CustomerCenterTab.ASSETS,
      CustomerCenterTab.CONTRACTS,
      CustomerCenterTab.HISTORY,
      CustomerCenterTab.FREE_TRIAL,
    ],
    [CustomerCenterTab.PARTNERS]: [CustomerCenterTab.INBOUND, CustomerCenterTab.OUTBOUND],
  };
};

export function isSubTabOfTabActive(mainTab: CustomerCenterTab, pathName: string): boolean {
  return subTabsByMainTab()[mainTab]?.some(subTab => pathName.includes(subTab.toLowerCase())) || false;
}

export function isSubTabVisible(subTab: CustomerCenterTab, pathName: string): boolean {
  for (const subTabs of Object.values(subTabsByMainTab())) {
    if (subTabs.some(tab => pathName.includes(tab.toLowerCase()))) {
      return subTabs?.includes(subTab);
    }
  }
  return false;
}

function isParentTab(tab: CustomerCenterTab): boolean {
  return !!subTabsByMainTab()[tab];
}

function isSubTab(tab: CustomerCenterTab): boolean {
  return Object.values(subTabsByMainTab()).flat().includes(tab);
}

function isUnusedParentTab(mainTab: CustomerCenterTab, existingTabs: CustomerCenterTab[]): boolean {
  return subTabsByMainTab()[mainTab]?.every(subTab => !existingTabs.includes(subTab)) || false;
}

function getAllowedTabs(permissions: string[] | undefined, tenant: Tenant) {
  if (!permissions) {
    return [];
  }

  const tabInfos = getCustomerCenterTabs()
    .filter(tab => (tab.requiredPermissions.length === 0 ? true : permissions.includes(tab.requiredPermissions[0])))
    .filter(tab => tab.limitedToTenants.includes(tenant));

  const existingTabs = tabInfos.map(tabInfo => tabInfo.componentName);
  return tabInfos.filter(tabInfo => !isUnusedParentTab(tabInfo.componentName, existingTabs));
}

export const getAllVisibleCustomerCenterTabs = (state: RootState) => {
  const permissions = getPermissions(state);
  const tenant = getTenant(state);
  const maintenanceMode = getFeatureEnabled(state, MAINTENANCE);

  const hasActiveFreeTrial = getFreeTrialEndDate(state) !== undefined;

  const allAllowedTabs = getAllowedTabs(permissions, tenant);
  if (maintenanceMode) {
    return [];
  }
  if (!hasActiveFreeTrial) {
    return allAllowedTabs.filter(tabInfo => tabInfo.componentName !== CustomerCenterTab.FREE_TRIAL);
  }
  return allAllowedTabs;
};

export const getMainNavigationCustomerCenterTabs = (state: RootState) => {
  return getAllVisibleCustomerCenterTabs(state).filter(it => !isSubTab(it.componentName));
};

export const getSubNavigationCustomerCenterTabs = (state: RootState) => {
  return getAllVisibleCustomerCenterTabs(state).filter(it => isSubTab(it.componentName));
};

export const getDefaultCustomerCenterTab = (state: RootState): CustomerCenterTab | undefined => {
  const allTabs = getAllVisibleCustomerCenterTabs(state)
    .filter(tabInfo => !isParentTab(tabInfo.componentName))
    .map(tabInfo => tabInfo.componentName);
  return allTabs.length > 0 ? allTabs[0] : undefined;
};

export function getManageContractsLink() {
  return getCustomerCenterPath(CustomerCenterTab.CONTRACTS);
}
