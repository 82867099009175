import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { RootState } from '../../../../configuration/setup/store';
import { ProductIcon } from '../../common/productImages/ProductIcon';
import { DATE_FORMAT } from '../../common/utils/dateUtil';
import { getProductLabel } from '../../common/utils/productLabelUtil';
import { getSelectedProductOrProductLevel } from '../redux/checkout.redux';
import type { BookableProduct } from '../redux/types';
import { getSelectedResourceIds } from '../resourceSelection/redux/resourceSelection.redux';
import { FuturePriceContainer, PriceContainer } from './PriceContainer';
import { getNumberOfBookableResources } from './bookableResourcesService';

interface Props {
  selectedResourcesCount: number;
  numberOfBookableResources: number;
  selectedProduct?: BookableProduct;
}

const ResourceSelectionSummary = (props: Props) => {
  const intl = useIntl();
  const { selectedProduct, numberOfBookableResources, selectedResourcesCount } = props;

  const showMultiPricing = !!(selectedProduct?.effectiveEndDate && selectedProduct?.futurePrice);

  if (!selectedProduct) {
    return <Spinner />;
  }
  const productName = getProductLabel(selectedProduct, useIntl());
  const sku = selectedProduct.sku;

  return (
    <div className='margin-bottom-20' data-testid='resource-selection-summary'>
      <div className='display-flex-sm flex-wrap-sm align-items-center-sm justify-content-around-sm margin-right-10-xs padding-top-5 padding-bottom-5'>
        <div className='display-flex flex-1-0'>
          <ProductIcon productId={sku} className={'productIcon width-50 height-50 margin-right-10 position-relative'} />
          <div>
            {productName && (
              <div className='productName text-size-h3 line-height-h3 text-bold margin-bottom-5'>{productName}</div>
            )}
            <div>
              <FormattedMessage
                id='marketplace.assetDialog.selectedAssets'
                values={{ amount: selectedResourcesCount, total: numberOfBookableResources }}
              />
            </div>
          </div>
        </div>
        {showMultiPricing ? <FuturePriceContainer /> : <PriceContainer />}
      </div>
      {showMultiPricing && (
        <div className='alert alert-success margin-bottom-20 margin-top-20 padding-10'>
          <div className='display-flex'>
            <span className='display-flex justify-content-center align-items-center margin-right-10 width-50 height-50'>
              <span className='text-color-success text-size-h1 rioglyph rioglyph-cost-efficency' />
            </span>
            <div data-testid='price-increase-info'>
              <p>
                <FormattedMessage
                  id='marketplace.priceIncrease.info'
                  values={{
                    effectiveEndDate: intl.formatDate(new Date(selectedProduct.effectiveEndDate!), DATE_FORMAT),
                  }}
                />
              </p>
            </div>
            <div className='flex-1-0'>
              <PriceContainer />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedResourcesCount: getSelectedResourceIds(state).length,
  selectedProduct: getSelectedProductOrProductLevel(state),
  numberOfBookableResources: getNumberOfBookableResources(state),
});

export const ResourceSelectionSummaryContainer = connect(mapStateToProps)(ResourceSelectionSummary);
