import { getMarketplaceEUBackend } from '../../config/pathURL/config';
import { decodeJson, jsonOrReject, onRejected } from '../api';
import { getRequest } from '../requests';
import { ApiPaymentMethodTypesCodec, type PaymentMethodType } from './paymentMethodTypes.types';

export function fetchPaymentMethodTypes(accountId: string): Promise<PaymentMethodType[]> {
  return fetch(`${getMarketplaceEUBackend()}/accounts/${accountId}/payment-method-types`, getRequest())
    .then(jsonOrReject)
    .then(decodeJson(ApiPaymentMethodTypesCodec))
    .then(it => it.payment_method_types)
    .catch(onRejected([403]));
}
