import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../../../configuration/setup/store';
import type { PartnerConnection, PartnersState } from './types';

const initialState: PartnersState = {
  connections: [],
  permissions: true,
};

export const { reducer: partnersReducer, actions: partnersActions } = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    setConnections: (state: PartnersState, action: PayloadAction<PartnerConnection[]>) => {
      state.connections = action.payload;
    },
    setHasPermissions: (state: PartnersState, action: PayloadAction<boolean>) => {
      state.permissions = action.payload;
    },
  },
});

const getBase = (state: RootState): PartnersState => state.marketplace.partners;

export const getConnections = (state: RootState) => getBase(state).connections;
export const getHasPartnerPermissions = (state: RootState) => getBase(state).permissions;
