import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../../../configuration/setup/store';
import type { BillingAddress, BillingState } from './types';

const initialState: BillingState = {
  billingAddress: undefined,
  billingErrorCode: undefined,
};

export const { reducer: billingReducer, actions: billingActions } = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setBillingAddress: (state: BillingState, action: PayloadAction<BillingAddress>) => {
      state.billingAddress = action.payload;
    },
    setBillingErrorCode: (state: BillingState, action: PayloadAction<number>) => {
      state.billingErrorCode = action.payload;
    },
  },
});

const getBase = (state: RootState) => state.marketplace.billing;

export const getBillingAddress = (state: RootState) => getBase(state).billingAddress;
export const getIsAccountNotFound = (state: RootState) => getBase(state).billingErrorCode === 404;
