declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

export function dataLayerPush(val: unknown) {
  if (window.dataLayer && val) {
    window.dataLayer.push(val);
  }
}
