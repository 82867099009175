import type React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  type: 'sepa_debit' | 'card';
}

export const LegalBanner: React.FC<Props> = ({ type }) => {
  return (
    <div className={'margin-top-15'}>
      {type === 'sepa_debit' ? (
        <>
          <div className='alert alert-info'>
            <h4 className={'margin-top-0'}>
              <FormattedMessage id='marketplace.payment.sepa.mandate.header' />
            </h4>
            <span>
              <FormattedMessage id='marketplace.payment.sepa.mandate' />
            </span>
          </div>
          <div className={'sepaInfoContainer alert alert-info margin-y-15'}>
            <FormattedMessage
              id={'marketplace.payment.sepa.info'}
              values={{
                // @ts-ignore
                bold: (chunks: string[]) => <strong>{chunks}</strong>,
              }}
            />
          </div>
        </>
      ) : (
        <div className={'creditCardInfoBox alert alert-info margin-y-15'}>
          <FormattedMessage
            id={'marketplace.payment.creditCard.info'}
            values={{
              // @ts-ignore
              bold: (chunks: string[]) => <strong>{chunks}</strong>,
            }}
          />
        </div>
      )}
    </div>
  );
};
