import EmptyState from '@rio-cloud/rio-uikit/lib/es/EmptyState';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import type { RootState } from '../../../configuration/setup/store';
import { getHasError, getIsLoading } from '../api/redux/api.redux';
import { ApiCalls } from '../api/redux/types';
import { safeDataLayerPush } from '../common/utils/googleTagManagerWrapper';
import {
  CheckoutSteps,
  type GACheckoutStepData,
  getGACheckoutStepData,
  getGAEventCheckoutProgress,
} from './checkoutGTMUtils';
import { BusCustomerHintContainer } from './components/BusCustomerHintContainer';
import { ExternalAdminHintContainer } from './components/ExternalAdminHintContainer';
import { NextButton } from './components/NavigationButtons';
import { VariantSelectionContainer } from './components/VariantSelectionContainer';
import { getIsCheckoutLocked, getSelectedProductLevel } from './redux/checkout.redux';
import { ProductTypeAwareFormattedMessage } from './resourceSelection/ProductTypeAwareFormattedMessage';
import { ResourceSelectionContainer } from './resourceSelection/ResourceSelectionContainer';
import { getAvailableResources, getSelectedResourceIds } from './resourceSelection/redux/resourceSelection.redux';

interface Props {
  nextButtonDisabled: boolean;
  nextPath: string;
  gAData: GACheckoutStepData;
  isResourceSelectionLocked: boolean;
  hasAvailableResources: boolean;
}

const CheckoutResourceSelection = ({
  nextButtonDisabled,
  nextPath,
  gAData,
  isResourceSelectionLocked,
  hasAvailableResources,
}: Props) => {
  useEffect(() => {
    if (gAData.product) {
      safeDataLayerPush(() => getGAEventCheckoutProgress(gAData));
    }
  }, [gAData.product]);

  return (
    <div className='container-fluid fluid-small'>
      <div className='text-center'>
        <h3>
          <ProductTypeAwareFormattedMessage id={isResourceSelectionLocked ? 'headlineLocked' : 'headline'} />
        </h3>
      </div>
      {hasAvailableResources ? (
        <div className='assetSelection-panel panel panel-default'>
          <div className='panel-body'>
            <VariantSelectionContainer />
            <div className='display-flex justify-content-center'>
              <ResourceSelectionContainer />
            </div>
            <div className={'padding-top-15'}>
              <ExternalAdminHintContainer />
              <BusCustomerHintContainer />
              <NextButton disabled={nextButtonDisabled} nextPath={nextPath} />
            </div>
          </div>
        </div>
      ) : (
        <EmptyState
          headline={<ProductTypeAwareFormattedMessage id='noResources' />}
          message={<ExternalAdminHintContainer />}
          outerClassName='margin-top-15pct'
        />
      )}
    </div>
  );
};
const mapStateToProps = (state: RootState) => ({
  nextButtonDisabled: getSelectedResourceIds(state).length === 0 || getIsLoading(state, ApiCalls.RESOURCE_SELECTION),
  gAData: getGACheckoutStepData(state, CheckoutSteps.TO_BILLING_ADDRESS),
  isResourceSelectionLocked: getIsCheckoutLocked(state),
  selectedProductLevel: getSelectedProductLevel(state),
  hasAvailableResources:
    getIsLoading(state, ApiCalls.RESOURCE_SELECTION) ||
    getHasError(state, ApiCalls.RESOURCE_SELECTION) ||
    getAvailableResources(state).length > 0,
});

export const CheckoutResourceSelectionContainer = connect(mapStateToProps)(CheckoutResourceSelection);
