import ApplicationLayoutBodyBottomBar from '@rio-cloud/rio-uikit/lib/es/ApplicationLayoutBodyBottomBar';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import type { RootState } from '../../../../configuration/setup/store';
import { type UikitOnSelectFn, isPresent } from '../../../../utils/typescriptUtil';
import { ProductState } from '../../checkout/resourceSelection/redux/types';
import { ProductIcon } from '../../common/productImages/ProductIcon';
import { getProductLabel } from '../../common/utils/productLabelUtil';
import { isRIO4RentalProductId } from '../../config/rio4rentalProduct';
import { openCheckoutPopup } from '../serviceOverview/services/checkoutService';
import { GtmPromotion } from '../serviceOverview/services/gtmPromotion';
import { getProductColumnKey } from './assetOverviewAssetMapper';
import { getAssetOverviewFilteredRows } from './assetOverviewSearchService';
import {
  assetOverviewActions,
  getAssetOverviewColumns,
  getSelectedAssetIdsInAssetOverview,
} from './redux/assetOverview.redux';
import { type AssetOverviewRow, type CancelInfo, type HeaderProduct, ProductBookable } from './redux/types';

interface Props {
  selectedAssetIds: string[];
  services: HeaderProduct[];
  openCancelDialog: (cancelInfo: CancelInfo) => void;
  displayedAssets: AssetOverviewRow[];
}

function Badge({ content }: { content: string | number }) {
  return (
    <div className={'display-inline-flex align-items-center pull-right'}>
      <span className='rioglyph rioglyph-truck-baseline text-size-16' />
      <span className='margin-left-3 label label-muted label-filled label-condensed'>{content}</span>
    </div>
  );
}

export const AssetOverviewBottomBar = (props: Props) => {
  const { services, selectedAssetIds, openCancelDialog, displayedAssets } = props;

  const bookActionItems = services
    .filter(service => service.bookable === ProductBookable.BOOKABLE && !isRIO4RentalProductId(service.productId))
    .map(service => {
      const assetIds = displayedAssets
        .filter(it => selectedAssetIds.includes(it.assetId))
        .filter(it => it.products[getProductColumnKey(service)]?.bookable)
        .map(it => it.assetId);
      if (assetIds.length === 0) {
        return null;
      }
      return {
        value: (
          <div>
            <ProductIcon productId={service.productId} className='width-20 height-20 margin-right-5' />
            {getProductLabel(service, useIntl())}
            <Badge content={assetIds.length} />
          </div>
        ),
        onSelect: openCheckoutPopup(
          service.productId,
          GtmPromotion.assetOverviewAddMultiple,
          service.levels.length > 0 ? service.levels[0] : undefined,
          selectedAssetIds
        ).callback as unknown as UikitOnSelectFn,
      };
    })
    .filter(isPresent);

  const cancelActionItems = services
    .filter(service => service.canBeCancelled && !isRIO4RentalProductId(service.productId))
    .map(service => {
      const assets = displayedAssets
        .filter(it => selectedAssetIds.includes(it.assetId))
        .filter(it => it.products[getProductColumnKey(service)]?.canBeCancelled)
        .map(it => ({ id: it.assetId, name: it.assetName, productState: ProductState.ACTIVE }));
      if (assets.length === 0) {
        return null;
      }
      const productLabel = getProductLabel(service, useIntl());
      return {
        value: (
          <div>
            <ProductIcon productId={service.productId} className='width-20 height-20 margin-right-5' />
            {productLabel}
            <Badge content={assets.length} />
          </div>
        ),
        onSelect: () =>
          openCancelDialog({
            productId: service.productId,
            assets: assets,
            productName: productLabel,
            level: service.levels.length > 0 ? service.levels[0] : undefined,
          }),
        disabled: assets.length === 0,
      };
    })
    .filter(isPresent);

  return (
    <ApplicationLayoutBodyBottomBar buttonAlignment={'right'}>
      <ButtonDropdown
        dropup={true}
        disabled={selectedAssetIds.length === 0}
        title={
          <div>
            <span className={'rioglyph rioglyph-trash margin-right-5'} />
            <FormattedMessage id={'marketplace.assetOverview.cancelForSelectedAssets'} />
          </div>
        }
        items={cancelActionItems}
      />
      <div className={'padding-right-20'} />
      <ButtonDropdown
        disabled={selectedAssetIds.length === 0}
        dropup={true}
        title={
          <div>
            <span className={'rioglyph rioglyph-shopping-cart margin-right-5'} />
            <FormattedMessage id={'marketplace.assetOverview.bookForSelectedAssets'} />
          </div>
        }
        items={bookActionItems}
        bsStyle={'primary'}
      />
    </ApplicationLayoutBodyBottomBar>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedAssetIds: getSelectedAssetIdsInAssetOverview(state),
  services: getAssetOverviewColumns(state),
  displayedAssets: getAssetOverviewFilteredRows(state).displayRows,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openCancelDialog: (cancelInfo: CancelInfo) => dispatch(assetOverviewActions.openCancelDialog(cancelInfo)),
});

export const AssetOverviewBottomBarContainer = connect(mapStateToProps, mapDispatchToProps)(AssetOverviewBottomBar);
