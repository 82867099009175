import { ProductTypedFormattedMessage } from '../../../common/product/ProductTypedFormattedMessage';
import type { ProductType } from '../../../common/product/product';

export function EmptyResourcesMessage(props: { productType: ProductType | undefined }) {
  return (
    <div className='padding-15'>
      <div className={'ServiceOverviewItemBodyAsset-emptyMessage form-group row'}>
        <div className='EmptyMessage notFoundMessage text-center text-muted'>
          <i>
            <ProductTypedFormattedMessage
              productType={props.productType}
              assetBasedId={'marketplace.myServices.assets.empty'}
              userBasedId={'marketplace.myServices.users.empty'}
            />
          </i>
        </div>
      </div>
    </div>
  );
}
