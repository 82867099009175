import type { BookableProduct } from '../../checkout/redux/types';
import { getMarketplaceBackend } from '../../config/pathURL/config';
import { onRejected, rejectIfNotOk } from '../api';
import { postRequest } from '../requests';
import type { ApiSubscriptionCancelPayload } from './subscription.types';
import { toSubscriptionCreateBody } from './subscriptionMapper';

export function postSubscriptionCancel(productId: string, resourceIds: string[]): Promise<void> {
  const body: ApiSubscriptionCancelPayload = { resource_ids: resourceIds };
  return fetch(`${getMarketplaceBackend()}/activations/${productId}/deactivate`, postRequest(body))
    .then(rejectIfNotOk)
    .catch(onRejected());
}

export async function postSubscriptionCreate(resourceIds: string[], product: BookableProduct): Promise<void> {
  const body = toSubscriptionCreateBody(resourceIds, product);

  return await fetch(`${getMarketplaceBackend()}/subscriptions`, postRequest(body)).then(rejectIfNotOk);
}
