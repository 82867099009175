import { getMarketplaceBackend } from '../../config/pathURL/config';
import type { OverviewResource } from '../../customerCenter/serviceOverview/redux/types';
import { decodeJson, jsonOrReject, onRejected } from '../api';
import { getParameters } from '../parameterService';
import { getRequest } from '../requests';
import { ApiActivationsCodec } from './asset.types';
import { mapAssetActivationsResponse } from './assetMapper';

export function fetchResourcesForProduct(sku: string): Promise<OverviewResource[]> {
  const params = getParameters({ product_id: sku });
  return fetch(`${getMarketplaceBackend()}/activations${params}`, getRequest())
    .then(jsonOrReject)
    .then(decodeJson(ApiActivationsCodec))
    .then(mapAssetActivationsResponse)
    .catch(onRejected());
}
