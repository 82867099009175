import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { getAccountId, getDisplayMessages } from '../../../../configuration';
import type { AppThunk } from '../../../../configuration/setup/store';
import { publishPaymentMethod } from '../../api/paymentMethods/paymentInformationCalls';
import type { PaymentMethodType } from '../../api/paymentMethods/paymentMethodTypes.types';
import { apiCallAction } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { isOnFreeTrialPage } from '../utils/isOnFreeTrialPage';
import { fetchPaymentMethodsIfNecessary } from './paymentMethods.thunk';
import { getGAPaymentType, publishPaymentMethodUpdateToGA } from './paymentMethodsGTMUtils';
import { paymentMethodsActions } from './redux/paymentMethods.redux';
import { getDefaultPaymentInformation } from './redux/paymentMethodsSelectionService';

export function publishPaymentMethodThunk(paymentMethodType: PaymentMethodType, paymentMethodId?: string): AppThunk {
  return (dispatch, getState) => {
    const paymentInformation = getDefaultPaymentInformation(getState());
    const accountId = getAccountId(getState());
    const languageData = getDisplayMessages(getState());
    dispatch(apiCallAction(ApiCalls.PAYMENT_METHODS_POST, true));
    return publishPaymentMethod(accountId, paymentMethodType, paymentMethodId)
      .then(() => {
        dispatch(paymentMethodsActions.setPaymentMethods(undefined));
        dispatch(paymentMethodsActions.setPaymentMethodDefaultId(undefined));
        dispatch(fetchPaymentMethodsIfNecessary);
        dispatch(apiCallAction(ApiCalls.PAYMENT_METHODS_POST, false, false));
        dispatch(paymentMethodsActions.setPaymentMethodEditMode(false));
        publishPaymentMethodUpdateToGA(paymentInformation, getGAPaymentType(paymentMethodType));
        if (isOnFreeTrialPage()) {
          Notification.success(languageData['marketplace.payment.adding.success']);
        }
      })
      .catch(() => {
        dispatch(apiCallAction(ApiCalls.PAYMENT_METHODS_POST, false, true));
      });
  };
}
