import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import { ProductTypedFormattedMessage } from '../../common/product/ProductTypedFormattedMessage';
import type { ProductType } from '../../common/product/product';
import { ServiceOverviewResourceSearch } from './components/ServiceOverviewResourceSearch';
import type { OverviewResource } from './redux/types';

interface Props {
  hasCheckbox: boolean;
  selectAllResources: (resource: OverviewResource[], shouldBeSelected: boolean) => void;
  resources: OverviewResource[];
  isChecked: undefined | boolean;
  setSearchTerm: (searchTerm: string) => void;
  searchTerm: string;
  showSearchInput: boolean;
  productType: ProductType;
}

export function ServiceResourceListHeader(props: Props) {
  const {
    hasCheckbox,
    isChecked,
    resources,
    selectAllResources,
    searchTerm,
    setSearchTerm,
    showSearchInput,
    productType,
  } = props;
  return (
    <div className='table-toolbar margin-bottom-0'>
      <div className='table-toolbar-container'>
        <div className='table-toolbar-group-left'>
          <div className='table-toolbar-column'>
            {hasCheckbox && (
              <div className='padding-bottom-10 padding-left-20'>
                <Checkbox
                  onClick={() => selectAllResources(resources, !isChecked)}
                  checked={Boolean(isChecked)}
                  indeterminate={isChecked === undefined}
                  inline
                >
                  <ProductTypedFormattedMessage
                    productType={productType}
                    assetBasedId={'marketplace.resourceSelection.asset.selectAll'}
                    userBasedId={'marketplace.resourceSelection.user.selectAll'}
                  />
                </Checkbox>
              </div>
            )}
          </div>
        </div>
        {showSearchInput && (
          <div className='table-toolbar-group-right'>
            <div className='table-toolbar-column margin-right-15 padding-10-xs'>
              <ServiceOverviewResourceSearch
                searchValue={searchTerm}
                onSearchChange={setSearchTerm}
                productType={productType}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
