import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { FormattedMessage } from 'react-intl';

interface Props {
  updateContract: () => void;
  isUpdating: boolean;
}

export const RenewFooterNoRenewal = (props: Props) => {
  const { updateContract, isUpdating } = props;

  return (
    <div>
      <div className={'display-flex justify-content-end margin-bottom-10 margin-top-20'}>
        <span className={'rioglyph rioglyph-warning-sign margin-right-5'} />
        <FormattedMessage id={'marketplace.contracts.noRenewal.info'} />
      </div>
      <div className='display-flex justify-content-end padding-bottom-20'>
        {isUpdating ? (
          <Spinner />
        ) : (
          <button className='btn btn-primary' onClick={updateContract}>
            <FormattedMessage id={'marketplace.contracts.updateRenewal'} />
          </button>
        )}
      </div>
    </div>
  );
};
