import EmptyState from '@rio-cloud/rio-uikit/lib/es/EmptyState';
import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import sortBy from 'lodash/sortBy';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { getLocale } from '../../../../configuration';
import type { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { getHasError, getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { getMarketplaceLink } from '../../common/ServiceBookButtonContainer';
import { DiscountInfoPanel } from '../../common/discount/DiscountInfoPanel';
import { fetchDiscountsThunk } from '../../common/discount/discounts.thunk';
import { getProductDiscounts } from '../../common/discount/redux/discount.redux';
import { isServiceVisible } from '../../common/utils/isServiceVisible';
import { type Tenant, getTenant } from '../../common/utils/tenants';
import { ServiceBodyContainer } from './ServiceBodyContainer';
import { ServiceHeaderContainer } from './ServiceHeaderContainer';
import { ServiceTabHeader } from './components/ServiceTabHeader';
import { getServiceOverviewList } from './redux/serviceOverview.redux';
import { fetchActivationSummaryThunk } from './thunks/activationSummary.thunk';
import type { ServiceOverviewItem } from './types';

interface StateProps {
  serviceList: ServiceOverviewItem[];
  hasError: boolean;
  isLoading: boolean;
  locale: string;
  tenant: Tenant;
}

interface DispatchProps {
  fetchServiceList: () => void;
}

export type ServiceOverviewProps = StateProps & DispatchProps;

const FetchErrorMessage = () => {
  return <ErrorState headline={<FormattedMessage id={'marketplace.myServices.fetch.error'} />} />;
};

const EmptyMessage = (props: { locale: string; tenant: Tenant }) => {
  return (
    <EmptyState
      headline={<FormattedMessage id={'marketplace.myServices.empty.headline'} />}
      message={<FormattedMessage id={'marketplace.myServices.empty'} />}
      buttons={[
        {
          text: (
            <span>
              <span className='rioglyph rioglyph-shopping-cart' aria-hidden='true' />
              <FormattedMessage id='marketplace.exploreServices' />
            </span>
          ),
          href: getMarketplaceLink(props.locale, props.tenant),
        },
      ]}
      outerClassName='margin-top-15pct'
    />
  );
};

const LoadingIndicator = () => {
  return (
    <div>
      <br />
      <Spinner text={<FormattedMessage id={'marketplace.myServices.loading'} />} isInverse={false} />
    </div>
  );
};

const ServiceList = ({ serviceList }: Pick<ServiceOverviewProps, 'serviceList'>) => {
  const allProductDiscounts = useSelector(getProductDiscounts);

  if (!serviceList) {
    return <></>;
  }

  const sortedItems = sortBy(serviceList, 'productName');

  return (
    <div>
      {sortedItems
        .filter(service => isServiceVisible(service))
        .map(service => {
          const productDiscount = allProductDiscounts.find(
            pd => pd.productId.toLowerCase() === service.sku.toLowerCase()
          );

          return (
            <ExpanderPanel
              key={service.sku}
              titleClassName={'width-100pct'}
              title={<ServiceHeaderContainer service={service} discount={productDiscount} />}
              bsStyle='default'
              bodyClassName='padding-0 border-none rounded-bottom bg-lightest'
              className='ServiceOverviewItem shadow-default'
            >
              <ServiceBodyContainer service={service} />
            </ExpanderPanel>
          );
        })}
    </div>
  );
};

export const ServiceOverview = (props: ServiceOverviewProps) => {
  useEffect(() => {
    props.fetchServiceList();
    // The effect should be like didMount and willUnmount => no deps
  }, []);

  // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
  let content;

  if (props.hasError) {
    content = <FetchErrorMessage />;
  } else if (props.isLoading) {
    content = <LoadingIndicator />;
  } else if (!props.serviceList.length) {
    return (
      <div className='ServiceOverview container-fluid fluid-default'>
        <DiscountInfoPanel />
        <EmptyMessage locale={props.locale} tenant={props.tenant} />
      </div>
    );
  } else {
    content = <ServiceList {...props} />;
  }

  return (
    <div className='ServiceOverview container-fluid fluid-default'>
      <ServiceTabHeader />
      <DiscountInfoPanel />
      {content}
    </div>
  );
};

export function mapStateToProps(state: RootState): StateProps {
  return {
    serviceList: getServiceOverviewList(state),
    hasError: getHasError(state, ApiCalls.ACTIVATION_SUMMARY),
    isLoading: getIsLoading(state, ApiCalls.ACTIVATION_SUMMARY),
    locale: getLocale(state),
    tenant: getTenant(state),
  };
}

export function mapDispatchToProps(dispatch: RootDispatch): DispatchProps {
  return {
    fetchServiceList: () => {
      dispatch(fetchActivationSummaryThunk(true));
      dispatch(fetchDiscountsThunk());
    },
  };
}

export const ServiceOverviewContainer = connect(mapStateToProps, mapDispatchToProps)(ServiceOverview);
