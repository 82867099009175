import Multiselect from '@rio-cloud/rio-uikit/lib/es/Multiselect';
import { type ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import type { ResourceType } from './redux/types';
import { getFilteredResourceTypes, usageActions } from './redux/usage.redux';

const options: Array<{ id: ResourceType; label: ReactElement; icon: ReactElement }> = [
  {
    id: 'asset',
    label: <FormattedMessage id='marketplace.usage.summary.asset' />,
    icon: <span className='rioglyph rioglyph-truck' />,
  },
  {
    id: 'user',
    label: <FormattedMessage id='marketplace.usage.summary.user' />,
    icon: <span className='rioglyph rioglyph-user' />,
  },
];

export const UsageResourceTypeSelectorContainer = () => {
  const selectedResourceTypes = useSelector(getFilteredResourceTypes);
  const dispatch = useDispatch();

  const onChange = useMemo(
    () => (selectedItems: string[]) => {
      dispatch(usageActions.setFilteredResourceTypes(selectedItems as ResourceType[]));
    },
    [dispatch]
  );
  return (
    <Multiselect
      options={options}
      showSelectedItemIcon
      showUnselectedItemIcons
      onChange={onChange}
      value={[...selectedResourceTypes]}
    />
  );
};
