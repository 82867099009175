import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../../../configuration/setup/store';
import { isManNowProduct } from '../../../config/manNowProducts';
import { isFreeTrialLevel } from '../freeTrialLevel';
import type { TermedLevel } from '../types';
import type { MissingRequirementsByAssets, TermedLevelId, TermedLevelRenewalState, TermedProductInfo } from './types';

const initialState: TermedLevelRenewalState = {
  termedLevels: [],
  isRenewDialogOpen: false,
  contractsForUpdate: undefined,
  productIdForUpdate: undefined,
  productForUpdate: undefined,
  renewsToLevelForUpdate: undefined,
  missingRequirementsByAssetForUpdate: undefined,
};

export const { reducer: termedLevelRenewalReducer, actions: termedLevelRenewalActions } = createSlice({
  name: 'termedLevelRenewal',
  initialState,
  reducers: {
    setTermedLevels: (state: TermedLevelRenewalState, action: PayloadAction<TermedLevel[]>) => {
      state.termedLevels = action.payload;
    },
    setIsRenewDialogOpen: (state: TermedLevelRenewalState, action: PayloadAction<boolean>) => {
      state.isRenewDialogOpen = action.payload;
    },
    setRenewsToLevelForUpdate: (state: TermedLevelRenewalState, action: PayloadAction<string | undefined>) => {
      state.renewsToLevelForUpdate = action.payload;
    },
    setProductForUpdate: (state: TermedLevelRenewalState, action: PayloadAction<TermedProductInfo | undefined>) => {
      state.productForUpdate = action.payload;
    },
    selectForUpdate: (
      state: TermedLevelRenewalState,
      action: PayloadAction<{ productId: string; renewsToLevel?: string; contracts?: TermedLevelId[] }>
    ) => {
      state.isRenewDialogOpen = true;
      state.contractsForUpdate = action.payload.contracts;
      state.renewsToLevelForUpdate =
        action.payload.renewsToLevel ??
        (isManNowProduct(action.payload.productId) ? '10' : action.payload.renewsToLevel);
      state.productIdForUpdate = action.payload.productId;
    },
    clearUpdateState: (state: TermedLevelRenewalState) => {
      state.contractsForUpdate = undefined;
      state.renewsToLevelForUpdate = undefined;
    },
    setMissingRequirementsForUpdate: (
      state: TermedLevelRenewalState,
      action: PayloadAction<MissingRequirementsByAssets | undefined>
    ) => {
      state.missingRequirementsByAssetForUpdate = action.payload;
    },
  },
});

const getBase = (state: RootState) => state.marketplace.termedLevelRenewal;
export const getFreeTrialContracts = (state: RootState) =>
  getBase(state)
    .termedLevels.filter(termedLevel => isFreeTrialLevel(termedLevel.level))
    .map(termedLevel => ({ ...termedLevel, paid: true }));

export const getContracts = (state: RootState) =>
  getBase(state).termedLevels.filter(termedLevel => !isFreeTrialLevel(termedLevel.level));

export const getIsRenewDialogOpen = (state: RootState) => getBase(state).isRenewDialogOpen;
export const getTermedLevelIdsForUpdate = (state: RootState) => getBase(state).contractsForUpdate;
export const getProductIdForUpdateOrThrow = (state: RootState): string => {
  const productId = getBase(state).productIdForUpdate;
  if (!productId) {
    throw Error('Expected product id for update');
  }
  return productId;
};
export const getRenewsToLevelForUpdate = (state: RootState) => getBase(state).renewsToLevelForUpdate;
export const getRenewsToLevelForUpdateOrThrow = (state: RootState): string => {
  const level = getBase(state).renewsToLevelForUpdate;
  if (!level) {
    throw Error('Expected level for update');
  }
  return level;
};
export const getMissingRequirementsByAssetForUpdate = (state: RootState) =>
  getBase(state).missingRequirementsByAssetForUpdate;
export const getProductForUpdateOrThrow = (state: RootState): TermedProductInfo => {
  const productForUpdate = getBase(state).productForUpdate;
  if (!productForUpdate) {
    throw Error('Expected product for update');
  }
  return productForUpdate;
};
export const getProductForUpdate = (state: RootState) => getBase(state).productForUpdate;
