import EmptyState from '@rio-cloud/rio-uikit/lib/es/EmptyState';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import groupBy from 'lodash/groupBy';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { getHasError, getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { fetchDiscountsThunk } from '../../common/discount/discounts.thunk';
import LoadingErrorState from '../../common/error/LoadingErrorState';
import { LimitedPermissionsInfoBanner } from '../../common/permissions/LimitedPermissionsInfoBanner';
import { RenewDialogContainer } from '../../common/termedLevel/dialog/RenewDialog';
import { getContracts, termedLevelRenewalActions } from '../../common/termedLevel/redux/termedLevelRenewal.redux';
import { fetchContractsThunk } from '../../common/termedLevel/termedLevel.thunk';
import type { TermedLevel } from '../../common/termedLevel/types';
import { ContractPanel } from './ContractPanel';
import { ContractStatsWidgetsContainer } from './ContractStatsWidgetsContainer';
import { ContractTableToolBarContainer } from './ContractTableToolBarContainer';
import { useContractDeepLinkingHook } from './contractDeepLinkingHook';
import { contractActions } from './redux/contract.redux';
import { getFilteredContractsByAssets } from './redux/contractSearchService';

interface Props {
  contracts: TermedLevel[];
  fetchContractsAndDiscounts: () => void;
  resetContractsState: () => void;
  hasError: boolean;
  isLoading: boolean;
  tableMessage?: string;
  isEmpty: boolean;
}

const ContractsPage = (props: Props) => {
  const { contracts, fetchContractsAndDiscounts, hasError, isLoading, tableMessage, resetContractsState, isEmpty } =
    props;

  useContractDeepLinkingHook();

  useEffect(() => {
    fetchContractsAndDiscounts();
    return () => resetContractsState();
  }, []);

  if (isLoading) {
    return <Spinner text={<FormattedMessage id={'marketplace.loading'} />} isInverse={false} />;
  }
  if (hasError) {
    return <LoadingErrorState />;
  }
  if (isEmpty) {
    return (
      <EmptyState
        headline={<FormattedMessage id={'marketplace.contracts.noContractsPresent'} />}
        outerClassName='margin-top-15pct'
      />
    );
  }

  const groupedContracts = groupBy(contracts, 'productId');
  return (
    <>
      <ContractStatsWidgetsContainer />
      <LimitedPermissionsInfoBanner />
      <ContractTableToolBarContainer />
      {Object.keys(groupedContracts).map(productId => (
        <ContractPanel key={productId} contracts={groupedContracts[productId]} open={true} />
      ))}
      <div className='text-center'>{tableMessage && <FormattedMessage id={tableMessage} />}</div>
      <RenewDialogContainer titleId={'intl-msg:common-message.contracts.renewal'} />
    </>
  );
};

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  fetchContractsAndDiscounts: () => {
    dispatch(fetchContractsThunk());
    dispatch(fetchDiscountsThunk());
  },
  resetContractsState: () => {
    dispatch(termedLevelRenewalActions.clearUpdateState());
    dispatch(contractActions.setSelectedContracts([]));
  },
});

const mapStateToProps = (state: RootState) => {
  const { displayRows, message } = getFilteredContractsByAssets(state);
  return {
    isEmpty: getContracts(state).length === 0,
    contracts: displayRows,
    isLoading: getIsLoading(state, ApiCalls.TERMED_LEVEL_GET),
    hasError: getHasError(state, ApiCalls.TERMED_LEVEL_GET),
    tableMessage: message,
  };
};

export const ContractPageContainer = connect(mapStateToProps, mapDispatchToProps)(ContractsPage);
