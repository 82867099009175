import { FormattedMessage, useIntl } from 'react-intl';
import { ProductTypedFormattedMessage } from '../product/ProductTypedFormattedMessage';
import type { ProductType } from '../product/product';
import { formatPrice } from '../utils/currencyUtil';

interface Props {
  price: number;
  currency: string | undefined;
  productType: ProductType | undefined;
}

export function PriceInfoDaily(props: Props) {
  const { price, currency, productType } = props;
  const intl = useIntl();
  if (price === 0) {
    return <FormattedMessage id={'marketplace.free'} />;
  }

  return (
    <>
      <span>{formatPrice(intl, price, currency)}</span>{' '}
      <ProductTypedFormattedMessage
        productType={productType}
        userBasedId='marketplace.productDetails.price.user.rate'
        assetBasedId='marketplace.productDetails.price.asset.rate'
      />
    </>
  );
}
