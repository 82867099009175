import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { connect } from 'react-redux';
import type { RootState } from '../../../../configuration/setup/store';
import { getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { isResourceBookable } from '../../common/utils/resourceUtil';
import { ResourceSelectionSummaryContainer } from '../preview/ResourceSelectionSummary';
import { getProductLevelsForVariant, getSelectedProduct } from '../redux/checkout.redux';
import { isMultiLevelProduct } from '../redux/types';
import { BookableResourcesSelectionContainer } from './BookableResourcesSelectionContainer';
import { GeoLHint } from './GeoLHint';
import { ProductTypeAwareFormattedMessage } from './ProductTypeAwareFormattedMessage';
import {
  ResourceSelectionBookedExpander,
  ResourceSelectionNotBookableExpander,
} from './ResourceSelectionExpanderContainer';
import { getAvailableResources } from './redux/resourceSelection.redux';

interface Props {
  hasBookableResources: boolean;
  hasAvailableResources: boolean;
  showExtraPadding: boolean;
  isLoading: boolean;
}

function ResourceSelectionDialogList(props: Props) {
  const { hasBookableResources, hasAvailableResources, showExtraPadding, isLoading } = props;

  if (!hasAvailableResources && !isLoading) {
    return (
      <div className='width-100pct'>
        <p>
          <ProductTypeAwareFormattedMessage id={'noResources'} />
        </p>
      </div>
    );
  }
  return (
    <div className='width-100pct'>
      <div className={'panel panel-default panel-body padding-bottom-0'}>
        <ResourceSelectionSummaryContainer />
        {isLoading ? (
          <div className='margin-20'>
            <Spinner />
          </div>
        ) : (
          <div className='margin-bottom-20'>
            {hasBookableResources ? (
              <ExpanderPanel
                bodyClassName={'padding-0'}
                title={
                  <b>
                    <ProductTypeAwareFormattedMessage id='availableVehicles' />
                  </b>
                }
                bsStyle='default'
                open={true}
              >
                <BookableResourcesSelectionContainer />
              </ExpanderPanel>
            ) : (
              <div className='panel-body text-center margin-15'>
                <ProductTypeAwareFormattedMessage id={'noBookableResources'} />
              </div>
            )}
          </div>
        )}
        {!isLoading && (
          <>
            <ResourceSelectionBookedExpander />
            <ResourceSelectionNotBookableExpander>
              <GeoLHint />
            </ResourceSelectionNotBookableExpander>
          </>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLoading: getIsLoading(state, ApiCalls.RESOURCE_SELECTION),
  hasBookableResources: getAvailableResources(state).filter(isResourceBookable).length > 0,
  productIsMultiLevel: isMultiLevelProduct(getSelectedProduct(state)),
  hasAvailableResources: getAvailableResources(state).length > 0,
  showExtraPadding: getProductLevelsForVariant(state) !== undefined,
});

export const ResourceSelectionListContainer = connect(mapStateToProps)(ResourceSelectionDialogList);
