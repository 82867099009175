import type { RootState } from '../../../../../configuration/setup/store';
import { ProductState } from '../../../checkout/resourceSelection/redux/types';
import { getContracts } from '../../../common/termedLevel/redux/termedLevelRenewal.redux';
import type { TermedLevel } from '../../../common/termedLevel/types';
import {
  getAssetSearch,
  getFromDate,
  getHideContractsForDeletedAssets,
  getSelectedContracts,
  getToDate,
} from './contract.redux';

export const getFilteredContractsByAssets = (state: RootState) => {
  const allRows = getContracts(state);
  const term = getAssetSearch(state);

  const filteredRowsBySearchTerm = filterRowsBySearchTerm(allRows, term);

  const fromDate = getFromDate(state);
  const toDate = getToDate(state);
  const filteredRowsByTermAndDates = filterRowsByDates(filteredRowsBySearchTerm, fromDate, toDate);

  const filterRowsByHidingDeletedAssets = filterRowsByHidingDeletedAssetsIfEnabled(state, filteredRowsByTermAndDates);

  // always show selected rows
  const selectedRows = getSelectedRows(state);
  const displayRows = mergeSelectedRowsToFilteredRows(selectedRows, filterRowsByHidingDeletedAssets);

  // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
  let message;
  if (displayRows.length === 0) {
    message = 'marketplace.contracts.search.noContractFound';
  }
  return { displayRows, message };
};

const filterRowsBySearchTerm = (rows: TermedLevel[], term: string) => {
  return rows.filter(contract => {
    if (contract.resourceName !== undefined) {
      return contract.resourceName.toLowerCase().includes(term.toLowerCase());
    }
    return contract.resourceId.toLowerCase().includes(term.toLowerCase());
  });
};

export const filterRowsByDates = (rows: TermedLevel[], from: string | undefined, to: string | undefined) => {
  return rows.filter(it => {
    if (from === undefined && to === undefined) {
      return true;
    }
    const endDate = it.endsAt ? new Date(it.endsAt) : undefined;
    if (endDate !== undefined) {
      endDate.setHours(0, 0, 0, 0);
    }
    const isoEndDate = endDate?.toISOString();
    return (
      isoEndDate !== undefined && // Do not return pending contracts
      (from === undefined || from <= isoEndDate) &&
      (to === undefined || isoEndDate <= to)
    );
  });
};

const getSelectedRows = (state: RootState) => {
  const allRows = getContracts(state);
  const selectedRows = getSelectedContracts(state);

  return allRows.filter(contract => {
    return (
      selectedRows.find(row => contract.productId === row.productId && contract.resourceId === row.resourceId) !==
      undefined
    );
  });
};

const mergeSelectedRowsToFilteredRows = (selectedRows: TermedLevel[], filteredRows: TermedLevel[]) => {
  const combinedRows = [...filteredRows, ...selectedRows];
  const uniqueRows = new Set(combinedRows);
  return Array.from(uniqueRows);
};

export const filterRowsByHidingDeletedAssetsIfEnabled = (state: RootState, rows: TermedLevel[]) => {
  const hideContractsForDeletedAssets = getHideContractsForDeletedAssets(state);
  return rows.filter(contract => {
    return hideContractsForDeletedAssets ? contract.activationState !== ProductState.INACTIVE : true;
  });
};
