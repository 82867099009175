import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { RootState } from '../../../../../configuration/setup/store';
import { formatPrice } from '../../../common/utils/currencyUtil';
import { getUsageOverallCurrency } from '../redux/usage.redux';

interface UsageSummaryTablePriceCellProps {
  price: number | undefined;
  currency: string | undefined;
}

export const UsageSummaryTablePriceCellRaw = (props: UsageSummaryTablePriceCellProps) => {
  const { price, currency } = props;
  const intl = useIntl();

  const priceString = price !== undefined && currency !== undefined ? formatPrice(intl, price, currency) : 'n/a';
  return (
    <td className={`${currency ? 'text-right' : 'text-center'} text-bold white-space-nowrap`}>
      <span>{priceString}</span>
    </td>
  );
};

const mapStateToProps = (state: RootState) => ({
  currency: getUsageOverallCurrency(state),
});

export const UsageSummaryTablePriceCell = connect(mapStateToProps)(UsageSummaryTablePriceCellRaw);
