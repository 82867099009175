import { useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { ProductState } from '../../../checkout/resourceSelection/redux/types';
import {
  type DiscountIdentifier,
  generateDiscountHint,
  mapStateToFindDiscountProps,
} from '../../../common/discount/DiscountService';
import type { AssetDiscount } from '../../../common/discount/redux/types';
import { useIsFleetUser } from '../../../common/permissions/hooks';
import { OverlayTrigger, Tooltip } from '../../../common/utils/tooltipReExports';
import { DEFAULT_TOOLTIP_DELAY } from '../../../config/config';
import { assetOverviewActions } from '../redux/assetOverview.redux';
import type { CancelInfo, ProductInfo } from '../redux/types';
import { IconButtonDanger, IconButtonDefault, IconButtonSuccess } from './productCellConfig';

interface Props {
  openCancelDialog: (cancelInfo: CancelInfo) => void;
  product: ProductInfo;
  assetId: string;
  assetName: string;
  findDiscount: (discountIdentifier: DiscountIdentifier) => AssetDiscount | undefined;
}

const ProductCellBooked = ({ openCancelDialog, product, assetId, assetName, findDiscount }: Props) => {
  const [showBin, setShowBin] = useState(false);
  const intl = useIntl();
  const { productId, level, productName, canBeCancelled } = product;
  const cancelInfo: CancelInfo = {
    productId: productId,
    productName: productName,
    level,
    assets: [{ id: assetId, name: assetName, productState: ProductState.ACTIVE }],
  };

  const discount = findDiscount({ assetId, productId, productLevel: level });
  const fleetUser = useIsFleetUser();
  const canUserCancel = canBeCancelled && !fleetUser;
  const tooltipTranslationKey = canBeCancelled
    ? fleetUser
      ? 'marketplace.unauthorized.tooltip'
      : 'marketplace.cancelServiceForAsset'
    : 'marketplace.canNotCancelService';

  const onClick = () => {
    if (canUserCancel) {
      openCancelDialog(cancelInfo);
    }
  };

  const overlay = (
    <Tooltip id='tooltip' className={'width-auto max-width-300'}>
      <div>{intl.formatMessage({ id: tooltipTranslationKey }, { service: productName, asset: assetName })}</div>
      {discount && (
        <div>
          <b>{generateDiscountHint(discount, intl)}</b>
        </div>
      )}
    </Tooltip>
  );

  const normalClasses = discount ? IconButtonSuccess : IconButtonDefault;
  const buttonClasses = showBin ? IconButtonDanger : normalClasses;
  return (
    <span>
      <OverlayTrigger
        trigger={OverlayTrigger.TRIGGER_HOVER}
        placement='top'
        overlay={overlay}
        delay={DEFAULT_TOOLTIP_DELAY}
      >
        <span>
          <button
            className={`${buttonClasses} ${canUserCancel ? '' : 'disabled'}`}
            onClick={onClick}
            onMouseEnter={() => setShowBin(canUserCancel)}
            onMouseLeave={() => setShowBin(false)}
          >
            <span className={`rioglyph ${showBin ? 'rioglyph-trash' : 'rioglyph-ok'} margin-0`} />
          </button>
        </span>
      </OverlayTrigger>
    </span>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openCancelDialog: (cancelInfo: CancelInfo) => dispatch(assetOverviewActions.openCancelDialog(cancelInfo)),
});

export const ProductCellBookedContainer = connect(mapStateToFindDiscountProps, mapDispatchToProps)(ProductCellBooked);
