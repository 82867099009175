import { connect } from 'react-redux';
import type { RootState } from '../../../../configuration/setup/store';
import { getSelectedProduct, getSelectedProductLevel } from '../redux/checkout.redux';
import { type Product, isMultiLevelProduct } from '../redux/types';
import { BookableResourceListLevelSectionContainer } from './BookableResourceListLevelSectionContainer';
import { ProductTypeAwareFormattedMessage } from './ProductTypeAwareFormattedMessage';
import { getSelectedResourceIds } from './redux/resourceSelection.redux';
import type { BookableResource } from './redux/types';
import { getFilteredGroupedResources } from './resourceSelectionSearchService';

interface Props {
  bookableFilteredResources: BookableResource[];
  selectedProduct: Product | undefined;
  selectedProductLevel: string | undefined;
  viewOnly: boolean;
}

const MultiLevelResourceSelectionBookableResourceList = (props: Props) => {
  const { bookableFilteredResources, selectedProduct, selectedProductLevel, viewOnly } = props;
  if (!isMultiLevelProduct(selectedProduct) || !selectedProductLevel) {
    return <div>Please provide a level</div>;
  }

  const productLevels = selectedProduct.levels.map(it => it.level);
  const otherProductLevels = productLevels.filter(it => it !== selectedProductLevel);

  if (bookableFilteredResources.length === 0) {
    return (
      <div className='text-danger margin-15'>
        <ProductTypeAwareFormattedMessage id='noSearchedResourcesFound' />
      </div>
    );
  }

  return (
    <div>
      <BookableResourceListLevelSectionContainer
        activeLevel={undefined}
        resources={bookableFilteredResources}
        viewOnly={viewOnly}
      />
      {otherProductLevels.map(it => (
        <BookableResourceListLevelSectionContainer
          activeLevel={it}
          key={it}
          resources={bookableFilteredResources}
          viewOnly={viewOnly}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  bookableFilteredResources: getFilteredGroupedResources(state).bookable,
  selectedProduct: getSelectedProduct(state),
  selectedProductLevel: getSelectedProductLevel(state),
  viewOnly: false,
});

export const MultiLevelResourceSelectionBookableResourceListContainer = connect(mapStateToProps)(
  MultiLevelResourceSelectionBookableResourceList
);

const mapStateToOnlySelectedProps = (state: RootState) => ({
  bookableFilteredResources: getFilteredGroupedResources(state).bookable.filter(it =>
    getSelectedResourceIds(state).includes(it.id)
  ),
  selectedProduct: getSelectedProduct(state),
  selectedProductLevel: getSelectedProductLevel(state),
  viewOnly: true,
});

export const MultiLevelSelectedResourceListContainer = connect(mapStateToOnlySelectedProps)(
  MultiLevelResourceSelectionBookableResourceList
);
