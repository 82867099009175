import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { UserProfile } from 'oidc-client-ts';
import type { RootState } from '../setup/store';
import { accessToken } from './accessToken';

export type AccessToken = string | undefined | null;

export interface RioProfile extends Partial<UserProfile> {
  account?: string;
}

export interface AccessTokenState {
  accessToken: AccessToken;
  idToken: RioProfile | null;
}

const initialState: AccessTokenState = {
  accessToken: accessToken.getAccessToken(),
  idToken: null,
};

const tokenSlice = createSlice({
  name: 'tokenHandling',
  initialState,
  reducers: {
    accessTokenStored: (state, action: PayloadAction<AccessToken>) => {
      state.accessToken = action.payload;
    },
    idTokenStored: (state, action: PayloadAction<RioProfile>) => {
      state.idToken = action.payload;
    },
  },
});

export const { accessTokenStored, idTokenStored } = tokenSlice.actions;

export const getAccessToken = (state: RootState) => state.tokenHandling.accessToken ?? 'NO_ACCESS_TOKEN_AVAILABLE';
export const getIdToken = (state: RootState) => state.tokenHandling.idToken;
export const getOptionalAccountId = (state: RootState) => state.tokenHandling.idToken?.account;

export const getAccountId = (state: RootState) => {
  const accountIdOrUndefined = getOptionalAccountId(state);
  if (!accountIdOrUndefined) {
    throw new Error('expected accountId to be present but it was not');
  }
  return accountIdOrUndefined;
};

export default tokenSlice.reducer;
