import TableToolbar from '@rio-cloud/rio-uikit/lib/es/TableToolbar';
import { FreeTrialTableSearchContainer } from './FreeTrialTableSearchContainer';

export function FreeTrialTableToolBar() {
  return (
    <TableToolbar className={''}>
      <div className='table-toolbar-container'>
        <div className='table-toolbar-group-right'>
          <div className='table-toolbar-column'>
            <FreeTrialTableSearchContainer />
          </div>
        </div>
      </div>
    </TableToolbar>
  );
}
