import type { Period } from '../../../common/termedLevel/types';
import type { OverviewResource } from '../../serviceOverview/redux/types';

export type TableViewTogglesValues = 'SINGLE_CARD' | 'MULTI_CARDS' | 'TABLE';

export interface HeaderProduct {
  productId: string;
  productName: string;
  levels: string[];
  bookable: ProductBookable;
  canBeCancelled: boolean;
  contractPeriod?: Period;
  variantName?: string;
  levelsWithContractPeriod: string[];
  bookableLevels: string[];
}

export enum ProductBookable {
  BOOKABLE = 0,
  LEGACY = 1,
  DEFAULT_ACTIVATED = 2,
}

export interface ProductInfo {
  productId: string;
  productName: string;
  level?: string;
  booked: boolean;
  bookable: boolean;
  isPending: boolean;
  canBeCancelled: boolean;
  missingRequirements?: string[][];
  hasContractPeriod: boolean;
  contractActiveForAnotherLevel: boolean;
  bookableLevels: string[];
}

export interface AssetOverviewRow {
  assetId: string;
  assetName: string;
  products: Record<string, ProductInfo>;
}

export interface Tag {
  id: string;
  name: string;
}

export interface TagToAssetsMap {
  [id: string]: string[];
}

export interface CancelInfo {
  productId: string;
  productName: string;
  level?: string;
  assets: OverviewResource[];
}

export interface AssetOverviewState {
  tableRows: AssetOverviewRow[];
  tableColumns: HeaderProduct[];
  assetsToDisplay: string[] | undefined;
  selectedAssets: string[];
  tags: Tag[];
  tagToAssetMap: TagToAssetsMap;
  viewType: TableViewTogglesValues;
  search: string;
  showCancelDialog: boolean;
  cancelInfo?: CancelInfo;
}
