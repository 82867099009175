import { getMarketplaceBackend } from '../../config/pathURL/config';
import { decodeJson, jsonOrReject, onRejected } from '../api';
import { getRequest } from '../requests';
import { ApiPermissionsSpec } from './permissions.types';

export function fetchPermissions(): Promise<string[]> {
  return fetch(`${getMarketplaceBackend()}/client-permissions`, getRequest())
    .then(jsonOrReject)
    .then(decodeJson(ApiPermissionsSpec))
    .catch(onRejected());
}
