import 'iframe-resizer/js/iframeResizer.contentWindow'; // Required for resizing when included as iframe
import { gsap } from 'gsap';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../configuration/setup/store';
import { FreeTrialButton } from './FreeTrialButton';
import { freeTrialStatusThunk } from './freeTrialStatus.thunk';
import manPointingRight from './images/man_pointing_right.webp';
import { getIsEligibleForFreeTrial } from './redux/freeTrial.redux';

function animateLetters() {
  document.querySelectorAll('.letters').forEach(letter => {
    if (letter.textContent) {
      letter.innerHTML = letter.textContent.replace(/\S/g, `<span class='letter'>$&</span>`);
    }
  });

  const letterAnimation = gsap.timeline({ repeat: -1, delay: 1, repeatDelay: 2 });
  document.querySelectorAll('.letter').forEach(letter => {
    letterAnimation.from(
      letter,
      {
        duration: 1,
        opacity: 0,
        ease: 'power4.out',
      },
      '-=0.9'
    );
  });
}

export function HomescreenFreeTrialSection() {
  const showSection = useSelector(getIsEligibleForFreeTrial);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(freeTrialStatusThunk);
  }, []);

  useEffect(() => {
    animateLetters();
  });

  if (!showSection) {
    return <div style={{ height: 0 }} />;
  }

  return (
    <div className='bg-primary'>
      <div className='display-flex-ls flex-1-1-ls padding-top-2pct position-relative overflow-hidden'>
        <div className='container-fluid display-flex-ls flex-1-1-ls fluid-default'>
          <div className='row display-flex-ls flex-wrap-ls flex-1-1-ls justify-content-center-ls'>
            <div className='col-xs-12 margin-bottom-0 display-flex-ls'>
              <div className='display-flex-ls flex-1-1-ls flex-column-ls'>
                <div className='width-100pct text-color-white padding-x-5pct'>
                  <div className='width-100pct max-width-800 rounded-circle bg-white aspect-ratio-1 position-absolute top-0 left-50pct translate-x-50pct-y-50pct opacity-30 blur-50' />
                  <div className='display-flex gap-50 justify-content-center align-items-end'>
                    <img
                      className='img-responsive max-width-250 max-width-300-md position-relative display-none-xs display-none-ls display-block-sm'
                      src={manPointingRight}
                      alt='Man pointing right'
                    />
                    <div className='position-relative padding-top-2pct'>
                      <div className='text-size-h1 text-bold margin-bottom-5 display-flex gap-20'>
                        <div>
                          <span className='rioglyph rioglyph-ok-sign' />
                        </div>
                        <div className='letters'>
                          <FormattedMessage id={'marketplace.freeTrial.homeScreenAnimation1'} />
                        </div>
                      </div>
                      <div className='text-size-h1 text-bold margin-bottom-5 display-flex gap-20'>
                        <div>
                          <span className='rioglyph rioglyph-ok-sign' />
                        </div>
                        <div className='letters'>
                          <FormattedMessage id={'marketplace.freeTrial.homeScreenAnimation2'} />
                        </div>
                      </div>
                      <div className='text-size-h1 text-bold margin-bottom-5 display-flex gap-20'>
                        <div>
                          <span className='rioglyph rioglyph-ok-sign' />
                        </div>
                        <div className='letters'>
                          <FormattedMessage id={'marketplace.freeTrial.homeScreenAnimation3'} />
                        </div>
                      </div>
                      <div className='max-width-50vw-xs max-width-250 scale-150 transform-origin-top-left margin-top-50 padding-bottom: 17px'>
                        <div className='buyButton'>
                          <FreeTrialButton />
                        </div>
                      </div>
                      <div className='max-width-700 text-size-12 margin-y-50'>
                        <FormattedMessage id={'marketplace.freeTrial.homeScreen'} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>&nbsp;</div>
          {/* force rendering margin */}
        </div>
      </div>
    </div>
  );
}
