import React from 'react';
import { useIntl } from 'react-intl';
import { ProductIcon } from '../../common/productImages/ProductIcon';
import { getProductLabel } from '../../common/utils/productLabelUtil';
import type { HeaderProduct } from './redux/types';

export function AssetOverviewTableHeaderItemRaw(props: HeaderProduct) {
  return (
    <div className='display-flex flex-column align-items-center'>
      <ProductIcon
        productId={props.productId}
        className='width-30 height-30 width-40-sm height-40-sm position-relative margin-bottom-5'
      />
      <div className='white-space-normal text-center'>{getProductLabel(props, useIntl())}</div>
    </div>
  );
}

export const AssetOverviewTableHeaderItem = React.memo(AssetOverviewTableHeaderItemRaw);
