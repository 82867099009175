import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import { useIntl } from 'react-intl';
import { ProductType } from '../../../common/product/product';

interface Props {
  searchValue: string;
  onSearchChange: (searchValue: string) => void;
  productType: ProductType;
}

export const ServiceOverviewResourceSearch = (props: Props) => {
  const { searchValue, onSearchChange, productType } = props;
  const intl = useIntl();
  const translationKey =
    productType === ProductType.ASSET_BASED
      ? 'marketplace.resourceSelection.asset.searchPlaceholder'
      : 'marketplace.resourceSelection.user.searchPlaceholder';
  const placeholder = intl.formatMessage({ id: translationKey });

  return <TableSearch placeholder={placeholder} value={searchValue} onChange={onSearchChange} />;
};
