import { subDays } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { DEFAULT_TOOLTIP_DELAY } from '../../../config/config';
import type { AssetDiscount } from '../../discount/redux/types';
import { PriceInfoDaily } from '../../price/PriceInfoDaily';
import { PriceInfoLongTerm } from '../../price/PriceInfoLongTerm';
import type { ProductType } from '../../product/product';
import { DATE_FORMAT } from '../../utils/dateUtil';
import { formatDurationUnit } from '../../utils/formatDurationUnit';
import { OverlayTrigger, Tooltip } from '../../utils/tooltipReExports';
import type { TermedProductLevel } from '../redux/types';
import type { TermedLevel } from '../types';

interface Props {
  productLevel: TermedProductLevel;
  isActiveTab: boolean;
  productType: ProductType;
  contracts: TermedLevel[];
  findDiscount?: (assetId: string) => AssetDiscount | undefined;
}

export function RenewDialogDataTabTitle({ productLevel, isActiveTab, productType, contracts, findDiscount }: Props) {
  const { price, currency, contractPeriod, variantName, productLevelName, futurePrice } = productLevel;
  const isDiscountAvailable = findDiscount ? contracts.some(contract => findDiscount(contract.resourceId)) : undefined;

  const intl = useIntl();

  const {
    mainPriceDisplay,
    infoBannerPriceDisplay,
    mainFullTermExplanation,
    currentFullTermExplanation,
    currentEffectiveEndDate,
  } = (() => {
    if (futurePrice) {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      const futurePriceEffectiveStartDate = new Date(futurePrice.effectiveStartDate!);
      const currentPriceEffectiveEndDate = subDays(futurePriceEffectiveStartDate, 1);
      return {
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        mainPriceDisplay: futurePrice?.price!,
        infoBannerPriceDisplay: price,
        mainFullTermExplanation: (
          <FormattedMessage
            data-testid='future-start-date'
            id='marketplace.productDetails.price.future.fullTerm.explanation'
            values={{ startDate: intl.formatDate(futurePriceEffectiveStartDate, DATE_FORMAT) }}
          />
        ),
        currentFullTermExplanation: <FormattedMessage id='marketplace.productDetails.price.fullTerm.explanation' />,
        currentEffectiveEndDate: currentPriceEffectiveEndDate,
      };
    }
    return {
      mainPriceDisplay: price,
      mainFullTermExplanation: <FormattedMessage id='marketplace.productDetails.price.fullTerm.explanation' />,
    };
  })();

  return (
    <div className='text-color-darkest'>
      <div className='margin-bottom-10 line-height-125rel display-flex align-items-center'>
        <span className='rioglyph rioglyph rioglyph-repeat text-color-success margin-right-3' />
        <span className={`${isActiveTab ? 'text-bold' : ''}`}>{variantName ?? productLevelName}</span>

        {isDiscountAvailable && (
          <span className={'margin-left-auto'}>
            <OverlayTrigger
              trigger={OverlayTrigger.TRIGGER_HOVER}
              placement='top'
              overlay={
                <Tooltip id='tooltip' className={'width-auto max-width-300 display-flex'}>
                  <FormattedMessage id='marketplace.contracts.discountsAvailable' />
                </Tooltip>
              }
              delay={DEFAULT_TOOLTIP_DELAY}
            >
              <span className='label label-filled label-success label-condensed text-size-12 padding-bottom-2 margin-left-3'>
                %
              </span>
            </OverlayTrigger>
          </span>
        )}
      </div>
      <ul className='padding-left-15 square-list'>
        <li>
          {contractPeriod ? (
            <>
              <FormattedMessage id='intl-msg:common-message.contracts.period' /> {contractPeriod.length}{' '}
              {formatDurationUnit(contractPeriod.unit, intl)}
            </>
          ) : (
            <FormattedMessage id='marketplace.productDetails.evergreen' />
          )}
        </li>
        <li>
          <PriceInfoDaily price={mainPriceDisplay} currency={currency} productType={productType} />{' '}
          {contractPeriod && (
            <OverlayTrigger
              trigger={OverlayTrigger.TRIGGER_HOVER}
              placement='bottom'
              overlay={
                <Tooltip id='tooltip' className={'width-auto max-width-300'}>
                  <PriceInfoLongTerm
                    price={mainPriceDisplay}
                    currency={currency}
                    contractPeriod={contractPeriod}
                    productType={productType}
                  />
                  <br />
                  {/* biome-ignore lint/style/noNonNullAssertion: <explanation> */}
                  {mainFullTermExplanation!}
                </Tooltip>
              }
              delay={DEFAULT_TOOLTIP_DELAY}
            >
              <span className='PriceDetails-estimated-info-icon rioglyph rioglyph-info-sign' />
            </OverlayTrigger>
          )}
        </li>
      </ul>
      {futurePrice && (
        <div className='alert alert-success padding-5 margin-top-5'>
          <div className='display-flex gap-5 align-items-center' data-testid='current-price-info'>
            {/* biome-ignore lint/style/noNonNullAssertion: <explanation> */}
            <PriceInfoDaily price={infoBannerPriceDisplay!} currency={currency} productType={productType} />{' '}
            <OverlayTrigger
              trigger={OverlayTrigger.TRIGGER_HOVER}
              placement='bottom'
              overlay={
                <Tooltip id='tooltip' className={'width-auto max-width-300'}>
                  {contractPeriod ? (
                    <>
                      <PriceInfoLongTerm
                        // biome-ignore lint/style/noNonNullAssertion: <explanation>
                        price={infoBannerPriceDisplay!}
                        currency={currency}
                        contractPeriod={contractPeriod}
                        productType={productType}
                      />
                      <br />
                      {/* biome-ignore lint/style/noNonNullAssertion: <explanation> */}
                      {currentFullTermExplanation!}
                    </>
                  ) : (
                    <FormattedMessage
                      id='marketplace.priceIncrease.info'
                      values={{
                        effectiveEndDate: intl.formatDate(currentEffectiveEndDate, DATE_FORMAT),
                      }}
                    />
                  )}
                </Tooltip>
              }
              delay={DEFAULT_TOOLTIP_DELAY}
            >
              <span className='PriceDetails-estimated-info-icon rioglyph rioglyph-info-sign' />
            </OverlayTrigger>
          </div>
        </div>
      )}
    </div>
  );
}
