import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import { connect } from 'react-redux';
import type { RootState } from '../../../../configuration/setup/store';
import { ResourceSelectionSummaryContainer } from '../preview/ResourceSelectionSummary';
import { getSelectedProduct } from '../redux/checkout.redux';
import { isMultiLevelProduct } from '../redux/types';
import { MultiLevelSelectedResourceListContainer } from '../resourceSelection/MultiLevelResourceSelectionBookableResourceList';
import { ResourceSelectionItemContainer } from '../resourceSelection/ResourceSelectionItem';
import { RESOURCE_SELECTION_TAGS_PER_ROW } from '../resourceSelection/constants';
import { getAvailableResources, getSelectedResourceIds } from '../resourceSelection/redux/resourceSelection.redux';
import type { BookableResource } from '../resourceSelection/redux/types';

interface Props {
  productIsMultiLevel: boolean;
  selectedResources: BookableResource[];
}

export function SelectedResourcesOverview(props: Props) {
  const { productIsMultiLevel, selectedResources } = props;
  return (
    <div>
      <ExpanderPanel
        title={<ResourceSelectionSummaryContainer />}
        bsStyle={'default'}
        titleClassName={'width-100pct'}
        bodyClassName={'bg-lightest'}
      >
        {productIsMultiLevel ? (
          <MultiLevelSelectedResourceListContainer />
        ) : (
          <TagList tagsPerRow={RESOURCE_SELECTION_TAGS_PER_ROW}>
            {selectedResources.map(resource => {
              return (
                <ResourceSelectionItemContainer
                  resource={resource}
                  key={resource.id}
                  isBooked={false}
                  isSelected={true}
                  noBookableTooltip={true}
                />
              );
            })}
          </TagList>
        )}
      </ExpanderPanel>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  const selectedResourceIds = getSelectedResourceIds(state);
  return {
    productIsMultiLevel: isMultiLevelProduct(getSelectedProduct(state)),
    selectedResources: getAvailableResources(state).filter(it => selectedResourceIds.includes(it.id)),
  };
};

export const SelectedResourcesOverviewContainer = connect(mapStateToProps)(SelectedResourcesOverview);
