import type { DisplayMessages } from '../../../../configuration';
import type { FetchedHistoryEvents, HistoryEvent } from '../../customerCenter/history/redux/types';
import { translatePeriodInProductLevelName } from '../periods';
import type { ApiHistoryEvent, ApiHistoryEventList } from './historyEvent.types';
import type { ApiHistoryFetchParams, HistoryFetchParams } from './types';

function mapHistoryEvent(it: ApiHistoryEvent, displayMessages: DisplayMessages): HistoryEvent {
  return {
    action: it.action,
    actor: it.actor,
    resources: it.resources,
    productName: translatePeriodInProductLevelName(it.service_name ?? '', displayMessages),
    timestamp: it.created_at,
    orderInfo: it.order_info,
  };
}

export function mapHistoryResponse(
  response: ApiHistoryEventList,
  displayMessages: DisplayMessages
): FetchedHistoryEvents {
  return {
    results: response.items.map(it => mapHistoryEvent(it, displayMessages)),
    nextPageCursor: response.next,
  };
}

export const createHistoryParams = (params: HistoryFetchParams): ApiHistoryFetchParams => {
  const ids = params.selectedProductIds;
  const productIds = ids !== undefined && ids.length > 0 ? ids.join(',') : undefined;
  return {
    limit: params.limit,
    cursor: params.cursor,
    product_ids: productIds,
    search_resource_name: params.resourceSearch,
    date_from: params.fromDate,
    date_to: params.toDate,
  };
};
