import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { RootState } from '../../../../configuration/setup/store';
import { getDiscounts } from '../../common/discount/redux/discount.redux';
import { TotalPrice } from '../../common/price/TotalPrice';
import { getTotalPrices } from '../../common/price/priceService';
import type { Period } from '../../common/termedLevel/types';
import { formatPrice } from '../../common/utils/currencyUtil';
import { DATE_FORMAT } from '../../common/utils/dateUtil';
import { formatDurationUnit } from '../../common/utils/formatDurationUnit';
import { getCurrencyOfSelectedProduct, isPurchaseFree } from '../CheckoutService';
import { getSelectedProductOrProductLevel } from '../redux/checkout.redux';
import type { BookableProduct } from '../redux/types';
import { getSelectedResourceIds } from '../resourceSelection/redux/resourceSelection.redux';
import { PriceDetailsContainer } from './PriceDetails';

interface Props {
  totalPrice: number;
  currency: string | undefined;
  isFree: boolean;
  futurePrices?: Array<{ totalPrice: number; startDate: Date }>;
  contractPeriod: Period | undefined;
  selectedProduct: BookableProduct;
}

const Price = ({ isFree, totalPrice, currency, futurePrices, contractPeriod, selectedProduct }: Props) => {
  const intl = useIntl();
  if (isFree) {
    return (
      <div className='text-size-14 line-height-20 text-medium label label-success label-filled'>
        <FormattedMessage id={'marketplace.free'} />
      </div>
    );
  }
  return (
    <div className='margin-left-20-sm margin-right-10-sm margin-top-20-xs margin-top-20-ls margin-top-0-sm'>
      <PriceDetailsContainer selectedProduct={selectedProduct} />
      <hr className='margin-top-3 margin-bottom-3' />
      <div className='text-right'>
        <TotalPrice totalPrice={totalPrice} currency={currency} />
      </div>
      <div className='text-right text-size-14'>
        {futurePrices?.map((futurePrice, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          <div key={index}>
            <FormattedMessage
              id={'marketplace.from'}
              values={{
                date: intl.formatDate(futurePrice.startDate, DATE_FORMAT),
              }}
            />
            {': '}
            {formatPrice(intl, futurePrice.totalPrice, currency)}{' '}
            <FormattedMessage id={'marketplace.productDetails.price.rate.total'} />
          </div>
        ))}
      </div>
      {contractPeriod !== undefined && (
        <div className='text-right text-italic'>
          <FormattedMessage id='intl-msg:common-message.contracts.period' /> {contractPeriod.length}{' '}
          {formatDurationUnit(contractPeriod.unit, intl)}
        </div>
      )}
    </div>
  );
};

const mapStateToPropsCommon = (state: RootState, selectedProduct: BookableProduct) => {
  const discounts = getDiscounts(state);
  const contractPeriod = selectedProduct?.contractPeriod;
  const selectedResourceIds = getSelectedResourceIds(state);

  const { totalPrice, futurePrices } = getTotalPrices(discounts, selectedProduct, selectedResourceIds);

  return {
    totalPrice,
    currency: getCurrencyOfSelectedProduct(state),
    isFree: isPurchaseFree(state),
    futurePrices,
    contractPeriod,
    selectedProduct,
  };
};

const mapStateToPropsForCurrentPrice = (state: RootState) => {
  const selectedProduct = getSelectedProductOrProductLevel(state);
  return mapStateToPropsCommon(state, selectedProduct);
};

const mapStateToPropsForFuturePrice = (state: RootState) => {
  const selectedProduct = getSelectedProductOrProductLevel(state);
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  const futurePriceSelectedProduct = { ...selectedProduct, price: selectedProduct?.futurePrice?.price! };

  return mapStateToPropsCommon(state, futurePriceSelectedProduct);
};
export const PriceContainer = connect(mapStateToPropsForCurrentPrice)(Price);
export const FuturePriceContainer = connect(mapStateToPropsForFuturePrice)(Price);
