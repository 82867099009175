import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import type { MessageKey } from '../../../../configuration';
import { type RootDispatch, type RootState, useAppDispatch } from '../../../../configuration/setup/store';
import LoadingErrorStateWithReload from './LoadingErrorStateWithReload';

interface Props {
  errorHeadline?: MessageKey;
  hasErrorSelector: (state: RootState) => boolean;
  isLoadingSelector: (state: RootState) => boolean;
  reloadAction: (dispatch: RootDispatch) => Promise<void>;
}

export function withLoadingAndErrorState<OuterProps extends JSX.IntrinsicAttributes>(props: Props) {
  return (Component: React.FC<OuterProps>): React.FC<OuterProps> =>
    function WithLoadingAndErrorState(wrappedComponentProps) {
      const { isLoadingSelector, hasErrorSelector, reloadAction, errorHeadline = 'marketplace.error.loading' } = props;

      const hasError = useSelector(hasErrorSelector);
      const isLoading = useSelector(isLoadingSelector);
      const dispatch = useAppDispatch();

      if (hasError) {
        return <LoadingErrorStateWithReload onReload={() => dispatch(reloadAction)} headline={errorHeadline} />;
      }

      if (isLoading) {
        return <Spinner text={<FormattedMessage id={'marketplace.loading'} />} isInverse={false} />;
      }

      return <Component {...wrappedComponentProps} />;
    };
}
