import type { Dispatch } from 'redux';
import { getDisplayMessages } from '../../../../configuration';
import type { AppThunk, RootState } from '../../../../configuration/setup/store';
import { fetchHistoryEvents } from '../../api/history/historyCalls';
import { apiCallAction } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { DEFAULT_HISTORY_LIMIT } from '../../config/config';
import {
  getHistoryFromDate,
  getHistoryResourceSearch,
  getHistorySelectedProductIds,
  getHistoryToDate,
  historyActions,
} from './redux/history.redux';

export function historyThunk(limit: number = DEFAULT_HISTORY_LIMIT, cursor?: string): AppThunk<Promise<void>> {
  return (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(apiCallAction(ApiCalls.HISTORY_EVENTS, true, false));
    const displayMessages = getDisplayMessages(getState());
    const fromDate = getHistoryFromDate(getState());
    const toDate = getHistoryToDate(getState());
    const selectedProductIds = getHistorySelectedProductIds(getState());
    const resourceSearch = getHistoryResourceSearch(getState());

    return fetchHistoryEvents({ limit, cursor, toDate, fromDate, selectedProductIds, resourceSearch }, displayMessages)
      .then(result => {
        dispatch(historyActions.successfulFetchedHistory(result));
        dispatch(apiCallAction(ApiCalls.HISTORY_EVENTS, false));
      })
      .catch(() => {
        dispatch(apiCallAction(ApiCalls.HISTORY_EVENTS, false, true));
      });
  };
}
