import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import { FormattedMessage } from 'react-intl';
import type { MessageKey } from '../../../../configuration';

interface Props {
  headline: MessageKey;
  onReload: () => void;
}

export default function LoadingErrorStateWithReload(props: Props) {
  return (
    <ErrorState
      headline={<FormattedMessage id={props.headline} />}
      buttons={[
        {
          text: (
            <span>
              <FormattedMessage id={'marketplace.error.button.reload'} />
            </span>
          ),
          onClick: props.onReload,
        },
      ]}
    />
  );
}
