import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getTrackingAttributes } from '../../common/utils/googleTagManagerAttributes';
import { checkoutActions, getSelectedProductId, getSelectedProductLevel } from '../redux/checkout.redux';

export function GeoLHint() {
  const productId = useSelector(getSelectedProductId);
  const productLevel = useSelector(getSelectedProductLevel);
  const trackingAttributesVisible = getTrackingAttributes('checkoutSwitchToGeoL', 'visible');
  const trackingAttributesClick = getTrackingAttributes('checkoutSwitchToGeoL', 'click');

  const dispatch = useDispatch();
  const setProductLevel = (level: string) => dispatch(checkoutActions.setSelectedProductLevel(level));

  if (productId?.toLowerCase() === 'rio-sku00000047' && productLevel !== '34') {
    return (
      <>
        {productId?.toLowerCase() === 'rio-sku00000047' && productLevel !== '34' ? (
          <div className='text-center align-middle' {...trackingAttributesVisible}>
            <span className='rioglyph rioglyph-info-sign' />{' '}
            <FormattedMessage id='marketplace.resourceSelection.asset.notBookableGeoHint' />{' '}
            <button
              className='btn btn-link btn-link-inline'
              onClick={() => setProductLevel('34')}
              {...trackingAttributesClick}
            >
              <FormattedMessage id='marketplace.resourceSelection.asset.notBookableGeoHint.buttonText' />
            </button>
          </div>
        ) : null}
      </>
    );
  }
  return null;
}
