import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../../../configuration/setup/store';
import type { DiscountState, Discounts } from './types';

const initialState: DiscountState = {
  assetDiscounts: [],
  accountDiscounts: [],
  productDiscounts: [],
};

export const { reducer: discountReducer, actions: discountActions } = createSlice({
  name: 'discount',
  initialState,
  reducers: {
    setDiscounts: (state: DiscountState, action: PayloadAction<Discounts>) => {
      state.assetDiscounts = action.payload.assetDiscounts;
      state.accountDiscounts = action.payload.accountDiscounts;
      state.productDiscounts = action.payload.productDiscounts;
    },
  },
});

const getBase = (state: RootState): DiscountState => state.marketplace.discount;
export const getAssetDiscounts = (state: RootState) => getBase(state).assetDiscounts;
export const getAccountDiscounts = (state: RootState) => getBase(state).accountDiscounts;
export const getProductDiscounts = (state: RootState) => getBase(state).productDiscounts;
export const getDiscounts = (state: RootState) => getBase(state);
export const getUnappliedAssetDiscounts = (state: RootState) =>
  getBase(state).assetDiscounts.filter(discount => discount.endsAt === undefined);
