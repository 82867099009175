import type { TermedLevel } from '../../common/termedLevel/types';
import { getMarketplaceBackend } from '../../config/pathURL/config';
import { decodeJson, jsonOrReject, onRejected, rejectIfNotOk } from '../api';
import { getRequest, postRequest } from '../requests';
import { ApiContractsCodec } from './contract.types';
import { mapApiContracts } from './contractMapper';

export function fetchTermedActivations(): Promise<TermedLevel[]> {
  return fetch(`${getMarketplaceBackend()}/contracts`, getRequest())
    .then(jsonOrReject)
    .then(decodeJson(ApiContractsCodec))
    .then(mapApiContracts())
    .catch(onRejected([401]));
}

export interface UpdateTermedLevelData {
  productId: string;
  resourceIds: string[];
  renewsToLevel?: string;
}

export function updateTermedActivations({
  resourceIds,
  productId,
  renewsToLevel,
}: UpdateTermedLevelData): Promise<void> {
  const requestBody = {
    resource_ids: resourceIds,
    renews_to_level: renewsToLevel,
  };
  return fetch(`${getMarketplaceBackend()}/contracts/${productId}/config`, postRequest(requestBody)).then(
    rejectIfNotOk
  );
}
