import { useIntl } from 'react-intl';
import { ProductTypedFormattedMessage } from '../../../common/product/ProductTypedFormattedMessage';
import { OverlayTrigger, Tooltip } from '../../../common/utils/tooltipReExports';
import { mapResourceTypeToProductType } from '../../contracts/mapResourceTypeToProductType';
import type { ResourceUsage } from '../redux/types';

type UsageSummaryTableResourceCellProps = { item: ResourceUsage };

export const UsageSummaryTableResourceCell = (props: UsageSummaryTableResourceCellProps) => {
  const intl = useIntl();
  const { item } = props;
  const { resourceName, resourceIdentification, resourceId, resourceType } = item;
  const productType = mapResourceTypeToProductType(resourceType);

  const resourceLabel = resourceName || resourceIdentification || (
    <ProductTypedFormattedMessage
      productType={productType}
      userBasedId='marketplace.usages.table.unknown.user'
      assetBasedId='marketplace.usages.table.unknown.asset'
    />
  );
  intl.formatMessage({ id: 'marketplace.history.details.tooltip.unknown' });
  const resourceTooltip = resourceIdentification || resourceId;
  const icon = resourceType === 'asset' ? 'truck' : 'user';

  return (
    <td className={'white-space-nowrap'}>
      <OverlayTrigger
        trigger={OverlayTrigger.TRIGGER_HOVER}
        placement={'right'}
        overlay={
          <Tooltip id={'resourceId'} className={'width-auto max-width-300'}>
            {resourceTooltip}
          </Tooltip>
        }
      >
        <div>
          <span className={`rioglyph margin-right-5 rioglyph-${icon}`} aria-hidden={'true'} data-testid={'row-icon'} />
          <span>{resourceLabel}</span>
        </div>
      </OverlayTrigger>
    </td>
  );
};
