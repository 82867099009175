import { isRight } from 'fp-ts/lib/Either';
import { AccountResponseCodec } from './accountCodec';
import type { Account } from './accountTypes';

export class AccountMapperError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AccountMapperError';
  }
}

export function mapAccountResponse(response: unknown): Account {
  const decoded = AccountResponseCodec.decode(response);
  if (!isRight(decoded)) {
    throw new AccountMapperError(`Invalid account response: ${JSON.stringify(response)}`);
  }

  const account = decoded.right;
  return {
    id: account.id,
    name: account.name,
    legalAddress: {
      line1: account.legal_address.line_1 || undefined,
      line2: account.legal_address.line_2 || undefined,
      line3: account.legal_address.line_3 || undefined,
      city: account.legal_address.city || undefined,
      postalCode: account.legal_address.postal_code || undefined,
      countryCode: account.legal_address.country_code,
    },
    taxId: account.tax_id
      ? {
          value: account.tax_id.value,
          taxType: account.tax_id.tax_type,
        }
      : undefined,
    externalIdentifiers: account.external_identifiers || [],
    tenant: account.tenant as Account['tenant'],
    createdAt: account.created_at || undefined,
    finalizationDate: account.finalization_date || undefined,
    logisticsTenant: (account.logistics_tenant as Account['logisticsTenant']) || undefined,
    registrationChannel: account.registration_channel || undefined,
    lifeCycleState: (account.life_cycle_state as Account['lifeCycleState']) || undefined,
    accountType: account.account_type || undefined,
  };
}
