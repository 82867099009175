import join from 'lodash/fp/join';
import { InMemoryWebStorage, User, UserManager, type UserProfile, WebStorageStateStore } from 'oidc-client-ts';
import { config } from '../../config';
import type { AccessToken } from '../tokenHandling/tokenSlice';
import type { UserProfile as CustomUserProfile } from './loginSlice';
import { mapUserProfile } from './userProfile';

export interface OAuthConfig {
  // biome-ignore lint/complexity/noBannedTypes: <explanation>
  onSessionExpired: Function;
  // biome-ignore lint/complexity/noBannedTypes: <explanation>
  onSessionRenewed: Function;
}

export interface SessionRenewedResult {
  accessToken: AccessToken;
  idToken: UserProfile;
  profile: CustomUserProfile;
  locale: string;
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const trace = import.meta.env.DEV ? (...args: any[]) => console.log('[oidcLogin]', ...args) : () => {};
const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

const retrySigninSilent = (oauthConfig: OAuthConfig, userManager: UserManager) => {
  userManager.signinSilent().catch((error: Error) => {
    if (error.message === 'login_required') {
      oauthConfig.onSessionExpired();
    } else {
      setTimeout(() => retrySigninSilent(oauthConfig, userManager), RETRY_SIGNIN_TIMEOUT_IN_MS);
    }
  });
};

export const adaptPublishedInfo = (result: User): SessionRenewedResult => ({
  accessToken: result.access_token,
  idToken: result.profile,
  locale: result.profile?.locale ?? 'en-GB',
  profile: mapUserProfile(result.profile),
});

export const createUserManager = () => {
  const redirectUri = config.login.redirectUri;
  const silentRedirectUri = config.login.silentRedirectUri;

  const settings = {
    authority: `${config.login.authority}`,
    client_id: `${config.login.clientId}`,
    loadUserInfo: false,
    redirect_uri: `${redirectUri}`,
    response_type: `${config.login.requestType}`,
    scope: join(' ', config.login.oauthScope),
    silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
    includeIdTokenInSilentRenew: false,
    automaticSilentRenew: true,
    staleStateAge: 600,
    userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
  };

  trace('oidc.auth.settings', settings);

  return new UserManager(settings);
};

export const configureUserManager = (oauthConfig: OAuthConfig, userManager: UserManager) => {
  userManager.events.addUserLoaded(user => {
    trace('oidc.signinSilent success!');
    oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
  });

  userManager.events.addUserUnloaded(() => {
    oauthConfig.onSessionExpired();
  });

  userManager.events.addSilentRenewError(error => {
    trace('oidc.silentRenewError', error);
    retrySigninSilent(oauthConfig, userManager);
  });

  userManager.events.addUserSignedOut((...args) => {
    trace('oidc.userSignedOut', ...args);
    oauthConfig.onSessionExpired();
  });

  return userManager;
};

export const configureMockUserManager = (oauthConfig: OAuthConfig): UserManager => {
  console.warn('[feature/login/oidc-session] Using mocked authorization due to config setting');

  const signinSilent = () => {
    const userSettings: User = new User({
      access_token: 'valid-mocked-oauth-bogus-token',
      profile: {
        iss: 'Issuer Identifier',
        aud: 'Audience(s): client_id',
        exp: 10,
        iat: 5,
        account: 'mockaccount',
        azp: 'test-client',
        email: 'test@example.com',
        family_name: 'Client',
        given_name: 'Test',
        name: 'Test Client',
        sub: 'prod-rio-users:mock-user',
        locale: config.login.mockLocale,
      },
      id_token: 'id_token',
      session_state: 'session_state',
      refresh_token: 'refresh_token',
      token_type: 'token_type',
      scope: 'scope',
      expires_at: 100000,
    });
    const user = new User(userSettings);
    oauthConfig.onSessionRenewed(adaptPublishedInfo(userSettings));
    return Promise.resolve(user);
  };
  const clearStaleState = () => {
    console.log('stale state cleared');
    return Promise.resolve();
  };

  return { signinSilent, clearStaleState } as UserManager;
};
