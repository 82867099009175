import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import type { RootState } from '../../../../configuration/setup/store';
import { ProductTypeAwareFormattedMessage } from './ProductTypeAwareFormattedMessage';
import { ResourceSelectionItemContainer } from './ResourceSelectionItem';
import { RESOURCE_SELECTION_TAGS_PER_ROW } from './constants';
import { getSelectedResourceIds, resourceSelectionActions } from './redux/resourceSelection.redux';
import type { BookableResource } from './redux/types';
import { getFilteredGroupedResources } from './resourceSelectionSearchService';

interface Props {
  bookableFilteredResources: BookableResource[];
  handleSelection: (resourceId: string) => void;
  selectedResourceIds: string[];
}

const ResourceSelectionBookableResourceList = (props: Props) => {
  const { bookableFilteredResources, handleSelection, selectedResourceIds } = props;

  if (bookableFilteredResources.length === 0) {
    return (
      <div className='text-danger margin-15'>
        <ProductTypeAwareFormattedMessage id='noSearchedResourcesFound' />
      </div>
    );
  }

  return (
    <TagList className={'margin-5'} tagsPerRow={RESOURCE_SELECTION_TAGS_PER_ROW}>
      {bookableFilteredResources.map(resource => {
        return (
          <ResourceSelectionItemContainer
            resource={resource}
            onSelect={selectedItem => handleSelection(selectedItem)}
            key={resource.id}
            isSelected={selectedResourceIds.includes(resource.id)}
          />
        );
      })}
    </TagList>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    bookableFilteredResources: getFilteredGroupedResources(state).bookable,
    selectedResourceIds: getSelectedResourceIds(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    handleSelection: (id: string) => dispatch(resourceSelectionActions.selectResource(id)),
  };
};

export const ResourceSelectionBookableResourceListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceSelectionBookableResourceList);
