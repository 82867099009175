import { connect } from 'react-redux';
import type { RootState } from '../../../../configuration/setup/store';
import { getHasError, getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { ExternalAdminHint } from '../../common/ExternalAdminHint';
import { getIsCheckoutLocked, getSelectedProductType } from '../redux/checkout.redux';

export const mapStateToProps = (state: RootState) => ({
  hasHidden:
    getHasError(state, ApiCalls.RESOURCE_SELECTION) ||
    getIsLoading(state, ApiCalls.CHECKOUT_PRODUCT_DETAILS) ||
    getIsCheckoutLocked(state),
  productType: getSelectedProductType(state),
});

export const ExternalAdminHintContainer = connect(mapStateToProps)(ExternalAdminHint);
