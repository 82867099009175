import type { RootState } from '../../../../configuration/setup/store';
import { getAssetOverviewRows, getCancelDialogInfo } from './redux/assetOverview.redux';

export function evaluateIfCancelWouldCancelService(state: RootState) {
  const cancelDialogInfo = getCancelDialogInfo(state);
  const assetOverviewRows = getAssetOverviewRows(state);

  if (!cancelDialogInfo) {
    return false;
  }
  const selectedProduct = cancelDialogInfo.productId;
  const bookedAssetIds = assetOverviewRows.filter(it => it.products[selectedProduct]?.booked).map(it => it.assetId);
  return (
    bookedAssetIds.sort().join(',') ===
    cancelDialogInfo.assets
      .map(it => it.id)
      .sort()
      .join(',')
  );
}
