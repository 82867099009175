import { UnreachableCaseError } from '../../../../../utils/typescriptUtil';
import type { ResourceUsage } from '../redux/types';
import { UsageSummaryTablePriceCell } from './UsageSummaryTablePriceCell';
import { UsageSummaryTableResourceCell } from './UsageSummaryTableResourceCell';
import { UsageSummaryTableUsageCell } from './UsageSummaryTableUsageCell';
import { type UsageSummaryColumn, UsageSummaryColumnType } from './types';

interface UsageSummaryTableRowProps {
  summaryItem: ResourceUsage;
  columns: UsageSummaryColumn[];
}

const renderTableData = (summaryItem: ResourceUsage, column: UsageSummaryColumn, index: number) => {
  switch (column.type) {
    case UsageSummaryColumnType.PRODUCT_COLUMN:
      return (
        <UsageSummaryTableUsageCell
          usageUnit={summaryItem.services.find(
            it => it.productSku === column.productSku && it.ratePlanId === column.ratePlanId
          )}
          key={index}
        />
      );
    case UsageSummaryColumnType.SPACER_COLUMN:
      return (
        <td key={index} className={'text-center padding-0 text-color-light'}>
          <span>{column.symbol}</span>
        </td>
      );
    case UsageSummaryColumnType.RESOURCE:
      return <UsageSummaryTableResourceCell item={summaryItem} key={index} />;
    case UsageSummaryColumnType.PRICE:
      return <UsageSummaryTablePriceCell price={summaryItem.price} key={index} />;
    default:
      throw new UnreachableCaseError(column.type);
  }
};

export const UsageSummaryTableRow = (props: UsageSummaryTableRowProps) => {
  const { summaryItem, columns } = props;

  return (
    <tr>
      {columns.map((column, index) => {
        return renderTableData(summaryItem, column, index);
      })}
    </tr>
  );
};
