import { add, differenceInDays } from 'date-fns';
import type { Period } from '../termedLevel/types';

export function getFullTermPrice(priceValue: number, contractPeriod: Period) {
  const now = new Date();
  const numberOfDays = differenceInDays(add(now, { [contractPeriod.unit]: contractPeriod.length }), now);
  return priceValue * numberOfDays;
}

const PRICE_MULTIPLIER = 31;
export const getEstimatedPrice = (priceValue: number) => priceValue * PRICE_MULTIPLIER;
