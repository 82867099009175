import type { RootState } from '../../../../../configuration/setup/store';
import { getCancellingSubscriptions } from '../redux/serviceOverview.redux';

function mergeSkuAndResourceId(sku: string, resourceId: string) {
  return `${sku}-${resourceId}`;
}

export function makeIds(productId: string, resourceIds: string[]): string[] {
  if (resourceIds.length === 0) {
    return [productId];
  }
  return resourceIds.map(resourceId => mergeSkuAndResourceId(productId, resourceId));
}

export function isSubscriptionCancelling(state: RootState, sku: string, resourceId?: string): boolean {
  const cancellingSubscriptions = getCancellingSubscriptions(state);
  if (resourceId) {
    return cancellingSubscriptions.includes(mergeSkuAndResourceId(sku, resourceId));
  }
  return cancellingSubscriptions.includes(sku);
}
