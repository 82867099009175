export enum InvoiceType {
  INVOICE = 0,
  CREDIT_MEMO = 1,
}

export interface Invoice {
  id: string;
  amount: number | undefined;
  type: InvoiceType;
  invoiceDate: string;
  invoiceNumber: string;
  partnerName: string | undefined;
  currency: string | undefined;
}
export interface DatedInvoices {
  invoiceDate: string;
  invoices: Invoice[];
}

export interface InvoicesState {
  invoices: DatedInvoices[];
  downloadId: string | undefined;
}
