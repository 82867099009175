import { type Duration, add } from 'date-fns';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';
import type { ProductFuturePrice } from '../../checkout/redux/types';
import type { AccountDiscount, AssetDiscount, DiscountDurationUnit, ProductDiscount } from '../discount/redux/types';

export type Discounts = {
  accountDiscounts: AccountDiscount[];
  productDiscounts: ProductDiscount[];
  assetDiscounts: AssetDiscount[];
};

export type ProductIdentifier = {
  sku: string;
  level?: string;
};

export type ProductWithPrice = {
  sku: string;
  level?: string;
  price: number;
  futurePrice?: ProductFuturePrice;
};

type FilteredDiscount = {
  discountPercentage: number;
  assetId: string;
  durationUnit: DiscountDurationUnit;
  durationPeriod: number;
  endsAt: string;
};

function addDiscount(
  discountsToAdd: ProductDiscount[] | AccountDiscount[],
  productSku: string,
  productLevel: string | undefined,
  selectedResourceIds: string[],
  assetDiscounts: AssetDiscount[]
) {
  const combinedDiscounts: AssetDiscount[] = [...assetDiscounts];

  const discount = discountsToAdd.length > 0 ? discountsToAdd[0] : undefined;
  if (discount) {
    const productLevels = productLevel !== undefined ? [productLevel] : undefined;

    selectedResourceIds.forEach((resourceId: string) => {
      combinedDiscounts.push({
        assetId: resourceId,
        discountPercentage: discount.discountPercentage,
        durationPeriod: discount.durationPeriod,
        durationUnit: discount.durationUnit,
        productId: productSku,
        endsAt: discount.endsAt,
        productLevels: productLevels,
        campaign: discount?.campaign,
      });
    });
  }

  return combinedDiscounts;
}

const calculateRelevantDiscounts = (discounts: Discounts, product: ProductIdentifier, selectedResources: string[]) => {
  const { accountDiscounts, productDiscounts, assetDiscounts } = discounts;
  const { sku, level } = product;
  const relevantAssetDiscounts = assetDiscounts
    .filter(discount => selectedResources.includes(discount.assetId))
    .filter(
      discount =>
        sku.toLowerCase() === discount.productId.toLowerCase() && (!level || discount.productLevels?.includes(level))
    );
  const relevantProductDiscounts = productDiscounts.filter(
    discount => sku.toLowerCase() === discount.productId.toLowerCase() && (!level || discount.levels?.includes(level))
  );
  const productAndAssetDiscounts = addDiscount(
    relevantProductDiscounts,
    sku,
    level,
    selectedResources,
    relevantAssetDiscounts
  );
  return addDiscount(accountDiscounts, sku, level, selectedResources, productAndAssetDiscounts);
};

const calculateFuturePrices = (filteredDiscounts: FilteredDiscount[], assetIds: string[], productPrice: number) => {
  const sortedEffectiveAssetDiscounts = sortBy(filteredDiscounts, 'endsAt');
  const groupedEffectiveAssetDiscounts = groupBy(sortedEffectiveAssetDiscounts, 'endsAt');
  const groupedDiscountsWithSameEndDate = Object.values(groupedEffectiveAssetDiscounts);
  return groupedDiscountsWithSameEndDate.map(discountsWithSameEndDate => {
    const discountsAfter = filteredDiscounts.filter(d => d.endsAt > discountsWithSameEndDate[0].endsAt);
    const totalDiscountedPrice = calculateDiscountedTotalPrice(discountsAfter, assetIds, productPrice);

    return {
      totalPrice: totalDiscountedPrice,
      startDate: new Date(discountsWithSameEndDate[0].endsAt),
    };
  });
};

function calculateDiscountedTotalPrice(
  filteredDiscounts: FilteredDiscount[],
  assetIds: string[],
  productPrice: number
) {
  const groupByAssetFilteredDiscounts = Object.values(groupBy(filteredDiscounts, 'assetId')).map(
    discountsWithSameAsset => {
      const totalDiscountPercentage = sum(discountsWithSameAsset.map(discount => discount.discountPercentage));
      return {
        assetId: discountsWithSameAsset[0].assetId,
        discountPercentage: totalDiscountPercentage > 100 ? 100 : totalDiscountPercentage,
      };
    }
  );
  return sum(
    assetIds.map(assetId => {
      const assetDiscounts = groupByAssetFilteredDiscounts.filter(discount => discount.assetId === assetId);
      return (
        productPrice *
        Number.parseFloat(
          (1 - (assetDiscounts.length > 0 ? assetDiscounts[0].discountPercentage : 0.0) / 100).toFixed(2)
        )
      );
    })
  );
}

function calculateAndSetDiscountEndDate(allDiscounts: AssetDiscount[]) {
  const now = new Date();

  function getDiscountEndDateStringPlusOneDay(isoDateTime: string) {
    return add(Date.parse(isoDateTime), { days: 1 }).toISOString().split('T')[0];
  }

  function getDiscountEndDateStringFromDuration(assetDiscount: AssetDiscount) {
    return add(now, <Duration>{ [`${assetDiscount.durationUnit}s`]: assetDiscount.durationPeriod })
      .toISOString()
      .split('T')[0];
  }

  return allDiscounts.map(assetDiscount => ({
    ...assetDiscount,
    endsAt: assetDiscount.endsAt
      ? getDiscountEndDateStringPlusOneDay(assetDiscount.endsAt)
      : getDiscountEndDateStringFromDuration(assetDiscount),
  }));
}

export const getTotalPrices = (
  discounts: Discounts,
  product: ProductWithPrice,
  selectedResourceIds: string[]
): { totalPrice: number; futurePrices?: Array<{ totalPrice: number; startDate: Date }> } => {
  const { sku, level, price } = product;

  const allDiscounts = calculateRelevantDiscounts(discounts, { sku: sku, level: level }, selectedResourceIds);

  if (allDiscounts.length === 0) {
    return { totalPrice: selectedResourceIds.length * price };
  }
  const effectiveAssetDiscountsWithEndDate = calculateAndSetDiscountEndDate(allDiscounts);
  const totalPrice = calculateDiscountedTotalPrice(effectiveAssetDiscountsWithEndDate, selectedResourceIds, price);

  const futurePrices = calculateFuturePrices(effectiveAssetDiscountsWithEndDate, selectedResourceIds, price);

  return {
    totalPrice,
    futurePrices,
  };
};
