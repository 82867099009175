import type { Dispatch } from 'redux';
import { type DisplayMessages, getDisplayMessages } from '../../../../configuration';
import type { RootState } from '../../../../configuration/setup/store';
import { fetchBookableResources } from '../../api/bookable/bookableCalls';
import { apiCallAction } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { isResourceBookable } from '../../common/utils/resourceUtil';
import { getNumberOfBookableResources } from '../preview/bookableResourcesService';
import {
  checkoutActions,
  getIsCheckoutLocked,
  getSelectedProductId,
  getSelectedProductLevel,
} from '../redux/checkout.redux';
import {
  filterBookableSelectedResourceIds,
  getAllSelectedResourceIds,
  getSelectedResourceIds,
  resourceSelectionActions,
} from './redux/resourceSelection.redux';

export const fetchBookableResourcesThunk = async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
  const productId = getSelectedProductId(getState());
  const productLevel = getSelectedProductLevel(getState());
  if (!productId) {
    return Promise.reject();
  }
  dispatch(apiCallAction(ApiCalls.RESOURCE_SELECTION, true, false));
  try {
    const response = await fetchBookableResources(productId, productLevel, getDisplayMessages(getState()));
    dispatch(resourceSelectionActions.setAvailableResources(response));
    dispatch(apiCallAction(ApiCalls.RESOURCE_SELECTION, false, false));
    await redirectToGeoLIfSelectedResourcesAreOnlyBookableForGeoL(dispatch, getState, productId, productLevel);
  } catch (e) {
    dispatch(apiCallAction(ApiCalls.RESOURCE_SELECTION, false, true));
  }
};

const levelForGeoL = '34';

async function redirectToGeoLIfSelectedResourcesAreOnlyBookableForGeoL(
  dispatch: Dispatch,
  getState: () => RootState,
  productId: string,
  productLevel?: string
) {
  const state = getState();
  const allSelectedResourceIds = getAllSelectedResourceIds(state);
  const preselectionWithoutBookableResources =
    allSelectedResourceIds.length > 0 && getIsCheckoutLocked(state) && getSelectedResourceIds(state).length === 0;
  const unlockedSelectionWithoutBookableResources =
    allSelectedResourceIds.length === 0 && getNumberOfBookableResources(state) === 0;
  const displayMessages = getDisplayMessages(getState());
  if (
    productId.toLowerCase() === 'rio-sku00000047' &&
    productLevel !== levelForGeoL &&
    (preselectionWithoutBookableResources || unlockedSelectionWithoutBookableResources)
  ) {
    if (
      await areResourcesBookableForGeoL(
        productId,
        displayMessages,
        allSelectedResourceIds,
        preselectionWithoutBookableResources,
        unlockedSelectionWithoutBookableResources
      )
    ) {
      dispatch(checkoutActions.setSelectedProductLevel(levelForGeoL));
    }
  }
}

async function areResourcesBookableForGeoL(
  productId: string,
  displayMessages: DisplayMessages,
  allSelectedResourceIds: string[],
  preselectionWithoutBookableResources: boolean,
  unlockedSelectionWithoutBookableResources: boolean
) {
  const resources = await fetchBookableResources(productId, levelForGeoL, displayMessages);
  return (
    (preselectionWithoutBookableResources &&
      filterBookableSelectedResourceIds(resources, allSelectedResourceIds).length > 0) ||
    (unlockedSelectionWithoutBookableResources && resources.filter(isResourceBookable).length > 0)
  );
}
