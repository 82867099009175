import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import { useIntl } from 'react-intl';

export const AccountNotFoundState = () => {
  const intl = useIntl();
  return (
    <ErrorState headline={intl.formatMessage({ id: 'marketplace.error.accountNotFound.headline' })}>
      {intl.formatMessage({ id: 'marketplace.error.accountNotFound' })}{' '}
      <a href='mailto:support@rio.cloud'>support@rio.cloud</a>
    </ErrorState>
  );
};
