import type { BookableProduct } from '../../checkout/redux/types';
import type { ApiSubscriptionCreatePayload } from './subscription.types';

function createPayload(productSku: string, resourceIds: string[], level?: string) {
  return {
    product_sku: productSku,
    resource_ids: resourceIds,
    level: level,
  };
}

export function toSubscriptionCreateBody(
  resourceIds: string[],
  product: BookableProduct
): ApiSubscriptionCreatePayload {
  return createPayload(product.sku, resourceIds, product.level);
}
