import { getUserTenant } from '../../../../configuration';
import type { RootState } from '../../../../configuration/setup/store';

export enum Tenant {
  EU = 'EU',
  BRAZIL = 'BRAZIL',
  SOUTH_KOREA = 'SOUTH_KOREA',
}

export const getTenant = (state: RootState): Tenant => {
  const userTenant = getUserTenant(state)?.split('.')[0];
  switch (userTenant) {
    case 'rio-brazil':
      return Tenant.BRAZIL;
    case 'rio-southkorea':
      return Tenant.SOUTH_KOREA;
    default:
      return Tenant.EU;
  }
};

export const isEuropeanTenant = (state: RootState) => getTenant(state) === Tenant.EU;
export const isKoreanTenant = (state: RootState) => getTenant(state) === Tenant.SOUTH_KOREA;
