import type { IntlShape } from 'react-intl';
import { trace } from '../../../../configuration/setup/trace';

export function formatPrice(
  intl: Pick<IntlShape, 'locale'>,
  price: number,
  currency: string | undefined,
  precision?: number
) {
  try {
    if (currency !== undefined) {
      return new Intl.NumberFormat(intl.locale, {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: precision,
      }).format(price);
    }
    return new Intl.NumberFormat(intl.locale).format(price);
  } catch (e) {
    trace('Error during formatting price using Intl.NumberFormat, falling back to default format', e);
    return `${price.toFixed(precision ?? 2)}${currency ? ` ${currency}` : ''}`;
  }
}
