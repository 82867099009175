import { type IntlShape, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { MessageKey } from '../../../../../configuration';
import { reportErrorToSentry } from '../../../../../configuration/setup/sentry';
import type { RootState } from '../../../../../configuration/setup/store';
import { MissingRequirement } from '../../../common/MissingRequirement';
import { OverlayTrigger, Tooltip } from '../../../common/utils/tooltipReExports';
import { DEFAULT_TOOLTIP_DELAY } from '../../../config/config';
import { getProductBookable } from '../redux/assetOverview.redux';
import { ProductBookable, type ProductInfo } from '../redux/types';
import { IconButtonPrimary } from './productCellConfig';

interface Props {
  product: ProductInfo;
  productBookable?: ProductBookable;
}

function getTooltip(
  isContractActiveForAnotherLevel: boolean,
  intl: IntlShape,
  hasMissingRequirements: undefined | boolean,
  overlayHeading:
    | 'marketplace.resourceSelection.asset.tooltipNotEligible'
    | 'marketplace.assetOverview.productNotBookable',
  isLegacy: boolean,
  product: ProductInfo
) {
  return (
    <Tooltip id='tooltip' className={'AssetItemTooltip width-auto max-width-300'}>
      {isContractActiveForAnotherLevel && (
        <div className={'text-bold'}>
          {intl.formatMessage({ id: 'marketplace.resourceSelection.asset.tooltipOpenContract' })}
        </div>
      )}
      {(!isContractActiveForAnotherLevel || hasMissingRequirements) && (
        <div className={'text-bold'}>{intl.formatMessage({ id: overlayHeading })}</div>
      )}
      {!isLegacy && hasMissingRequirements ? (
        <MissingRequirement intl={intl} missingRequirements={product.missingRequirements ?? []} />
      ) : null}
    </Tooltip>
  );
}

const ProductCellNotBookable = ({ product, productBookable }: Props) => {
  const intl = useIntl();

  if (productBookable === undefined) {
    reportErrorToSentry(`productBookable is undefined but expected for product ${JSON.stringify(product)}`);
    return null;
  }

  const isLegacy = productBookable === ProductBookable.LEGACY;
  const isContractActiveForAnotherLevel = product.contractActiveForAnotherLevel;
  const hasMissingRequirements = product.missingRequirements && product.missingRequirements.length > 0;
  const overlayHeading: MessageKey = isLegacy
    ? 'marketplace.assetOverview.productNotBookable'
    : 'marketplace.resourceSelection.asset.tooltipNotEligible';

  const overlay = getTooltip(
    isContractActiveForAnotherLevel,
    intl,
    hasMissingRequirements,
    overlayHeading,
    isLegacy,
    product
  );

  return (
    <OverlayTrigger
      trigger={OverlayTrigger.TRIGGER_HOVER}
      placement='top'
      overlay={overlay}
      delay={DEFAULT_TOOLTIP_DELAY}
    >
      <span>
        <div className={`${IconButtonPrimary} disabled`}>
          <span className={`rioglyph ${isContractActiveForAnotherLevel ? 'rioglyph-lock' : 'rioglyph-warning-sign'}`} />
        </div>
      </span>
    </OverlayTrigger>
  );
};

const mapStateToProps = (state: RootState, ownProps: { product: ProductInfo }) => ({
  productBookable: getProductBookable(state, ownProps.product.productId, ownProps.product.level),
});

export const ProductCellNotBookableContainer = connect(mapStateToProps)(ProductCellNotBookable);
