import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import type { RootState } from '../../../../configuration/setup/store';
import { getAssetDiscounts } from '../../common/discount/redux/discount.redux';
import type { AssetDiscount } from '../../common/discount/redux/types';
import {
  type BookableProduct,
  type CheckoutState,
  type Product,
  type ProductLevel,
  isBookableProduct,
  isMultiLevelProduct,
} from './types';

const initialState: CheckoutState = {
  selectedProductId: undefined,
  selectedProduct: undefined,
  selectedProductLevel: undefined,
  locked: false,
};

export const { reducer: checkoutReducer, actions: checkoutActions } = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setSelectedProductId: (state: CheckoutState, action: PayloadAction<string | undefined>) => {
      state.selectedProductId = action.payload;
    },
    setSelectedProduct: (state: CheckoutState, action: PayloadAction<Product | undefined>) => {
      state.selectedProduct = action.payload;
    },
    setSelectedProductLevel: (state: CheckoutState, action: PayloadAction<string | undefined>) => {
      state.selectedProductLevel = action.payload;
    },
    resetSelectedProductState: (state: CheckoutState) => {
      state.selectedProductId = undefined;
      state.selectedProduct = undefined;
      state.selectedProductLevel = undefined;
    },
    setCheckoutIsLocked: (state: CheckoutState, action: PayloadAction<boolean>) => {
      state.locked = action.payload;
    },
  },
});

const getBase = (state: RootState) => state.marketplace.checkout;

export const getSelectedProduct = (state: RootState) => getBase(state).selectedProduct;
export const getSelectedProductType = (state: RootState) => getSelectedProduct(state)?.productType;
export const getSelectedProductLevel = (state: RootState) => getBase(state).selectedProductLevel;
export const getSelectedProductId = (state: RootState) => getBase(state).selectedProductId;
export const getIsCheckoutLocked = (state: RootState) => getBase(state).locked;
export const getSelectedVariant = (state: RootState) => getLevelOfProduct(state)?.variantName;
export const getLevelOfProduct = (state: RootState): ProductLevel | undefined => {
  const selectedProduct = getSelectedProduct(state);
  const selectedProductLevel = getSelectedProductLevel(state);
  if (!isMultiLevelProduct(selectedProduct)) {
    return undefined;
  }
  return selectedProduct.levels.find(level => level.level === selectedProductLevel);
};
export const getSelectedProductOrProductLevel = createSelector(
  getSelectedProduct,
  getLevelOfProduct,
  (selectedProduct, levelOfProduct): BookableProduct => {
    if (isMultiLevelProduct(selectedProduct)) {
      if (!levelOfProduct) {
        throw Error(`Selected level not found for ${JSON.stringify(selectedProduct)}.`);
      }
      return {
        sku: selectedProduct.sku,
        productType: selectedProduct.productType,
        name: levelOfProduct.name,
        price: levelOfProduct.price,
        currency: levelOfProduct.currency,
        ratePlanId: levelOfProduct.ratePlanId,
        level: levelOfProduct.level,
        contractPeriod: levelOfProduct.contractPeriod,
        variantName: levelOfProduct.variantName,
        effectiveEndDate: levelOfProduct.effectiveEndDate,
        futurePrice: levelOfProduct.futurePrice,
      };
    }
    if (!isBookableProduct(selectedProduct)) {
      throw Error(`Selected product ${selectedProduct} is not bookable.`);
    }
    return selectedProduct;
  }
);

export const getProductLevelsForVariant = (state: RootState): ProductLevel[] | undefined => {
  const selectedProduct = getSelectedProduct(state);
  const selectedVariant = getSelectedVariant(state);
  if (!isMultiLevelProduct(selectedProduct)) {
    return undefined;
  }
  if (selectedVariant) {
    const levelsForVariant = selectedProduct.levels.filter(
      productLevel => productLevel.variantName === selectedVariant
    );
    if (levelsForVariant.length > 1) {
      return levelsForVariant;
    }
  }
  return undefined;
};

export const getFullyDiscountedLevel = (state: RootState): string | undefined => {
  const assetDiscounts = getAssetDiscounts(state);
  try {
    const selectedProduct = getSelectedProduct(state);
    if (!isMultiLevelProduct(selectedProduct)) {
      return undefined;
    }
    const matchesProduct = (discount: AssetDiscount) =>
      discount.productId.toLowerCase() === selectedProduct.sku.toLowerCase();
    const matchesPercentage = (discount: AssetDiscount) => discount.discountPercentage === 100;
    const matchesContractPeriod = (discount: AssetDiscount) => {
      const matchesProductLevel = selectedProduct.levels.find(productLevel =>
        discount.productLevels?.includes(productLevel.level)
      );
      if (matchesProductLevel?.contractPeriod) {
        return (
          matchesProductLevel.contractPeriod.length === discount.durationPeriod &&
          matchesProductLevel.contractPeriod.unit.toString().startsWith(discount.durationUnit.toString())
        );
      }
      return undefined;
    };
    const matchesUnappliedDiscount = (discount: AssetDiscount) => discount.endsAt === undefined;
    const relevantAssetDiscounts = assetDiscounts
      .filter(matchesUnappliedDiscount)
      .filter(matchesProduct)
      .filter(matchesPercentage)
      .filter(matchesContractPeriod);
    if (relevantAssetDiscounts.length !== 0) {
      return relevantAssetDiscounts[0].productLevels?.at(0);
    }
  } catch (e) {
    //setSelectedProduct is called after VariantSelectionContainer is rendered
  }
  return undefined;
};
