import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import type { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { getHasError, getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { fetchPermissionsThunk } from './permissions.thunk';
import { getPermissions } from './redux/permissions.redux';

interface WrapperProps {
  fetchPermissions: () => void;
  permissions?: string[];
  hasError: boolean;
  isLoading: boolean;
}

export interface PermissionValidatorWrappedComponentProps {
  userHasPermissions: (requiredPermissions: string[]) => boolean;
}

export function createPermissionWrapper(ErrorStateComponent: React.ComponentType) {
  return (WrappedComponent: React.ComponentType<PermissionValidatorWrappedComponentProps>) => {
    return class WithPermissions extends React.Component<WrapperProps> {
      constructor(props: WrapperProps) {
        super(props);
        this.props.fetchPermissions();

        this.hasSingleRequiredPermission = this.hasSingleRequiredPermission.bind(this);
        this.userHasPermissions = this.userHasPermissions.bind(this);
      }

      userHasPermissions(requiredPermissions: string[]) {
        if (!Array.isArray(requiredPermissions)) {
          throw new Error('userHasPermissions expects an array as argument.');
        }
        return requiredPermissions.every(this.hasSingleRequiredPermission);
      }

      hasSingleRequiredPermission(requiredPermission: string) {
        return Array.isArray(this.props.permissions) && this.props.permissions.includes(requiredPermission);
      }

      renderLoadingSpinner() {
        return (
          <div>
            <Spinner text={<FormattedMessage id={'marketplace.loading'} />} isInverse={false} />
          </div>
        );
      }

      render() {
        if (this.props.hasError) {
          return <ErrorStateComponent />;
        }

        if (this.props.isLoading || !this.props.permissions) {
          return this.renderLoadingSpinner();
        }

        // Used to pass only original props to component, hide permission props
        const { permissions, fetchPermissions, isLoading, hasError, ...otherProps } = this.props;

        return <WrappedComponent userHasPermissions={this.userHasPermissions} {...otherProps} />;
      }
    };
  };
}

export function mapStateToProps(state: RootState) {
  return {
    permissions: getPermissions(state),
    hasError: getHasError(state, ApiCalls.PERMISSIONS),
    isLoading: getIsLoading(state, ApiCalls.PERMISSIONS),
  };
}

export function mapDispatchToProps(dispatch: RootDispatch) {
  return {
    fetchPermissions: () => dispatch(fetchPermissionsThunk),
  };
}

export const wrapWithPermissionsValidator: <T>(
  ErrorStateComponent: React.ComponentType
) => (WrappedComponent: React.ComponentType<T & PermissionValidatorWrappedComponentProps>) => React.ComponentType<T> = (
  ErrorStateComponent: React.ComponentType
) => compose(injectIntl, connect(mapStateToProps, mapDispatchToProps), createPermissionWrapper(ErrorStateComponent));
