import Slider, { type Settings } from 'react-slick';
import { FREE_TRIAL_PRODUCTS } from '../FreeTrialConfig';
import { ProductSlide } from './ProductSlide';

export function ProductImageSlider() {
  const sliderSettings: Settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: '0',
    dots: false,
    fade: false,
    infinite: true,
    pauseOnHover: true,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: false,
  };

  return (
    <div className={'bg-white padding-y-50 margin-top-50'}>
      <Slider {...sliderSettings}>
        {FREE_TRIAL_PRODUCTS.map((product, index) => (
          <ProductSlide key={index} productName={product.productName} iconUrl={product.iconUrl} />
        ))}
      </Slider>
    </div>
  );
}
