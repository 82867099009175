import type { RootState } from '../../../../configuration/setup/store';
import { getFreeTrialContracts } from '../../common/termedLevel/redux/termedLevelRenewal.redux';
import type { TermedLevelId } from '../../common/termedLevel/redux/types';
import type { TermedLevel } from '../../common/termedLevel/types';
import { getAssetSearch, getSelectedFreeTrialActivations } from './redux/freeTrialTab.redux';

export const getFilteredContractsByAssets = (state: RootState) => {
  const allRows = getFreeTrialContracts(state);
  const term = getAssetSearch(state);

  const filteredRowsBySearchTerm = filterRowsBySearchTerm(allRows, term);

  // always show selected rows
  const selectedRows = getSelectedRows(allRows, getSelectedFreeTrialActivations(state));
  const displayRows = mergeSelectedRowsToFilteredRows(selectedRows, filteredRowsBySearchTerm);

  // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
  let message;
  if (displayRows.length === 0) {
    message = 'marketplace.contracts.search.noContractFound';
  }
  return { displayRows, message };
};

const filterRowsBySearchTerm = (rows: TermedLevel[], term: string) => {
  return rows.filter(contract => {
    if (contract.resourceName !== undefined) {
      return contract.resourceName.toLowerCase().includes(term.toLowerCase());
    }
    return contract.resourceId.toLowerCase().includes(term.toLowerCase());
  });
};

const getSelectedRows = (allRows: TermedLevel[], selectedRows: TermedLevelId[]) => {
  return allRows.filter(contract => {
    return (
      selectedRows.find(row => contract.productId === row.productId && contract.resourceId === row.resourceId) !==
      undefined
    );
  });
};

const mergeSelectedRowsToFilteredRows = (selectedRows: TermedLevel[], filteredRows: TermedLevel[]) => {
  const combinedRows = [...filteredRows, ...selectedRows];
  const uniqueRows = new Set(combinedRows);
  return Array.from(uniqueRows);
};
