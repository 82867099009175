import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  partnerName?: string;
  linkText?: string;
}

export function getPrivacyPolicyUrl(partnerName: string | undefined) {
  if (partnerName === undefined) {
    return 'https://rio.cloud/fileadmin/Marketplace/RIO/Products/Fleet/Localized/de-DE/Fleet_privacy_policy.pdf';
  }
  return `https://rio.cloud/fileadmin/Marketplace/RIO/Partner/partner_${partnerName}_privacy_policy.pdf`;
}

export const PrivacyPolicyOpener = ({ partnerName, linkText }: Props) => {
  const url = getPrivacyPolicyUrl(partnerName);
  const [show, setShow] = useState(false);
  const intl = useIntl();

  return (
    <>
      <Dialog
        show={show}
        title={<FormattedMessage id={'marketplace.partners.table.header.privacyPolicy'} />}
        body={
          <iframe
            className='position-absolute top-0 left-0 width-100pct height-100pct'
            src={url}
            title={intl.formatMessage({ id: 'marketplace.partners.table.header.privacyPolicy' })}
          />
        }
        onHide={() => setShow(false)}
        footer={null}
        showCloseButton={true}
        bsSize={Dialog.SIZE_FULL_SCREEN}
      />

      <span onClick={() => setShow(true)}>
        {linkText ? (
          <a href={url} onClick={event => event.preventDefault()}>
            {linkText}
          </a>
        ) : (
          <button className={'btn btn-default btn-link padding-0'}>
            <span className={'rioglyph rioglyph-document cursor-pointer'} />{' '}
            <FormattedMessage id={'marketplace.showDocument'} />
          </button>
        )}
      </span>
    </>
  );
};
