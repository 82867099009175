import groupBy from 'lodash/groupBy';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import type { RootDispatch } from '../../../../configuration/setup/store';
import { ProductState } from '../../checkout/resourceSelection/redux/types';
import { isServiceCareLBeta } from '../../common/betaService';
import { type BookingSuccessMessage, SUCCESSFULLY_BOOKED } from '../../common/postMessage.types';
import { sortingByName } from '../../common/utils/sortUtils';
import { ServiceBodyContentContainer } from './ServiceBodyContentContainer';
import { ServiceBodyFooterContainer } from './ServiceBodyFooterContainer';
import type { OverviewResource } from './redux/types';
import { hasTwoOrMoreLevels } from './services/serviceOverviewLevelService';
import { findSmallestNonFreeTrialLevel } from './services/tabLevelService';
import { fetchResourcesForSkuThunk } from './thunks/resourceActivations.thunk';
import { cancelSubscriptionThunk } from './thunks/subscriptionCancel.thunk';
import type { ServiceOverviewItem } from './types';

interface Props {
  service: ServiceOverviewItem;
  fetchServiceResources: (sku: string, level?: string) => void;
  updateSubscription: (service: ServiceOverviewItem, resourceIds: string[], level: string | undefined) => void;
}

function ServiceBody(props: Props) {
  const { service, fetchServiceResources, updateSubscription } = props;
  const tabs = Object.values(
    groupBy(props.service.productRatePlans, ratePlan => ratePlan.variantName ?? ratePlan.level)
  );
  const [selectedTab, setSelectedTab] = useState('10');
  const [selectedResources, setSelectedResources] = useState<OverviewResource[]>([]);

  useEffect(() => {
    const smallestLevel = findSmallestNonFreeTrialLevel(tabs);
    setSelectedTab(smallestLevel);
  }, []);

  useEffect(() => {
    fetchServiceResources(service.sku);
    window.addEventListener('message', listenToSuccessfulFinishOfBooking);
    return () => window.removeEventListener('message', listenToSuccessfulFinishOfBooking);
    // adding listen... function results in triggering for each render
  }, [service.sku, fetchServiceResources]);

  function listenToSuccessfulFinishOfBooking(message: MessageEvent) {
    if (message.origin !== window.origin) {
      return;
    }
    const messageData = message.data as unknown as BookingSuccessMessage;
    if (messageData.type === SUCCESSFULLY_BOOKED && messageData.productId === service.sku) {
      fetchServiceResources(service.sku);
      setSelectedResources([]);
      setTimeout(() => fetchServiceResources(service.sku), 5000);
    }
  }

  function handleSelectResource(resource: OverviewResource) {
    const selectedResourceIds = selectedResources.map(it => it.id);
    if (selectedResourceIds.includes(resource.id)) {
      setSelectedResources(selectedResources.filter(it => it.id !== resource.id));
    } else {
      setSelectedResources([...selectedResources, resource].sort(sortingByName));
    }
  }

  function handleSelectAll(resources: OverviewResource[], shouldBeSelected: boolean) {
    if (shouldBeSelected) {
      setSelectedResources(
        resources.filter(
          resource =>
            resource.productState === ProductState.ACTIVE && !isServiceCareLBeta(service.sku, resource.targetLevel)
        )
      );
    } else {
      setSelectedResources([]);
    }
  }

  function handleConfirmUpdate() {
    const ids = selectedResources.map(resource => resource.id);
    updateSubscription(service, ids, hasTwoOrMoreLevels(service) ? selectedTab : undefined);
    setSelectedResources([]);
  }

  function selectTab(tabKey: string) {
    if (tabKey !== selectedTab) {
      setSelectedTab(tabKey);
      setSelectedResources([]);
    }
  }

  return (
    <div className={'ServiceOverviewItemBodyResource'}>
      <ServiceBodyContentContainer
        service={service}
        selectedResources={selectedResources}
        fetchContent={() => fetchServiceResources(service.sku)}
        handleSelectAll={handleSelectAll}
        handleSelectResource={handleSelectResource}
        tabs={tabs}
        tab={selectedTab}
        selectTab={selectTab}
      />
      <hr className='margin-0' />
      <ServiceBodyFooterContainer
        service={service}
        tab={selectedTab}
        selectedResources={selectedResources}
        handleConfirmUpdate={handleConfirmUpdate}
      />
    </div>
  );
}

export function mapDispatchToProps(dispatch: RootDispatch) {
  return {
    fetchServiceResources: (sku: string) => dispatch(fetchResourcesForSkuThunk(sku)),
    updateSubscription: (subscription: ServiceOverviewItem, resourceIds: string[], level: string | undefined) =>
      dispatch(cancelSubscriptionThunk(subscription, resourceIds, level)),
  };
}

export const ServiceBodyContainer = connect(null, mapDispatchToProps)(ServiceBody);
