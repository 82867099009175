import { differenceInMonths } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { openContactFormInPopup } from '../../common/popupService';
import { DATE_FORMAT, getDateFromIsoString } from '../../common/utils/dateUtil';
import manPointingRightSquare from '../../freeTrial/images/man_pointing_right_square.webp';
import { getFreeTrialEndDate } from '../../freeTrial/redux/freeTrial.redux';
import { FreeTrialExpirationNotice } from './FreeTrialExpirationNotice';

export function FreeTrialInformationBox() {
  const freeTrialEndDateIsoString = useSelector(getFreeTrialEndDate);
  const freeTrialEndDate = getDateFromIsoString(freeTrialEndDateIsoString);
  const intl = useIntl();

  if (!freeTrialEndDate) {
    return null;
  }

  const endDate = intl.formatDate(freeTrialEndDate, { ...DATE_FORMAT, timeZone: 'UTC' });

  if (differenceInMonths(freeTrialEndDate, new Date()) < 1 && differenceInMonths(freeTrialEndDate, new Date()) >= 0) {
    return <FreeTrialExpirationNotice endDate={endDate} />;
  }

  return (
    <div className='display-flex justify-content-center'>
      <div>
        <div className='well padding-x-50-sm padding-top-25-sm padding-bottom-0 bg-primary display-flex-sm align-items-end-sm max-width-1000 gap-25 position-relative'>
          <div className='width-100pct max-width-800 rounded-circle bg-white aspect-ratio-1 position-absolute top-0 left-50pct translate-x-50pct-y-50pct opacity-10 blur-50' />
          <div className='max-width-250-sm display-flex-sm justify-content-center-sm margin-bottom-0-sm visible-min-sm position-relative z-index-1'>
            <img
              className='img-responsive margin-top-20 position-relative max-width-250 min-width-250-sm'
              src={manPointingRightSquare}
              alt='Man pointing right'
            />
          </div>
          <div className='text-color-white padding-bottom-15 line-height-125rel position-relative z-index-1'>
            <div className='text-medium text-size-h3 margin-bottom-20'>
              <FormattedMessage id={'marketplace.freeTrialTab.informationBox.title'} values={{ date: endDate }} />
            </div>
            <div className='text-light'>
              <p className='margin-y-10'>
                <FormattedMessage
                  id={'marketplace.freeTrialTab.informationBox.text1'}
                  values={{
                    // @ts-ignore
                    bold: (chunks: string[]) => <strong>{chunks}</strong>,
                  }}
                />
              </p>
              <p className='margin-y-10'>
                <FormattedMessage id={'marketplace.freeTrialTab.informationBox.text2'} />
              </p>
              <p className='margin-y-10'>
                <FormattedMessage
                  id={'marketplace.freeTrialTab.informationBox.text3'}
                  values={{
                    // @ts-ignore
                    bold: (chunks: string[]) => <strong>{chunks}</strong>,
                  }}
                />
              </p>
              <hr className='border-color-highlight-light' />
              <p className='margin-y-10'>
                <FormattedMessage
                  id={'marketplace.freeTrialTab.informationBox.text4'}
                  values={{
                    // @ts-ignore
                    link: (chunks: string[]) => (
                      <a
                        className='text-color-white text-decoration-underline'
                        // biome-ignore lint/a11y/useValidAnchor: <explanation>
                        onClick={() => openContactFormInPopup('freeTrialInfoContactPopup')}
                      >
                        {chunks}
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          </div>
        </div>
        <div className='text-center'>
          <small>
            <FormattedMessage id={'marketplace.freeTrialTab.informationBox.note'} />
          </small>
        </div>
      </div>
    </div>
  );
}
