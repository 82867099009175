import type { DisplayMessages } from '../../../../configuration';
import { getMarketplaceBackend } from '../../config/pathURL/config';
import type { FetchedHistoryEvents } from '../../customerCenter/history/redux/types';
import { decodeJson, jsonOrReject, onRejected } from '../api';
import { getParameters } from '../parameterService';
import { getRequest } from '../requests';
import { ApiHistoryEventListCodec } from './historyEvent.types';
import { createHistoryParams, mapHistoryResponse } from './historyMapper';
import type { HistoryFetchParams } from './types';

export function fetchHistoryEvents(
  params: HistoryFetchParams,
  displayMessages: DisplayMessages
): Promise<FetchedHistoryEvents> {
  const encodedParameters = getParameters({ ...createHistoryParams(params) });
  return fetch(`${getMarketplaceBackend()}/history-events${encodedParameters}`, getRequest())
    .then(jsonOrReject)
    .then(decodeJson(ApiHistoryEventListCodec))
    .then(response => mapHistoryResponse(response, displayMessages))
    .catch(onRejected([403]));
}
