import classnames from 'classnames';
import { useEffect } from 'react';
import { ProductTypedFormattedMessage } from '../../../common/product/ProductTypedFormattedMessage';
import { safeDataLayerPush } from '../../../common/utils/googleTagManagerWrapper';
import { openCheckoutPopup } from '../services/checkoutService';
import { GtmPromotion, getGtmPromotionViewEvent } from '../services/gtmPromotion';
import type { ServiceOverviewItem } from '../types';

interface Props {
  service: ServiceOverviewItem;
  level?: string;
  isPrimary: boolean;
}

export const AddMoreButton = (props: Props) => {
  const { level, service, isPrimary } = props;
  const { sku } = service;
  useEffect(() => {
    safeDataLayerPush(() => getGtmPromotionViewEvent(GtmPromotion.customerCenterAddMore));
  }, [true]);

  const { callback, checkoutUrl } = openCheckoutPopup(sku, GtmPromotion.customerCenterAddMore, level);

  return (
    <a
      className={classnames('addMore btn btn-default', isPrimary && 'btn-primary')}
      href={checkoutUrl}
      onClick={callback}
    >
      <span className='rioglyph rioglyph-plus' aria-hidden={true} />
      <ProductTypedFormattedMessage
        productType={service.productType}
        assetBasedId={'marketplace.myServices.assets.addMore'}
        userBasedId={'marketplace.myServices.users.addMore'}
      />
    </a>
  );
};
