import { createSelector } from '@reduxjs/toolkit';
import { getDisplayMessages } from '../../../../../configuration';
import type { RootState } from '../../../../../configuration/setup/store';
import type { ResourceUsage } from '../redux/types';
import { getFilteredResourceTypes, getResourceUsages, getUsageSearchTerm } from '../redux/usage.redux';

function resourceIsWithinSearch(
  resourceUsage: ResourceUsage,
  usageSearchTerm: string,
  unknownResourcesMatch: undefined | boolean
) {
  return (
    resourceUsage.resourceName?.toLocaleLowerCase().includes(usageSearchTerm) ||
    resourceUsage.resourceIdentification?.toLocaleLowerCase().includes(usageSearchTerm) ||
    (unknownResourcesMatch &&
      (resourceUsage.resourceName === undefined || resourceUsage.resourceIdentification === undefined))
  );
}

export const getSearchedResourceSummaries: (state: RootState) => ResourceUsage[] = createSelector(
  getResourceUsages,
  getUsageSearchTerm,
  getDisplayMessages,
  getFilteredResourceTypes,
  (resourceUsages, usageSearchTerm, languageData, filteredResourceTypes) => {
    const lowerCaseSearchTerm = usageSearchTerm.toLocaleLowerCase();
    const unknownVehicleLabel = languageData['marketplace.history.details.tooltip.unknown'];

    const unknownVehiclesMatch = unknownVehicleLabel?.toLocaleLowerCase().includes(lowerCaseSearchTerm);
    return resourceUsages.filter(
      usage =>
        (filteredResourceTypes as string[]).includes(usage.resourceType) &&
        (lowerCaseSearchTerm === '' || resourceIsWithinSearch(usage, lowerCaseSearchTerm, unknownVehiclesMatch))
    );
  }
);
