import { PaymentMethodPageContainer } from '../common/payment/PaymentMethodPageContainer';

export const PaymentMethodsContainer = () => (
  <div className='PaymentMethods container-fluid fluid-default'>
    <div className='panel panel-default'>
      <div className='panel-body'>
        <PaymentMethodPageContainer />
      </div>
    </div>
  </div>
);
