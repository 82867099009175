import type { RootState } from '../../../../../configuration/setup/store';
import { getContracts } from '../../../common/termedLevel/redux/termedLevelRenewal.redux';
import { getCurrentDate } from '../../../common/utils/dateUtil';
import { filterRowsByDates, filterRowsByHidingDeletedAssetsIfEnabled } from './contractSearchService';

export const getExpiresThisMonth = (state: RootState) => filterByDates(today(), endOfThisMonth(), state);

export const getExpiresNextMonth = (state: RootState) => filterByDates(startOfNextMonth(), endOfNextMonth(), state);

const filterByDates = (from: Date, to: Date, state: RootState) => {
  const allRows = getContracts(state);

  const filterRowsByHidingDeletedAssets = filterRowsByHidingDeletedAssetsIfEnabled(state, allRows);

  const filtered = filterRowsByDates(filterRowsByHidingDeletedAssets, from.toISOString(), to.toISOString());
  return filtered.length;
};

export const today = () => {
  const todayDate = getCurrentDate();
  todayDate.setHours(0, 0, 0, 0);
  return todayDate;
};

export const endOfThisMonth = () => {
  const endOfThisMonthDate = getCurrentDate();
  endOfThisMonthDate.setMonth(endOfThisMonthDate.getMonth() + 1);
  endOfThisMonthDate.setDate(0);
  endOfThisMonthDate.setHours(0, 0, 0, 0);
  return endOfThisMonthDate;
};

export const startOfNextMonth = () => {
  const startOfNextMonthDate = getCurrentDate();
  startOfNextMonthDate.setMonth(startOfNextMonthDate.getMonth() + 1);
  startOfNextMonthDate.setDate(1);
  startOfNextMonthDate.setHours(0, 0, 0, 0);
  return startOfNextMonthDate;
};

export const endOfNextMonth = () => {
  const endOfNextMonthDate = getCurrentDate();
  endOfNextMonthDate.setMonth(endOfNextMonthDate.getMonth() + 2);
  endOfNextMonthDate.setDate(0);
  endOfNextMonthDate.setHours(0, 0, 0, 0);
  return endOfNextMonthDate;
};
