import { FormattedMessage, useIntl } from 'react-intl';
import { formatPrice } from '../../common/utils/currencyUtil';

export const SavingHint = ({ price, currency }: { price: number | undefined; currency: string | undefined }) => {
  const intl = useIntl();

  if (price === undefined) {
    return null;
  }
  return (
    <div className='display-flex align-items-center gap-15'>
      <div className='text-size-h1 text-bold'>{formatPrice(intl, 0, currency, 0)}</div>
      <div className='text-color-light'>
        <div className='text-size-18 line-height-18 text-decoration-line-through'>
          {formatPrice(intl, price * 31, currency)}
        </div>
        <div className='text-size-14 line-height-14'>
          <FormattedMessage id='marketplace.freeTrial.productShowCase.priceDisclaimer' />
        </div>
      </div>
    </div>
  );
};
