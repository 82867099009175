import { FormattedMessage, type IntlShape, useIntl } from 'react-intl';
import { NavLink } from 'react-router';
import { DiscountBadgeWithTooltip } from '../../../common/discount/DiscountBadge';
import type { ProductDiscount } from '../../../common/discount/redux/types';
import { getCustomerCenterPath } from '../../../common/routes';
import type { Period } from '../../../common/termedLevel/types';
import { formatDurationUnit } from '../../../common/utils/formatDurationUnit';
import { OverlayTrigger, Tooltip } from '../../../common/utils/tooltipReExports';
import { CustomerCenterTab } from '../../types';

interface Props {
  period: Period | undefined;
  freeTrial: boolean;
  integratedOffer: boolean;
  legacy: boolean;
  discount: ProductDiscount | undefined;
}

function getFreeTrialDividerText() {
  return (
    <NavLink className={'btn btn-secondary btn-sm'} to={`${getCustomerCenterPath(CustomerCenterTab.FREE_TRIAL)}`}>
      <FormattedMessage id='marketplace.free.trial' />
    </NavLink>
  );
}

function getIntegratedOfferDividerText() {
  return <FormattedMessage id='marketplace.integrated.offer.divider' />;
}

function getLegacyInfoIcon() {
  return (
    <>
      {' '}
      <OverlayTrigger
        placement='top'
        overlay={
          <Tooltip>
            <FormattedMessage id='marketplace.product.legacy.tooltip' />
          </Tooltip>
        }
      >
        <span className={'rioglyph rioglyph-info-sign'} />
      </OverlayTrigger>
    </>
  );
}

function getPeriodDividerText(intl: IntlShape, period: Period) {
  return (
    <>
      <FormattedMessage id='intl-msg:common-message.contracts.period' />{' '}
      <b>
        {period.length} {formatDurationUnit(period.unit, intl)}
      </b>
    </>
  );
}

function getDividerText(
  intl: IntlShape,
  period: Period | undefined,
  freeTrialLevel: boolean,
  integratedOfferLevel: boolean
) {
  if (freeTrialLevel) {
    return getFreeTrialDividerText();
  }

  if (integratedOfferLevel) {
    return getIntegratedOfferDividerText();
  }

  if (period) {
    return getPeriodDividerText(intl, period);
  }

  return <FormattedMessage id='marketplace.productDetails.evergreen' />;
}

export const LevelDivider = ({ period, freeTrial, integratedOffer, legacy, discount }: Props) => {
  const intl = useIntl();
  const dividerText = getDividerText(intl, period, freeTrial, integratedOffer);
  const legacyInfoIcon = legacy ? getLegacyInfoIcon() : undefined;

  return (
    <div className='position-relative padding-top-15 padding-bottom-15'>
      <hr className='margin-0 border-width-2 margin-left-15 margin-right-15' />
      <span className='bg-lightest padding-10 text-size-h6 position-absolute top-50pct left-50pct translate-x-50pct-y-50pct'>
        {dividerText}
        {legacyInfoIcon}
        {discount && (
          <>
            <span>&nbsp;</span>
            <DiscountBadgeWithTooltip discount={discount} />
          </>
        )}
      </span>
    </div>
  );
};
