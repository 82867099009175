import type { Dispatch } from 'redux';
import { getAccountId, getDisplayMessages } from '../../../../configuration';
import type { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { fetchBookableResources } from '../../api/bookable/bookableCalls';
import { apiCallAction } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { fetchActivationSummaryDetailed } from '../../api/services/activationSummaryCalls';
import { mapAssetsToRows } from './assetOverviewAssetMapper';
import { mapProductResponse } from './assetOverviewProductMapper';
import { assetOverviewActions } from './redux/assetOverview.redux';
import type { HeaderProduct } from './redux/types';

function fetchAllProductInfoForAssets(setLoadingFlags: boolean, headerProducts: HeaderProduct[]) {
  return (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
    const displayMessages = getDisplayMessages(getState());

    dispatch(apiCallAction(ApiCalls.ASSET_OVERVIEW_INFO, setLoadingFlags, false));
    const flatProductLevels: Array<{ productId: string; level?: string }> = headerProducts.flatMap(it => {
      if (it.levels.length === 0) {
        return [{ productId: it.productId }];
      }
      return it.levels.map(level => ({ productId: it.productId, level }));
    });
    const requests = flatProductLevels.map(it => fetchBookableResources(it.productId, it.level, displayMessages));
    return Promise.all(requests)
      .then(bookableAssetsPerProduct => {
        const assetOverviewRows = mapAssetsToRows(bookableAssetsPerProduct, flatProductLevels, headerProducts);
        dispatch(assetOverviewActions.setTableRows(assetOverviewRows));
        dispatch(apiCallAction(ApiCalls.ASSET_OVERVIEW_INFO, false, false));
      })
      .catch(() => {
        dispatch(apiCallAction(ApiCalls.ASSET_OVERVIEW_INFO, false, true));
      });
  };
}

export function fetchProductInfoForAssets(setLoadingFlags: boolean) {
  return (dispatch: RootDispatch, getState: () => RootState) => {
    const accountId = getAccountId(getState());
    if (!accountId) {
      throw Error();
    }
    // TODO: either combine with "serviceOverview" state part or already pre-process in backend
    dispatch(apiCallAction(ApiCalls.ASSET_OVERVIEW_PRODUCTS, setLoadingFlags, false));
    return fetchActivationSummaryDetailed()
      .then(response => {
        const headerProducts = mapProductResponse(response);
        dispatch(assetOverviewActions.setTableColumns(headerProducts));
        dispatch(fetchAllProductInfoForAssets(setLoadingFlags, headerProducts));
        dispatch(apiCallAction(ApiCalls.ASSET_OVERVIEW_PRODUCTS, false, false));
      })
      .catch(() => {
        dispatch(apiCallAction(ApiCalls.ASSET_OVERVIEW_PRODUCTS, false, true));
      });
  };
}
