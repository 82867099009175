import { ProductState } from '../../checkout/resourceSelection/redux/types';
import { isManNowProduct } from '../../config/manNowProducts';
import type { TermedLevel } from './types';

export const isTermedLevelSelectable = (contract: TermedLevel) =>
  !(
    contract.hasInactiveActivationState ||
    contract.activationState === ProductState.PENDING_ACTIVATION ||
    (isManNowProduct(contract.productId) && !contract.paid)
  );
