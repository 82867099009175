import { FormattedMessage } from 'react-intl';
import { PaymentMethodType } from '../../api/paymentMethods/paymentMethodTypes.types';
import { PaymentInformationBankTransfer } from './PaymentInformationBankTransfer';
import { PaymentInformationCreditCard } from './PaymentInformationCreditCard';
import { PaymentInformationSepa } from './PaymentInformationSepa';
import type { PaymentMethod } from './redux/types';

export const PaymentInformation = (props: { paymentInformation?: PaymentMethod }) => {
  const payment = props.paymentInformation || {};

  let content;
  if (payment.paymentType === PaymentMethodType.SEPA) {
    content = <PaymentInformationSepa paymentInformation={payment} />;
  } else if (payment.paymentType === PaymentMethodType.CREDIT_CARD) {
    content = <PaymentInformationCreditCard paymentInformation={payment} />;
  } else if (payment.paymentType === PaymentMethodType.BANK_TRANSFER) {
    content = <PaymentInformationBankTransfer />;
  } else {
    content = (
      <span>
        <FormattedMessage id='marketplace.payment.information.unknown' />
      </span>
    );
  }

  return <div className='PaymentInformation'>{content}</div>;
};
