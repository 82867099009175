import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { type RootState, useAppDispatch } from '../../../../configuration/setup/store';
import { PaymentMethodType } from '../../api/paymentMethods/paymentMethodTypes.types';
import { getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { StripeCreatePaymentButton } from './StripeCreatePaymentButton';
import { publishPaymentMethodThunk } from './paymentMethodsPublish.thunk';

export const BankTransferPayment = () => {
  const paymentFetchInProgress = useSelector((state: RootState) => getIsLoading(state, ApiCalls.PAYMENT_METHODS_GET));
  const dispatch = useAppDispatch();

  return (
    <>
      <p className='payment-info-text'>
        <FormattedMessage id='marketplace.payment.bankTransfer.update.info' />
      </p>
      <div>
        <div className={'alert alert-info margin-y-15'}>
          <FormattedMessage
            id={'marketplace.payment.bankTransfer.info'}
            values={{
              bold: chunks => <strong>{chunks}</strong>,
            }}
          />
        </div>
        <div className='display-flex justify-content-end'>
          <StripeCreatePaymentButton
            confirmMessageId='marketplace.payment.bankTransfer.update.button'
            loadingMessageId='marketplace.payment.bankTransfer.update.button.inProgress'
            isInProgress={paymentFetchInProgress}
            onClick={() => dispatch(publishPaymentMethodThunk(PaymentMethodType.BANK_TRANSFER))}
          />
        </div>
      </div>
    </>
  );
};
