import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getAccountId } from '../../../../configuration';
import type { RootDispatch, RootState } from '../../../../configuration/setup/store';
import type { PaymentMethodType } from '../../api/paymentMethods/paymentMethodTypes.types';
import { getHasError, getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import LoadingErrorStateWithReload from '../error/LoadingErrorStateWithReload';
import { PaymentMethodPage } from './PaymentMethodPage';
import { fetchPaymentDetailsIfNecessaryByTenant } from './paymentMethods.thunk';
import {
  getPaymentMethodEditMode,
  getPaymentMethodTypes,
  getPaymentMethods,
  paymentMethodsActions,
} from './redux/paymentMethods.redux';
import { getDefaultPaymentInformation } from './redux/paymentMethodsSelectionService';
import type { PaymentMethod } from './redux/types';

interface Props {
  defaultPaymentInformation?: PaymentMethod;
  isLoading: boolean;
  hasError: boolean;
  paymentMethodTypes?: PaymentMethodType[];
  fetchPaymentDetailsIfNecessary: () => void;
  setEditMode: (isEditMode: boolean) => void;
  isEditPayment: boolean;
  accountId: string;
}

function PaymentMethodPageEurope(props: Props) {
  const {
    defaultPaymentInformation,
    isLoading,
    hasError,
    paymentMethodTypes,
    fetchPaymentDetailsIfNecessary,
    setEditMode,
    isEditPayment,
  } = props;
  useEffect(() => {
    fetchPaymentDetailsIfNecessary();
  }, [fetchPaymentDetailsIfNecessary]);

  if (hasError) {
    return (
      <LoadingErrorStateWithReload
        onReload={() => fetchPaymentDetailsIfNecessary()}
        headline={'marketplace.payment.information.error'}
      />
    );
  }

  if (isLoading || !paymentMethodTypes) {
    return (
      <div className='padding-20'>
        <Spinner text={''} isInverse={false} />
      </div>
    );
  }

  return (
    <PaymentMethodPage
      paymentInformation={defaultPaymentInformation}
      paymentMethodTypes={paymentMethodTypes}
      setEditMode={setEditMode}
      isEditPayment={isEditPayment}
    />
  );
}

function mapStateToProps(state: RootState) {
  return {
    defaultPaymentInformation: getDefaultPaymentInformation(state),
    hasError:
      getHasError(state, ApiCalls.PAYMENT_METHODS_GET) ||
      getHasError(state, ApiCalls.PAYMENT_METHODS_TYPES_GET) ||
      getHasError(state, ApiCalls.PAYMENT_METHODS_POST),
    isLoading:
      !getPaymentMethods(state) ||
      !getPaymentMethodTypes(state) ||
      getIsLoading(state, ApiCalls.PAYMENT_METHODS_GET) ||
      getIsLoading(state, ApiCalls.PAYMENT_METHODS_POST) ||
      getIsLoading(state, ApiCalls.PAYMENT_METHODS_TYPES_GET),
    paymentMethodTypes: getPaymentMethodTypes(state),
    isEditPayment: getPaymentMethodEditMode(state),
    accountId: getAccountId(state),
  };
}

function mapDispatchToProps(dispatch: RootDispatch) {
  return {
    fetchPaymentDetailsIfNecessary: () => {
      const returnedAction = fetchPaymentDetailsIfNecessaryByTenant();
      if (returnedAction) {
        dispatch(returnedAction);
      }
    },
    setEditMode: (isEditMode: boolean) => dispatch(paymentMethodsActions.setPaymentMethodEditMode(isEditMode)),
  };
}

export const PaymentMethodPageContainer = connect(mapStateToProps, mapDispatchToProps)(PaymentMethodPageEurope);
