const STORAGE_PREFIX = 'marketplace';

export const saveInLocalStorage = (key: string, value: unknown) => {
  try {
    window.localStorage.setItem(`${STORAGE_PREFIX}.${key}`, JSON.stringify(value));
  } catch (_) {
    // Intentionally left blank
  }
};

export const loadFromLocalStorage = (key: string) => {
  try {
    const item = window.localStorage.getItem(`${STORAGE_PREFIX}.${key}`);
    if (item) {
      return JSON.parse(item);
    }
  } catch (_) {
    // Intentionally left blank
  }
};
