import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../../configuration/setup/store';
import type { FailedPayment, PaymentsState } from './types';

const initialState: PaymentsState = {
  failedPayments: undefined,
  paymentSecret: undefined,
};

export const { reducer: paymentsReducer, actions: paymentsActions } = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setFailedPayments: (state: PaymentsState, action: PayloadAction<FailedPayment[]>) => {
      state.failedPayments = action.payload;
    },
    setPaymentSecret: (state: PaymentsState, action: PayloadAction<string>) => {
      state.paymentSecret = action.payload;
    },
  },
});

const getBase = (state: RootState): PaymentsState => state.marketplace.payment;

export const getFailedPayments = (state: RootState) => getBase(state).failedPayments;
export const getPaymentSecret = (state: RootState) => getBase(state).paymentSecret;
