import { FormattedMessage } from 'react-intl';
import { PartnerConnectionStatus } from '../redux/types';

interface Props {
  status: PartnerConnectionStatus;
}

export function mapStatusToLabelClass(status: PartnerConnectionStatus): string {
  switch (status) {
    case PartnerConnectionStatus.ACTIVATED:
      return 'label-success';
    case PartnerConnectionStatus.FAILED_ACTIVATION:
      return 'label-danger';
    case PartnerConnectionStatus.FAILED_DEACTIVATION:
      return 'label-warning';
    default:
      return 'label-default';
  }
}

export function PartnersTableStatusLabel({ status }: Props) {
  const labelType = mapStatusToLabelClass(status);

  return (
    <span className={`label ${labelType} label-filled`}>
      <FormattedMessage id={`marketplace.partners.table.status.${status}`} />
    </span>
  );
}
