import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../../../configuration/setup/store';
import type { TermedLevelId } from '../../../common/termedLevel/redux/types';
import type { TermedLevel } from '../../../common/termedLevel/types';
import { getCurrentDate } from '../../../common/utils/dateUtil';
import { endOfNextMonth, today } from './contractOverviewService';
import type { ContractState } from './types';

function initFromDate() {
  const from = getCurrentDate();
  from.setHours(0, 0, 0, 0);
  return from.toISOString();
}

const initialState: ContractState = {
  contracts: [],
  search: '',
  fromDate: initFromDate(),
  toDate: undefined,
  selectedContracts: [],
};

export const { reducer: contractReducer, actions: contractActions } = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    setContracts: (state: ContractState, action: PayloadAction<TermedLevel[]>) => {
      state.contracts = action.payload;
    },
    setAssetSearch: (state: ContractState, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setFromDate: (state: ContractState, action: PayloadAction<string | undefined>) => {
      const fromDate = action.payload ? new Date(action.payload) : undefined;

      if (fromDate !== undefined) {
        fromDate.setHours(0, 0, 0, 0);
      }
      state.fromDate = fromDate?.toISOString();
    },
    setToDate: (state: ContractState, action: PayloadAction<string | undefined>) => {
      const toDate = action.payload ? new Date(action.payload) : undefined;

      if (toDate !== undefined) {
        toDate.setHours(0, 0, 0, 0);
      }
      state.toDate = toDate?.toISOString();
    },
    toggleSelectedContract: (state: ContractState, { payload }: PayloadAction<TermedLevelId>) => {
      const index = state.selectedContracts.findIndex(
        contractId => contractId.productId === payload.productId && contractId.resourceId === payload.resourceId
      );
      if (index >= 0) {
        state.selectedContracts.splice(index, 1);
      } else {
        state.selectedContracts.push(payload);
      }
    },
    setSelectedContracts: (state: ContractState, action: PayloadAction<TermedLevelId[]>) => {
      state.selectedContracts = action.payload;
    },
    setDatesFromEmail: (state: ContractState) => {
      state.fromDate = today().toISOString();
      state.toDate = endOfNextMonth().toISOString();
    },
    setHideContractsForDeletedAssets: (state: ContractState, action: PayloadAction<boolean>) => {
      state.hideContractsForDeletedAssets = action.payload;
    },
  },
});

const getBase = (state: RootState) => state.marketplace.contract;
export const getAssetSearch = (state: RootState) => getBase(state).search;
export const getFromDate = (state: RootState) => getBase(state).fromDate;
export const getToDate = (state: RootState) => getBase(state).toDate;
export const getSelectedContracts = (state: RootState) => getBase(state).selectedContracts;
export const getHideContractsForDeletedAssets = (state: RootState) =>
  getBase(state).hideContractsForDeletedAssets ?? true;
