import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';
import classNames from 'classnames';
import { type IntlShape, useIntl } from 'react-intl';
import { DEFAULT_TOOLTIP_DELAY } from '../../../config/config';
import { MissingRequirement } from '../../MissingRequirement';
import { DiscountBadgeWithTooltip } from '../../discount/DiscountBadge';
import type { AssetDiscount } from '../../discount/redux/types';
import { OverlayTrigger, Tooltip } from '../../utils/tooltipReExports';
import type { TermedLevel } from '../types';

interface Props {
  contract: TermedLevel;
  discount?: AssetDiscount;
}

export const ASSET_SELECTED = '';
export const ASSET_NOT_BOOKABLE = 'rioglyph rioglyph-warning-sign disabled';

export const ASSET_NOT_BOOKABLE_TOOLTIP = 'marketplace.resourceSelection.asset.tooltipNotEligible';

const getClassName = (hasMissingRequirements: boolean): string => {
  return !hasMissingRequirements ? ASSET_SELECTED : ASSET_NOT_BOOKABLE;
};

const getAssetSelectionTooltip = (intl: IntlShape, missingRequirements: string[][]) => (
  <Tooltip id='tooltip' className={'width-auto max-width-300'}>
    <div className={'text-bold'}>{intl.formatMessage({ id: ASSET_NOT_BOOKABLE_TOOLTIP })}</div>
    <MissingRequirement intl={intl} missingRequirements={missingRequirements} />
  </Tooltip>
);

export const ContractSelectionItem = ({ contract, discount }: Props) => {
  const missingRequirements = contract.missingRequirements;
  const hasMissingRequirements = !!missingRequirements && missingRequirements.length !== 0;
  const intl = useIntl();

  const element = (
    <Tag className={classNames(getClassName(hasMissingRequirements), 'rounded', 'cursor-default')}>
      <div className='display-flex align-items-center justify-content-between'>
        <span>{contract.resourceName}</span>
        {discount && <DiscountBadgeWithTooltip discount={discount} />}
      </div>
    </Tag>
  );

  if (!hasMissingRequirements) {
    return element;
  }

  const tooltip = getAssetSelectionTooltip(intl, missingRequirements);

  return (
    <OverlayTrigger
      trigger={OverlayTrigger.TRIGGER_HOVER}
      placement='bottom'
      overlay={tooltip}
      delay={DEFAULT_TOOLTIP_DELAY}
    >
      {element}
    </OverlayTrigger>
  );
};
