import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { RootDispatch, RootState } from '../../../../../configuration/setup/store';
import { getIsLoading } from '../../../api/redux/api.redux';
import { ApiCalls } from '../../../api/redux/types';
import { isManNowProduct } from '../../../config/manNowProducts';
import { getDefaultPaymentInformation } from '../../payment/redux/paymentMethodsSelectionService';
import {
  getIsRenewDialogOpen,
  getProductForUpdate,
  getRenewsToLevelForUpdate,
} from '../redux/termedLevelRenewal.redux';
import type { TermedProductInfo } from '../redux/types';
import { RenewDialogContentManNowContainer } from './RenewDialogContentManNow';
import { RenewDialogDataTabsContainer } from './RenewDialogDataTabs';
import { RenewDialogPaymentMethodContent } from './RenewDialogPaymentMethodContent';
import { RenewDialogFooterContainer } from './RenewFooterContainer';
import { getTermedLevelsForUpdate } from './dialogService';
import { fetchRenewalDialogDataThunk, isDialogDataLoaded } from './renewData.thunk';

interface Props {
  product?: TermedProductInfo;
  isDialogOpen: boolean;
  isOnlyOneContractSelected: boolean;
  isLoading: boolean;
  fetchData: () => void;
  isShowPaymentMethod: boolean;
}

export const RenewDialogContent = (props: Props) => {
  const { product, fetchData, isDialogOpen, isLoading, isOnlyOneContractSelected, isShowPaymentMethod } = props;
  const textId = isOnlyOneContractSelected
    ? 'marketplace.contracts.renewal.dialog.text.singular'
    : 'marketplace.contracts.renewal.dialog.text.plural';

  useEffect(() => {
    fetchData();
  }, []);

  if (!isDialogOpen) {
    return <div />;
  }

  if (isLoading || !product) {
    return <Spinner />;
  }

  if (isManNowProduct(product.productId)) {
    return (
      <div className={'margin-bottom-20'}>
        <RenewDialogContentManNowContainer />
      </div>
    );
  }

  return (
    <div className={'margin-bottom-20'}>
      <div className={'margin-bottom-20'}>
        <FormattedMessage id={textId} />
      </div>
      <RenewDialogDataTabsContainer
        paymentMethodContainer={isShowPaymentMethod ? <RenewDialogPaymentMethodContent /> : undefined}
      />
      {!isShowPaymentMethod && <RenewDialogFooterContainer />}
    </div>
  );
};

export const requirePaymentMethod = (state: RootState, product: TermedProductInfo | undefined): boolean => {
  const selectedLevel = getRenewsToLevelForUpdate(state);
  const productLevelPrice = product?.levels?.find(level => level.level === selectedLevel)?.price ?? 0;

  return !getDefaultPaymentInformation(state) && productLevelPrice > 0;
};

const mapStateToProps = (state: RootState) => {
  const { updatable, notUpdatable } = getTermedLevelsForUpdate(state);
  const product = getProductForUpdate(state);
  return {
    product: product,
    isDialogOpen: getIsRenewDialogOpen(state),
    isOnlyOneContractSelected: [...updatable, ...notUpdatable].length === 1,
    isLoading:
      !isDialogDataLoaded(state) ||
      getIsLoading(state, ApiCalls.CONTRACT_BOOKABLE) ||
      getIsLoading(state, ApiCalls.CONTRACT_PRODUCT),
    isShowPaymentMethod: requirePaymentMethod(state, product),
  };
};
const mapDispatchToProps = (dispatch: RootDispatch) => ({
  fetchData: () => dispatch(fetchRenewalDialogDataThunk),
});

export const RenewDialogContentContainer = connect(mapStateToProps, mapDispatchToProps)(RenewDialogContent);
