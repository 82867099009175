export type ProductConfig = {
  applicationLink: string;
  marketplaceLink: string;
};

const checkoutSuccessProductConfiguration: Record<string, ProductConfig> = {
  'man-sku00000004': {
    applicationLink: 'https://remotedownload.rio.cloud/#archive',
    marketplaceLink: 'https://rio.cloud/en/marketplace/produkt/compliant-m',
  },
  'man-sku00000005': {
    applicationLink: 'https://perform.rio.cloud/#fleet',
    marketplaceLink: 'https://rio.cloud/en/marketplace/produkt/perform',
  },
  'rio-sku00000027': {
    applicationLink: 'https://drivingtime.rio.cloud/#realtime',
    marketplaceLink: 'https://rio.cloud/en/marketplace/produkt/timed',
  },
  'rio-sku00000047': {
    applicationLink: 'https://livemonitor.rio.cloud/#map',
    marketplaceLink: 'https://rio.cloud/en/marketplace/produkt/geo',
  },
  'man-sku00000049': {
    applicationLink: 'https://manservicecare.rio.cloud/',
    marketplaceLink: 'https://rio.cloud/en/marketplace/produkt/man-servicecare-m',
  },
  'man-sku00000050': {
    applicationLink: 'https://ota-charging.rio.cloud/#overview',
    marketplaceLink: 'https://rio.cloud/en/marketplace/produkt/man-emanager-m',
  },
  'man-sku00000066': {
    applicationLink: 'https://simplepay.rio.cloud/',
    marketplaceLink: 'https://rio.cloud/en/marketplace/produkt/man-simplepay',
  },
  'man-sku00000073': {
    applicationLink: 'https://rio.cloud/en/marketplace/produkt/man-datapackage-tigr/next-steps',
    marketplaceLink: 'https://rio.cloud/en/marketplace/produkt/man-datapackage-tigr',
  },
  'man-sku00000081': {
    applicationLink: 'https://jupiter.rio.cloud/',
    marketplaceLink: 'https://rio.cloud/en/marketplace/produkt/man-simplepay',
  },
  'man-sku00000085': {
    applicationLink: 'https://livemonitor.rio.cloud/',
    marketplaceLink: 'https://rio.cloud/en/marketplace/produkt/man-smartroute',
  },
};

export function getServiceLinkForProductId(productId?: string): string | undefined {
  if (!productId) {
    return undefined;
  }
  const lowerCaseProductId = productId.toLowerCase();

  return checkoutSuccessProductConfiguration[lowerCaseProductId]?.applicationLink;
}

export function getOpenMarketplaceProductLink(productId?: string): string | undefined {
  if (!productId) {
    return undefined;
  }
  const lowerCaseProductId = productId.toLowerCase();

  return checkoutSuccessProductConfiguration[lowerCaseProductId]?.marketplaceLink;
}

export function getOpenMarketplaceLink(): string {
  return 'https://rio.cloud/en/marketplace/overview';
}
