import { combineReducers } from 'redux';
import { apiCallReducer } from './api/redux/api.redux';
import { checkoutReducer } from './checkout/redux/checkout.redux';
import { resourceSelectionReducer } from './checkout/resourceSelection/redux/resourceSelection.redux';
import { billingReducer } from './common/billing/redux/billing.redux';
import { discountReducer } from './common/discount/redux/discount.redux';
import { onboardingReducer } from './common/onboarding/redux/oboarding.redux';
import { paymentMethodsReducer } from './common/payment/redux/paymentMethods.redux';
import { permissionsReducer } from './common/permissions/redux/permissions.redux';
import { termedLevelRenewalReducer } from './common/termedLevel/redux/termedLevelRenewal.redux';
import { assetOverviewReducer } from './customerCenter/assetOverview/redux/assetOverview.redux';
import { contractReducer } from './customerCenter/contracts/redux/contract.redux';
import { freeTrialTabReducer } from './customerCenter/freeTrial/redux/freeTrialTab.redux';
import { historyReducer } from './customerCenter/history/redux/history.redux';
import { invoicesReducer } from './customerCenter/invoices/redux/invoices.redux';
import { partnersReducer } from './customerCenter/partners/redux/partners.redux';
import { serviceOverviewReducer } from './customerCenter/serviceOverview/redux/serviceOverview.redux';
import { usageReducer } from './customerCenter/usage/redux/usage.redux';
import { freeTrialReducer } from './freeTrial/redux/freeTrial.redux';
import { paymentsReducer } from './paymentReauthentication/redux/payments.redux';

export const marketplaceReducers = combineReducers({
  api: apiCallReducer,
  resourceSelection: resourceSelectionReducer,
  onboarding: onboardingReducer,
  payment: paymentsReducer,
  paymentMethods: paymentMethodsReducer,
  services: serviceOverviewReducer,
  contract: contractReducer,
  termedLevelRenewal: termedLevelRenewalReducer,
  discount: discountReducer,
  assetOverview: assetOverviewReducer,
  invoices: invoicesReducer,
  myHistory: historyReducer,
  usage: usageReducer,
  permissions: permissionsReducer,
  checkout: checkoutReducer,
  partners: partnersReducer,
  freeTrial: freeTrialReducer,
  freeTrialTab: freeTrialTabReducer,
  billing: billingReducer,
});
