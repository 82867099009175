import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { PaymentElement, useElements } from '@stripe/react-stripe-js';
import type { SetupIntentResult, Stripe } from '@stripe/stripe-js';
import type React from 'react';
import { useEffect, useState } from 'react';
import type { IntlShape } from 'react-intl';
import { useSelector } from 'react-redux';
import { getAccountId } from '../../../../configuration';
import { sendError } from '../../../../configuration/lang/services';
import { reportErrorToSentry } from '../../../../configuration/setup/sentry';
import { UnreachableCaseError } from '../../../../utils/typescriptUtil';
import { useGetAccountByIdQuery } from '../../api/accounts/accountApiSlice';
import { PaymentMethodType } from '../../api/paymentMethods/paymentMethodTypes.types';
import { LegalBanner } from './LegalBanner';
import type { StripePaymentMethod } from './PaymentElementContainer';
import { StripeCreatePaymentButton } from './StripeCreatePaymentButton';
import type { PaymentMethod } from './redux/types';

const mapPaymentMethodType = (selectedPaymentMethod: StripePaymentMethod): PaymentMethodType => {
  switch (selectedPaymentMethod) {
    case 'card':
      return PaymentMethodType.CREDIT_CARD;
    case 'sepa_debit':
      return PaymentMethodType.SEPA;
    default:
      throw new UnreachableCaseError(selectedPaymentMethod);
  }
};

interface Props {
  selectedPaymentMethod: 'card' | 'sepa_debit';
  stripe: Stripe;
  publishPaymentInformation: (payment: { id?: string; type: PaymentMethodType }) => void;
  fetchStripeClientSecret: () => void;
  stripeClientSecret?: string;
  paymentInformation: PaymentMethod | undefined;
  intl: IntlShape;
}

export const RioPaymentMethod: React.FC<Props> = props => {
  const {
    stripe,
    selectedPaymentMethod,
    publishPaymentInformation,
    fetchStripeClientSecret,
    stripeClientSecret,
    paymentInformation,
    intl,
  } = props;
  const [error, setError] = useState<string>();
  const [inProgress, setInProgress] = useState(false);
  const elements = useElements();
  const [paymentElementReady, setPaymentElementReady] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const paymentMethodType = mapPaymentMethodType(selectedPaymentMethod);
  const paymentMethodTypeErrorMessage =
    paymentMethodType === PaymentMethodType.CREDIT_CARD
      ? 'marketplace.payment.creditCard.error'
      : 'marketplace.payment.sepa.error';
  const confirmButtonMessageId = paymentInformation
    ? 'marketplace.payment.method.update.button'
    : 'marketplace.payment.method.confirm.button';

  useEffect(() => {
    fetchStripeClientSecret();
  }, []);

  useEffect(() => {
    setError(undefined);
  }, [selectedPaymentMethod]);

  const accountId = useSelector(getAccountId);

  const { data } = useGetAccountByIdQuery({ accountId: accountId! });

  const handleSubmit = async (event: React.MouseEvent) => {
    event.preventDefault();

    if (!elements || inProgress) {
      if (!elements) {
        reportErrorToSentry('No elements found though it was expected');
      }
      return;
    }

    setInProgress(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setError(intl.formatMessage({ id: paymentMethodTypeErrorMessage }));
      setInProgress(false);
      return;
    }

    if (!stripeClientSecret || !stripe) {
      throw new Error('Stripe secret was not retrieved');
    }

    await stripe
      .confirmSetup({
        elements,
        clientSecret: stripeClientSecret,
        confirmParams: {
          return_url: 'https://marketplace.rio.cloud/customerCenter/paymentmethods',
          payment_method_data: {
            billing_details: {
              email: 'finance@rio.cloud',
              address: {
                country: data?.legalAddress.countryCode,
                state: null,
                city: null,
                postal_code: null,
                line1: data?.legalAddress.line1,
                line2: null,
              },
            },
          },
        },
        redirect: 'if_required',
      })
      .then(
        result => {
          handleStripePaymentResponse(result);
        },
        errorResponse => {
          setError(intl.formatMessage({ id: paymentMethodTypeErrorMessage }));
          sendError(JSON.stringify(errorResponse?.error));
          setInProgress(false);
        }
      );
  };

  const handleStripePaymentResponse = (result: SetupIntentResult) => {
    if (result.error || !result.setupIntent || result.setupIntent.status !== 'succeeded') {
      setError(intl.formatMessage({ id: paymentMethodTypeErrorMessage }));
      setInProgress(false);
      return;
    }
    setInProgress(false);
    publishPaymentInformation({
      id:
        typeof result.setupIntent.payment_method === 'string'
          ? result.setupIntent.payment_method
          : result.setupIntent.payment_method?.id,
      type: paymentMethodType,
    });
  };

  if (!stripeClientSecret) {
    return (
      <div className='padding-20'>
        <Spinner text={''} isInverse={false} />
      </div>
    );
  }

  return (
    <div>
      <PaymentElement
        onReady={() => setPaymentElementReady(true)}
        onChange={event => setEnableSubmit(event.complete)}
        options={{
          terms: {
            card: 'never',
            sepaDebit: 'never',
          },
          fields: {
            billingDetails: {
              name: 'auto',
              email: 'never',
              address: 'never',
            },
          },
        }}
      />
      {error && <div className='alert alert-danger margin-top-5'>{error}</div>}
      {paymentElementReady && (
        <>
          <LegalBanner type={selectedPaymentMethod} />
          <div className='form-group text-right'>
            <StripeCreatePaymentButton
              confirmMessageId={confirmButtonMessageId}
              loadingMessageId='marketplace.payment.method.inProgress'
              isInProgress={inProgress}
              onClick={event => handleSubmit(event)}
              disabled={!enableSubmit}
            />
          </div>
        </>
      )}
    </div>
  );
};
