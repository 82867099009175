import type { FormatDateOptions, IntlShape } from 'react-intl';
import { MAX_NUMBER_OF_USAGE_MONTHS } from './UsageMonthSelectorContainer';
import type { UsageSelectOption } from './types';

const dateFormat: FormatDateOptions = {
  year: 'numeric',
  month: 'long',
};

interface YearAndMonth {
  year: number;
  month: number;
  marked?: boolean;
}

const createOption = (yearAndMonth: YearAndMonth, intl: IntlShape): UsageSelectOption => {
  // Marker for the current month to disclaim, that usages are only updates once a day
  const marker = yearAndMonth?.marked ? ' **' : '';
  return {
    id: `${yearAndMonth.year}-${yearAndMonth.month}`,
    label: `${intl.formatDate(new Date(yearAndMonth.year, yearAndMonth.month - 1), dateFormat)}${marker}`,
    month: yearAndMonth.month,
    year: yearAndMonth.year,
  };
};

export function getYearsAndMonths(year: number, month: number, startYear: number, startMonth: number): YearAndMonth[] {
  let yearIterator = year;
  let monthIterator = month;
  let counter = 0;

  const yearsAndMonths: YearAndMonth[] = [];

  while (counter < MAX_NUMBER_OF_USAGE_MONTHS && !(yearIterator === startYear && monthIterator === startMonth)) {
    yearsAndMonths.push({
      year: yearIterator,
      month: monthIterator,
      marked: yearIterator === year && monthIterator === month ? true : undefined,
    });
    monthIterator--;
    counter++;
    if (monthIterator === 0) {
      monthIterator = 12;
      yearIterator--;
    }
  }
  yearsAndMonths.push({ year: yearIterator, month: monthIterator });
  return yearsAndMonths;
}

export const getUsageSelectionItems = (intl: IntlShape): UsageSelectOption[] => {
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const startYear = 2019;
  const startMonth = 8;

  const yearsAndMonths = getYearsAndMonths(year, month, startYear, startMonth);
  return yearsAndMonths.map(it => createOption(it, intl));
};
