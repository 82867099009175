import { naturalSortByProperty } from '@rio-cloud/rio-uikit/lib/es/SortUtils';
import { UnreachableCaseError } from '../../../../utils/typescriptUtil';
import { type TermedLevel, TermedLevelState } from '../../common/termedLevel/types';
import { convertApiProductState } from '../asset/assetMapper';
import { getPeriodLength, getPeriodUnit } from '../periods';
import { enhanceContract } from './ContractEnhancer';
import {
  API_CONTRACT_STATE_ACTIVE,
  API_CONTRACT_STATE_PENDING,
  type ApiContractState,
  type ApiContracts,
} from './contract.types';

const convertApiContractState = (apiState: ApiContractState): TermedLevelState => {
  switch (apiState) {
    case API_CONTRACT_STATE_ACTIVE:
      return TermedLevelState.ACTIVE;
    case API_CONTRACT_STATE_PENDING:
      return TermedLevelState.PENDING;
    default:
      throw new UnreachableCaseError(apiState);
  }
};

export function mapApiContracts() {
  return (apiContracts: ApiContracts): TermedLevel[] => {
    const contracts = apiContracts.contracts
      .map(apiContract => ({
        resourceId: apiContract.resource_id,
        resourceType: apiContract.resource_type,
        resourceName: apiContract.resource_name,
        productId: apiContract.product_id,
        productName: apiContract.product_name,
        productLevelName: apiContract.product_level_name,
        variantName: apiContract.product_variant_name,
        paid: apiContract.paid,
        level: apiContract.level,
        state: convertApiContractState(apiContract.state),
        activationState: convertApiProductState(apiContract.activation_state),
        periodLength: getPeriodLength(apiContract.period),
        periodUnit: getPeriodUnit(apiContract.period),
        startsAt: apiContract.starts_at,
        endsAt: apiContract.ends_at,
        renewsToLevel: apiContract.renews_to?.level,
        renewsToLevelName: apiContract.renews_to?.product_level_name ?? '',
        renewsToLevelVariantName: apiContract.renews_to?.product_variant_name,
        renewsToLevelContractPeriodUnit: apiContract.renews_to?.period
          ? getPeriodUnit(apiContract.renews_to.period)
          : undefined,
        renewsToLevelContractPeriodLength: apiContract.renews_to?.period
          ? getPeriodLength(apiContract.renews_to.period)
          : undefined,
      }))
      .map(enhanceContract);
    return naturalSortByProperty(contracts, 'resourceName');
  };
}
