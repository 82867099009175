import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../../../../../configuration/setup/store';
import { getUsageOverallCurrency, getUsageProducts } from '../redux/usage.redux';
import { type UsageSummaryColumn, UsageSummaryColumnType } from './types';

export const getUsageSummaryColumns: (state: RootState) => UsageSummaryColumn[] = createSelector(
  getUsageOverallCurrency,
  getUsageProducts,
  (overallCurrency, products) => {
    const columns: UsageSummaryColumn[] = [];

    products.forEach(product => {
      if (columns.length !== 0) {
        columns.push({
          type: UsageSummaryColumnType.SPACER_COLUMN,
          symbol: overallCurrency ? '+' : '',
        });
      }
      columns.push({
        productName: product.productName,
        productSku: product.productSku,
        type: UsageSummaryColumnType.PRODUCT_COLUMN,
        ratePlanId: product.ratePlanId,
        variantName: product.variantName,
        contractPeriod: product.contractPeriod,
        level: product.level,
      });
    });
    return columns;
  }
);
