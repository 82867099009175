import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router';
import { getFeatureEnabled } from '../../configuration/featureToggle/featureToggleService';
import { MAINTENANCE } from '../../configuration/featureToggle/toggles';
import type { RootState } from '../../configuration/setup/store';
import { CheckoutContainer } from './checkout/Checkout';
import { MaintenanceView } from './common/MaintenanceView';
import {
  MARKETPLACE_CHECKOUT_PATH,
  MARKETPLACE_CUSTOMER_CENTER_PATH,
  MARKETPLACE_PAYMENTS_AUTHENTICATION,
  MARKETPLACE_PRODUCT_DETAILS_PATH,
} from './common/routes';
import { CustomerCenterContainer } from './customerCenter/CustomerCenter';
import { ProductDetailsRedirect } from './openMarketplaceRedirects/ProductDetailsRedirect';
import ReauthenticationPageStripeContainer from './paymentReauthentication/ReauthenticationPageStripeContainer';

interface Props {
  maintenanceMode: boolean;
}

// Define routing to sub modules
const MarketplaceRoutes = (props: Props) => {
  const { maintenanceMode } = props;
  return (
    <Routes>
      <Route
        path={`${MARKETPLACE_CUSTOMER_CENTER_PATH}/*`}
        element={maintenanceMode ? <MaintenanceView /> : <CustomerCenterContainer />}
      />
      <Route
        path={`${MARKETPLACE_PAYMENTS_AUTHENTICATION}`}
        element={maintenanceMode ? <MaintenanceView /> : <ReauthenticationPageStripeContainer />}
      />
      <Route path={`${MARKETPLACE_PRODUCT_DETAILS_PATH}/*`} element={<ProductDetailsRedirect />} />
      <Route
        path={`${MARKETPLACE_CHECKOUT_PATH}/*`}
        element={maintenanceMode ? <MaintenanceView /> : <CheckoutContainer />}
      />
      <Route path='*' element={<Navigate to={`/${MARKETPLACE_CUSTOMER_CENTER_PATH}`} />} />
    </Routes>
  );
};
const mapStateToProps = (state: RootState) => ({
  maintenanceMode: getFeatureEnabled(state, MAINTENANCE),
});
export const MarketplaceContent = connect(mapStateToProps)(MarketplaceRoutes);
