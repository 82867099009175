import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../../../configuration/setup/store';
import type { ResourceType, UsageState, UsageSummary } from './types';

const initialState: UsageState = {
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
  products: [],
  resourceUsages: [],
  overallCurrency: undefined,
  totalSum: undefined,
  searchTerm: '',
  filteredResourceTypes: ['asset', 'user'],
};

export const { reducer: usageReducer, actions: usageActions } = createSlice({
  name: 'usage',
  initialState,
  reducers: {
    setYearAndMonth: (state: UsageState, action: PayloadAction<{ year: number; month: number }>) => {
      state.year = action.payload.year;
      state.month = action.payload.month;
    },
    setUsageSummaries: (state: UsageState, action: PayloadAction<UsageSummary>) => {
      state.products = action.payload.products;
      state.resourceUsages = action.payload.resourceUsages;
    },
    setOverallCurrency: (state: UsageState, action: PayloadAction<string | undefined>) => {
      state.overallCurrency = action.payload;
    },
    setTableSearch: (state: UsageState, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setFilteredResourceTypes: (state: UsageState, action: PayloadAction<ResourceType[]>) => {
      state.filteredResourceTypes = action.payload;
    },
  },
});

const getBase = (state: RootState): UsageState => state.marketplace.usage;
export const getUsageYear = (state: RootState) => getBase(state).year;
export const getUsageMonth = (state: RootState) => getBase(state).month;
export const getUsageProducts = (state: RootState) => getBase(state).products;
export const getResourceUsages = (state: RootState) => getBase(state).resourceUsages;
export const getUsageOverallCurrency = (state: RootState) => getBase(state).overallCurrency;
export const getUsageSearchTerm = (state: RootState) => getBase(state).searchTerm;
export const getFilteredResourceTypes = (state: RootState) => getBase(state).filteredResourceTypes;
