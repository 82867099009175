import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../../../configuration/setup/store';
import type { DatedInvoices, InvoicesState } from './types';

const initialState: InvoicesState = {
  invoices: [],
  downloadId: undefined,
};

export const { reducer: invoicesReducer, actions: invoicesActions } = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setDatedInvoices: (state: InvoicesState, action: PayloadAction<DatedInvoices[]>) => {
      state.invoices = action.payload;
    },
    setDownloadingInvoiceId: (state: InvoicesState, action: PayloadAction<string | undefined>) => {
      state.downloadId = action.payload;
    },
  },
});

const getBase = (state: RootState): InvoicesState => state.marketplace.invoices;
export const getInvoices = (state: RootState) => getBase(state).invoices;
export const getInvoiceDownloadId = (state: RootState) => getBase(state).downloadId;
