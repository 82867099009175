export function CompanySettingsTab() {
  return (
    <iframe
      src='https://users.iam.rio.cloud/#/company-settings'
      title='Company Settings'
      className={'unstyled full-window'}
    />
  );
}

export const CompanySettingsTabContainer = CompanySettingsTab;
