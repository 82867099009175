import { getAccountId } from '../../../../configuration';
import { sendError } from '../../../../configuration/lang/services';
import type { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { apiCallAction } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { postSubscriptionCreate } from '../../api/subscription/subscriptionCalls';
import { getDiscounts } from '../../common/discount/redux/discount.redux';
import { getTotalPrices } from '../../common/price/priceService';
import { safeDataLayerPush } from '../../common/utils/googleTagManagerWrapper';
import { getGAEventPurchase } from '../checkoutGTMUtils';
import { getSelectedProductOrProductLevel } from '../redux/checkout.redux';
import type { BookableProduct } from '../redux/types';
import { getSelectedResourceIds, resourceSelectionActions } from '../resourceSelection/redux/resourceSelection.redux';
import { fetchBookableResourcesThunk } from '../resourceSelection/resourceSelection.thunk';

export const subscribeThunk = (dispatch: RootDispatch, getState: () => RootState): Promise<void> => {
  dispatch(apiCallAction(ApiCalls.SUBSCRIPTION_CREATE, true, false));

  const resourceIds = getSelectedResourceIds(getState());
  const selectedResourceIds = getSelectedResourceIds(getState());
  const accountId = getAccountId(getState());
  const discounts = getDiscounts(getState());

  let product: BookableProduct;
  try {
    product = getSelectedProductOrProductLevel(getState());
  } catch (e) {
    sendError(e);
    dispatch(apiCallAction(ApiCalls.SUBSCRIPTION_CREATE, false, true));
    return Promise.resolve();
  }

  const productWithPrice = {
    sku: product.sku,
    level: product.level,
    price: product.price,
  };

  return postSubscriptionCreate(resourceIds, product)
    .then(() => {
      safeDataLayerPush(() =>
        getGAEventPurchase(
          product,
          selectedResourceIds.length,
          getTotalPrices(discounts, productWithPrice, selectedResourceIds).totalPrice,
          accountId
        )
      );
    })
    .then(() => {
      dispatch(apiCallAction(ApiCalls.SUBSCRIPTION_CREATE, false));
      dispatch(resourceSelectionActions.resetResourceSelectionState());
      dispatch(fetchBookableResourcesThunk);
    })
    .catch(() => {
      dispatch(apiCallAction(ApiCalls.SUBSCRIPTION_CREATE, false, true));
    });
};
