import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import type { RootState } from '../../../../configuration/setup/store';
import { useProductTypeAwareIntl } from './ProductTypeAwareFormattedMessage';
import { getResourceSearchTerm, resourceSelectionActions } from './redux/resourceSelection.redux';

interface Props {
  searchTerm: string;
  onSearchChange: (searchTerm: string) => void;
}

const ResourceSelectionSearch = (props: Props) => {
  const { searchTerm, onSearchChange } = props;
  const intl = useProductTypeAwareIntl();

  return (
    <TableSearch
      placeholder={intl.formatMessage({ id: 'searchPlaceholder' })}
      value={searchTerm}
      onChange={onSearchChange}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    searchTerm: getResourceSearchTerm(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onSearchChange: (searchTerm: string) => dispatch(resourceSelectionActions.setResourceSearchTerm(searchTerm)),
  };
};

export const ResourceSelectionSearchContainer = connect(mapStateToProps, mapDispatchToProps)(ResourceSelectionSearch);
