import defaultTo from 'lodash/fp/defaultTo';
import flow from 'lodash/fp/flow';
import has from 'lodash/fp/has';
import head from 'lodash/fp/head';
import split from 'lodash/fp/split';

const DEFAULT_LOCALE = 'en-GB';

const supportedLocaleMap: Record<string, string> = {
  'bg-BG': 'bg-BG',
  'cs-CZ': 'cs-CZ',
  'da-DK': 'da-DK',
  'de-DE': 'de-DE',
  'el-GR': 'el-GR',
  'en-GB': 'en-GB',
  'es-ES': 'es-ES',
  'et-EE': 'et-EE',
  'fi-FI': 'fi-FI',
  'fr-FR': 'fr-FR',
  'hr-HR': 'hr-HR',
  'hu-HU': 'hu-HU',
  'ko-KR': 'ko-KR',
  'it-IT': 'it-IT',
  'lt-LT': 'lt-LT',
  'lv-LV': 'lv-LV',
  'nb-NO': 'nb-NO',
  'nl-NL': 'nl-NL',
  'pl-PL': 'pl-PL',
  'pt-BR': 'pt-BR',
  'pt-PT': 'pt-PT',
  'ro-RO': 'ro-RO',
  'sk-SK': 'sk-SK',
  'sl-SI': 'sl-SI',
  'sv-SE': 'sv-SE',

  bg: 'bg-BG',
  cs: 'cs-CZ',
  da: 'da-DK',
  de: 'de-DE',
  el: 'el-GR',
  en: 'en-GB',
  es: 'es-ES',
  et: 'et-EE',
  fi: 'fi-FI',
  fr: 'fr-FR',
  hr: 'hr-HR',
  hu: 'hu-HU',
  ko: 'ko-KR',
  it: 'it-IT',
  lt: 'lt-LT',
  lv: 'lv-LV',
  nb: 'nb-NO',
  nl: 'nl-NL',
  pl: 'pl-PL',
  pt: 'pt-PT',
  ro: 'ro-RO',
  sk: 'sk-SK',
  sl: 'sl-SI',
  sv: 'sv-SE',
};

const extractLanguage = flow(defaultTo(DEFAULT_LOCALE), split('-'), head);

const DEFAULT_LANG = extractLanguage(DEFAULT_LOCALE);

const getSupportedLocale = (preferredLocale: string): string =>
  has(preferredLocale, supportedLocaleMap) ? supportedLocaleMap[preferredLocale] : DEFAULT_LOCALE;

export { DEFAULT_LANG, DEFAULT_LOCALE, extractLanguage, supportedLocaleMap, getSupportedLocale };
