import classnames from 'classnames';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { UnreachableCaseError } from '../../../../utils/typescriptUtil';
import { PaymentMethodType } from '../../api/paymentMethods/paymentMethodTypes.types';
import { safeDataLayerPush } from '../utils/googleTagManagerWrapper';
import { BankTransferPayment } from './BankTransferPayment';
import { PaymentInformation } from './PaymentInformation';
import { StripePaymentFormContainer } from './StripePaymentFormContainer';
import { getGAPaymentType } from './paymentMethodsGTMUtils';
import type { PaymentMethod } from './redux/types';

function pushToGA({ paymentType }: { paymentType?: PaymentMethodType } = {}) {
  if (paymentType) {
    const createEvent = () => ({
      event: 'PaymentMethodSelected',
      eventPayload: {
        paymentType: getGAPaymentType(paymentType),
      },
    });
    safeDataLayerPush(createEvent);
  }
}

interface Props {
  paymentInformation?: PaymentMethod;
  paymentMethodTypes: PaymentMethodType[];
  setEditMode: (isEditMode: boolean) => void;
  isEditPayment: boolean;
}

type OwnState = { paymentMethodType?: PaymentMethodType; isSubmittingToStripe: boolean };

function disableOrEmpty(disableNoneActiveTabs: boolean) {
  return disableNoneActiveTabs ? 'disabled' : '';
}

export class PaymentMethodPage extends Component<Props, OwnState> {
  constructor(props: Props) {
    super(props);
    if (props.paymentInformation) {
      this.state = {
        paymentMethodType: props.paymentInformation.paymentType,
        isSubmittingToStripe: false,
      };
    } else {
      this.state = {
        paymentMethodType: PaymentMethodType.CREDIT_CARD,
        isSubmittingToStripe: false,
      };
      this.props.setEditMode(true);
    }
    this.setSubmittingToStripe = this.setSubmittingToStripe.bind(this);
  }

  componentWillUnmount() {
    this.props.setEditMode(false);
  }

  setSubmittingToStripe(isSubmittingToStripe: boolean) {
    this.setState({ isSubmittingToStripe });
  }

  setPaymentMethodType(paymentMethodType: PaymentMethodType) {
    pushToGA({ paymentType: paymentMethodType });
    this.setState({ paymentMethodType });
  }

  handleShowCurrentPaymentMethod() {
    this.props.setEditMode(false);
    this.setState({
      paymentMethodType: this.props.paymentInformation?.paymentType,
    });
  }

  renderPaymentForm(paymentMethod?: PaymentMethodType, paymentInformation?: PaymentMethod) {
    if (paymentMethod === PaymentMethodType.BANK_TRANSFER) {
      return (
        <div className='max-width-700'>
          <BankTransferPayment />
        </div>
      );
    }
    return (
      <div className='max-width-700'>
        <StripePaymentFormContainer
          paymentMethod={paymentMethod}
          paymentInformation={paymentInformation}
          setSubmittingToStripe={this.setSubmittingToStripe}
        />
      </div>
    );
  }

  renderPaymentInformation(paymentInformation?: PaymentMethod) {
    return (
      <div>
        <h4>
          <FormattedMessage id='marketplace.payment.information.header.current' />
        </h4>
        <PaymentInformation paymentInformation={paymentInformation} />
      </div>
    );
  }

  renderPaymentTabs({
    paymentMethodType,
    disableNoneActiveTabs,
    paymentMethodTypes,
  }: {
    paymentMethodType?: PaymentMethodType;
    disableNoneActiveTabs: boolean;
    paymentMethodTypes: PaymentMethodType[];
  }) {
    const paymentTypeProperties1 = paymentMethodTypes.map(type => {
      switch (type) {
        case PaymentMethodType.SEPA:
          return {
            type,
            className: 'tabSepa cursor-pointer',
            headerId: 'marketplace.payment.method.sepa',
          };
        case PaymentMethodType.CREDIT_CARD:
          return {
            type,
            className: 'tabCreditCard cursor-pointer',
            headerId: 'marketplace.payment.method.creditCard',
          };
        case PaymentMethodType.BANK_TRANSFER:
          return {
            type,
            className: 'tabBankTransfer cursor-pointer',
            headerId: 'marketplace.payment.method.bankTransfer',
          };
        default:
          throw new UnreachableCaseError(type);
      }
    });

    return (
      <ul className='nav nav-tabs flex-wrap'>
        {paymentTypeProperties1.map((it, index) => {
          return (
            <li
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              key={index}
              className={`${it.className} ${
                paymentMethodType === it.type ? 'active' : disableOrEmpty(disableNoneActiveTabs)
              }`}
              role='presentation'
            >
              {/* biome-ignore lint/a11y/useValidAnchor: <explanation> */}
              <a onClick={() => !disableNoneActiveTabs && this.setPaymentMethodType(it.type)}>
                <FormattedMessage id={it.headerId} />
              </a>
            </li>
          );
        })}
        <div className='display-flex justify-content-end flex-1-1'>
          {this.props.isEditPayment ? this.renderShowCurrentPaymentButton() : this.renderEditPaymentButton()}
        </div>
      </ul>
    );
  }

  renderEditPaymentButton() {
    return (
      // biome-ignore lint/a11y/useButtonType: <explanation>
      <button
        key='editPayment'
        className={'changePayment btn btn-default'}
        onClick={() => this.props.setEditMode(true)}
      >
        <FormattedMessage id='marketplace.payment.button.changePayment' />
      </button>
    );
  }

  renderShowCurrentPaymentButton() {
    if (!this.props.paymentInformation) {
      return null;
    }
    const disabled = this.state.isSubmittingToStripe;

    return (
      // biome-ignore lint/a11y/useButtonType: <explanation>
      <button
        key='showCurrentPayment'
        className={classnames('showCurrentPayment', 'btn', 'btn-default', disabled && 'disabled')}
        onClick={() => !disabled && this.handleShowCurrentPaymentMethod()}
      >
        <span className='rioglyph rioglyph-revert' />
        <FormattedMessage id='marketplace.payment.button.showCurrentPayment' />
      </button>
    );
  }

  renderPaymentMethodPageBody({
    paymentInformation,
    paymentMethodType,
    paymentMethodTypes,
  }: Pick<Props, 'paymentInformation' | 'paymentMethodTypes'> & { paymentMethodType?: PaymentMethodType }) {
    // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
    let paymentContent;

    if (!this.props.isEditPayment) {
      paymentContent = this.renderPaymentInformation(paymentInformation);
    } else {
      paymentContent = this.renderPaymentForm(paymentMethodType, paymentInformation);
    }

    const disableNoneActiveTabs = !!paymentInformation && !this.props.isEditPayment;

    return (
      <div>
        {this.renderPaymentTabs({
          paymentMethodType,
          disableNoneActiveTabs,
          paymentMethodTypes,
        })}
        <div className='tab-content padding-top-25 display-flex flex-column align-items-center'>{paymentContent}</div>
      </div>
    );
  }

  render() {
    return (
      <div className='PaymentMethodPage'>
        {this.renderPaymentMethodPageBody({
          paymentInformation: this.props.paymentInformation,
          paymentMethodType: this.state.paymentMethodType,
          paymentMethodTypes: this.props.paymentMethodTypes,
        })}
      </div>
    );
  }
}
