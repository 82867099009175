export enum FreeTrialStatus {
  ELIGIBLE = 0,
  INELIGIBLE = 1,
  ACTIVE = 2,
}

export interface FreeTrial {
  status: FreeTrialStatus;
  endsAt?: Date;
  products?: FreeTrialProduct[];
}

export interface FreeTrialProduct {
  productId: string;
  level: string;
}

export interface FreeTrialState {
  freeTrialStatus: FreeTrialStatus;
  freeTrialEndDate?: string;
  products?: FreeTrialProduct[];
}
