import { UnreachableCaseError } from '../../../../utils/typescriptUtil';
import { ProductState } from '../../checkout/resourceSelection/redux/types';
import { sortingByName } from '../../common/utils/sortUtils';
import type { OverviewResource } from '../../customerCenter/serviceOverview/redux/types';
import {
  API_PRODUCT_STATE_ACTIVE,
  API_PRODUCT_STATE_INACTIVE,
  API_PRODUCT_STATE_PENDING_ACTIVATION,
  API_PRODUCT_STATE_PENDING_CANCELLATION,
  type ApiActivations,
  type ApiProductState,
} from './asset.types';

export function convertApiProductState(apiState: ApiProductState): ProductState {
  switch (apiState) {
    case API_PRODUCT_STATE_ACTIVE:
      return ProductState.ACTIVE;
    case API_PRODUCT_STATE_INACTIVE:
      return ProductState.INACTIVE;
    case API_PRODUCT_STATE_PENDING_ACTIVATION:
      return ProductState.PENDING_ACTIVATION;
    case API_PRODUCT_STATE_PENDING_CANCELLATION:
      return ProductState.PENDING_CANCELLATION;
    default:
      throw new UnreachableCaseError(apiState);
  }
}

export function mapAssetActivationsResponse(apiAssetActivations: ApiActivations): OverviewResource[] {
  const activations = apiAssetActivations.activations.map(asset => ({
    id: asset.resource_id,
    name: asset.resource_name ? asset.resource_name : asset.resource_id,
    originLevel: asset.origin_level,
    targetLevel: asset.target_level,
    productState: convertApiProductState(asset.activation_state),
  }));

  return activations.sort(sortingByName);
}
