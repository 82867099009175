import TableToolbar from '@rio-cloud/rio-uikit/lib/es/TableToolbar';
import { HistoryDateRangeContainer } from './HistoryDateRangeContainer';
import { HistoryTableSearchContainer } from './HistoryTableSearchContainer';
import { ProductSelectContainer } from './ProductSelectContainer';

export function HistoryTableToolbar() {
  return (
    <TableToolbar>
      <div className='table-toolbar-container'>
        <div className='table-toolbar-group-left'>
          <div className='table-toolbar-column'>
            <HistoryDateRangeContainer />
          </div>
          <div className='table-toolbar-column min-width-400'>
            <ProductSelectContainer />
          </div>
        </div>
        <div className='table-toolbar-group-right'>
          <div className='table-toolbar-column'>
            <HistoryTableSearchContainer />
          </div>
        </div>
      </div>
    </TableToolbar>
  );
}
