import SimpleDialog from '@rio-cloud/rio-uikit/lib/es/SimpleDialog';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router';
import { MARKETPLACE_ROUTE_PATH } from '../routes';

interface Props {
  onClose?: () => void;
}

export function UnauthorizedDialog(props: Props) {
  const { onClose } = props;
  const [isClosed, setClosed] = useState(false);

  function handleClose() {
    if (onClose) {
      onClose();
    } else {
      setClosed(true);
    }
  }

  if (isClosed) {
    return <Navigate to={{ pathname: `/${MARKETPLACE_ROUTE_PATH}` }} />;
  }
  return (
    <SimpleDialog
      show={!isClosed}
      title={<FormattedMessage id='marketplace.unauthorized.dialog.title' />}
      content={<FormattedMessage id='marketplace.unauthorized.dialog.message' />}
      onClose={handleClose}
    />
  );
}
