import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';
import { FormattedMessage } from 'react-intl';
import { MaintenanceView } from '../../../common/MaintenanceView';
import { ProductTypedFormattedMessage } from '../../../common/product/ProductTypedFormattedMessage';
import type { ProductType } from '../../../common/product/product';
import type { OverviewResource } from '../redux/types';

function CancellationConfirmationDialog({
  serviceName,
  wouldCancelService,
  productType,
}: {
  serviceName: string;
  wouldCancelService: boolean;
  productType?: ProductType;
}) {
  //In case it is the last asset show the confirmation text for service cancellation
  if (wouldCancelService) {
    return (
      <div className='confirmationDialogText'>
        <ProductTypedFormattedMessage
          productType={productType}
          assetBasedId={'marketplace.myServices.subscription.update.assets.and.service.message'}
          userBasedId={'marketplace.myServices.subscription.update.users.and.service.message'}
          values={{ serviceName: serviceName }}
        />{' '}
        <FormattedMessage id={'marketplace.myServices.subscription.update.warning'} />
      </div>
    );
  }
  return (
    <div className='confirmationDialogText'>
      <ProductTypedFormattedMessage
        productType={productType}
        assetBasedId={'marketplace.myServices.subscription.update.assets.confirmation.message'}
        userBasedId={'marketplace.myServices.subscription.update.users.confirmation.message'}
        values={{ serviceName: serviceName }}
      />{' '}
      <ProductTypedFormattedMessage
        productType={productType}
        userBasedId='marketplace.myServices.subscription.update.warning.users'
        assetBasedId='marketplace.myServices.subscription.update.warning.assets'
      />
    </div>
  );
}

interface Props {
  show: boolean;
  isMaintenanceModeEnabled?: boolean;
  serviceName: string;
  selectedResources: OverviewResource[];
  wouldCancelService: boolean;
  onClickConfirmCallback: () => void;
  onClickCancelCallback: () => void;
  productType?: ProductType;
}

export const CancelResourceSubscriptionsDialog = (props: Props) => {
  const title = (
    <FormattedMessage
      id={'marketplace.myServices.subscription.update.confirmation.headline'}
      values={{ serviceName: props.serviceName }}
    />
  );

  const content = props.isMaintenanceModeEnabled ? (
    <MaintenanceView />
  ) : (
    <div>
      <CancellationConfirmationDialog
        wouldCancelService={props.wouldCancelService}
        serviceName={props.serviceName}
        productType={props.productType}
      />
      {props.wouldCancelService ? (
        <h5>
          <ProductTypedFormattedMessage
            productType={props.productType}
            assetBasedId={'marketplace.myServices.subscription.cancelAllAssets'}
            userBasedId={'marketplace.myServices.subscription.cancelAllUsers'}
          />
        </h5>
      ) : (
        <div>
          <br />
          <h5>
            <ProductTypedFormattedMessage
              productType={props.productType}
              userBasedId='marketplace.myServices.subscription.update.confirmation.userList'
              assetBasedId='marketplace.myServices.subscription.update.confirmation.assetList'
            />
          </h5>
          <ul className='UpdateSubscriptionResourcesDialog-assetList max-height-50vh overflow-auto'>
            {props.selectedResources.map(item => (
              <li key={item.id}>{item.name}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );

  const getConfirmButtonText = () => {
    if (props.wouldCancelService) {
      return <FormattedMessage id={'marketplace.myServices.subscription.cancel.confirmation.button.confirm'} />;
    }
    return <FormattedMessage id={'marketplace.myServices.subscription.update.confirmation.button.confirm'} />;
  };

  const cancelButtonText = (
    <FormattedMessage id={'marketplace.myServices.subscription.update.confirmation.button.abort'} />
  );

  return (
    <ConfirmationDialog
      show={props.show}
      title={title}
      content={content}
      onClickConfirm={() => {
        if (!props.isMaintenanceModeEnabled) {
          props.onClickConfirmCallback();
        }
      }}
      onClickCancel={() => props.onClickCancelCallback()}
      cancelButtonText={cancelButtonText}
      confirmButtonText={getConfirmButtonText()}
      useOverflow={false}
    />
  );
};
