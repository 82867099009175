import { ProductTypedFormattedMessage } from './product/ProductTypedFormattedMessage';
import { ProductType } from './product/product';

interface Props {
  isHidden?: boolean;
  productType: ProductType | undefined;
}

export function ExternalAdminHint({ isHidden, productType }: Props) {
  if (isHidden || !productType) {
    return null;
  }

  const externalAdministrationLink =
    productType === ProductType.ASSET_BASED
      ? 'https://administration.assets.rio.cloud/#/assets?createAssetModal=show'
      : 'https://users.iam.rio.cloud/#/users?createUserModal=show';

  return (
    <div className={'pull-left padding-top-5'}>
      <span className={'rioglyph rioglyph-info-sign margin-left-10'} />
      <span>
        {' '}
        <ProductTypedFormattedMessage
          productType={productType}
          assetBasedId='marketplace.resourceSelection.asset.externalAdmin.hint'
          userBasedId='marketplace.resourceSelection.user.externalAdmin.hint'
        />
      </span>
      <span>
        <a className={'margin-left-5'} href={externalAdministrationLink} target='_blank' rel='noopener noreferrer'>
          <ProductTypedFormattedMessage
            productType={productType}
            assetBasedId='marketplace.resourceSelection.asset.externalAdmin.link'
            userBasedId='marketplace.resourceSelection.user.externalAdmin.link'
          />
        </a>
      </span>
    </div>
  );
}
