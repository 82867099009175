import { reportErrorToSentry } from '../../../../configuration/setup/sentry';
import { dataLayerPush } from './googleTagManagerUtils';

export function safeDataLayerPush(gtmObjectFactory: () => unknown) {
  try {
    dataLayerPush(gtmObjectFactory());
  } catch (error) {
    reportErrorToSentry(error);
  }
}
