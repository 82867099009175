import { FormattedMessage } from 'react-intl';
import { PaymentMethodPageContainer } from '../../payment/PaymentMethodPageContainer';

export const RenewDialogPaymentMethodContent = () => {
  return (
    <div>
      <div className='panel panel-warning'>
        <div className='panel-body'>
          <FormattedMessage id={'marketplace.renewDialog.paymentMethod.prompt'} />
        </div>
      </div>
      <PaymentMethodPageContainer />
    </div>
  );
};
