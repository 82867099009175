import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import TableViewToggles from '@rio-cloud/rio-uikit/lib/es/TableViewToggles';
import type { RootState } from '../../../../../configuration/setup/store';
import { getAssetsByTagIds } from './tagToAssetMapper';
import type {
  AssetOverviewRow,
  AssetOverviewState,
  CancelInfo,
  HeaderProduct,
  ProductBookable,
  TableViewTogglesValues,
  Tag,
  TagToAssetsMap,
} from './types';

const initialState: AssetOverviewState = {
  selectedAssets: [],
  assetsToDisplay: undefined,
  tableColumns: [],
  tableRows: [],
  tags: [],
  tagToAssetMap: {},
  viewType: TableViewToggles.VIEW_TYPE_TABLE,
  search: '',
  showCancelDialog: false,
};

export const { reducer: assetOverviewReducer, actions: assetOverviewActions } = createSlice({
  name: 'assetOverview',
  initialState,
  reducers: {
    setTags: (state: AssetOverviewState, action: PayloadAction<Tag[]>) => {
      state.tags = action.payload;
    },
    setTagToAssetMap: (state: AssetOverviewState, action: PayloadAction<TagToAssetsMap>) => {
      state.tagToAssetMap = action.payload;
    },
    setTableRows: (state: AssetOverviewState, action: PayloadAction<AssetOverviewRow[]>) => {
      state.tableRows = action.payload;
    },
    setTableColumns: (state: AssetOverviewState, action: PayloadAction<HeaderProduct[]>) => {
      state.tableColumns = action.payload;
    },
    selectAsset: (state: AssetOverviewState, action: PayloadAction<string>) => {
      if (state.selectedAssets.includes(action.payload)) {
        state.selectedAssets = state.selectedAssets.filter(it => it !== action.payload);
      } else {
        state.selectedAssets = [...state.selectedAssets, action.payload];
      }
    },
    setSelectedAssets: (state: AssetOverviewState, action: PayloadAction<string[]>) => {
      state.selectedAssets = action.payload;
    },
    clearAssetSelection: (state: AssetOverviewState) => {
      state.selectedAssets = [];
    },
    setAssetsToDisplayByTags: (state: AssetOverviewState, action: PayloadAction<string[]>) => {
      const tags = action.payload;
      state.assetsToDisplay = getAssetsByTagIds(state.tagToAssetMap, tags);
    },
    setViewType: (state: AssetOverviewState, action: PayloadAction<TableViewTogglesValues>) => {
      state.viewType = action.payload;
    },
    setTableSearch: (state: AssetOverviewState, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    closeShowCancelDialog: (state: AssetOverviewState) => {
      state.showCancelDialog = false;
      state.cancelInfo = undefined;
    },
    openCancelDialog: (state: AssetOverviewState, action: PayloadAction<CancelInfo>) => {
      state.showCancelDialog = true;
      state.cancelInfo = action.payload;
    },
  },
});

const getBase = (state: RootState): AssetOverviewState => state.marketplace.assetOverview;

export const getTags = (state: RootState) => getBase(state).tags;
export const getTagToAssetMap = (state: RootState) => getBase(state).tagToAssetMap;
export const getAssetOverviewRows = (state: RootState) => getBase(state).tableRows;
export const getAssetOverviewColumns = (state: RootState) => getBase(state).tableColumns;
export const getAssetsIdsToDisplayInAssetOverview = (state: RootState) => getBase(state).assetsToDisplay;
export const getViewType = (state: RootState) => getBase(state).viewType;
export const getSelectedAssetIdsInAssetOverview = (state: RootState) => getBase(state).selectedAssets;
export const getTableSearch = (state: RootState) => getBase(state).search;
export const getShowCancelDialog = (state: RootState) => getBase(state).showCancelDialog;
export const getCancelDialogInfo = (state: RootState) => getBase(state).cancelInfo;

export const getProductBookable = (
  state: RootState,
  productId: string,
  level: string | undefined
): ProductBookable | undefined => {
  const column = getAssetOverviewColumns(state).find(
    it => it.productId === productId && (level ? it.levels.includes(level) : it.levels.length === 0)
  );
  return column?.bookable;
};
