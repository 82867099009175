import { DEFAULT_INVOICE_MONTH_LIMIT } from '../../config/config';
import { getMarketplaceEUBackend } from '../../config/pathURL/config';
import type { DatedInvoices } from '../../customerCenter/invoices/redux/types';
import { convertContentToBlob, decodeJson, jsonOrReject, onRejected } from '../api';
import { getParameters } from '../parameterService';
import { getRequest } from '../requests';
import { ApiInvoiceDownloadCodec, ApiInvoicesCodec } from './invoices.types';
import { mapInvoicesResponse } from './invoicesMapper';

const PDF_TYPE = 'application/pdf';

export function fetchInvoices(accountId: string): Promise<DatedInvoices[]> {
  const params = { limit_months: DEFAULT_INVOICE_MONTH_LIMIT };
  const encodedParameters = getParameters(params);
  return fetch(
    `${getMarketplaceEUBackend()}/accounts/${accountId}/financial-statements${encodedParameters}`,
    getRequest()
  )
    .then(jsonOrReject)
    .then(decodeJson(ApiInvoicesCodec))
    .then(mapInvoicesResponse)
    .catch(onRejected([403]));
}

export function fetchInvoiceDownload(accountId: string, invoiceId: string): Promise<Blob> {
  return fetch(
    `${getMarketplaceEUBackend()}/accounts/${accountId}/financial-statements/${invoiceId.trim()}`,
    getRequest()
  )
    .then(jsonOrReject)
    .then(decodeJson(ApiInvoiceDownloadCodec))
    .then(res => convertContentToBlob(res.content, PDF_TYPE))
    .catch(onRejected());
}
