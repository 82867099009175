import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AssetOverviewGotoContractsDialog } from '../../assetOverview/AssetOverviewGotoContractsDialog';

export function ManageContractsButton() {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      {showDialog && <AssetOverviewGotoContractsDialog closeDialog={() => setShowDialog(false)} />}
      <button className='btn btn-primary' onClick={() => setShowDialog(true)}>
        <FormattedMessage id={'marketplace.myServices.manageContracts'} />
      </button>
    </>
  );
}
