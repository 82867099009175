import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { getLocale } from '../../../configuration';
import type { RootState } from '../../../configuration/setup/store';
import { type Tenant, getTenant } from '../common/utils/tenants';
import { redirectIfSkuMatches } from './redirectToOpenMarketplace';

const ProductDetailsRedirectComponent = (props: { locale: string; tenant: Tenant }) => {
  const { pathname } = useLocation();
  const pathParts = pathname.split('/');
  const sku = pathParts[pathParts.length - 1];
  redirectIfSkuMatches(sku, props.locale, props.tenant);
  return null;
};

const mapStateToProps = (state: RootState) => ({
  tenant: getTenant(state),
  locale: getLocale(state),
});

export const ProductDetailsRedirect = connect(mapStateToProps)(ProductDetailsRedirectComponent);
