import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';
import classNames from 'classnames';
import { type IntlShape, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { RootState } from '../../../../configuration/setup/store';
import { MissingRequirement } from '../../common/MissingRequirement';
import { DiscountBadge } from '../../common/discount/DiscountBadge';
import { generateDiscountHint } from '../../common/discount/DiscountService';
import {
  getAccountDiscounts,
  getAssetDiscounts,
  getProductDiscounts,
} from '../../common/discount/redux/discount.redux';
import type { AssetDiscount } from '../../common/discount/redux/types';
import { OverlayTrigger, Tooltip } from '../../common/utils/tooltipReExports';
import { DEFAULT_TOOLTIP_DELAY } from '../../config/config';
import { getSelectedProductId, getSelectedProductLevel } from '../redux/checkout.redux';
import { type ProductTypeAwareFormattedMessageId, useProductTypeAwareIntl } from './ProductTypeAwareFormattedMessage';
import { type BookableResource, ProductState } from './redux/types';

export const getResourceSelectionTooltip = (
  bookableMessageId: ProductTypeAwareFormattedMessageId | undefined,
  intl: IntlShape,
  productTypeAwareIntl: ReturnType<typeof useProductTypeAwareIntl>,
  missingRequirements: string[][] | undefined,
  discount: AssetDiscount | undefined
) => {
  const technicalDetails = missingRequirements && !!missingRequirements.length && (
    <MissingRequirement intl={intl} missingRequirements={missingRequirements} />
  );

  const missingFeaturesLabel = !!technicalDetails && 'hasMissingFeatures';

  const hint = bookableMessageId && (
    <div className={'text-bold'}>{productTypeAwareIntl.formatMessage({ id: bookableMessageId })}</div>
  );

  return (
    <Tooltip id='tooltip' className={`${missingFeaturesLabel} width-auto max-width-300`}>
      {hint}
      {discount && generateDiscountHint(discount, intl)}
      {technicalDetails}
    </Tooltip>
  );
};

const getDiscountTooltip = (intl: IntlShape, discount: AssetDiscount) => {
  return (
    <Tooltip id='tooltip' className={'width-auto max-width-300'}>
      {generateDiscountHint(discount, intl)}
    </Tooltip>
  );
};

interface Props {
  resource: BookableResource;
  onSelect?: (resourceId: string) => void;
  resourceClassName?: string;
  isSelected?: boolean;
  isBooked?: boolean;
  noBookableTooltip?: boolean;
}

export const RESOURCE_SELECTED = 'rioglyph rioglyph-checkbox-checked active';
export const RESOURCE_SELECTABLE = 'rioglyph rioglyph-checkbox';
export const RESOURCE_OPEN_CONTRACT = 'rioglyph rioglyph-lock';
export const RESOURCE_BOOKED = 'rioglyph rioglyph-ok';
export const RESOURCE_NOT_BOOKABLE = 'rioglyph rioglyph-warning-sign disabled';
export const RESOURCE_PENDING_CANCELLATION = 'rioglyph rioglyph-hour-glass disabled';

const getTooltipIdAndClassName = (
  isSelected: boolean | undefined,
  isBooked: boolean | undefined,
  isBookable: boolean | undefined,
  isPendingCancellation: boolean | undefined,
  hasOpenContract: boolean | undefined
): [ProductTypeAwareFormattedMessageId | undefined, string] => {
  if (hasOpenContract && !isBookable) {
    return ['tooltipOpenContract', RESOURCE_OPEN_CONTRACT];
  }
  if (isPendingCancellation) {
    return ['tooltipPendingCancellation', RESOURCE_PENDING_CANCELLATION];
  }
  if (isBooked) {
    return ['tooltipAlreadyBooked', RESOURCE_BOOKED];
  }
  if (isSelected) {
    return [undefined, RESOURCE_SELECTED];
  }
  if (!isBookable) {
    return ['tooltipNotEligible', RESOURCE_NOT_BOOKABLE];
  }
  return [undefined, RESOURCE_SELECTABLE];
};

const ResourceSelectionItem = (props: Props & { assetDiscount?: AssetDiscount; totalDiscountPercentage: number }) => {
  const intl = useIntl();
  const productTypeAwareIntl = useProductTypeAwareIntl();
  const { resource, onSelect, isSelected, isBooked, noBookableTooltip, assetDiscount, totalDiscountPercentage } = props;
  const isPendingCancellation = resource.productState === ProductState.PENDING_CANCELLATION;
  const [bookableTooltipId, className] = getTooltipIdAndClassName(
    isSelected,
    isBooked,
    resource.bookable,
    isPendingCancellation,
    resource.hasOpenContract
  );
  const element = (
    <Tag
      className={classNames(className, 'rounded', onSelect ? 'clickable' : 'cursor-default')}
      onClick={onSelect ? () => onSelect(resource.id) : undefined}
    >
      <div className='display-flex align-items-center justify-content-between'>
        <span>{resource.name}</span>
        {assetDiscount && <DiscountBadge percentage={totalDiscountPercentage} />}
      </div>
    </Tag>
  );

  if (!assetDiscount && (noBookableTooltip || !bookableTooltipId)) {
    return element;
  }

  const tooltip =
    noBookableTooltip && assetDiscount
      ? getDiscountTooltip(intl, assetDiscount)
      : getResourceSelectionTooltip(
          bookableTooltipId,
          intl,
          productTypeAwareIntl,
          resource.missingRequirements,
          assetDiscount
        );

  return (
    <OverlayTrigger
      trigger={OverlayTrigger.TRIGGER_HOVER}
      placement='top'
      overlay={tooltip}
      delay={DEFAULT_TOOLTIP_DELAY}
    >
      {element}
    </OverlayTrigger>
  );
};

const mapStateToProps = (state: RootState, ownProps: Props) => {
  const selectedProductId = getSelectedProductId(state);
  const selectedLevel = getSelectedProductLevel(state);
  const accountDiscount = getAccountDiscounts(state);
  const productDiscount = getProductDiscounts(state).find(
    discount =>
      discount.productId.toLowerCase() === selectedProductId?.toLowerCase() &&
      selectedLevel &&
      discount.levels?.includes(selectedLevel)
  );
  const assetDiscount = getAssetDiscounts(state).find(
    discount =>
      discount.assetId === ownProps.resource.id &&
      discount.productId.toLowerCase() === selectedProductId?.toLowerCase() &&
      selectedLevel &&
      discount.productLevels?.includes(selectedLevel)
  );
  const total = assetDiscount
    ? Math.min(
        100,
        (accountDiscount.length > 0 ? accountDiscount[0].discountPercentage : 0) +
          (productDiscount ? productDiscount.discountPercentage : 0) +
          assetDiscount.discountPercentage
      )
    : 0;

  return {
    assetDiscount: assetDiscount,
    totalDiscountPercentage: total,
  };
};

export const ResourceSelectionItemContainer = connect(mapStateToProps)(ResourceSelectionItem);
